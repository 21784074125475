import React,{useEffect, useState} from "react";
import styles from "./AdminDashboard.module.css";
import { useLazyQuery } from "@apollo/client";
import * as msgConstants from "../../../common/MessageConstants";
import { Typography, } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from 'react-router-dom';
import * as routeConstants from "../../../common/RouteConstants";
import CssBaseline from "@mui/material/CssBaseline";
import { ADMIN_GET_PARTNER_DELETE_DATA, ADMIN_GET_USER_DELETE_DATA, ADMIN_GET_CLIENT_DELETE_DATA } from '../../../graphql/queries/AdminUI';
import { GET_CSET_ASSESSMENT, GET_SOFT_DELETE_ASSESSMENT } from '../../../graphql/queries/CSETAssessment';
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Alert from "../../../components/UI/Alert/Alert";

const AdminDashboard = () => {

  const moment = require("moment-timezone");
  const [partnerCount, setPartnerCount] = useState(0);
  const [partnerUserCount, setPartnerUserCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const [csetAssessmentCount, setCsetAssessmentCount] = useState(0);
  const [softDeleteAssessmentCount, setSoftDeleteAssessmentCount] = useState(0);
  const [showBackDrop, setShowBackDrop] = useState(true);
  const [formState, setFormState] = useState({
    isDelete: false,
    isSuccess: false,
    isFailed: false,
    errMessage: "",
  })
  const history = useNavigate();

  useEffect(() => {
    getDeletePartner({
      variables: {
        where: { subtype: "Partner", active_status: 3 },
        limit: 1000,
        sort: "id:desc"
      }
    })
    getDeletePartnerUser({
      variables: {
        where: { active_status: 3 },
        limit: 1000,
        sort: "id:desc"
      }
    })
    getDeleteClient({
      variables: {
        where: { subtype: "Client", active_status: 3 },
        limit: 1000,
        sort: "id:desc"
      }
    })
    getSoftDeleteAssessment({
      fetchPolicy: msgConstants.FETCH_POLICY,
    })
    let startDateOfWeekFromNow = moment().subtract(7, 'days').calendar();
    const date = new Date(startDateOfWeekFromNow);
    getLatestAssessment({
      fetchPolicy: msgConstants.FETCH_POLICY,
      variables: {
        where: {created_at_gte:date.toISOString()},
        limit: 1000,
        sort: "created_at:desc"
      }
    })
  },[])

  const  [getDeletePartner, { loading: loadPartner }]  = useLazyQuery(ADMIN_GET_PARTNER_DELETE_DATA, {
    onCompleted: (data: any) => {
      console.log("data.or",data.organizations)
      setPartnerCount(data.organizations ? data.organizations.length : 0);
    },
    fetchPolicy: "cache-and-network",
    onError: (err:any)=>{
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  });

  const [getDeletePartnerUser, { loading: loadPartnerUser }]  = useLazyQuery(ADMIN_GET_USER_DELETE_DATA, {
    onCompleted: (data: any) => {
      console.log("data.or",data.individuals)
      setPartnerUserCount(data.individuals ? data.individuals.length : 0);
    },
    fetchPolicy: "cache-and-network",
    onError: (err:any)=>{
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  });

  const [getDeleteClient, { loading: loadClient }]  = useLazyQuery(ADMIN_GET_CLIENT_DELETE_DATA, {
    onCompleted: (data: any) => {
      setClientCount(data.organizations ? data.organizations.length : 0);
    },
    fetchPolicy: "cache-and-network",
    onError: (err:any)=>{
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  });

  const [getSoftDeleteAssessment,{ loading: loadDeleteAssessment }]  = useLazyQuery(GET_SOFT_DELETE_ASSESSMENT, {
    onCompleted: (assessmentData: any) => {
      if(assessmentData?.getDeleteAssessment?.delete_assessment_data){
        setSoftDeleteAssessmentCount(assessmentData?.getDeleteAssessment?.delete_assessment_data.length);
      }else{
        setSoftDeleteAssessmentCount(0);
      }
    },
    fetchPolicy: "cache-and-network",
    onError: (err:any)=>{
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  });

  const [getLatestAssessment ,{ loading: loadCsetAssessment }]  = useLazyQuery(GET_CSET_ASSESSMENT, {
    onCompleted: (assessmentData: any) => {
      setCsetAssessmentCount(assessmentData.csetAssessments ? assessmentData.csetAssessments.length : 0);
    },
    fetchPolicy: "cache-and-network",
    onError: (err:any)=>{
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  });

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isDelete: false,
      isSuccess: false,
      isFailed: false,
      errMessage: ""
    }))
   }

  const handleClick = (event: any) => {
   if(event === "deletePartner"){
     history(routeConstants.ADMIN_PARTNER)
   }else if(event === "deletePartnerUser"){
    history(routeConstants.ADMIN_USER)
   }else if(event === "deleteClient"){
    history(routeConstants.ADMIN_CLIENT)
  }else if(event === "viewLatestAssessment"){
    history(routeConstants.ADMIN_ASSESSMENT)
   }else if(event === "deletedAssessment"){
    history(routeConstants.DELETE_ASSESSMENT,{ state:  "deleteAssessment"  })
   }
  };
  console.log(showBackDrop)
  return (
    <React.Fragment>
      <CssBaseline />
      {loadPartner || loadPartnerUser || loadClient ||  loadDeleteAssessment || loadCsetAssessment ? <SimpleBackdrop/>: null}
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography component="h5" variant="h1">
            {"Admin Menu List"}
          </Typography>
          <br />
          {formState.isFailed ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <strong>{formState.errMessage}</strong>
          </Alert>
        ) : null}
        <br />
          <Grid container spacing={3} className={styles.GridBox}>
            <Grid item xs={12} sm={4} className={styles.FilterAddWrap}>
              <div className={styles.dash_block}>
                <div className={styles.dash_head}>Partners</div>
                <div className={styles.dash_count} onClick={(event) => handleClick("deletePartner")}>{partnerCount}</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} className={styles.FilterAddWrap}>
              <div className={styles.dash_block}>
                <div className={styles.dash_head}>Partner Users</div>
                <div className={styles.dash_count} onClick={(event) => handleClick("deletePartnerUser")}>{partnerUserCount}</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} className={styles.FilterAddWrap}>
              <div className={styles.dash_block}>
                <div className={styles.dash_head}>Clients </div>
                <div className={styles.dash_count} onClick={(event) => handleClick("deleteClient")}>{clientCount}</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} className={styles.FilterAddWrap}>
              <div className={styles.dash_block}>
                <div className={styles.dash_head}>Latest Assessment</div>
                <div className={styles.dash_count} onClick={(event) => handleClick("viewLatestAssessment")}>{csetAssessmentCount}</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} className={styles.FilterAddWrap}>
              <div className={styles.dash_block}>
                <div className={styles.dash_head}>Deleted Assessment</div>
                <div className={styles.dash_count} onClick={(event) => handleClick("deletedAssessment")}>{softDeleteAssessmentCount}</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AdminDashboard;
