import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Logout from "./containers/Auth/Logout/Logout";
import * as routeConstants from "./common/RouteConstants";
import Client from "./containers/Client/Client";
import Assessment from "./containers/CSet/Assessment/Assessment";
import AssessmentInfo from "./containers/CSet/AssessmentInfo/AssessmentInfo";
import SecurityAssuranceLevel from "./containers/CSet/SecurityAssuranceLevel/SecurityAssuranceLevel";
import Standards from "./containers/CSet/Standards/Standards";
import Reports from "./containers/CSet/Reports/Report/Reports";
import ExecutiveSummary from "./containers/CSet/Reports/ReportGeneration/ExecutiveSummary/ExecutiveSummary";
import Results from "./containers/CSet/Results/Results";
import StandardSummary from "./containers/CSet/Results/StandardSummary/StandardSummary";
import Questions from "./containers/CSet/Questions/Questions";
import SiteCyberSecurityPlan from "./containers/CSet/Reports/ReportGeneration/SiteCyberSecurityPlan/SiteCyberSecurityPlan";
import SiteDetailsReport from "./containers/CSet/Reports/ReportGeneration/SiteDetailReport/SiteDetailsReport";
import SiteSummaryReport from "./containers/CSet/Reports/ReportGeneration/SiteSummaryReport/SiteSummaryReport";
import ObservationsTearOutSheet from "./containers/CSet/Reports/ReportGeneration/ObservationsTearOutSheet/ObservationsTearOutSheet";
import FirmType from "./containers/CSet/FirmType/FirmType";
import Help from "./containers/CSet/Help/Help";
import MaturityQuestions from "./containers/CSet/MaturityQuestions/MaturityQuestions";
import CMMC2Results from "./containers/CSet/CMMC2/CMMC2Results/CMMC2Results";
import CMMC2Reports from "./containers/CSet/CMMC2/CMMC2Reports/CMMC2Reports";
import DeficiencyReport from "./containers/CSet/CMMC2/CMMC2Reports/DeficiencyReport/DeficiencyReport";
import CMMC2ExecutiveSummary from "./containers/CSet/CMMC2/CMMC2Reports/ExecutiveSummary/CMMC2ExecutiveSummary";
import CMMC2Level from "./containers/CSet/CMMC2/CMMC2Level/CMMC2Level";
import AssessmentType from "./containers/CSet/AssessmentType/AssessmentType";
import RRAResults from "./containers/CSet/RRA/RRAResults/RRAResults";
import RRAReports from "./containers/CSet/RRA/RRAReports/RRAReports";
import RRAExecutiveSummary from "./containers/CSet/RRA/RRAReports/ExecutiveSummary/RRAExecutiveSummary";
import RRADeficiencyReport from "./containers/CSet/RRA/RRAReports/DeficiencyReport/RRADeficiencyReport";
import RRAReviewCommentReport from "./containers/CSet/RRA/RRAReports/CommentsAndReview/CommentsAndReview";
import CRRResults from "./containers/CSet/CRR/CRRResults/CRRResults";
import CRRReports from "./containers/CSet/CRR/CRRReports/CRRReports";
import CRRCommentAndReview from "./containers/CSet/CRR/CRRReports/CRRCommentAndReview/CommentAndReview";
import CRRDeficiencyReport from "./containers/CSet/CRR/CRRReports/DeficiencyReport/CRRDeficiencyReport";
import CRRExecutiveSummary from "./containers/CSet/CRR/CRRReports/ExecutiveSummary/CRRExecutiveSummary";
import AssessmentDemographics from "./containers/CSet/AssessmentDemographics/AssessmentDemographics";
import CRRTutorial from "./containers/Tutorials/CRRTutorial/CRRTutorial";
import CMMCTutorial from "./containers/Tutorials/CMMCTutorial/CMMCTutorial";
import RRATutorial from "./containers/Tutorials/RRATutorial/RRATutorial";
import StandardTutorial from "./containers/Tutorials/StandardTutorial/StandardTutorial";
import Layout from "./components/UI/Layout/Layout";
import Login from "./containers/Auth/Login/login";
import CommentAndReview from "./containers/CSet/CMMC2/CMMC2Reports/CommentAndReview/CommentAndReview";
import AboutSection from "./containers/AboutSection/AboutSection";

export const AuthRoutes = (
  <Routes>
    <Route
      path="/login"
      element={
        <Layout>
          <Login />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CLIENT}
      element={
        <Layout>
          <Client />
        </Layout>
      }
    />
    <Route
      path={routeConstants.ABOUTSECTION}
      element={
        <Layout>
          <AboutSection />
        </Layout>
      }
    />
    <Route
      path={routeConstants.LOGOUT_URL}
      element={
        <Layout>
          <Logout />
        </Layout>
      }
    />

    {/* -------------Routes for CSET------------ */}
    <Route
      path={routeConstants.ASSESSMENT_LIST}
      element={
        <Layout>
          <Assessment />
        </Layout>
      }
    />
    <Route
      path={routeConstants.ASSESSMENT_INFO}
      element={
        <Layout>
          <AssessmentInfo />
        </Layout>
      }
    />
    <Route
      path={`/assessmentInfo/:token/:assessmentId`}
      element={
        <Layout>
          <AssessmentInfo />
        </Layout>
      }
    />
    <Route
      path={routeConstants.SECURITY_ASSURANCE_LEVEL}
      element={
        <Layout>
          <SecurityAssuranceLevel />
        </Layout>
      }
    />
    <Route
      path={routeConstants.STANDARDS}
      element={
        <Layout>
          <Standards />
        </Layout>
      }
    />
    <Route
      path={routeConstants.RESULTS}
      element={
        <Layout>
          <Results />
        </Layout>
      }
    />
    <Route
      path={routeConstants.STANDARDSUMMARY}
      element={
        <Layout>
          <StandardSummary />
        </Layout>
      }
    />
    <Route
      path={routeConstants.QUESTIONS}
      element={
        <Layout>
          <Questions />
        </Layout>
      }
    />
    <Route
      path={routeConstants.REPORTS}
      element={
        <Layout>
          <Reports />
        </Layout>
      }
    />
    <Route
      path={routeConstants.EXECUTIVE_SUMMARY}
      element={
        <Layout>
          <ExecutiveSummary />
        </Layout>
      }
    />
    <Route
      path={routeConstants.SITE_CYBERSECURITY_PLAN}
      element={
        <Layout>
          <SiteCyberSecurityPlan />
        </Layout>
      }
    />
    <Route
      path={routeConstants.SITE_DETAIL_REPORT}
      element={
        <Layout>
          <SiteDetailsReport />
        </Layout>
      }
    />
    <Route
      path={routeConstants.SITE_SUMMARY_REPORT}
      element={
        <Layout>
          <SiteSummaryReport />
        </Layout>
      }
    />
    <Route
      path={routeConstants.OBSERVATIONS_TEAR_OUT_SHEETS}
      element={
        <Layout>
          <ObservationsTearOutSheet />
        </Layout>
      }
    />
    <Route
      path={routeConstants.FIRM_TYPE}
      element={
        <Layout>
          <FirmType />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CMMC2_QUESTIONS}
      element={
        <Layout>
          <MaturityQuestions />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CMMC2_REPORTS}
      element={
        <Layout>
          <CMMC2Reports />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CMMC2_RESULTS}
      element={
        <Layout>
          <CMMC2Results />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CMMC2_EXECUTIVE_SUMMARY}
      element={
        <Layout>
          <CMMC2ExecutiveSummary />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CMMC2_DEFICIENCY_REPORT}
      element={
        <Layout>
          <DeficiencyReport />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CMMC2_COMMENT_AND_REVIEW_REPORT}
      element={
        <Layout>
          <CommentAndReview />
        </Layout>
      }
    />
    <Route
      path={routeConstants.ASSESSMENT_TYPE}
      element={
        <Layout>
          <AssessmentType />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CMMC2_LEVEL}
      element={
        <Layout>
          <CMMC2Level />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CRR_QUESTIONS}
      element={
        <Layout>
          <MaturityQuestions />
        </Layout>
      }
    />
    <Route
      path={routeConstants.RRA_QUESTIONS}
      element={
        <Layout>
          <MaturityQuestions />
        </Layout>
      }
    />
    <Route
      path={routeConstants.RRA_RESULTS}
      element={
        <Layout>
          <RRAResults />
        </Layout>
      }
    />
    <Route
      path={routeConstants.RRA_REPORTS}
      element={
        <Layout>
          <RRAReports />
        </Layout>
      }
    />
    <Route
      path={routeConstants.RRA_REPORT}
      element={
        <Layout>
          <RRAExecutiveSummary />
        </Layout>
      }
    />
    <Route
      path={routeConstants.RRA_DEFICIENCY_REPORT}
      element={
        <Layout>
          <RRADeficiencyReport />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CRR_RESULTS}
      element={
        <Layout>
          <CRRResults />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CRR_REPORTS}
      element={
        <Layout>
          <CRRReports />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CRR_EXECUTIVE_SUMMARY}
      element={
        <Layout>
          <CRRExecutiveSummary />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CRR_DEFICIENCY_REPORT}
      element={
        <Layout>
          <CRRDeficiencyReport />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CRR_COMMENT_AND_REVIEW_REPORT}
      element={
        <Layout>
          <CRRCommentAndReview />
        </Layout>
      }
    />
    <Route
      path={routeConstants.RRA_COMMENT_AND_REVIEW_REPORT}
      element={
        <Layout>
          <RRAReviewCommentReport />
        </Layout>
      }
    />
    <Route
      path={routeConstants.ASSESSMENT_DEMOGRAPHICS}
      element={
        <Layout>
          <AssessmentDemographics />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CRR_TUTORIAL}
      element={
        <Layout>
          <CRRTutorial />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CMMC_TUTORIAL}
      element={
        <Layout>
          <CMMCTutorial />
        </Layout>
      }
    />
    <Route
      path={routeConstants.RRA_TUTORIAL}
      element={
        <Layout>
          <RRATutorial />
        </Layout>
      }
    />
    <Route
      path={routeConstants.STANDARD_TUTORIAL}
      element={
        <Layout>
          <StandardTutorial />
        </Layout>
      }
    />
    <Route
      path={routeConstants.HELP}
      element={
        <Layout>
          <Help />
        </Layout>
      }
    />
    <Route path="*" element={<Navigate to={routeConstants.CLIENT}/>} />
  </Routes>
);
