import { CssBaseline, Grid, Paper, Typography, IconButton } from "@mui/material";
import { Button } from "../../../../../components/UI/Form/Button/Button";
import React, { useEffect, useState } from "react";
import styles from "./DeficiencyReport.module.css";
import GetAppIcon from "@mui/icons-material/GetApp";
import * as msgConstants from "../../../../../common/MessageConstants";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import * as routeConstant from "../../../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { CSET_URL } from "../../../../../config/index";
import logout from "../../../../Auth/Logout/Logout";
import { saveAs } from "file-saver";
import autoTable from "jspdf-autotable";
import * as docx from "docx";
import { jsPDF } from "jspdf";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { GET_ORGANIZATION } from "../../../../../graphql/queries/Organization";
import DOMPurify from "dompurify";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../../../../components/UI/Alert/Alert";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";

export interface DeficiencyProps {
  from?: string;
  props?: any;
  reportBlobData?: any;
  handleChange?: any;
  partnerLogo?: any;
  clientLogo?: any;
  partnerImageDimension?: any;
  clientImageDimension?: any;
}

export const DeficiencyReport: React.FC<DeficiencyProps> = ({
  from,
  reportBlobData,
  props,
  handleChange,
  // location,
  partnerLogo,
  clientLogo,
  clientImageDimension,
  partnerImageDimension,
}: DeficiencyProps) => {
  const history = useNavigate();
  const location = useLocation();
  const accessControl = "Access Control (AC)";
  const awarenessTraining = "Awareness and Training (AT)";
  const auditAccountability = "Audit and Accountability (AU)";
  const configurationManagement = "Configuration Management (CM)";
  const identificationAuthentication = "Identification and Authentication (IA)";
  const incidentResponse = "Incident Response (IR)";
  const maintenance = "Maintenance (MA)";
  const mediaProtection = "Media Protection (MP)";
  const personnelSecurity = "Personnel Security (PS)";
  const physicalProtection = "Physical Protection (PE)";
  const riskAssessment = "Risk Assessment (RA)";
  const securityAssessment = "Security Assessment (CA)";
  const systemCommunicationProtection =
    "Systems and Communications Protection (SC)";
  const systemInformationIntegrity = "System and Information Integrity (SI)";
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const componentRef = React.useRef<HTMLHeadingElement>(null);
  const [newData, setNewData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [param, setParam] = useState<any>(
    location?.state[Object.keys(location?.state)[0]]
  );
  const [bannerImage, setBannerImage] = useState<any>("");
  const [cpaImage, setCPAImage] = useState<any>("");
  const [partnerImage, setPartnerImage] = useState<any>("");
  const [cpaImageDimensions, setCPAImageDimensions] = useState<any>("");
  const [partnerImageDimensions, setPartnerImageDimensions] = useState<any>("");
  const [defaultCPAImage, setDefaultCPAImage] = useState<any>("");
  const [partnerData, setPartnerData] = useState<any>({});
  const [clientData, setClientData] = useState<any>({});
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const preparedForBy = "preparedFor/By";
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;

  const column = [
    { title: "Practice", field: "displayNumber" },
    { title: "Question", field: "questionText" },
    { title: "Response", field: "answer" },
  ];

  useEffect(() => {
    const controller = new AbortController();
    if (props) {
      setParam(props);
    } else {
      setParam(location?.state[Object.keys(location?.state)[0]]);
    }
    getDeficiencyData();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (param.clientInfo) {
      GetOrganizationDataOfPartner({
        variables: {
          where: {
            contact_id: param?.clientInfo.partnerId,
          },
        },
      });
      GetOrganizationDataOfClient({
        variables: {
          where: {
            id: param?.clientInfo.clientOrgId,
            subtype: "Client",
          },
        },
      });
    }
  }, [param, props]);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getDeficiencyData = async () => {
    try {
      setLoading(true);
      await fetch(
        `${CSET_URL}api/MaturityQuestions?installationMode=&fill=false`,
        {
          method: "GET",
          headers: headerObj,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setNewData(data.groupings[0].subGroupings);
          setLoading(false);
        })
        .catch((err:any)=>{
          sentry_error_catch(err,setLoading,setFormState)
        })
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const [GetOrganizationDataOfPartner] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param?.clientInfo.partnerId === element.contact_id.id) {
          setPartnerData(element);
        }
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err)
    },
  });
  const [GetOrganizationDataOfClient] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err:any) => {
      handleError(err);
    },
  });
  const getBannerImages = async () => {
    await fetch(`${process.env.PUBLIC_URL}/images/ExecutiveSummary.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((ExecutiveSummarydata: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(ExecutiveSummarydata);
        reader.onloadend = function () {
          const base64data = reader.result;
          setBannerImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    await fetch(`${process.env.PUBLIC_URL}/images/blank_img.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((blankImgdata) => {
        const reader = new FileReader();
        reader.readAsDataURL(blankImgdata);
        reader.onloadend = function () {
          const base64data = reader.result;
          setDefaultCPAImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    if (from === "result") {
      setPartnerImage(partnerLogo);
      setCPAImage(clientLogo);
      setCPAImageDimensions(clientImageDimension);
      setPartnerImageDimensions(partnerImageDimension);
    } else {
      if (clientData.logo && clientData.logo[0]) {
        const clientLogoUrl = clientData.logo[0].url.slice(1);
        await fetch(`${serverUrl}${clientLogoUrl}`, {
          method: "GET",
          headers: headerObj,
        })
          .then((response) => response.blob())
          .then(async (clientLogoUrldata) => {
            const reader = new FileReader();
            await reader.readAsDataURL(clientLogoUrldata);
            reader.onloadend = async function (theFile: any) {
              const base64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setCPAImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setCPAImage(base64data);
            };
          })
          .catch((error) => {
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
      if (partnerData.logo && partnerData.logo[0]) {
        const partnerLogoUrl = partnerData.logo[0].url.slice(1);
        await fetch(`${serverUrl}${partnerLogoUrl}`, {
          method: "GET",
        })
          .then((response) => response.blob())
          .then(async (partnerLogoUrldata: any) => {
            const reader = new FileReader();
            await reader.readAsDataURL(partnerLogoUrldata);
            reader.onloadend = async function (theFile: any) {
              const ClientLogobase64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setPartnerImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setPartnerImage(ClientLogobase64data);
            };
          })
          .catch((error) => {
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
    }
  };

  useEffect(() => {
    if (from && from === "result") {
      setTimeout(() => {
        generate();
      }, 800);
    }
  }, [bannerImage && partnerData.contact_id && newData.length > 0 && cpaImage && partnerImage]);
  useEffect(() => {
    getBannerImages();
  }, [partnerData, clientData]);

  const handleBack = () => {
    history(routeConstant.CMMC2_REPORTS, { state: { param } });
  };

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const generateTableHeaderCell = (text: string) => {
    return new docx.TableCell({
      children: [new docx.Paragraph({ text, style: "TableHeading" })],
      margins: {
        top: docx.convertInchesToTwip(0.1),
        bottom: docx.convertInchesToTwip(0.1),
        left: docx.convertInchesToTwip(0.1),
        right: docx.convertInchesToTwip(0.1),
      },
      shading: {
        fill: "#d9d2e9",
        color: "auto",
      },
      verticalAlign: docx.VerticalAlign.CENTER,
    });
  };

  const generateTableIndividualCell = (text: string) => {
    let t = text;
    if (typeof text === "number") {
      t = String(text);
    }
    if (t === "N") {
      t = "No";
    }
    if (t === "U") {
      t = "Unanswered";
    }
    let tParas: any = [];
    if (Array.isArray(t)) {
      tParas = t.map((it: any) => {
        return new docx.Paragraph({
          text: it,
          heading: docx.HeadingLevel.HEADING_3,
        });
      });
    }
    return new docx.TableCell(
      Array.isArray(t)
        ? {
            children: [...tParas],
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1),
            },
            shading: {
              fill: "fcfaff",
              color: "auto",
            },
          }
        : {
            children: [
              new docx.Paragraph({
                text: t,
                heading: docx.HeadingLevel.HEADING_3,
              }),
            ],
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1),
            },
            shading: {
              fill: "fcfaff",
              color: "auto",
            },
          }
    );
  };

  const generateTableCells = (data: any, columns: any[]) => {
    let tableRows: any[] = [];
    tableRows = columns.map((c) => {
      return generateTableIndividualCell(data[c.field]);
    });
    return new docx.TableRow({
      children: [...tableRows],
    });
  };

  const generateTableHeaderRow = (columns: any[]) => {
    let tableColumns: any[] = [];
    tableColumns = columns.map((c) => {
      return generateTableHeaderCell(c.title);
    });
    return new docx.TableRow({
      children: [...tableColumns],
    });
  };

  const generateFinalTable = (data: any[], columns: any[]) => {
    const filterData: any[] = data.filter((x) => x.answer !== "Y");
    if (filterData.length > 0) {
      const rows: any[] = filterData.map((d) => {
        return generateTableCells(d, columns);
      });
      return new docx.Table({
        rows: [generateTableHeaderRow(columns), ...rows],
      });
    } else {
      return new docx.Paragraph({
        text: "",
        style: "",
      });
    }
  };

  const fitImg = (targetHeight: any, originalSize: any) => {
    const originalAspectRatio = originalSize.width / originalSize.height;
    let width: number;
    let height: number;
    if (!originalSize) {
      width = targetHeight;
    } else {
      height = targetHeight;
      width = height * originalAspectRatio;
    }
    return width;
  };

  const generate = async () => {
    if (
      bannerImage &&
      partnerData.contact_id !== undefined &&
      newData.length > 0
    ) {
      const DeficiencyTable1 = generateFinalTable(
        newData[0].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      const DeficiencyTable2 = generateFinalTable(
        newData[1].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      const DeficiencyTable3 = generateFinalTable(
        newData[2].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      const DeficiencyTable4 = generateFinalTable(
        newData[3].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      const DeficiencyTable5 = generateFinalTable(
        newData[4].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      const DeficiencyTable6 = generateFinalTable(
        newData[5].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      const DeficiencyTable7 = generateFinalTable(
        newData[6].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      const DeficiencyTable8 = generateFinalTable(
        newData[7].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      const DeficiencyTable9 = generateFinalTable(
        newData[8].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      const DeficiencyTable10 = generateFinalTable(
        newData[9].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      const DeficiencyTable11 = generateFinalTable(
        newData[10].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      const DeficiencyTable12 = generateFinalTable(
        newData[11].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      const DeficiencyTable13 = generateFinalTable(
        newData[12].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );

      const DeficiencyTable14 = generateFinalTable(
        newData[13].questions
          .filter((x: any) => {
            return x.answer === "N" || x.answer === "U";
          })
          .map((item: any) => (Array.isArray(item) ? [...item] : { ...item }))
          .map((rq: any) => {
            const sanitizedHTML = createDangerousHTML(rq["questionText"]);
            const plainText = sanitizedHTML.__html
              .replace(/&nbsp;/g, "\n")
              .replace(/<[^>]+>/g, "");
            rq["questionText"] = plainText.split("\n");
            return rq;
          }),
        column
      );
      console.log("__Generate");
      setLoading(true);
      const doc = new docx.Document({
        numbering: {
          config: [
            {
              reference: "top_categories",
              levels: [
                {
                  level: 0,
                  format: docx.LevelFormat.DECIMAL,
                  text: "%1",
                  alignment: docx.AlignmentType.START,
                  style: {
                    paragraph: {
                      indent: { left: 720, hanging: 260 },
                    },
                  },
                },
              ],
            },
            {
              reference: "questions",
              levels: [
                {
                  level: 0,
                  format: docx.LevelFormat.DECIMAL,
                  text: "%1",
                  alignment: docx.AlignmentType.START,
                  style: {
                    paragraph: {
                      indent: { left: 720, hanging: 260 },
                    },
                  },
                },
              ],
            },
          ],
        },
        styles: {
          default: {
            heading1: {
              run: {
                font: "Helvetica",
                size: 36,
                bold: true,
                color: "#4b0079",
              },
              paragraph: {
                alignment: docx.AlignmentType.LEFT,
                spacing: { line: 340 },
              },
            },
            heading2: {
              run: {
                font: "Helvetica",
                size: 30,
                bold: true,
                color: "#0b4785",
              },
              paragraph: {
                spacing: { line: 340 },
              },
            },
            heading3: {
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                spacing: { line: 276 },
              },
            },
            heading4: {
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                alignment: docx.AlignmentType.JUSTIFIED,
              },
            },
          },
          paragraphStyles: [
            {
              id: "normalPara",
              name: "Normal Para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                spacing: {
                  line: 276,
                  before: 20 * 72 * 0.1,
                  after: 20 * 72 * 0.05,
                },
                rightTabStop: docx.TabStopPosition.MAX,
                leftTabStop: 453.543307087,
              },
            },
            {
              id: "mainHeading",
              name: "Normal Para2",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 80,
                color: "#4B0079",
                bold: true,
              },
            },
            {
              id: "date",
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                color: "#4B0079",
                size: 40,
                bold: true,
              },
            },
            {
              id: "bulletText",
              name: "Normal para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
            {
              id: "customFontCng",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
            {
              id: "miniHeader",
              name: "Normal para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 24,
                bold: true,
              },
            },
            {
              id: "TableHeading",
              name: "Normal Para2",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
                color: "#4B0079",
                bold: true,
              },
            },
            {
              id: "BoldPara",
              name: "Normal Para3",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
                bold: true,
              },
            },
            {
              id: preparedForBy,
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                color: "#4B0079",
                size: 20,
                bold: true,
              },
            },
            {
              id: "clientPartner",
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
          ],
        },
      });

      const exeSummaryLogo = bannerImage ? bannerImage.split(",")[1] : "";
      const defaultcpaLogo = defaultCPAImage
        ? defaultCPAImage.split(",")[1]
        : "";
      const cpaLogo = cpaImage ? cpaImage.split(",")[1] : defaultcpaLogo;
      const msplogo = partnerImage
        ? partnerImage.split(",")[1]
        : defaultcpaLogo;

      const image5 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(exeSummaryLogo), (c) => c.charCodeAt(0)),
        600,
        350
      );

      const image6 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(cpaLogo), (c) => c.charCodeAt(0)),
        fitImg(50, cpaImageDimensions),
        50
      );

      const image7 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
        fitImg(50, partnerImageDimensions),
        50
      );
      const footerLogo = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
        fitImg(50, partnerImageDimensions),
        50,
        {
          floating: {
            horizontalPosition: {
              align: docx.HorizontalPositionAlign.RIGHT,
            },
            verticalPosition: {
              align: docx.VerticalPositionAlign.BOTTOM,
            },
          },
        }
      );
      const filterData: any = newData[0].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData1: any = newData[1].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData2: any = newData[2].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData3: any = newData[3].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData4: any = newData[4].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData5: any = newData[5].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData6: any = newData[6].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData7: any = newData[7].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData8: any = newData[8].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData9: any = newData[9].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData10: any = newData[10].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData11: any = newData[11].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData12: any = newData[12].questions.filter(
        (x: any) => x.answer !== "Y"
      );
      const filterData13: any = newData[13].questions.filter(
        (x: any) => x.answer !== "Y"
      );

      const bannertable = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    heading: docx.HeadingLevel.HEADING_1,
                    style: preparedForBy,
                    children: [
                      new docx.TextRun({
                        text: "Prepared for",
                        bold: true,
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),

                  new docx.Paragraph(image6),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph({
                    text: param?.clientInfo.name,
                    style: "clientPartner",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.2),
                  right: docx.convertInchesToTwip(1.8),
                },
                columnSpan: 5,
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },

                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    heading: docx.HeadingLevel.HEADING_1,
                    style: preparedForBy,
                    children: [
                      new docx.TextRun({
                        text: "Prepared By",
                        bold: true,
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph(image7),
                  new docx.Paragraph({
                    text: "",
                  }),
                  new docx.Paragraph({
                    text: partnerData.contact_id?.name,
                    style: "clientPartner",
                  }),
                  new docx.Paragraph({
                    text: "",
                    style: "clientPartner",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.5),
                  right: docx.convertInchesToTwip(2),
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                columnSpan: 5,
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
            ],
          }),
        ],
      });

      doc.addSection({
        children: [
          new docx.Paragraph(image5),
          new docx.Paragraph({
            text: "Deficiency Report CMMC 2.0",
            style: "mainHeading",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: moment().format("MMMM DD, YYYY"),
            heading: docx.HeadingLevel.HEADING_1,
            style: "date",
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),

          bannertable,
        ],
      });

      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: msgConstants.DEFICIENCY_REPORT,
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: "This report was prepared as an account of work sponsored by an agency of the U.S. Government. Neither the U.S. Government nor any agency thereof, nor any employee, makes any warranty, expressed or implied, or assumes any legal liability or responsibility for any third-party's use, or the results of such use, or any information, apparatus, product, or process disclosed in this publication, or represents that its use by such third party would not infringe privately owned rights. ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            style: "normalPara",
            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: "This deficiency report lists the statements that are not currently compliant or 'No' answers This is often referred to as the GAP Report. This report intends to list the gaps, assist users ofthe report in identifying gaps, prioritizing work, and beginning to make a plan to address the gapsby implementing the controls necessary to come into compliance with the associated statement.The percentage gap in each domain is also listed and will help to determine the priority. CMMC is a cumulative maturity model meaning lower levels should be completed before moving to higher levels.Ideally baseline should be completed before focusing efforts on controls in evolving and so forth up the line of maturity levels.",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            style: "normalPara",
            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: "Deficiencies",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          new docx.Paragraph({
            text: filterData.length > 0 ? accessControl : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable1,
          new docx.Paragraph({
            text: filterData1.length > 0 ? awarenessTraining : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable2,
          new docx.Paragraph({
            text: filterData2.length > 0 ? auditAccountability : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable3,
          new docx.Paragraph({
            text: filterData3.length > 0 ? configurationManagement : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable4,
          new docx.Paragraph({
            text: filterData4.length > 0 ? identificationAuthentication : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable5,
          new docx.Paragraph({
            text: filterData5.length > 0 ? incidentResponse : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable6,
          new docx.Paragraph({
            text: filterData6.length > 0 ? maintenance : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable7,
          new docx.Paragraph({
            text: filterData7.length > 0 ? mediaProtection : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable8,
          new docx.Paragraph({
            text: filterData8.length > 0 ? personnelSecurity : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable9,
          new docx.Paragraph({
            text: filterData9.length > 0 ? physicalProtection : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable10,
          new docx.Paragraph({
            text: filterData10.length > 0 ? riskAssessment : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable11,
          new docx.Paragraph({
            text: filterData11.length > 0 ? securityAssessment : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable12,
          new docx.Paragraph({
            text: filterData12.length > 0 ? systemCommunicationProtection : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable13,
          new docx.Paragraph({
            text: filterData13.length > 0 ? systemInformationIntegrity : "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
              before: 300,
            },
          }),
          DeficiencyTable14,
        ],
      });

      docx.Packer.toBlob(doc)
        .then(async (blob: any) => {
          if (from && from === "result") {
            await Promise.resolve()
              .then(() => {
                setLoading(false);
                reportBlobData = blob;
                reportBlobData["name"] = "Deficiency_Report.docx";
                handleChange(reportBlobData);
              })
              .catch((err) => {
                console.log(err);
                Sentry.captureException(err)
              });
          } else {
            await Promise.resolve(saveAs(blob, "Deficiency_Report.docx"))
              .then(() => {
                setLoading(false);
              })
              .catch((err) => {
                console.log(err);
                Sentry.captureException(err)
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const startLoader = async () => {
    setLoading(true);
    setTimeout(function () {
      generatePdf();
    }, 1000);
  };

  const generatePdf = async () => {
    try {
      setLoading(true);
      const pdf: any = new jsPDF({compress:true});
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(12);
      //cover page
      pdf.addImage(bannerImage, "JPEG", 10, 10, 190, 125);
      pdf.setDrawColor(0);
      pdf.setTextColor(75, 0, 121);
      pdf.setFontSize(40);
      pdf.setFont("helvetica", "bold");
      pdf.text(msgConstants.DEFICIENCY_REPORT, 20, 150);
      pdf.text("Report", 20, 165);
      pdf.setFontSize(20);
      const date = moment().toDate();
      const formatedDate = moment(date).format("MMMM DD, YYYY");
      pdf.text(formatedDate, 20, 180);
      pdf.setFontSize(10);
      pdf.text("Prepared For", 20, 200);
      pdf.text("Prepared By", 110, 200);
      pdf.setDrawColor(75, 0, 121);
      pdf.setLineWidth(0.75);
      pdf.line(100, 200, 100, 240);
      if (cpaImage !== undefined) {
        pdf.addImage(
          cpaImage,
          "JPEG",
          20,
          205,
          fitImg(18, cpaImageDimensions),
          18
        );
      }
      if (partnerImage !== undefined) {
        pdf.addImage(
          partnerImage,
          "JPEG",
          107,
          205,
          fitImg(18, partnerImageDimensions),
          18
        );
      }
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      pdf.text(param.clientInfo.name, 20, 235);
      pdf.text(partnerData.contact_id.name, 110, 235);
      pdf.text("", 110, 240);

      // first page
      pdf.addPage("a4", "p");
      //header
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.setFontSize(18);
      pdf.text(msgConstants.DEFICIENCY_REPORT, 10, 20);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10);
      const description = pdf.splitTextToSize(
        "This report was prepared as an account of work sponsored by an agency of the U.S. Government.Neither the U.S. Government nor any agency thereof, nor any employee, makes any warranty, expressed or implied, or assumes any legal liability or responsibility for any third-party's use, or the results of such use, or any information, apparatus, product, or process disclosed in this publication, or represents that its use by such third party would not infringe privately owned rights. ",
        190
      );
      pdf.text(description, 10, 30);
      const defiDescription = pdf.splitTextToSize(
        "This deficiency report lists the statements that are not currently compliant or 'No' answersThis is often referred to as the GAP Report. This report intends to list the gaps, assist usersof the report in identifying gaps, prioritizing work, and beginning to make a plan to address the gaps by implementing the controls necessary to come into compliance with the associated statement.The percentage gap in each domain is also listed and will help to determine the priority. CMMC is a cumulative maturity model meaning lower levels should be completed beforemoving to higher levels.Ideally baseline should be completed before focusing efforts on controlsin evolving and so forth up the line of maturity levels.",
        190
      );
      pdf.text(defiDescription, 10, 60);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133); //subheading orange color
      //footer
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);

      const headers = [["Practice", "Question", "Response"]];
      const defiTable1: any[] = [];
      const defiTable2: any[] = [];
      const defiTable3: any[] = [];
      const defiTable4: any[] = [];
      const defiTable5: any[] = [];
      const defiTable6: any[] = [];
      const defiTable7: any[] = [];
      const defiTable8: any[] = [];
      const defiTable9: any[] = [];
      const defiTable10: any[] = [];
      const defiTable11: any[] = [];
      const defiTable12: any[] = [];
      const defiTable13: any[] = [];
      const defiTable14: any[] = [];
      newData.forEach((rq: any) => {
        if (rq.title === accessControl) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable1.push(data);
            }
          });
        }
        if (rq.title === awarenessTraining) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable2.push(data);
            }
          });
        }
        if (rq.title === auditAccountability) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable3.push(data);
            }
          });
        }
        if (rq.title === configurationManagement) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable4.push(data);
            }
          });
        }
        if (rq.title === identificationAuthentication) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable5.push(data);
            }
          });
        }
        if (rq.title === incidentResponse) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable6.push(data);
            }
          });
        }
        if (rq.title === maintenance) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable7.push(data);
            }
          });
        }
        if (rq.title === mediaProtection) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable8.push(data);
            }
          });
        }
        if (rq.title === personnelSecurity) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable9.push(data);
            }
          });
        }
        if (rq.title === physicalProtection) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable10.push(data);
            }
          });
        }
        if (rq.title === riskAssessment) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable11.push(data);
            }
          });
        }
        if (rq.title === securityAssessment) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable12.push(data);
            }
          });
        }
        if (rq.title === systemCommunicationProtection) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable13.push(data);
            }
          });
        }
        if (rq.title === systemInformationIntegrity) {
          rq.questions.forEach((q: any) => {
            if (q.answer !== "Y" && q.answer !== "NA") {
              const data: any = [];
              data.push(q["displayNumber"]);
              const sanitizedHTML = createDangerousHTML(q["questionText"]);
              const plainText = sanitizedHTML.__html
                .replace(/&nbsp;/g, "\n")
                .replace(/<[^>]+>/g, "");
              data.push(plainText);
              data.push(q["answer"] === "N" ? "No" : "Unanswered");
              defiTable14.push(data);
            }
          });
        }
      });

      if (defiTable1.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text("", 10, 10);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        pdf.text(accessControl, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable1,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable2.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text("", 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        pdf.text(awarenessTraining, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable2,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable3.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text(auditAccountability, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable3,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable4.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text(configurationManagement, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable4,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable5.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text(identificationAuthentication, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable5,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable6.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text(incidentResponse, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable6,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable7.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text(maintenance, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable7,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable8.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text(mediaProtection, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable8,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable9.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text(personnelSecurity, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable9,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable10.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text(physicalProtection, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable10,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable11.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text(riskAssessment, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable11,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable12.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text(securityAssessment, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable12,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable13.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text(systemCommunicationProtection, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable13,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable14.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text(systemInformationIntegrity, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable14,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }

      //header and footer
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 0; i <= pageCount; i++) {
        if (i > 1) {
          pdf.setPage(i);
          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0); //paragraph black color
          pdf.text(
            `Page |  ${pdf.internal.getCurrentPageInfo().pageNumber - 1}`,
            10,
            290
          );
          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();
          if (partnerImage !== undefined) {
            pdf.addImage(
              partnerImage,
              "JPEG",
              180,
              270,
              width / 10,
              height / 14
            );
          }
        }
      }

      await Promise.resolve(pdf.save("Deficiency_Report.pdf"))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          Sentry.captureException(error)
        });
    } catch(error:any) {
      setLoading(false);
      console.log("ERR");
      Sentry.captureException(error)
    }
  };

  return (

      <div>
        <CssBaseline />
        <Grid container ref={componentRef}>
          <Grid item md={12}>
            <Typography component="h5" variant="h1">
              {msgConstants.DEFICIENCY_REPORT}
            </Typography>
            <Grid item xs={12} sm={6} className={styles.FooterActions}>
              <div className={styles.prevBtn}>
                <Button
                  id="previous-button"
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                >
                  {"Previous"}
                </Button>
                <div className={styles.borderButton}>
                  <Button
                    id="doc-generate-button-1"
                    variant="contained"
                    color="primary"
                    onClick={() => generate()}
                  >
                    <GetAppIcon className={styles.EditIcon} />
                    &nbsp; Doc
                  </Button>
                  <Button
                    id="pdf-generate-button-1"
                    variant="contained"
                    color="primary"
                    onClick={() => startLoader()}
                  >
                    <GetAppIcon className={styles.EditIcon} />
                    &nbsp; PDF
                  </Button>
                </div>
              </div>
            </Grid>
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {formState.errMessage}
              </Alert>
            ) : null}
            <Paper className={styles.paper}>
              {loading ? <SimpleBackdrop /> : null}

              <div>
                <p>
                  This deficiency report lists the statements that are not
                  currently compliant or "No" answers.
                </p>
                <p>
                  This is often referred to as the GAP Report. This report
                  intends to list the gaps, assist users of the report in
                  identifying gaps, prioritizing work, and beginning to make a
                  plan to address the gaps by implementing the controls
                  necessary to come into compliance with the associated
                  statement.
                </p>
                <p>
                  The percentage gap in each domain is also listed and will help
                  to determine the priority. CMMC is a cumulative maturity model
                  meaning lower levels should be completed before moving to
                  higher levels.
                </p>
              </div>

              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                className={styles.FooterActions}
              >
                <div className={styles.securityPlanTable}>
                  {newData.map((element: any, index: any) => {
                    const filterQuestionsData = element?.questions?.filter(
                      (x: any) => {
                        return x.answer === "N" || x.answer === "U";
                      }
                    );

                    if (
                      element?.questions?.filter((x: any) => {
                        return x.answer === "N" || x.answer === "U";
                      }).length > 0
                    ) {
                      return (
                        <table
                          id="secondTable"
                          key={element?.groupingID}
                          className={styles.tableStyle}
                        >
                          <tr key={element?.groupingID}>
                            <td colSpan={5}>
                              <Typography component="h3" variant="h3">
                                {element.title}
                              </Typography>
                            </td>
                          </tr>
                          <tr key={element?.groupingID}>
                            <td colSpan={1}>
                              {" "}
                              <Typography component="h3" variant="h3">
                                Practice
                              </Typography>
                            </td>
                            <td colSpan={3}>
                              {" "}
                              <Typography
                                component="h3"
                                variant="h3"
                              ></Typography>
                            </td>
                            <td colSpan={1}>
                              {" "}
                              <Typography component="h3" variant="h3">
                                Response
                              </Typography>
                            </td>
                          </tr>
                          {filterQuestionsData.map(
                            (questionData: any, index: number) => {
                              return (
                                <>
                                  {questionData.answer === "Y" ? null : (
                                    <tr key={questionData?.questionId}>
                                      <td colSpan={1}>
                                        {questionData.displayNumber}{" "}
                                      </td>
                                      <td colSpan={3}>
                                        <span
                                          dangerouslySetInnerHTML={createDangerousHTML(
                                            questionData.questionText
                                          )}
                                        ></span>
                                      </td>
                                      <td colSpan={1}>
                                        {questionData.answer === "Y"
                                          ? "Yes"
                                          : questionData.answer === "N"
                                          ? "No"
                                          : questionData.answer === "NA"
                                          ? "NA"
                                          : "Unanswered"}{" "}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              );
                            }
                          )}
                        </table>
                      );
                    }
                  })}
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} className={styles.FooterActions}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                {"Previous"}
              </Button>
              <div className={styles.borderButton}>
                <Button
                  id="doc-generate-button-2"
                  variant="contained"
                  color="primary"
                  onClick={() => generate()}
                >
                  <GetAppIcon className={styles.EditIcon} />
                  &nbsp; Doc
                </Button>
                <Button
                  id="pdf-generate-button-2"
                  variant="contained"
                  color="primary"
                  onClick={() => startLoader()}
                >
                  <GetAppIcon className={styles.EditIcon} />
                  &nbsp; PDF
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
  );
};

export default DeficiencyReport;
