import React, { useState, useEffect } from "react";
import { CSET_URL } from "../../../../../config";
import logout from "../../../../Auth/Logout/Logout";
import DOMPurify from "dompurify";
import styles from "./CRRExecutiveSummary.module.css";

export interface CrrPerformanceSummaryProps {
  data?: any;
  props?: any;
  CrrPerformanceSummaryref?: any;
  crrmilPerformanceSummaryRef?: any;
  CRRModelAPIData?: any;
  handleChildCompError?: (err: any) => void;
}

export const CrrPerformanceSummary: React.FC<CrrPerformanceSummaryProps> = ({
  data,
  props,
  CrrPerformanceSummaryref,
  crrmilPerformanceSummaryRef,
  CRRModelAPIData,
  handleChildCompError = () => {}
}: CrrPerformanceSummaryProps) => {
  const [legend, setLegend] = useState<any>(null);
  const [crrModel, setCRRModel] = useState<any>();
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [charts, setCharts] = useState<any>([]);

  useEffect(() => {
    getCrrPerformanceSummaryBodyCharts();
    getCrrPerformanceSummaryLegendWidget();
  }, []);

  useEffect(() => {
    if (CRRModelAPIData !== null) {
      setCRRModel(CRRModelAPIData.structure.Model);
    }
  }, [CRRModelAPIData]);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getCrrPerformanceSummaryBodyCharts = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/reportscrr/getCrrPerformanceSummaryBodyCharts`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.json();
    if (response.status === 200) {
      setCharts(data);
    }else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }catch(error){
    handleChildCompError(error)
  }
  };

  const getCrrPerformanceSummaryLegendWidget = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/reportscrr/widget/performanceLegend`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.text();
    if (response.status === 200) {
      setLegend(data);
    }else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }catch(error){
    handleChildCompError(error)
  }
  };

  const getChart = (i: any, j: any) => {
    return charts[i][j];
  };

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };
  return (
      <div
        ref={CrrPerformanceSummaryref}
        className={`${styles.containerFluid} ${styles.landscape}`}
        style={{ fontFamily: "Verdana, sans-serif" }}
      >
        <h3
          className={styles.depictionTitle}
          style={{ marginLeft: "-15px", marginBottom: "5px" }}
        >
          CRR Performance Summary
        </h3>

        <div
          className={`${styles.row} ${styles.header} ${styles.crrPerformance}`}
        >
          <div style={{ width: "9%", padding: "0 5px 0 0" }}>
            <p>
              <b>Domain Summary</b>
            </p>
          </div>
          <div style={{ width: "27%", padding: "0 5px 0 0" }}>
            <p>
              <b>MIL-1 Performed</b>
              <br />
              Domain Practices are being performed
            </p>
          </div>
          <div style={{ width: "18%", padding: "0 5px 0 0" }}>
            <p>
              <b>MIL-2 Planned</b>
              <br />
              Domain practices are supported by planning, policy, stakeholders,
              and standards.
            </p>
          </div>
          <div style={{ width: "18%", padding: "0 5px 0 0" }}>
            <p>
              <b>MIL-3 Managed</b>
              <br />
              Domain practices are supported by governance and adequate
              resources.
            </p>
          </div>
          <div style={{ width: "14%", padding: "0 5px 0 0" }}>
            <p>
              <b>MIL-4 Measured</b>
              <br />
              Domain practices are supported by measurement, monitoring, and
              executive oversight.
            </p>
          </div>
          <div style={{ width: "14%", padding: "0 5px 0 0" }}>
            <p>
              <b>MIL-5 Defined</b>
              <br />
              Domain practices are supported by enterprise standardization and
              analysis of lessons learned.
            </p>
          </div>
        </div>

        {crrModel?.Domain.map((domain: any, i: any) => {
          return (
              <div key={domain?.title} className={`${styles.row} ${styles.domain}`}>
                <div
                  className={styles.domainLabel}
                  style={{ width: "9%", padding: 0 }}
                >
                  {domain.title}
                </div>
                {charts[i].map((chart: any, j: any) => {
                  return (
                    <div
                      style={{
                        width:
                          j == 0 ? "27%" : j == 4 || j == 3 ? "14%" : "18%",
                        padding: "0",
                      }}
                      dangerouslySetInnerHTML={createDangerousHTML(
                        getChart(i, j)
                      )}
                    ></div>
                  );
                })}
              </div>
          );
        })}
        <div
          className={styles.row}
          style={{ padding: "0.25rem", marginLeft: "15px" }}
          dangerouslySetInnerHTML={createDangerousHTML(legend)}
        ></div>
      </div>
  );
};

export default CrrPerformanceSummary;
