import React, { useState, useEffect } from "react";
import { CSET_URL } from "../../../../config/index";
import { HorizontalBar } from "react-chartjs-2";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import Paper from '@mui/material/Paper';
import styles from "./ResultByCategory.module.css";
import logout from "../../../../containers/Auth/Logout/Logout";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../components/UI/Alert/Alert";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";
import { ERROR_MESSAGE } from "../../../../common/MessageConstants";

export interface ResultCategoryProps {
  data?: any;
}

export const StandardsResultsByCategory: React.FC<ResultCategoryProps> = ({
  data,
}: ResultCategoryProps) => {
  const [chartData, setChartData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [dataSet, setDataSet] = useState<any>([]);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const authToken = sessionStorage.getItem("ra_token") || logout();

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  useEffect(() => {
    const controller = new AbortController();
    if (data !== undefined) {
      console.log(data);
    }
    getStandardsResultsByCategoryDetails();
    return () => controller.abort();
  }, []);


  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const getStandardsResultsByCategoryDetails = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/analysis/StandardsResultsByCategory`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
            const updatedDataSets = data.dataSets.map((val:any) => {
              if (val.borderColor === null) {
                val.borderColor = 0;
              }
              return val;
            });
            setChartData({
              labels: data.labels,
              datasets: updatedDataSets,
            });
            setDataSet(updatedDataSets);
          })
        .catch((error) => {
          handleError(error);
        });
    } catch (err:any) {
      sentry_error_catch(err,setLoading,setFormState)
    }
  };
  const options = {
    title: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
          },
        },
      ],
    },
  };

  if (loading) {
    return <SimpleBackdrop />;
  }
  console.log("chartData",chartData)
  return (
    <React.Fragment>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formState.errMessage}
        </Alert>
      ) : null}
      <Paper className={styles.paper}>
        <div className="App">
          <Paper className={styles.paper}>
            <HorizontalBar data={chartData} options={options} />
          </Paper>
        </div>
        {dataSet.length > 0 ? (<Paper className={styles.paper}>
          {dataSet.map((res: any) => {
            return (
              <div key={res.label}>
                <h2>
                  <div
                    style={{
                      width: "0.8em",
                      height: "0.8em",
                      margin: "4px 10px 4px 0px",
                      backgroundColor: res.backgroundColor,
                    }}
                  ></div>
                  {res.label}
                </h2>
                <table width={"100%"}>
                  <tr>
                    <th colSpan={2}>Category</th>
                    <th>Passed</th>
                    <th>Total</th>
                    <th>Percent</th>
                  </tr>
                  {res.dataRows.map((data: any) => {
                    return (
                      <tr key={data.title}>
                        <td colSpan={2}>{data.title}</td>
                        <td>{data.failed}</td>
                        <td>{data.total}</td>
                        <td>{Math.round(data.percent)}%</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            );
          })}
        </Paper>):null}
      </Paper>
    </React.Fragment>
  );
};
export default StandardsResultsByCategory;
