import React, { useState, useMemo, useEffect, useRef } from "react";
import styles from "./RequirementsMode.module.css";
import { CSET_URL } from "../../../../config/index";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "../../../../components/UI/Form/Button/Button";
import Accordion from "@mui/material/Accordion";
import FlagIcon from "@mui/icons-material//Flag";
import AccordionSummary from "@mui/material/AccordionSummary";
import { DialogBox } from "../../../../components/UI/DialogBox/DialogBox";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material//ExpandMore";
import * as CSET from "../../../../common/CSET";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import CircularProgresBar from "../../../../components/UI/CircularProgresBar/CircularProgresBar";
import { HISTORY } from "../../../../graphql/mutations/AuditTrail";
import { useLazyQuery, useMutation } from "@apollo/client";
import MessageIcon from "@mui/icons-material//Message";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GetAppIcon from "@mui/icons-material/GetApp";
import Input from "../../../../components/UI/Form/Input/Input";
import { IconButton, Tooltip } from "@mui/material";
import Alert from "../../../../components/UI/Alert/Alert";
import CloseIcon from "@mui/icons-material/Close";
import * as msgConstants from "../../../../common/MessageConstants";
import AutoCompleteDropDown from "../../../../components/UI/Form/Autocomplete/Autocomplete";
import DOMPurify from "dompurify";
import { saveAs } from "file-saver";
import {
  CREATE_QUESTION_DOCUMENT,
  UPDATE_QUESTION_DOCUMENT,
  DELETE_QUESTION_DOCUMENT,
} from "../../../../graphql/mutations/QuestionDocument";
import {
  GET_QUESTION_DOCUMENT,
  GET_ALL_DOCUMENTS,
} from "../../../../graphql/queries/QuestionDocument";
import * as Sentry from "@sentry/react";
import { deleteFileById } from "../../../../graphql/mutations/Upload";
import { useErrorBoundary } from "react-error-boundary";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";

export interface RequirementsProps {
  data?: any;
  props?: any;
  paginationData?: any;
  getPageCount: any;
  getQuestionData?: any;
  questionListmessageDisplay?: any;
  questionDocumentList?: any;
  showQuestionRefList?: any;
}

interface ImportanceValueObj {
  importance_Id: number;
  value: string;
}

export const RequirementsMode: React.FC<RequirementsProps> = ({
  data,
  props,
  paginationData,
  getPageCount,
  getQuestionData,
  questionListmessageDisplay,
  questionDocumentList,
  showQuestionRefList,
}: RequirementsProps) => {
  const componentGuid = "00000000-0000-0000-0000-000000000000";
  const { showBoundary } = useErrorBoundary();
  const [requirementList, setRequirementList] = useState<any>([]);
  const authToken = sessionStorage.getItem("ra_token") || "";
  const authSession = sessionStorage.getItem("ra_session") || "";
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState<string | false | true>(false);
  const [expandAll, setExpandAll] = useState<boolean | undefined>(false);
  const [questionData, setQuestionData] = useState();
  const [quesData, setQuesData] = useState<any>({});
  const [featureName, setFeatureName] = useState("");
  const [showCommentDialogBox, setShowCommentDialogBox] = useState(false);
  const [showFeedbackDialogBox, setShowFeedbackDialogBox] = useState(false);
  const [showObservations, setShowObservations] = useState<any>(false);
  const [showAddObservations, setShowAddObservations] = useState<any>(false);
  const [editObservationData, setEditObservationData] = useState<any>();
  const [commentValue, setCommentValue] = useState("");
  const [feedbackValue, setFeedbackValue] = useState("");
  const [title, setTitle] = useState<any>("");
  const [resolutionDate, setResolutionDate] = useState<any>(new Date());
  const [issues, setIssues] = useState<any>("");
  const [impacts, setImpacts] = useState<any>("");
  const [recommendations, setRecommendations] = useState<any>("");
  const [vulnerabilities, setVulnerabilities] = useState<any>("");
  const [importance, setImportance] = useState<any>("");
  const [findings, setFindings] = useState<any>([]);
  const [findingData, setFindingData] = useState<any>("");
  const [showDocuments, setShowDocuments] = useState<any>(false);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [importanceValueList, setImportanceValueList] = useState<any>([]);
  const [suppleQues, setSuppleQues] = useState<any>([]);
  const [supplementInfo, setSupplementInfo] = useState<any>("");
  const [questionNumber,setQuestionNumber] = useState<any>([]);
  const [existingFileNames, setExistingFileNames] = useState<string[]>([]);
  const getImportanceValue = {
    options: importanceValueList,
    getOptionLabel: (option: ImportanceValueObj) =>
      option.importance_Id ? option.value : "",
  };
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const PageSize = 1;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (paginationData - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return requirementList.length > 0
      ? requirementList.slice(firstPageIndex, lastPageIndex)
      : undefined;
  }, [requirementList, paginationData]);
  const [auditTrail] = useMutation(HISTORY);
  const headerObj = {
    "Content-Type": msgConstants.CONTENT_TYPE,
    Authorization: authToken,
  };

  const [documentData, setDocumentData] = useState<any>();
  const [practise, setPractise] = useState<any>();
  const [allDocuments, setAllDocuments] = useState<any>();
  const [isFileSet, setIsFileSet] = useState(false);
  const [documentList, setDocumentList] = useState<any>([]);
  const inputFileRef: any = useRef();
  const [createDocument] = useMutation(CREATE_QUESTION_DOCUMENT);
  const [updateDocument] = useMutation(UPDATE_QUESTION_DOCUMENT, {
    fetchPolicy: msgConstants.FETCH_POLICY,
  });
  const [deleteDocument] = useMutation(DELETE_QUESTION_DOCUMENT);
  const [getDocument] = useLazyQuery(GET_QUESTION_DOCUMENT, {
    fetchPolicy: msgConstants.FETCH_POLICY,
  });

  const [deleteDocumentFileById] = useMutation(deleteFileById);
  const [getAllDocuments] = useLazyQuery(GET_ALL_DOCUMENTS, {
    fetchPolicy: msgConstants.FETCH_POLICY,
  });
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [doc, setDoc] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    setMode();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    setAllDocuments(questionDocumentList);
    return () => controller.abort();
  }, [questionDocumentList]);

  useEffect(() => {
    const controller = new AbortController();
    getTotalQuestionsAns();
    return () => controller.abort();
  }, [currentTableData]);

  if (loading) {
    return <SimpleBackdrop />;
  }

  const handleCancel = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const setMode = async () => {
    try {
      await fetch(`${CSET_URL}api/setmode?mode=R`, {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(CSET.MODE_REQUEST),
      })
        .then((data) => {
          getRequirementList();
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      handleCancel(e);
      setLoading(false);
    }
  };

  const onError = (error:any) => {
    handleCancel(error);
  };

  const getRequirementList = async () => {
    try {
      await fetch(`${CSET_URL}api/questionlist`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          const requirementArray: any = [];
          // data.domains.forEach((element: any) => {
          data.categories.forEach((element: any) => {
            requirementArray.push(element);
          });
          // });
          console.log("get count caller")
          setRequirementList(requirementArray);
          getPageCount({ pageCount: requirementArray?.length });
          setQuestionData(data);
          getQuestionData(data);
          auditTrail({
            variables: {
              auditTrailInput: {
                individual: props.individualId,
                organization: props.partnerOrganizationId,
                action: {
                  message: "Questions API Called",
                },
                flowtype: props.flowType.type
                  ? props.flowType.type
                  : props.flowType,
                page: "Requirements Mode Page",
                client: props.clientOrgId,
                cset_assessment: props.ccAssessmentInfo.id,
              },
            },
          }).then((res: any) => {});
        })
        .catch((error) => {
          console.log(error, "error");
          onError(error);
        });
    } catch (e) {
      setLoading(false);
      console.log(e, "error2");
      handleCancel(e);
    }
  };

  const onButtonClick = (
    ans: any,
    obj: any,
    markForReview = true,
    reviewed?: any
  ) => {
    let answer: any = "U";
    if (ans === "Y") {
      if (obj.answer === "Y") {
        answer = "U";
      } else {
        answer = "Y";
      }
    }
    if (ans === "N") {
      if (obj.answer === "N") {
        answer = "U";
      } else {
        answer = "N";
      }
    }
    if (ans === "NA") {
      if (obj.answer === "NA") {
        answer = "U";
      } else {
        answer = "NA";
      }
    }
    if (ans === "") {
      if (markForReview !== obj.markForReview) {
        answer = obj.answer;
        obj.markForReview = true;
      } else {
        answer = obj.answer;
        obj.markForReview = false;
      }
    }

    let requirementMode: any = [];
    if (requirementList) {
      requirementMode = [...requirementList];
      let questionObj: any = {};
      requirementList.forEach((data: any, groupIndex: any) => {
        data.subCategories.forEach((val: any, catIndex: any) => {
          val.questions.forEach((queObj: any, queIndex: any) => {
            if (queObj === obj) {
              questionObj = queObj;
              questionObj["answer"] = answer;
              questionObj["QuestionId"] = obj.questionId;
              questionObj["QuestionNumber"] = obj.displayNumber;
              requirementMode[groupIndex].subCategories[catIndex].questions[
                queIndex
              ] = questionObj;
              setRequirementList(requirementMode);
              getQuestionData(questionData);
            }
          });
        });
      });
    }

    const submitObject = {
      altAnswerText: null,
      questionType: "Requirement",
      answerText: answer,
      comment: obj.comment,
      is_Maturity: false,
      componentGuid,
      feedback: null,
      answer_Id: obj.answer_Id,
      is_Component: false,
      is_Requirement: true,
      markForReview: obj.markForReview,
      questionId: obj.questionId,
      questionNumber: obj.displayNumber,
      // reviewed: obj.reviewed,
    };
    submit(submitObject);
  };

  const submit = async (obj: any, feature?: any) => {
    try {
      await fetch(CSET_URL + "api/answerquestion", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(obj),
      })
        .then((data) => {
          setCommentValue("");
          if (feature) {
            setFormState((formState) => ({
              ...formState,
              isSuccess: true,
              isUpdate: false,
              isDelete: false,
              isFailed: false,
              errMessage: `${featureName} Added Successfully !`,
            }));
          }
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {}
  };
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setExpandAll(undefined);
    };

  const getRefrenceData = async (queData: any, showRef: any) => {
    setCommentValue(queData.comment);
    fetch(
      `${CSET_URL}api/details?questionid=${queData.questionId}&=&questionType=Requirement`,
      {
        method: "POST",
        headers: headerObj,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        data.listTabs.forEach((values: any) => {
          if (values?.resourceDocumentList) {
            questionListmessageDisplay(values);
          }
          if (values?.sourceDocumentsList) {
            questionListmessageDisplay(values);
          }
        });
        for (let i = 0; i < data.listTabs.length; i++) {
          if (data.listTabs[i].requirementsData.supplementalInfo === null) {
            setSupplementInfo([
              ...supplementInfo,
              "(no supplemental guidance available)",
            ]);
          } else if (data.listTabs[i].requirementsData.supplementalInfo) {
            setSupplementInfo([
              ...supplementInfo,
              data.listTabs[i].requirementsData.supplementalInfo,
            ]);
          }
        }
        if (showRef) {
          showQuestionRefList(true);
        }
      })
      .catch((err: any) => {
        sentry_error_catch(err,setLoading,setFormState)
      });
  };

  const getTotalQuestions = () => {
    let TotalCount: any = 0;
    if (currentTableData !== undefined) {
      const tempArray: any = [];
      currentTableData[0]?.subCategories.forEach((element: any) => {
        tempArray.push(element.questions.length);
      });
      for (let i = 0; i < tempArray.length; ++i) {
        TotalCount += tempArray[i];
      }
    }
    return TotalCount;
  };

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const loaderPercentage = (que: any) => {
    const answered = que.filter(
      (item: any) =>
        item.answer === "Y" || item.answer === "N" || item.answer === "NA"
    );
    const notAnswered = que.filter(
      (item: any) => item.answer === "U" || item.answer === null
    );
    const val = (answered.length / que.length) * 100;
    if (que.length === notAnswered.length) {
      return <CircularProgresBar style={{ color: "#BEBEBE" }} value={100} />;
    } else {
      return <CircularProgresBar value={val} />;
    }
  };

  const getTotalQuestionsAns = () => {
    let TotalAnswerd: any = 0;
    if (currentTableData !== undefined) {
      const tempArray: any = [];
      currentTableData[0]?.subCategories.forEach((element: any) => {
        element.questions.forEach((questionElement: any) => {
          tempArray.push(questionElement.answer);
        });
      });
      for (let i = 0; i < tempArray.length; ++i) {
        if (tempArray[i] !== "U" && tempArray[i] !== null) {
          TotalAnswerd++;
        }
      }
    }
    return TotalAnswerd;
  };

  const getImportanceData = () => {
    fetch(`${CSET_URL}api/GetImportance`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.json())
      .then((data) => {
        setImportanceValueList(data);
      })
      .catch((err: any) => {
        sentry_error_catch(err,setLoading,setFormState)
      });
  };

  const getFindingData = (queData: any) => {
    fetch(
      `${CSET_URL}api/GetFinding?Answer_Id=${
        queData.answer_Id ? queData.answer_Id : 0
      }&Finding_Id=0&question_Id=${queData.questionId}&questionType=Question`,
      {
        method: "POST",
        headers: headerObj,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setFindingData(data);
        setLoading(false);
        if (editObservationData === undefined) {
          postObservations(data);
        }
      })
      .catch((err: any) => {
        sentry_error_catch(err,setLoading,setFormState)
      });
  };

  const postObservations = async (data: any) => {
    const postObservationObj: any = {
      question_Id: quesData.questionId,
      answer_Id: quesData.answer_Id ? quesData.answer_Id : 0,
      finding_Id: data.finding_Id,
      summary: title,
      issue: issues,
      impact: impacts,
      recommendations,
      vulnerabilities,
      resolution_Date: resolutionDate,
      importance_Id: importance.importance_Id,
      importance: null,
      finding_Contacts: [
        {
          finding_Id: 0,
          assessment_Contact_Id: 15524,
          name: "zostelpartner@mail.com -- Zostel  Partner",
          selected: true,
        },
      ],
    };
    await fetch(`${CSET_URL}api/AnswerSaveDiscovery`, {
      method: "POST",
      headers: headerObj,
      body: JSON.stringify(postObservationObj),
    })
      .then((data) => {})
      .catch((err: any) => {
        sentry_error_catch(err,setLoading,setFormState)
      });
  };

  const handleDocumentChange = (event: any) => {
    try{
    let validFile = false;
    const fileTypes: any = [".pdf", ".txt", ".docx", ".doc", ".xlsx"];
    if (event && event?.target?.files && event?.target?.files[0]?.name) {
      fileTypes.forEach((filetype: any) => {
        if (event.target.files[0].name.endsWith(filetype)) {
          validFile = true;
        }
        if (validFile) {
          return;
        }
      });
      if (validFile) {
        setDocumentData(event.target.files[0]);
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isFailed: false,
          errMessage: "",
        }));
      } else {
        setDocumentData("");
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isFailed: true,
          errMessage: "Selected File Type is not Valid.",
        }));
      }
    }
    event.target.value = null;
   }catch(err){
    showBoundary(err);
   }
  };
  /**
   *
   * @param fileItem
   * @param extraData
   */
  const fileUpload = async () => {
    const formData: FormData = new FormData();
    let docId;
    setIsLoadingDocument(true);
    try {
      if (!documentData) {
        setIsFileSet(true);
      } else {
        const existingDocumentsArr = await getDocument({
          variables: {
            question_id: quesData.questionId,
            assessment_id: +props.ccAssessmentInfo.id,
            featureType: "Document",
          },
        }).then((data: any) => {
          return data.data.questionDocuments || [];
        })
        .catch((err: any) => {
          sentry_error_catch(err, setLoading, setFormState);
          return []; 
        });

        // console.log("existingDocuments",existingDocuments[0].document_id)

        let fileName = documentData.name;
        let orignalName = fileName.replace(/\.[^/.]+$/, ""); 
        let extension = fileName.split('.').pop(); 
        let count = 1;
        let updatedFileNames = [...existingFileNames];
        if(existingDocumentsArr[0]?.document_id.length > 0){
          const fetchedFileNames = existingDocumentsArr[0].document_id.map((doc: any) => doc.name);
          updatedFileNames = [...fetchedFileNames];
          setExistingFileNames(updatedFileNames);
          if (updatedFileNames.includes(`${orignalName}_${questionNumber}.${extension}`)) {
            count = updatedFileNames.filter(item => item.includes(`${orignalName}_${questionNumber}`)).length
            fileName = `${orignalName}_${questionNumber}_${count}.${extension}`;
          }else{
            fileName = `${orignalName}_${questionNumber}.${extension}`;
            formData.append("files", new File([documentData], fileName));
          }
          
  
          formData.append("files", new File([documentData], fileName));
        }else{
          fileName = `${orignalName}_${questionNumber}.${extension}`;
          formData.append("files", new File([documentData], fileName));
        }
        await fetch(process.env.REACT_APP_SERVER_URL + "upload/", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authSession}`,
          },
          body: formData,
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error(res.statusText);
          })
          .then((data: any) => {
            setExistingFileNames((prevFileNames) => [...prevFileNames, fileName]);
            docId = data[0].id;
          })
          .catch((err: any) => {
            sentry_error_catch(err,setLoading,setFormState)
          });

        const data: any = await getDocument({
          variables: {
            question_id: quesData.questionId,
            assessment_id: +props.ccAssessmentInfo.id,
            featureType: "Document",
          },
        })
          .then((data) => data)
          .catch((err: any) => {
                sentry_error_catch(err,setLoading,setFormState)
              });
        if (
          data?.data &&
          data?.data?.questionDocuments &&
          data.data.questionDocuments.length !== 0
        ) {
          const id: any = +data.data.questionDocuments[0].id;
          const updateDoc: any = [];
          for (const i of data.data.questionDocuments[0].document_id) {
            updateDoc.push(i.id);
          }
          updateDoc.push(docId);
          updateDocument({
            variables: {
              id,
              document_id: updateDoc,
            },
          })
            .then((data: any) => {
              const newDoc =
                data?.data?.updateQuestionDocument.questionDocument.document_id.pop();
              setDocumentList((documentList: any) => [...documentList, newDoc]);
              setFormState((formState) => ({
                ...formState,
                isSuccess: true,
                errMessage: "Document Added Successfully.",
              }));
            })
            .catch((err: any) => {
              sentry_error_catch(err,setLoading,setFormState)
            });
        } else {
          createDocument({
            variables: {
              question_id: quesData.questionId,
              flow_type: props.flowType.type,
              assessment_id: +props.ccAssessmentInfo.id,
              practise,
              document_id: docId,
              featureType: "Document",
              question_Text: quesData.questionText,
            },
          })
            .then((data: any) => {
              const newDoc =
                data?.data?.createQuestionDocument.questionDocument.document_id.pop();
              setDocumentList((documentList: any) => [newDoc]);
              setFormState((formState) => ({
                ...formState,
                isSuccess: true,
                errMessage: "Document Added Successfully.",
              }));
            })
            .catch((err: any) => {
              sentry_error_catch(err,setLoading,setFormState)
            });
        }
      }
    } catch (err: any) {
      sentry_error_catch(err,setLoading,setFormState)
    }
    setDocumentData("");
    setIsLoadingDocument(false);
  };

  const handleOkay = () => {
    setShowFeedbackDialogBox(false);
    setShowObservations(false);
    setShowCommentDialogBox(false);
    setShowAddObservations(false);
    if (featureName === "Observation") {
      if (editObservationData) {
        postObservations(findingData);
      } else {
        getFindingData(quesData);
      }
    } else {
      setCommentValue("");
      setFeedbackValue("");
      const submitObject = {
        altAnswerText: null,
        questionType: "Question",
        answerText: quesData.answer,
        comment: commentValue,
        is_Maturity: false,
        componentGuid,
        feedback: feedbackValue,
        answer_Id: quesData.answer_Id,
        is_Component: false,
        is_Requirement: true,
        markForReview: quesData.markForReview,
        questionId: quesData.questionId,
        questionNumber: quesData.displayNumber,
        reviewed: false,
      };
      submit(submitObject, true);
      getRequirementList();
    }
  };
  const handleSupplement = (quoData: any, initial: any) => {
    try{
    if (suppleQues.includes(quoData.questionId)) {
      const index = suppleQues.findIndex(
        (id: any) => id === quoData.questionId
      );
      setSuppleQues((suppleQues: any) =>
        suppleQues.filter((id: any) => id !== quoData.questionId)
      );
      setSupplementInfo((supplementInfo: any) =>
        supplementInfo.filter((info: any, i: any) => index !== i)
      );
    } else {
      setSuppleQues((suppleQues: any) => [...suppleQues, quoData.questionId]);
      getRefrenceData(quoData, false);
    }
    }catch(err){
      showBoundary(err);
    }
  };
  const handleFeature = async (name: any, que: any, practice?: any) => {
    try{
    setQuesData(que);
    setFeatureName(name);
    getRefrenceData(que, false);
    if (name === "Observation") {
      setShowObservations(true);
    } else if (name === "Comment") {
      setShowCommentDialogBox(true);
    } else if (name === "Feedback") {
      setShowFeedbackDialogBox(true);
    }
    if (name === "Document") {
      setQuestionNumber(que.displayNumber)
      setPractise(`${practice} -> ${que.displayNumber}`);
      const dataDocumentList: any = await getDocument({
        variables: {
          question_id: que.questionId,
          assessment_id: +props.ccAssessmentInfo.id,
          featureType: "Document",
        },
      })
        .then((data: any) => data?.data?.questionDocuments[0]?.document_id)
        .catch((err: any) => {
                sentry_error_catch(err,setLoading,setFormState)
              });
      handleAlertClose();
      setShowDocuments(true);
      setDocumentList(dataDocumentList);
    }
    getImportanceData();
    }catch(err){
      showBoundary(err);
    }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  const handleClose = () => {
    setShowCommentDialogBox(false);
    setShowFeedbackDialogBox(false);
    setShowObservations(false);
    setShowAddObservations(false);
    setShowDocuments(false);
    setExistingFileNames([])
    setDocumentData("");

    if (practise) {
      const newpractise = practise?.split("->");
      handleDocumentToggle(`${newpractise[0]}->${newpractise[1]}`);
    }
  };

  const onClickHandler = async (docInfo: any, val: any, docList?: any) => {
    try{
    if (val === "download") {
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const urlDoc = docInfo.url.slice(1);
      const url = serverUrl + urlDoc;
      await fetch(url, {
        method: "GET",
      })
        .then((res: any) => res.blob())
        .then((data: any) => {
          if (data) {
            saveAs(data, docInfo.name);
          }
        })
        .catch((err: any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
    }
    if (val === "delete") {
      setIsLoadingDocument(true);
      deleteDocumentFileById({
        variables: {
          id: docInfo.id,
        },
      })
        .then(async (data: any) => {
          const docData: any = await getDocument({
            variables: {
              question_id: quesData.questionId,
              assessment_id: +props.ccAssessmentInfo.id,
              featureType: "Document",
            },
          })
            .then((data) => data)
            .catch((err: any) => {
              sentry_error_catch(err,setLoading,setFormState)
            });
          setDocumentList(
            (documentList: any) =>
              docData?.data?.questionDocuments[0]?.document_id
          );
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            errMessage: "Document Deleted Successfully.",
          }));
          if (docList.length <= 1) {
            deleteDocument({
              variables: {
                id: docData?.data?.questionDocuments[0]?.id,
              },
            })
              .then((data) => {})
              .catch((err: any) => {
                sentry_error_catch(err,setLoading,setFormState)
              });
          }
          setIsLoadingDocument(false);
        })
        .catch((err: any) => {
          sentry_error_catch(err,setLoading,setFormState)
          setIsLoadingDocument(false);
        });
      closeDialogBox();
    }
    }catch(err){
      showBoundary(err)
    }
  };

  const handleCommentChange = (event: any) => {
    setCommentValue(event.target.value);
  };
  const handlefeedbackChange = (event: any) => {
    setFeedbackValue(event.target.value);
  };
  const handleChangeIssues = (event: any) => {
    setIssues(event.target.value);
  };
  const handleChangeTitle = (event: any) => {
    setTitle(event.target.value);
  };
  const handleChangeImportance = (event: any, selectedValue: any) => {
    setImportance(selectedValue);
  };
  const handleChangevulnerabilities = (event: any) => {
    setVulnerabilities(event.target.value);
  };
  const handleChangeImpacts = (event: any) => {
    setImpacts(event.target.value);
  };
  const handleChangeRecommendations = (event: any) => {
    setRecommendations(event.target.value);
  };
  const handleChangeresolutionDate = (event: any) => {
    setResolutionDate(new Date(event.target.value));
  };

  const handleOkayObservation = () => {
    setShowObservations(false);
    setShowAddObservations(true);
  };

  const openFileHandler = () => {
    inputFileRef.current.click();
  };
  const openDialogBoxHandler = (doc: any) => {
    setDoc(doc);
    setOpenDialogBox(true);
  };
  const closeDialogBox = () => {
    setOpenDialogBox(false);
  };
  const confirmDelete = (doc: any, documentList: any) => {
    onClickHandler(doc, "delete", documentList);
  };
  async function handleDocumentToggle(practise: any) {
    try{
    await getAllDocuments({
      variables: {
        featureType: "Document",
        assId: +props?.ccAssessmentInfo?.id,
        practise,
      },
    })
      .then((data: any) => {
        setAllDocuments(data.data.questionDocuments);
      })
      .catch((err: any) => {
        sentry_error_catch(err,setLoading,setFormState)
      });
    }catch(err){
      showBoundary(err);
    }
  }
  const handleUploadToolTipOpen = () => {
    setShowTooltip(true);
  };
  const handleUploadToolTipClose = () => {
    setShowTooltip(false);
  };
  return (
    <React.Fragment>
      {requirementList ? (
        <React.Fragment>
          {currentTableData?.map((obj: any, j: any) => {
            return (
              <React.Fragment key={obj?.groupHeadingText}>
                <span className={styles.CompanyFormHead}>
                  {obj.groupHeadingText} - {obj.standardShortName} / Total
                  questions:{" "}
                  {getTotalQuestions() !== undefined
                    ? `${getTotalQuestionsAns()} / ${getTotalQuestions()}`
                    : ""}
                </span>
                {/* <Paper className={styles.paper}> */}
                <div className={styles.uploadFilePaper}>
                  {formState.isSuccess ? (
                    <Alert
                      severity="success"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={handleAlertClose}>
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }>
                      <strong>{formState.errMessage}</strong>
                    </Alert>
                  ) : null}
                  {formState.isFailed ? (
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={handleAlertClose}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {formState.errMessage}
                    </Alert>
                  ) : null}
                </div>
                <DialogBox
                  open={isFileSet}
                  handleOk={() => {
                    setIsFileSet(false);
                  }}
                  skipCancel={true}
                  buttonOk={"Ok"}
                  classes={{
                    root: styles.MainOfficeDialogRoot,
                    container: styles.MainOfficeDialogboxContainer,
                    paper: styles.MainOfficeDialogboxPaper,
                    scrollPaper: styles.MainOfficeScrollPaper,
                  }}>
                  <div className={styles.DialogBoxTitle}>
                    <Typography component="h1" variant="h1">
                      Warning
                    </Typography>
                  </div>
                  <div className={styles.DialogBoxContext}>
                    <p>Please Select a File.</p>
                  </div>
                </DialogBox>
                <DialogBox
                  open={openDialogBox}
                  handleOk={() => {
                    confirmDelete(doc, documentList);
                  }}
                  handleCancel={closeDialogBox}
                  buttonOk={"Yes"}
                  buttonCancel={"No"}
                  classes={{
                    root: styles.MainOfficeDialogRoot,
                    container: styles.MainOfficeDialogboxContainer,
                    paper: styles.MainOfficeDialogboxPaper,
                    scrollPaper: styles.MainOfficeScrollPaper,
                  }}>
                  <div className={styles.DialogBoxTitle}>
                    <Typography component="h1" variant="h1">
                      Please Confirm
                    </Typography>
                  </div>
                  <div className={styles.DialogBoxContext}>
                    <p>Are you sure you want to remove {documentData?.name}?</p>
                  </div>
                </DialogBox>
                <div>
                  <Fade in={showCommentDialogBox}>
                    <Paper elevation={4}>
                      <DialogBox
                        open={showCommentDialogBox}
                        handleOk={handleOkay}
                        handleCancel={handleClose}
                        buttonCancel={"Close"}
                        buttonOk={"save"}
                        classes={{
                          root: styles.MainOfficeDialogRoot,
                          container: styles.refrenceDialogboxContainer,
                          paper: styles.refrenceDialogboxPaper,
                          scrollPaper: styles.MainOfficeScrollPaper,
                        }}>
                        <>
                          <div className={styles.DialogBoxTitle}>
                            <Typography component="h1" variant="h1">
                              {featureName}
                            </Typography>
                          </div>
                          <div className={styles.DialogBoxContext}>
                            <textarea
                              id="comment"
                              rows={4}
                              cols={50}
                              name={featureName}
                              value={commentValue || ""}
                              onChange={handleCommentChange}></textarea>
                          </div>
                        </>
                      </DialogBox>
                    </Paper>
                  </Fade>
                </div>
                <div>
                  <Fade in={showFeedbackDialogBox}>
                    <Paper elevation={4}>
                      <DialogBox
                        open={showFeedbackDialogBox}
                        handleOk={handleOkay}
                        handleCancel={handleClose}
                        buttonCancel={"Close"}
                        buttonOk={"save"}
                        classes={{
                          root: styles.MainOfficeDialogRoot,
                          container: styles.refrenceDialogboxContainer,
                          paper: styles.refrenceDialogboxPaper,
                          scrollPaper: styles.MainOfficeScrollPaper,
                        }}>
                        <>
                          <h1>
                            {" "}
                            <b>{featureName}</b>
                          </h1>
                          <textarea
                            id="feedback"
                            rows={4}
                            cols={50}
                            name={featureName}
                            value={
                              quesData?.feedback
                                ? quesData?.feedback
                                : feedbackValue
                            }
                            onChange={handlefeedbackChange}></textarea>
                        </>
                      </DialogBox>
                    </Paper>
                  </Fade>
                </div>
                <div>
                  <Fade in={showDocuments}>
                    <Paper elevation={4}>
                      <DialogBox
                        open={showDocuments}
                        handleOk={fileUpload}
                        handleCancel={handleClose}
                        buttonCancel={"Close"}
                        buttonOk={"save"}
                        classes={{
                          root: styles.MainOfficeDialogRoot,
                          container: styles.refrenceDialogboxContainer,
                          paper: styles.refrenceDialogboxPaper,
                          scrollPaper: styles.MainOfficeScrollPaper,
                        }}>
                        <>
                          <div className={styles.DialogBoxTitle}>
                            <Typography component="h1" variant="h1">
                              {featureName}s
                            </Typography>
                          </div>
                          {isLoadingDocument ? <SimpleBackdrop /> : null}
                          <div className={styles.DialogBoxContext}>
                            <table className={styles.tableStyle}>
                              <tr>
                                <th style={{ textAlign: "left" }}>File Name</th>
                                <th
                                  style={{
                                    width: "5px",
                                    textAlign: "center",
                                  }}></th>
                                <th
                                  style={{
                                    width: "5px",
                                    textAlign: "center",
                                  }}></th>
                              </tr>
                              {documentList && documentList?.length !== 0 ? (
                                documentList.map((doc: any, index: any) => {
                                  return (
                                    <tr key={doc?.id}>
                                      <td>{doc.name}</td>
                                      <td
                                        style={{
                                          width: "5px",
                                          textAlign: "center",
                                        }}>
                                        <GetAppIcon
                                          style={{
                                            color: "#4b0079",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            onClickHandler(doc, "download");
                                          }}
                                        />{" "}
                                      </td>
                                      <td
                                        style={{
                                          width: "5px",
                                          textAlign: "center",
                                        }}>
                                        <DeleteOutlineIcon
                                          style={{
                                            color: "#4b0079",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            openDialogBoxHandler(doc);
                                          }}
                                        />{" "}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <h4 style={{ alignItems: "center" }}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;No documents Found.
                                </h4>
                              )}
                            </table>
                            <br />
                            &nbsp;&nbsp;
                            <Tooltip
                              placement="top-start"
                              title={
                                "Note: File type should be pdf,word,excel or text."
                              }
                              open={showTooltip}
                            >
                              <label
                                htmlFor="file"
                                onMouseLeave={handleUploadToolTipClose}
                                onMouseEnter={handleUploadToolTipOpen}>
                                <Button
                                  id="addDocument"
                                  variant={"contained"}
                                  color="primary"
                                  type="button"
                                  onClick={openFileHandler}>
                                  Add Document
                                </Button>
                              </label>
                            </Tooltip>
                            <span className={styles.spanStyle}>
                              &nbsp;
                              {documentData?.name ? (
                                documentData?.name
                              ) : formState.isFailed ? (
                                <b style={{ color: "red" }}>
                                  Not a Valid File.
                                </b>
                              ) : null}
                            </span>
                            <input
                              type="file"
                              ref={inputFileRef}
                              onChange={handleDocumentChange}
                              style={{ display: "none" }}
                            />
                          </div>
                        </>
                      </DialogBox>
                    </Paper>
                  </Fade>
                </div>
                <div>
                  <Fade in={showAddObservations}>
                    <Paper elevation={4}>
                      <DialogBox
                        open={showAddObservations}
                        handleOk={handleOkay}
                        handleCancel={handleClose}
                        buttonCancel={"Close"}
                        buttonOk={"save"}
                        classes={{
                          root: styles.MainOfficeDialogRoot,
                          container: styles.refrenceDialogboxContainer,
                          paper: styles.refrenceDialogboxPaper,
                          scrollPaper: styles.MainOfficeScrollPaper,
                        }}>
                        <div className={styles.DialogBoxContext}>
                            <div className={styles.DialogBoxContext}>
                              The question observation section is for advanced
                              users that want to collect extra information about
                              specific questions in the assessment.
                            </div>
                            <Input
                              id="title"
                              name="title"
                              label="Title"
                              variant="outlined"
                              value={title}
                              onChange={handleChangeTitle}
                            />
                            <AutoCompleteDropDown
                              {...getImportanceValue}
                              id="importance"
                              value={importance}
                              name="Importance"
                              onChange={handleChangeImportance}
                              renderInput={(
                                params:
                                  | (JSX.IntrinsicAttributes &
                                      import("@mui/material").StandardTextFieldProps)
                                  | (JSX.IntrinsicAttributes &
                                      import("@mui/material").FilledTextFieldProps)
                                  | (JSX.IntrinsicAttributes &
                                      import("@mui/material").OutlinedTextFieldProps)
                              ) => (
                                <Input
                                  {...params}
                                  id="assetValue"
                                  label="Importance"
                                />
                              )}
                            />
                            <Input
                              id="resolutionDate"
                              name="resolutionDate"
                              type="date"
                              label="Resolution Date"
                              variant="outlined"
                              value={resolutionDate}
                              onChange={handleChangeresolutionDate}
                            />
                            <p>Issues</p>
                            <textarea
                              id="issues"
                              name="issues"
                              rows={4}
                              cols={105}
                              value={issues}
                              onChange={handleChangeIssues}></textarea>
                            <p>Impacts</p>
                            <textarea
                              id="impacts"
                              name="impacts"
                              rows={4}
                              cols={105}
                              value={impacts}
                              onChange={handleChangeImpacts}></textarea>
                            <p>Recommendations</p>
                            <textarea
                              id="recommendations"
                              name="recommendations"
                              rows={4}
                              cols={105}
                              value={recommendations}
                              onChange={handleChangeRecommendations}></textarea>
                            <p>Vulnerabilities</p>
                            <textarea
                              id="vulnerabilities"
                              name="vulnerabilities"
                              rows={4}
                              cols={105}
                              value={vulnerabilities}
                              onChange={handleChangevulnerabilities}></textarea>
                        </div>
                      </DialogBox>
                    </Paper>
                  </Fade>
                </div>
                <div>
                  <Fade in={showObservations}>
                    <Paper elevation={4}>
                      <DialogBox
                        open={showObservations}
                        handleOk={handleOkayObservation}
                        handleCancel={handleClose}
                        buttonCancel={"Close"}
                        buttonOk={"Add Observation"}
                        classes={{
                          root: styles.MainOfficeDialogRoot,
                          container: styles.refrenceDialogboxContainer,
                          paper: styles.refrenceDialogboxPaper,
                          scrollPaper: styles.MainOfficeScrollPaper,
                        }}>
                        <div className={styles.DialogBoxTitle}>
                          <Typography component="h1" variant="h1">
                            Observation
                          </Typography>
                          <table
                            id="ReferenceTable"
                            className={styles.tableStyle}>
                            <tr>
                              <th colSpan={3}>Observation Title</th>
                              <th colSpan={2}>Importance</th>
                              <th colSpan={2}>&nbsp;</th>
                            </tr>
                            {findings.map((element: any, index: any) => {
                              return (
                                <tr key={element?.id}>
                                  <td
                                    colSpan={3}
                                    className={styles.referenceText}>
                                    {" "}
                                    {element.summary}
                                  </td>
                                  <td colSpan={2}>
                                    {element.importance.value}
                                  </td>
                                  <td colSpan={2}>
                                      <Button
                                        id="deleteButton"
                                        onClick={() =>
                                          console.log(element)
                                        }>
                                        Delete
                                      </Button>
                                      <Button
                                        id="editButton"
                                        onClick={() =>
                                          setEditObservationData(element)
                                        }>
                                        Edit
                                      </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </DialogBox>
                    </Paper>
                  </Fade>
                </div>
                {obj.subCategories.map((element: any, i: any) => {
                  return (
                    <React.Fragment key={element?.navigationGUID}>
                      <Accordion
                        className={styles.ComplianceAccordion}
                        TransitionProps={{ unmountOnExit: true }}
                        key={element?.navigationGUID}
                        expanded={
                          expandAll !== undefined
                            ? expandAll
                            : expanded === element
                        }
                        onChange={handleChange(element)}
                        onClick={() => {
                          handleDocumentToggle(
                            `${obj.groupHeadingText} ${
                              obj.standardShortName
                                ? "-" + obj.standardShortName
                                : ""
                            } -> ${element.subCategoryHeadingText}`
                          );
                        }}>
                        <AccordionSummary
                          className={styles.ComplianceAccordionSummary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          key={element?.navigationGUID}>
                          <Typography className={styles.heading}>
                            {element.subCategoryHeadingText}{" "}
                            <span>
                              {element.questions.filter(
                                (x: any) => x.markForReview
                              ).length > 0
                                ? "Marked for Review"
                                : ""}
                            </span>
                          </Typography>
                          {loaderPercentage(element.questions)}
                        </AccordionSummary>
                        <AccordionDetails
                          className={styles.ComplianceAccordionDetails}>
                          <Grid container spacing={0}>
                            {element.questions.map((que: any, index: any) => {
                              return (
                                <Grid
                                  container
                                  alignItems="center"
                                  spacing={0}
                                  key={que?.questionId}>
                                  <Grid
                                    item
                                    xs={9}
                                    sm={9}
                                    className={styles.qContainerWrap}>
                                    <div className={styles.qContainer}>
                                      <div className={styles.qno}>
                                        {" "}
                                        {que?.displayNumber}.
                                      </div>
                                      <div className={styles.qtext}>
                                        {" "}
                                        {que?.questionText?.replaceAll(
                                          /(<([^>]+)>)/gi,
                                          " "
                                        )}
                                      </div>
                                    </div>
                                    <div className={styles.qIconContainer}>
                                      <MessageIcon
                                        id="comment"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleFeature("Comment", que)
                                        }
                                      />
                                      <DescriptionIcon
                                        id="Document"
                                        style={{
                                          color: allDocuments?.find(
                                            (x: any) =>
                                              x?.assessment_id?.id ===
                                                props?.ccAssessmentInfo?.id &&
                                              x?.question_id ===
                                                que?.questionId &&
                                              x?.document_id?.length > 0
                                          )
                                            ? "#707070"
                                            : "#4b0079",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleFeature(
                                            "Document",
                                            que,
                                            `${obj.groupHeadingText} ${
                                              obj.standardShortName
                                                ? "-" + obj.standardShortName
                                                : ""
                                            } -> ${
                                              element.subCategoryHeadingText
                                            }`
                                          )
                                        }
                                      />
                                      <button
                                        id="Supplement"
                                        style={{
                                          borderStyle: "none",
                                          backgroundColor: "white",
                                          padding: 0,
                                          lineHeight: 1,
                                          display: "flex",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleSupplement(que, true)
                                        }>
                                        <svg
                                          viewBox="0 0 30 30"
                                          style={{
                                            marginTop: "4px",
                                            fontSize: "1.2rem",
                                          }}>
                                          <path
                                            fill="#4b0079"
                                            stroke="transparent"
                                            stroke-width="0"
                                            transform="scale(.75)"
                                            d="M18.879,29.328C17.64,29.934 16.341,30.24 14.986,30.24 14.044,30.24 13.424,29.824 13.125,28.99 12.927,28.434 13.014,27.26 13.386,25.472L15.071,17.634C15.214,16.934 15.287,16.422 15.287,16.09 15.287,15.614 15.063,15.364 14.615,15.336L13.088,15.228C12.989,15.05 12.945,14.794 12.958,14.466 12.972,14.134 13.039,13.892 13.164,13.74 15.756,13.402 17.941,13.236 19.718,13.236 20.239,13.236 20.499,13.506 20.499,14.044 20.499,14.24 20.462,14.516 20.384,14.868 20.308,15.22 20.206,15.686 20.077,16.27 19.949,16.85 19.846,17.31 19.772,17.65L18.119,25.136C17.758,26.69 17.807,27.464 18.263,27.464 18.49,27.464 18.877,27.294 19.429,26.954 19.981,26.612 20.487,26.19 20.948,25.682 21.171,25.682 21.391,25.802 21.608,26.042 21.825,26.286 21.971,26.518 22.045,26.74 21.175,27.856 20.121,28.718 18.879,29.328 M16.841,5.47C17.307,4.998 17.962,4.764 18.804,4.764 19.538,4.764 20.133,5.024 20.592,5.546 21.052,6.068 21.281,6.652 21.281,7.296 21.281,7.856 21.028,8.388 20.518,8.896 20.008,9.406 19.363,9.66 18.582,9.66 17.849,9.66 17.26,9.404 16.812,8.888 16.365,8.374 16.142,7.788 16.142,7.126 16.142,6.496 16.375,5.942 16.841,5.47 M17.5,0C7.835,0 0,7.834 0,17.5 0,27.164 7.835,35 17.5,35 27.166,35 35,27.164 35,17.5 35,7.834 27.166,0 17.5,0"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    className={styles.qTabContainer}>
                                    <div className={styles.TabButtons}>
                                      <div className={styles.ComplainceDiv}>
                                        <div className={styles.TabOptions}>
                                          <Button
                                            id="yesButton"
                                            className={
                                              que.answer === "Y"
                                                ? styles.TabOptionsActive
                                                : styles.TabOptionsInactive
                                            }
                                            onClick={() =>
                                              onButtonClick("Y", que)
                                            }
                                            value={"Yes"}>
                                            <p
                                              className={
                                                que.answer === "Y"
                                                  ? styles.para
                                                  : styles.TabOptionsInactive
                                              }>
                                              {" "}
                                              Yes{" "}
                                            </p>
                                          </Button>
                                          <Button
                                            id="noButton"
                                            className={
                                              que.answer === "N"
                                                ? styles.TabOptionsActive
                                                : styles.TabOptionsInactive
                                            }
                                            onClick={() =>
                                              onButtonClick("N", que)
                                            }
                                            value={"No"}>
                                            <p
                                              className={
                                                que.answer === "N"
                                                  ? styles.para
                                                  : styles.TabOptionsInactive
                                              }>
                                              {" "}
                                              No{" "}
                                            </p>
                                          </Button>
                                          <Button
                                            id="naButton"
                                            className={
                                              que.answer === "NA"
                                                ? styles.TabOptionsActive
                                                : styles.TabOptionsInactive
                                            }
                                            onClick={() =>
                                              onButtonClick("NA", que)
                                            }
                                            value={"NA"}>
                                            <p
                                              className={
                                                que.answer === "NA"
                                                  ? styles.para
                                                  : styles.TabOptionsInactive
                                              }>
                                              {" "}
                                              NA{" "}
                                            </p>
                                          </Button>
                                          <Button
                                            id="refrenceButton"
                                            className={
                                              styles.TabOptionsInactive
                                            }
                                            onClick={() =>
                                              getRefrenceData(que, true)
                                            }
                                            value={"Reference"}>
                                            <p
                                              className={
                                                styles.TabOptionsInactive
                                              }>
                                              {" "}
                                              Reference{" "}
                                            </p>
                                          </Button>
                                          <Button
                                            id="markForReviewButton"
                                            className={
                                              que.markForReview
                                                ? styles.TabFlagOptionsActive
                                                : styles.TabFlagOptionsInactive
                                            }
                                            onClick={() =>
                                              onButtonClick("", que, true)
                                            }
                                            value={true}>
                                            <p
                                              className={
                                                que.markForReview
                                                  ? styles.paraFlag
                                                  : styles.TabOptionsInactiveFlag
                                              }>
                                              <FlagIcon />
                                            </p>
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                  {suppleQues.map((id: any, i: any) => {
                                    if (id === que.questionId) {
                                      return (
                                        <div key={id} className={styles.qtext}>
                                          <p key={id}
                                            dangerouslySetInnerHTML={createDangerousHTML(
                                              supplementInfo[i]
                                            )}></p>
                                        </div>
                                      );
                                    }
                                  })}
                                </Grid>
                              );
                            })}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default RequirementsMode;
