import React, { useEffect, useState } from 'react';
import classes from "./AdminAssessment.module.css";
import * as routeConstants from '../../../common/RouteConstants';
import * as msgConstants from "../../../common/MessageConstants";
import { Button } from "../../../components/UI/Form/Button/Button";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import Alert from "../../../components/UI/Alert/Alert";
import CloseIcon from '@mui/icons-material/Close';
import Input from "../../../components/UI/Form/Input/Input";
import ClearIcon from "@mui/icons-material//Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_LATEST_ASSESSMENT, GET_SOFT_DELETE_ASSESSMENT } from '../../../graphql/queries/CSETAssessment';
import { DELETE_CSET_ASSESSMENT } from '../../../graphql/mutations/CSETAssessment';
import { CSET_URL } from "../../../config/index";
import { deleteFileById } from "../../../graphql/mutations/Upload";
import { saveAs } from "file-saver";
import { sentry_error_catch } from '../../../common/sentry_error_catch';

const AdminAssessment: React.FC = (props:any) => {
  const history = useNavigate();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const timeFormat = "MM/DD/YYYY hh:mm a";
  const moment = require("moment-timezone");
  const contentType = "application/json";
  const deleteAssess= "Delete Assessment";
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [newData, setNewData] = useState<any>([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [rowsData, setRowsData] = useState<any>({});
  const [token, setToken] = useState<any>("");
  const location: any = useLocation();
  const [assessmentFilter,setAssessmentFilter] = useState<any>("");
  const [searchData,setSearchData] = useState<any>([]);
  const [assessmentDelete] = useMutation(DELETE_CSET_ASSESSMENT);
  const [assessmentReportDelete] = useMutation(deleteFileById);
  const [startDate,setStartDate] = useState<any>("");
  const [endDate,setEndDate] = useState<any>("");
  const [formState, setFormState] = useState({
    isDelete: false,
    isSuccess: false,
    isFailed: false,
    errMessage: "",
  })

  const headerObj = {
    "Content-Type": contentType,
    Authorization: token,
  }

  useEffect(() => {
    // csetUserLogin()
  }, [])
  // query to get assessment by client contact id.
  const [getLatestAssessment] = useLazyQuery(GET_LATEST_ASSESSMENT, {
    onCompleted: (assessmentData) => {
      setShowBackDrop(false);
      createTableDataObject(assessmentData?.getLatestAssessment?.latest_assessment_data)
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  })
  console.log("searchData",searchData)
  
  const [getSoftDeleteAssessment] = useLazyQuery(GET_SOFT_DELETE_ASSESSMENT, {
    onCompleted: (assessmentData) => {
      setShowBackDrop(false);
      createTableDataObject(assessmentData?.getDeleteAssessment?.delete_assessment_data)
    },
    onError: (err:any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  })
  
  // array to display the column.
  const column: any = [
    {title: "Partner Name", key: "partner_name"},
    { title: "Assessment Name", key: "assessment_name" },
    { title: "Client Name", key: "client_name" },
    { title: "Created on", key: "created_at" },
    { title: "Updated on", key: "updated_at" },
    { title: "Status", key: "status" },
    { title: "Assessment Type", key: "assessment_type" },
    { title: "Assessment Subtype", key: "assessment_subtype" },
    {
      key: "Download",
      title: "",
      icon: <GetAppIcon className={classes.iconStyle} />,
      tooltip: "Download",
      displayIcon: (rowData: any) => {
        return rowData?.allow_download  && location.state !== "deleteAssessment";
      },
    },
  ];
  //this effect gets called after every initial render. 
  useEffect(() => {
    if(location.state === "deleteAssessment"){
      setShowBackDrop(true);
      getSoftDeleteAssessment({
        fetchPolicy: msgConstants.FETCH_POLICY,
      })
    }else{
      setShowBackDrop(true)
      let startDateOfWeekFromNow = moment().subtract(7, 'days').calendar();
      const date = new Date(startDateOfWeekFromNow);
      getLatestAssessment({
        fetchPolicy: msgConstants.FETCH_POLICY,
        variables: {
          queryType:"week",
          todayDate: date.toISOString(),
          startDate:"",
          endDate:""
        }
      })
    }
  }, [])

  // function to handel click event to action button 
  const onRowClick:any = (rowData: any, val: string) => {
    if (val === deleteAssess) {
      setRowsData(rowData);
      setOpenDialogBox(true);
    }
    if(val === "Download"){
      onDownload(rowData);
    }
  }
  const onDownload = async (rowData: any) => {
    if (rowData?.document_url !== "") {
      setShowBackDrop(true);
      if (rowData?.document_url) {
        const lawUrl = rowData?.document_url.slice(1);
        const DocUrl = serverUrl + lawUrl;
        await fetch(DocUrl, {
          method: "GET",
        })
          .then((res) => res.blob())
          .then((response) => {
            if (rowData.assessment_name) {
              saveAs(
                response,
                rowData.assessment_name.indexOf("%") > -1
                  ? rowData.assessment_name.slice(
                      0,
                      rowData.assessment_name.indexOf("%")
                    )
                  : rowData.assessment_name + ".zip"
              );
            }
            setShowBackDrop(false);
          })
          .catch((err:any)=>{
            sentry_error_catch(err,setShowBackDrop,setFormState)
          })
      }
    }
  };
  // data object creation to display in table
  const createTableDataObject = async (data: any,partner_names?:any) => {
    let arr: any = [];
    console.log("data",data,"----");
    if (data && data.length !== 0) {
        data.forEach(async (element: any, i: any) => {
          let toDelete = false;
          const obj: any = {}
          obj["id"] = element.id ? element.id : " ";
          obj["assessment_name"] = element.assessment_name ? element?.assessment_name?.indexOf("%") > -1 ? element?.assessment_name?.slice(
            0,
            element.assessment_name?.indexOf("%")
          ) : element?.assessment_name : " ";
          obj["client_name"] = element?.client_name ? element.client_name : " ";
          obj['assessment_id'] = element.assessment_id;
          obj['created_at'] = element.created_at ? moment(element.created_at).tz("US/Eastern").format(
            timeFormat
          ) : " ";
          obj['updated_at'] =  element.updated_at ? moment(element.updated_at).tz("US/Eastern").format(
            timeFormat
          ) : " ";
          obj["assessment_type"] = element?.cset_assessment_type?.group_Title ? element?.cset_assessment_type?.group_Title : element?.assessment_type;
          obj["assessment_subtype"] = element?.cset_assessment_type?.name ? element?.cset_assessment_type?.name : "-"
          obj["document_url"] = element?.assessment_document ? element.assessment_document.url : " ";
          if (element.assessment_document && element.assessment_document.url !== "") {
            obj['status'] = "Report Generated";
          } else {
            obj['status'] = "Incomplete Report";
          }
          if(location.state !== "deleteAssessment"){
            if(element.deleteData && element.deleteData.deleteAssessment[0].delete){
              obj["status"] = "Deleted ";
            }
          }
          if (element.deleteData) {
            toDelete = element.deleteData.deleteAssessment[0].delete;
          }
          console.log("element.assessment_document[0]?.url",element.assessment_document)
          obj["partner_name"] = element.partner_name;
          obj["allow_download"] = element.allow_download;
          obj["client_contactId"] = element.client_id ? element.client_id : " ";
          obj["to_Delete"] = toDelete;
          arr.push(obj);
        })
    }
    setNewData(arr);
    setSearchData(arr);
  }

  // to handle delete event.
  const confirmDelete = async () => {
    setOpenDialogBox(false);
    setShowBackDrop(true);
    if (rowsData.document_id) {
      assessmentReportDelete({
        fetchPolicy: msgConstants.FETCH_POLICY_1,
        variables: {
          id: parseInt(rowsData.document_id)
        }
      }).then((data) => {
      }).catch((err:any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState)
      })
    }
    if (token) {
      const headerObj = {
        "Content-Type": contentType,
        Authorization: token
      }
      fetch(`${CSET_URL}api/contacts/validateremoval?assessmentId=${rowsData.assessment_id}`, {
        method: "POST",
        headers: headerObj,
      }
      ).then((response) => response.json())
        .then((data) => {
          deleteAssessment(rowsData.assessment_id, token);
        }).catch((err:any) => {
          sentry_error_catch(err,setShowBackDrop,setFormState)
        })
    } else {
      onDeteleAssessment(rowsData.id)
    }
  }

  const deleteAssessment = async (id: any, token: any) => {
    try {
      await fetch(`${CSET_URL}api/contacts/remove`, {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify({ assessmentId: id }),
      })
        .then((response) => response.json())
        .then((data) => {
          onDeteleAssessment(rowsData.id);
        }).catch((err:any) => {
          sentry_error_catch(err,setShowBackDrop,setFormState)
        })
    } catch (err:any) {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  };

  const onDeteleAssessment = (id: any) => {
    assessmentDelete({
      variables: {
        id
      }
    }).then((data) => {
      setShowBackDrop(false);
      // getAssessmentByContactId({
      //   fetchPolicy: msgConstants.FETCH_POLICY_1,
      //   variables: {
      //     where: { contact_id: location?.state?.client_contact_id }
      //   }
      // })
      setFormState((formState) => ({
        ...formState,
        isDelete: true,
        isSuccess: true,
        isFailed: false,
        errMessage: `${rowsData.assessment_name} Assessment Deleted Successfully`,
      }))
    }).catch((err:any) => {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    })
  }
  const backToList = () => {
    history(routeConstants.ADMIN_DASHBOARD);
  }
  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isDelete: false,
      isSuccess: false,
      isFailed: false,
      errMessage: ""
    }))
  }
  const closeDialogBox = () => {
    setOpenDialogBox(false);
  }
  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }
  const handleAssessmentFilterChange = (event:any) => {
    if(event.target.name === "endDate"){
      setEndDate(event.target.value)
    }else if(event.target.name === "startDate"){
      setStartDate(event.target.value)
    }else{
      setAssessmentFilter(event.target.value)
    }
   }
   const searchResetHandler = (event:any) => {
     setAssessmentFilter("")
     setStartDate("")
     setEndDate("")
     setFormState((formState) => ({
      ...formState,
      isDelete: false,
      isSuccess: false,
      isFailed: false,
      errMessage: ""
     }))
     if((location.state !== "deleteAssessment")){
      setShowBackDrop(true)
      setSearchData([])
      setNewData([])
      let startDateOfWeekFromNow = moment().subtract(7, 'days').calendar();
      const date = new Date(startDateOfWeekFromNow);
      getLatestAssessment({
        fetchPolicy: msgConstants.FETCH_POLICY,
        variables: {
          queryType:"week",
          todayDate: date.toISOString(),
          startDate:"",
          endDate:""
        }
      })
    }else{
      setSearchData(newData)
    }
   }
   const searchHandler = (event:any) => {
    if(assessmentFilter.trim().length > 0){
       const search:any = []
       newData.forEach((element:any) => {
         if(element["assessment_name"].includes(assessmentFilter)){
           search.push(element)
         }
       });
       setSearchData(search)
    }else if(startDate === "" && location.state !== "deleteAssessment"){
      setSearchData([])
      setNewData([])
      setFormState((formState) => ({
        ...formState,
        isDelete: false,
        isSuccess: false,
        isFailed: true,
        errMessage: "Please Select the StartDate Field"
       }))
    }else if(endDate === "" && location.state !== "deleteAssessment"){
      setSearchData([])
      setNewData([])
      setFormState((formState) => ({
        ...formState,
        isDelete: false,
        isSuccess: false,
        isFailed: true,
        errMessage: "Please Select the EndDate Field"
       }))
    }
    else if(endDate !== "" && startDate !== "" && assessmentFilter === "" && location.state !== "deleteAssessment"){
      if(startDate > endDate && location.state !== "deleteAssessment"){
        setFormState((formState) => ({
          ...formState,
          isDelete: false,
          isSuccess: false,
          isFailed: true,
          errMessage: "Start Date must be smaller than EndDate"
        }))
      }
        setShowBackDrop(true)
        setSearchData([])
        setNewData([])
        getLatestAssessment({
          fetchPolicy: msgConstants.FETCH_POLICY,
          variables: {
            queryType:"range",
            todayDate: "",
            startDate:startDate,
            endDate:endDate
          }
        })
       
    }else{
      console.log(true)
    }
   }
  return <React.Fragment>
    <CssBaseline />
    {showBackDrop ? <SimpleBackdrop /> : null}
    <DialogBox
      open={openDialogBox}
      handleOk={confirmDelete}
      handleCancel={closeDialogBox}
      buttonOk={"Yes"}
      buttonCancel={"No"}
      classes={{
        root: classes.MainOfficeDialogRoot,
        container: classes.MainOfficeDialogboxContainer,
        paper: classes.MainOfficeDialogboxPaper,
        scrollPaper: classes.MainOfficeScrollPaper,
      }}
    >
      <div className={classes.DialogBoxTitle}>
        <Typography component="h1" variant="h1">
          Please Confirm
        </Typography>
      </div>
      <div className={classes.DialogBoxContext}>
        <p>Are you sure you want to remove ? {rowsData.assessment_name}</p>
      </div>
    </DialogBox>
    <Typography component="h5" variant="h1">
      {location.state === "deleteAssessment"? "Soft Deleted Assessments" : "Latest Assessments"}
    </Typography>
    <Grid container>
      <Grid item xs={12} sm={8} className={classes.FilterWrap}>
        <span style={{ width: "230px" }}>
          <Grid item md={12} xs={12}>
            <Input
              // required={true}
              id="assessment-id"
              type="search"
              name="filterAssessment"
              label="Search Assessment Name"
              variant="outlined"
              value={assessmentFilter}
              onChange={handleAssessmentFilterChange}
              error
            />
          </Grid>
        </span>
        {location.state !== "deleteAssessment" ? 
        (<div style={{ width: "180px",marginLeft:"10px" }} className={classes.FilterDateInput}>
          <Input
            // required={true}
            id="start-date"
            type="date"
            name="startDate"
            label="Start Date"
            variant="outlined"
            value={startDate}
            onChange={handleAssessmentFilterChange}
          />
        </div>) : null}
        {location.state !== "deleteAssessment" ? (<div style={{ width: "180px", marginLeft:"10px" }}>
          <Input
            // required={true}
            id="end-date"
            type="date"
            name="endDate"
            label="End Date"
            variant="outlined"
            value={endDate}
            onChange={handleAssessmentFilterChange}
          />
        </div>) : null}
        <div className={classes.FilterWrap1}>
          <span>
            <Button id="search" onClick={searchHandler}>
              Search <SearchIcon />
            </Button>
          </span>
        </div>
        <div className={classes.FilterWrap2}>
          <span>
            <Button id="reset" color="secondary" onClick={searchResetHandler}>
              RESET <ClearIcon />
            </Button>
          </span>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.FilterAddWrap}>
        <div className={classes.ButtonGroup2}>
          <Button
            id="cancel-button"
            className={classes.BackToButton}
            variant={"contained"}
            onClick={backToList}
            color="secondary"
          >
            <img
              src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
              alt="user icon"
            />
            &nbsp; Back to List
          </Button>
        </div>
      </Grid>
    </Grid>
    <Grid>
      {formState.isSuccess ? (
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <strong>{formState.errMessage}</strong>
        </Alert>
      ) : null}
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <strong>{formState.errMessage}</strong>
        </Alert>
      ) : null}
      <Paper className={classes.paper}>
        <div className={classes.ScrollTable}>
          {searchData.length !== 0 ? (
            <MaterialTable
              data={searchData}
              columns={column}
              pageSize={10}
              onButtonClick={onRowClick}
           />
          ) : !enableBackdrop() ? (
            <Typography component="h5" variant="h3">
              You don't have any {location.state === "deleteAssessment" ? " Soft  Deleted" : " Latests "}  Assessments
            </Typography>
          ) : null}
        </div>
      </Paper>
    </Grid>
  </React.Fragment>
}

export default AdminAssessment;
