import React, { useEffect, useState } from "react";
import * as docx from "docx";
import { useNavigate, useLocation } from "react-router-dom";
import { CSET_URL } from "../../../../../config/index";
import moment from "moment";
import * as msgConstants from "../../../../../common/MessageConstants";
import { saveAs } from "file-saver";
import Grid from "@mui/material/Grid";
import { Button } from "../../../../../components/UI/Form/Button/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import styles from "./CRRExecutiveSummary.module.css";
import * as routeConstant from "../../../../../common/RouteConstants";
import logout from "../../../../Auth/Logout/Logout";
import DOMPurify from "dompurify";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import * as Sentry from "@sentry/react";
export interface ExecutiveReportDocGenerationProps {
  from?: string;
  props?: any;
  reportBlobData?: any;
  handleReportGenerate?: any;
  partnerLogo?: any;
  clientLogo?: any;
  reportData?: any;
  partnerImageDimension?: any;
  clientImageDimension?: any;
  handleChildCompError?: (err: any) => void;
}

export const ExecutiveReportDocGeneration: React.FC<
  ExecutiveReportDocGenerationProps
> = ({
  from,
  reportBlobData,
  props,
  handleReportGenerate,
  partnerImageDimension,
  clientImageDimension,
  reportData,
  partnerLogo,
  clientLogo,
  handleChildCompError = () => {}
}: ExecutiveReportDocGenerationProps) => {
  const history = useNavigate();
  const location = useLocation();
  const preparedForBy = "preparedFor/By";

  const [loading, setLoading] = useState(false);
  const [bannerImage, setBannerImage] = useState<any>("");
  const [cpaImage, setCPAImage] = useState<any>("");

  const [partnerImage, setPartnerImage] = useState<any>("");
  const [cpaImageDimensions, setCPAImageDimensions] = useState<any>("");
  const [partnerImageDimensions, setPartnerImageDimensions] = useState<any>("");

  const [defaultCPAImage, setDefaultCPAImage] = useState<any>("");
  const [param, setParam] = useState<any>(
    location.state[Object.keys(location.state)[0]]
  );
  const [partnerData, setPartnerData] = useState<any>({});
  const [clientData, setClientData] = useState<any>({});
  const [generateReport, setGenerateReport] = useState<boolean>(false);
  const [mil1FullAnswerDistribChart, setMil1FullAnswerDistribChart] =
    useState<any>(null);
  const [mil1Performancelegend, setMil1Performancelegend] =
    useState<any>(null);
  const [nistcsfFrameworkSummarylegend, setNistcsfFrameworkSummarylegend] =
    useState<any>(null);
  const [nistcsfFrameworkSummarychartAll, setNistcsfFrameworkSummarychartAll] =
    useState<any>(null);
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [crrMILPerfSumData, setCRRMILPerfSumData] = useState<any>(null);
  const [crrNISTCSFSumData, setCRRNISTCSFSumData] = useState<any>(null);
  const [finalData, setFinalData] = useState<any>(null);
  const [assetManageData, setAssetManageData] = useState<any>(null);
  const [configurationManagementData, setConfigurationManagementData] =
    useState<any>(null);
  const [controlManageData, setControlManageData] = useState<any>(null);
  const [externalDependenciesManageData, setExternalDependenciesManageData] =
    useState<any>(null);
  const [incidentManageData, setIncidentManageData] = useState<any>(null);
  const [riskManageData, setRiskManageData] = useState<any>(null);
  const [serviceContinuityManageData, setServiceContinuityManageData] =
    useState<any>(null);
  const [situaltionalAwarenessData, setSitualtionalAwarenessData] =
    useState<any>(null);
  const [trainingAwarenessData, setTrainingAwarenessData] = useState<any>(null);
  const [vulnerabilityManagementData, setVulnerabilityManagementData] =
    useState<any>(null);
  const [nistcsfSummaryFinalData, setNistcsfSummaryFinalData] =
    useState<any>(null);
  const [stackedBarCharts, setStackedBarCharts] = useState<any>(null);
  const [assessmentDocuments, setAssessmentDocuments] = useState<any>(null);
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;

  useEffect(() => {
    if (Object.keys(location.state).length > 0) {
      setParam(location.state[Object.keys(location.state)[0]]);
    }
  }, [location]);

  useEffect(() => {
    if (
      reportData !== null &&
      !generateReport &&
      from === "result" &&
      finalData !== null &&
      assetManageData !== null &&
      configurationManagementData !== null &&
      controlManageData !== null &&
      externalDependenciesManageData !== null &&
      incidentManageData !== null &&
      riskManageData !== null &&
      serviceContinuityManageData !== null &&
      situaltionalAwarenessData !== null &&
      trainingAwarenessData !== null &&
      vulnerabilityManagementData &&
      nistcsfSummaryFinalData !== null &&
      stackedBarCharts !== null &&
      reportData.Crrmil1PerformanceData &&
      reportData.CrrNistCsfSummaryReportData &&
      nistcsfFrameworkSummarychartAll !== null &&
      nistcsfFrameworkSummarylegend !== null &&
      cpaImageDimensions !== undefined
      && cpaImage !== ""
      && partnerImage !== ""
    ) {
        generate();
    }
  }, [
    finalData,
    assetManageData,
    configurationManagementData,
    controlManageData,
    externalDependenciesManageData,
    incidentManageData,
    riskManageData,
    serviceContinuityManageData,
    situaltionalAwarenessData,
    trainingAwarenessData,
    vulnerabilityManagementData,
    nistcsfSummaryFinalData,
    stackedBarCharts,
    reportData,
    nistcsfFrameworkSummarychartAll,
    nistcsfFrameworkSummarylegend,
    cpaImageDimensions,
    cpaImage ,
    partnerImage ,
  ]);

  useEffect(() => {
    if (generateReport && nistcsfSummaryFinalData && nistcsfSummaryFinalData) {
      if (generateReport) {
        generate();
      }
    }
  }, [generateReport]);

  const getBannerImages = async () => {
    await fetch(`${process.env.PUBLIC_URL}/images/ExecutiveSummary.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setBannerImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    await fetch(`${process.env.PUBLIC_URL}/images/blank_img.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setDefaultCPAImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    if (from === "result") {
      console.log("from === result",partnerLogo,clientLogo)
      setPartnerImage(partnerLogo);
      setCPAImage(clientLogo);
      setCPAImageDimensions(clientImageDimension);
      setPartnerImageDimensions(partnerImageDimension);
    } else {
      if (clientData.logo && clientData.logo[0]) {
        const clientLogoUrl = clientData.logo[0].url.slice(1);
        await fetch(`${serverUrl}${clientLogoUrl}`, {
          method: "GET",
          headers: headerObj,
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const base64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setCPAImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setCPAImage(base64data);
            };
          })
          .catch((error) => {
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
      if (partnerData.logo && partnerData.logo[0]) {
        const partnerLogoUrl = partnerData.logo[0].url.slice(1);
        await fetch(`${serverUrl}${partnerLogoUrl}`, {
          method: "GET",
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const ClientLogobase64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setPartnerImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setPartnerImage(ClientLogobase64data);
            };
          })
          .catch((error) => {
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
    }
  };

  useEffect(() => {
    getBannerImages();
  }, [partnerData, clientData]);

  useEffect(() => {
    const controller = new AbortController();
    getMil1PerformanceData();
    return () => controller.abort();
  }, [generateReport]);

  useEffect(() => {
    if (
      reportData !== null &&
      reportData.Crrmil1PerformanceData &&
      reportData.CrrNistCsfSummaryReportData
    ) {
      setDataforReportGeneration(reportData);
    }
  }, [reportData]);

  useEffect(() => {
    if (crrMILPerfSumData !== null && crrNISTCSFSumData !== null) {
      generateTableDataObject();
    }
  }, [crrMILPerfSumData, crrNISTCSFSumData]);

  const setDataforReportGeneration = async (reportData: any) => {
    setPartnerData(reportData?.partner);
    setClientData(reportData?.client);
    setCRRMILPerfSumData(reportData?.Crrmil1PerformanceData);
    setCRRNISTCSFSumData(reportData?.CrrNistCsfSummaryReportData);
    setAssetManageData(reportData?.Assetdata);
    setConfigurationManagementData(reportData?.ConfigData);
    setVulnerabilityManagementData(reportData?.VulnData);
    setControlManageData(reportData?.ControlData);
    setServiceContinuityManageData(reportData?.ServiceData);
    setIncidentManageData(reportData?.IncidentData);
    setRiskManageData(reportData?.RiskData);
    setExternalDependenciesManageData(reportData?.ExternalData);
    setSitualtionalAwarenessData(reportData?.SituationalData);
    setTrainingAwarenessData(reportData?.TrainingData);
    setStackedBarCharts(reportData?.Crrmil1PerformanceData.stackedBarCharts);
    setAssessmentDocuments(reportData?.assessmentDocument);
    await svgToBase64(
      reportData.Crrmil1PerformanceData.mil1FullAnswerDistribChart
    ).then((base64Data: any) => {
      setMil1FullAnswerDistribChart(base64Data);
    }).catch((err:any)=>{
      Sentry.captureException(err)
    })
    await svgToBase64(reportData.Crrmil1PerformanceData.legend).then(
      (base64Data: any) => {
        setMil1Performancelegend(base64Data);
      }
    ).catch((err:any)=>{
      Sentry.captureException(err)
    })
    await svgToBase64(reportData.CrrNistCsfSummaryReportData.legend).then(
      (base64Data: any) => {
        setNistcsfFrameworkSummarylegend(base64Data);
      }
    ).catch((err:any)=>{
      Sentry.captureException(err)
    })
    await svgToBase64(reportData.CrrNistCsfSummaryReportData.chartAll).then(
      (base64Data: any) => {
        setNistcsfFrameworkSummarychartAll(base64Data);
      }
    ).catch((err:any)=>{
      Sentry.captureException(err)
    })
  };

  const getMil1PerformanceData = async () => {
    getMil1FullAnswerDistribWidget();
  };

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getMil1FullAnswerDistribWidget = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/reportscrr/widget/mil1FullAnswerDistrib`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.text();
    if (response.status === 200) {
      svgToBase64(data).then((base64Data: any) => {
        setMil1FullAnswerDistribChart(base64Data);
      });
    }else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }catch(err){
    handleChildCompError(err)
  }
  };

  const svgToBase64 = (svgContent: any) => {
    const svgBlob = new Blob([svgContent], { type: "image/svg+xml" });
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(svgBlob);
    });
  };

  const fitImg = (targetHeight: any, originalSize: any) => {
    let width: number;
    let height: number;
    console.log("originalSize", originalSize);
    const originalAspectRatio = originalSize.width / originalSize.height;

    if (!originalSize) {
      width = targetHeight;
    } else {
      height = targetHeight;
      width = height * originalAspectRatio;
    }
    return width;
  };

  /**
   * Actually, "non-child questions"
   * @param q
   */
  const parentQuestions = (q: any): any[] => {
    // q might be a single question or might be an array of questions
    let questions: any = [];

    if (q instanceof Array) {
      questions = q;
    } else {
      questions = [].concat(q);
    }

    return questions.filter((x: any) => !x.parentquestionid);
  };

  /**
   * Until question numbers are broken out into their own data element,
   * we'll parse them from the question text.
   * @param q
   */
  const getQuestionNumber = (q: any): string => {
    const dot = q.questiontext.trim().indexOf(".");
    if (dot < 0) {
      return "Q";
    }
    return "Q" + q.questiontext.trim().substring(0, dot);
  };

  const generateTableHeaderCell = (text: string) => {
    return new docx.TableCell({
      children: [new docx.Paragraph({ text, style: "TableHeading" })],
      margins: {
        top: docx.convertInchesToTwip(0.1),
        bottom: docx.convertInchesToTwip(0.1),
        left: docx.convertInchesToTwip(0.1),
        right: docx.convertInchesToTwip(0.1),
      },
      shading: {
        fill: "#d9d2e9",
        color: "auto",
      },
      verticalAlign: docx.VerticalAlign.CENTER,
    });
  };

  const doc = new docx.Document({
    numbering: {
      config: [
        {
          reference: "top_categories",
          levels: [
            {
              level: 0,
              format: docx.LevelFormat.DECIMAL,
              text: "%1",
              alignment: docx.AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 720, hanging: 260 },
                },
              },
            },
          ],
        },
        {
          reference: "questions",
          levels: [
            {
              level: 0,
              format: docx.LevelFormat.DECIMAL,
              text: "%1",
              alignment: docx.AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 720, hanging: 260 },
                },
              },
            },
          ],
        },
        {
          reference: "numbering-style-1",
          levels: [
            {
              level: 0,
              format: docx.LevelFormat.UPPER_ROMAN,
              text: "%1)", // results in '1)', '2)', etc.
              alignment: docx.AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 720, hanging: 260 },
                },
              },
            },
            {
              level: 1,
              format: docx.LevelFormat.DECIMAL,
              text: "%2.", // results in '1.', '2.', etc.
              alignment: docx.AlignmentType.START,
              style: {
                paragraph: {
                  // Increase indentation for nested levels
                  indent: { left: 1440, hanging: 980 },
                },
              },
            },
            // And so on...
          ],
        },
      ],
    },
    styles: {
      default: {
        heading1: {
          run: {
            font: "Helvetica",
            size: 36,
            bold: true,
            color: "#4b0079",
          },
          paragraph: {
            alignment: docx.AlignmentType.LEFT,
            spacing: { line: 340 },
          },
        },
        heading2: {
          run: {
            font: "Helvetica",
            size: 30,
            bold: true,
            color: "#0b4785",
          },
          paragraph: {
            spacing: { line: 340 },
          },
        },
        heading3: {
          run: {
            font: "Helvetica",
            size: 20,
          },
          paragraph: {
            spacing: { line: 276 },
          },
        },
        heading4: {
          run: {
            font: "Helvetica",
            size: 20,
          },
          paragraph: {
            alignment: docx.AlignmentType.JUSTIFIED,
          },
        },
      },
      paragraphStyles: [
        {
          id: "normalPara",
          name: "Normal Para",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Helvetica",
            size: 20,
          },
          paragraph: {
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
            rightTabStop: docx.TabStopPosition.MAX,
            leftTabStop: 453.543307087,
          },
        },
        {
          id: "mainHeading",
          name: "Normal Para2",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Helvetica",
            size: 80,
            color: "#4B0079",
            bold: true,
          },
        },
        {
          id: "date",
          name: "Aside",
          basedOn: "Normal",
          next: "Normal",
          run: {
            font: "Helvetica",
            color: "#4B0079",
            size: 40,
            bold: true,
          },
        },
        {
          id: "bulletText",
          name: "Normal para",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Helvetica",
            size: 20,
          },
        },
        {
          id: "customFontCng",
          quickFormat: true,
          run: {
            font: "Helvetica",
            size: 20,
          },
        },
        {
          id: "miniHeader",
          name: "Normal para",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,

          run: {
            font: "Helvetica",
            size: 24,
            italics: true,
            bold: true,
          },
        },
        {
          id: "TableHeading",
          name: "Normal Para2",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,

          run: {
            font: "Helvetica",
            size: 20,
            color: "#4B0079",
            bold: true,
          },
        },
        {
          id: "BoldPara",
          name: "Normal Para3",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,

          run: {
            font: "Helvetica",
            size: 20,
            bold: true,
          },
        },
        {
          id: preparedForBy,
          name: "Aside",
          basedOn: "Normal",
          next: "Normal",
          run: {
            font: "Helvetica",
            color: "#4B0079",
            size: 20,
            bold: true,
          },
        },
        {
          id: "clientPartner",
          name: "Aside",
          basedOn: "Normal",
          next: "Normal",
          run: {
            font: "Helvetica",
            size: 20,
          },
        },
      ],
    },
  });

  const generateTableIndividualImageCell = (Arrbase64String: any) => {
    let chartData: any = [];
    if (Array.isArray(Arrbase64String)) {
      chartData = Arrbase64String.map((data: any, i: any) => {
        const chart = btoa(data);
        let milImage = docx.Media.addImage(
          doc,
          Uint8Array.from(atob(chart), (c: any) => c.charCodeAt(0)),
          100,
          20
        );

        return new docx.Paragraph(milImage);
      });
    }
    return new docx.TableCell({
      children: [...chartData],
      margins: {
        top: docx.convertInchesToTwip(0.1),
        bottom: docx.convertInchesToTwip(0.1),
        left: docx.convertInchesToTwip(0.1),
        right: docx.convertInchesToTwip(0.1),
      },
    });
  };

  const generateTableIndividualCell = (text: string) => {
    let t = text;
    if (typeof text === "number") {
      t = String(text);
    }
    if (t === "N") {
      t = "No";
    }
    if (t === "U") {
      t = "Unanswered";
    }
    let textData: any = [];
    let ftextData: any = [];
    if (Array.isArray(t)) {
      textData = t.map((data: any) => {
        return new docx.Paragraph({
          text: data,
          heading: docx.HeadingLevel.HEADING_3,
        });
      });
    }
    return new docx.TableCell(
      Array.isArray(t)
        ? {
            children: [...textData],
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1),
            },
            shading: {
              fill: "fcfaff",
              color: "auto",
            },
          }
        : {
            children: [
              new docx.Paragraph({
                text: t,
                heading: docx.HeadingLevel.HEADING_3,
              }),
            ],
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1),
            },
            shading: {
              fill: "fcfaff",
              color: "auto",
            },
          }
    );
  };

  const generateTableIndividualdomainSummaryCell = (Arrbase64String: any) => {
    let chartData: any = [];
    let finalChartData: any = [];
    if (Arrbase64String !== null && Arrbase64String.length > 0) {
      chartData = Arrbase64String.map((element: any) => {
        const chart = btoa(element);
        let milImage = docx.Media.addImage(
          doc,
          Uint8Array.from(atob(chart), (c: any) => c.charCodeAt(0)),
          60,
          30
        );
        return element.includes("<svg")
          ? new docx.Paragraph(milImage)
          : new docx.Paragraph({
              text: element,
              heading: docx.HeadingLevel.HEADING_3,
            });
      });
    }
    return new docx.TableCell(
      Array.isArray(Arrbase64String)
        ? {
            children: [...chartData],
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1),
            },
          }
        : {
            children: [
              new docx.Paragraph({
                text: Arrbase64String,
                heading: docx.HeadingLevel.HEADING_3,
              }),
            ],
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1),
            },
            shading: {
              fill: "fcfaff",
              color: "auto",
            },
          }
    );
  };

  const generateTableCells = (data: any, columns: any[]) => {
    let tableRows: any[] = [];
    tableRows = columns.map((c: any) => {
      if (c.field === "scorechart") {
        return generateTableIndividualImageCell(data[c.field]);
      }
      if (c.field === "domainSummary") {
        return generateTableIndividualdomainSummaryCell(data[c.field]);
      }
      if (c.field === "performanceSummary") {
        return generateTableIndividualCell(data[c.field]);
      }
    });
    return new docx.TableRow({
      children: [...tableRows],
    });
  };

  const generateTableHeaderRow = (columns: any[]) => {
    let tableColumns: any[] = [];
    tableColumns = columns.map((c) => {
      return generateTableHeaderCell(c.title);
    });
    return new docx.TableRow({
      children: [...tableColumns],
    });
  };

  const generateFinalTable = (data: any[], columns: any[]) => {
    const rows: any[] = data.map((d) => {
      return generateTableCells(d, columns);
    });
    return new docx.Table({
      rows: [generateTableHeaderRow(columns), ...rows],
    });
  };

  const documentColumn = [
    { title: "DOMAIN SUMMARY", field: "domainSummary" },
    {
      title: "MIL-1 PRACTICE LEVEL PERFORMANCE SUMMARY",
      field: "performanceSummary",
    },
    { title: " ", field: "scorechart" },
  ];

  const stringSplitter = (str: string) => {
    return str.split(" - ")[1] ?? str.split("-")[1];
  };

  const getStackedBarChart = (goalTitle: string) => {
    return stackedBarCharts.find((c: any) => c.title === goalTitle).chart;
  };

  const filterMilDomainGoals = (goals: any) => {
    return goals.filter((g: any) => !g.title.startsWith("MIL"));
  };

  const modifySvgText = (svgInput: any) => {
    // Create a temporary DOM element to parse the SVG
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = svgInput;
    // Find the <text> tag
    const textElement = tempDiv.querySelector("text");
    if (textElement) {
      // Modify the attributes
      textElement.setAttribute("font-size", "6%");
      textElement.setAttribute("y", "7.5");
      textElement.setAttribute("x", "50");
      // Convert the modified SVG back to a string
      const modifiedSvg = tempDiv.innerHTML;

      return modifiedSvg;
    } else {
      console.error("No <text> tag found in the provided SVG.");
      return svgInput;
    }
  };

  const generateTableDataObject = () => {
    const domainSummaryfinalData: any = [];
    const nistcsfSummaryFinalDataArr: any = [];
    if (crrMILPerfSumData?.CRRModel) {
      crrMILPerfSumData?.CRRModel.Domain.forEach((domain: any, i: any) => {
        const obj: any = {
          domainSummary: [],
          performanceSummary: [],
          scorechart: [],
        };
        obj["domainSummary"].push(
          domain.title,
          crrMILPerfSumData.performanceMil[i]
        );
        filterMilDomainGoals(domain.Goal).map((goal: any, j: any) => {
          obj["performanceSummary"].push(
            `Goal - ${j + 1} ${stringSplitter(goal.title)}`
          );
          obj["scorechart"].push(modifySvgText(getStackedBarChart(goal.title)));
        });
        domainSummaryfinalData.push(obj);
      });
    }
    if (crrNISTCSFSumData?.bodyData) {
      crrNISTCSFSumData?.bodyData.forEach((data: any, i: any) => {
        const obj: any = {
          domainSummary: [],
          performanceSummary: [],
          scorechart: [],
        };
        obj["domainSummary"].push(
          `${data.function.Function.name} ${data.function.Function.code}`,
          data.chart
        );
        data.cats.map((cat: any) => {
          obj["performanceSummary"].push(
            `${cat.parentCode}.${cat.code} - ${cat.name}`
          );
          obj["scorechart"].push(modifySvgText(cat.catChart));
        });
        nistcsfSummaryFinalDataArr.push(obj);
      });
    }
    setFinalData(domainSummaryfinalData);
    setNistcsfSummaryFinalData(nistcsfSummaryFinalDataArr);
  };
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const generateDocxTable = (data: any) => {
    let getControlList: any[] = [];
    getControlList = data.Goal.map((goal: any, index: any) => {
      const rows = [
        new docx.TableRow({
          children: [
            new docx.TableCell({
              width: {
                size: 9505,
                type: docx.WidthType.DXA,
              },
              children: [
                new docx.Paragraph({
                  text: goal.title,
                  heading: docx.HeadingLevel.HEADING_3,
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              columnSpan: 3,
            }),
          ],
        }),
      ];

      goal.Question.forEach((Quesdata: any, QuesIndex: number) => {
        rows.push(
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 9505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text: Quesdata.questiontext,
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                columnSpan: 2,
              }),
              new docx.TableCell({
                width: {
                  size: 3505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    text:
                      Quesdata.isparentquestion === "true"
                        ? " "
                        : Quesdata.answer === "Y"
                        ? "Yes"
                        : Quesdata.answer === "N"
                        ? "No"
                        : Quesdata.answer === "NA"
                        ? "NA"
                        : "Unanswered",
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                columnSpan: 1,
              }),
            ],
          })
        );
      });

      rows.push(
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: "Option(s) for Consideration ",
                  heading: docx.HeadingLevel.HEADING_3,
                  spacing: {
                    after: 200,
                  },
                }),
              ],
              columnSpan: 3,
            }),
          ],
        })
      );

      parentQuestions(goal.Question).forEach(
        (refData: any, refIndex: number) => {
          const sanitizedHTML = createDangerousHTML(refData.referencetext);
          const plainText = sanitizedHTML.__html
          rows.push(
            new docx.TableRow({
              children: [
                new docx.TableCell({
                  width: {
                    size: 1500,
                    type: docx.WidthType.DXA,
                  },
                  children: [
                    new docx.Paragraph({
                      text: getQuestionNumber(refData),
                      heading: docx.HeadingLevel.HEADING_3,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "ffffff",
                    color: "auto",
                  },
                  columnSpan: 1,
                }),
                new docx.TableCell({
                  width: {
                    size: 1500,
                    type: docx.WidthType.DXA,
                  },
                  children: [
                    new docx.Paragraph({
                      text: plainText
                        .replace(/&nbsp;/g, "\n")
                        .replace(/<[^>]+>/g, ""),
                      heading: docx.HeadingLevel.HEADING_3,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "ffffff",
                    color: "auto",
                  },
                  columnSpan: 1,
                }),
                new docx.TableCell({
                  width: {
                    size: 1500,
                    type: docx.WidthType.DXA,
                  },
                  children: [
                    new docx.Paragraph({
                      text: "",
                      heading: docx.HeadingLevel.HEADING_3,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "ffffff",
                    color: "auto",
                  },
                  columnSpan: 1,
                }),
              ],
            })
          );
        }
      );

      return [
        new docx.Table({
          columnWidths: [3505, 3505, 3505],
          rows: rows,
        }),
        new docx.Paragraph({
          text: "",
          spacing: {
            after: 200,
          },
        }),
      ];
    });

    const dataToReturn: any[] = [];
    getControlList.forEach((f: any) => {
      dataToReturn.push(...f);
    });
    return dataToReturn;
  };

  const generate = async () => {
    console.log("cpaImage",cpaImage);
    console.log("partnerImage",partnerImage);
    const table2 = generateFinalTable(finalData, documentColumn);
    const table3 = generateFinalTable(nistcsfSummaryFinalData, documentColumn);
    console.log("Generating Executive Summary");
    const imageBase64Data1 = mil1FullAnswerDistribChart;
    const imageBase64Data2 = mil1Performancelegend;
    const imageBase64Data4 = nistcsfFrameworkSummarylegend;
    const imageBase64Data5 = nistcsfFrameworkSummarychartAll;

    const mil1Performancefirstchart =
      mil1FullAnswerDistribChart !== null
        ? imageBase64Data1?.split(",")[1]
        : "";
    const mil1Performancesecondchart =
      mil1Performancelegend !== null ? imageBase64Data2?.split(",")[1] : "";
    const nistcsfFrameworkSummaryChart =
      nistcsfFrameworkSummarylegend !== null
        ? imageBase64Data4?.split(",")[1]
        : "";
    const nistcsfFrameworkchartAll =
      nistcsfFrameworkSummarychartAll !== null
        ? imageBase64Data5?.split(",")[1]
        : "";
    const exeSummaryLogo = bannerImage ? bannerImage.split(",")[1] : "";
    const defaultcpaLogo = defaultCPAImage ? defaultCPAImage.split(",")[1] : "";
    const cpaLogo = cpaImage ? cpaImage.split(",")[1] : defaultcpaLogo;
    const msplogo = partnerImage ? partnerImage.split(",")[1] : defaultcpaLogo;
    const image1 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(mil1Performancefirstchart), (c) => c.charCodeAt(0)),
      60,
      30
    );
    const image2 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(mil1Performancesecondchart), (c) => c.charCodeAt(0)),
      160,
      50
    );

    const image5 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(exeSummaryLogo), (c) => c.charCodeAt(0)),
      600,
      350
    );

    const image6 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(cpaLogo), (c) => c.charCodeAt(0)),
      fitImg(50, cpaImageDimensions),
      50
    );

    const image7 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
      fitImg(50, partnerImageDimensions),
      50
    );

    const image8 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(nistcsfFrameworkSummaryChart), (c) =>
        c.charCodeAt(0)
      ),
      160,
      50
    );

    const image9 = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(nistcsfFrameworkchartAll), (c) => c.charCodeAt(0)),
      160,
      50
    );

    const footerLogo = docx.Media.addImage(
      doc,
      Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
      fitImg(50, partnerImageDimensions),
      50,
      {
        floating: {
          horizontalPosition: {
            align: docx.HorizontalPositionAlign.RIGHT,
          },
          verticalPosition: {
            align: docx.VerticalPositionAlign.BOTTOM,
          },
        },
      }
    );
    const bannertable = new docx.Table({
      rows: [
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  alignment: docx.AlignmentType.LEFT,
                  heading: docx.HeadingLevel.HEADING_1,
                  style: preparedForBy,
                  children: [
                    new docx.TextRun({
                      text: "Prepared for",
                      bold: true,
                    }),
                  ],
                }),
                new docx.Paragraph({
                  text: "                                               ",
                }),

                new docx.Paragraph(image6),
                new docx.Paragraph({
                  text: "                                               ",
                }),
                new docx.Paragraph({
                  text: "                                               ",
                }),
                new docx.Paragraph({
                  text: clientData?.contact_id?.name,
                  style: "clientPartner",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.2),
                right: docx.convertInchesToTwip(1.8),
              },
              columnSpan: 5,
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },

                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              shading: {
                fill: "ffffff",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  alignment: docx.AlignmentType.LEFT,
                  heading: docx.HeadingLevel.HEADING_1,
                  style: preparedForBy,
                  children: [
                    new docx.TextRun({
                      text: "Prepared By",
                      bold: true,
                    }),
                  ],
                }),
                new docx.Paragraph({
                  text: "                                               ",
                }),
                new docx.Paragraph(image7),
                new docx.Paragraph({
                  text: "",
                }),
                new docx.Paragraph({
                  text: partnerData?.contact_id?.name,
                  style: "clientPartner",
                }),
                new docx.Paragraph({
                  text: "",
                  style: "clientPartner",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.5),
                right: docx.convertInchesToTwip(2),
              },
              borders: {
                top: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                right: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                bottom: {
                  style: docx.BorderStyle.SINGLE,
                  size: 0,
                  color: "ffffff",
                },
                left: {
                  style: docx.BorderStyle.THICK,
                  size: 3,
                  color: "4B0079",
                },
              },
              columnSpan: 5,
              shading: {
                fill: "ffffff",
                color: "auto",
              },
            }),
          ],
        }),
      ],
    });

    doc.addSection({
      children: [
        new docx.Paragraph(image5),
        new docx.Paragraph({
          text: "CRR Executive Summary Report",
          style: "mainHeading",
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,

          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: moment().format("MMMM DD, YYYY"),
          heading: docx.HeadingLevel.HEADING_1,
          style: "date",
          alignment: docx.AlignmentType.LEFT,

          spacing: {
            after: 400,
          },
        }),

        bannertable,
      ],
    });

    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: msgConstants.EXECUTIVE_SUMMARY,
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,

          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: `Notification`,
          heading: docx.HeadingLevel.HEADING_1,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: `This report is provided "as-is" for informational purposes only. The Department of Homeland Security (DHS) does not provide any warranties of any kind regarding any information contained within. In no event shall the United States Government or its contractors or subcontractors be liable for any damages, including but not limited to, direct, indirect, special or consequential damages and including damages based on any negligence of the United States Government or its contractors or subcontractors, arising out of, resulting from, or in any way connected with this report, whether or not based upon warranty, contract, tort, or otherwise, whether or not injury was sustained from, or arose out of the results of, or reliance upon the report`,
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 100,
          },
        }),
        new docx.Paragraph({
          text: `The DHS does not endorse any commercial product or service, including the subject of the analysis in this report. Any reference to specific commercial products, processes, or services by service mark, trademark, manufacturer, or otherwise, does not constitute or imply their endorsement, recommendation, or favoring by DHS.`,
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 100,
          },
        }),
        new docx.Paragraph({
          text: ` The display of the DHS official seal or other DHS visual identities on this report shall not be interpreted to provide the recipient organization authorization to use the official seal, insignia or other visual identities of the Department of Homeland Security. The DHS seal, insignia, or other visual identities shall not be used in any manner to imply endorsement of any commercial product or activity by DHS or the United States Government. Use of the DHS seal without proper authorization violates federal law (e.g., 18 U.S.C. Â§Â§ 506, 701, 1017), and is against DHS policies governing usage of its seal.`,
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
      ],
    });
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Introduction",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 300,
          },
        }),
        new docx.Paragraph({
          text: `On ${new Date()}, ${
            param?.clientInfo.name
          }conducted a Cyber Resilience Review (CRR) Self-Assessment. Answers to questions about cybersecurity practices were gathered from key stakeholders within ${
            param?.clientInfo.name
          }and scored. `,
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 300,
          },
        }),
        new docx.Paragraph({
          text: "Overview and Scope of the CRR",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 300,
          },
        }),
        new docx.Paragraph({
          text: `The CRR consists of a one-day, structured facilitation and interview of key personnel. The primary goal of the CRR is to develop an understanding and qualitative measurement of essential cybersecurity capabilities. Personnel are asked to describe how these capabilities are institutionalized and managed, and how these capabilities are applied to support the organization during times of stress. The assessment questions asked participants to articulate evidence regarding both performances of cybersecurity practices as well as sustainment of those practices over time. Individual organizations are examined for specific capacities and capabilities in defining, managing, and measuring cybersecurity practices and behaviors, as described in categories. The categories examined are: `,
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 300,
          },
        }),
        new docx.Paragraph({
          text: "1. Asset Management ",
          numbering: {
            reference: "normalPara",
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "2. Controls Management ",
          numbering: {
            reference: "normalPara",
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "3. Configuration and Change Management ",
          numbering: {
            reference: "normalPara",
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "4. Vulnerability Management ",
          numbering: {
            reference: "normalPara",
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "5. Incident Management ",
          numbering: {
            reference: "normalPara",
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "6. Service Continuity Management ",
          numbering: {
            reference: "normalPara",
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "7. Risk Management ",
          numbering: {
            reference: "normalPara",
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "8. External Dependencies Management ",
          numbering: {
            reference: "normalPara",
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "9. Training and Awareness ",
          numbering: {
            reference: "normalPara",
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "10. Situational Awareness ",
          numbering: {
            reference: "normalPara",
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "The categories examined are derived from a larger security and business continuity framework known as the CERT® Resilience Management Model (CERT-RMM), which was developed by the CERT Program at Carnegie Mellon University's Software Engineering Institute.",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
      ],
    });

    // Intoduction
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "About this Report",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: "The CRR has a service orientation, meaning that one of the foundational principles of its design is the idea that an organization deploys its people, information, technology and facilities to support specific operational missions. During the CRR, this focus on services is how [Facility Name] improves its understanding of the cybersecurity management of services that support critical infrastructure. This improved understanding helps the organization focus its efforts in improving cybersecurity management. ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "This report summarizes the assessment findings and provides your organization with options for consideration in each category. The options for consideration aim to provide general guidelines or activities as to how your organization can improve the organization's cybersecurity posture and preparedness. These options are not meant to fully represent all activities needed for a robust cybersecurity management program, but to provide initial guidance on how to incorporate various cybersecurity practices including CERT® Resilience Management Model (CERT-RMM), National Institute of Standard and Technology (NIST), and other cybersecurity standards. ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "Please note that guidance provided in this report includes National Institute of Standards and Technology (NIST) Special Publications. While the primary audience for these documents is United States Federal Civilian Agencies, NIST encourages the adoption of these guidelines by State, local, and tribal governments, as well as private sector organizations. Guidance from the NIST Cybersecurity Framework (NIST CSF) for Improving Critical Infrastructure Cybersecurity is also included. The Framework, created through collaboration between industry and government, consists of standards, guidelines, and practices to promote the protection of critical infrastructure. Additionally, while the CRR bases its questions and options for consideration on CERT-RMM, the results do not constitute a formal “rating” and should not be interpreted as a formal appraisal of your organization against CERT-RMM. Detailed information about the RMM can be found at www.cert.org/resilience. ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "An additional benefit of the CRR is that it allows an organization to compare its capabilities to the criteria of the NIST CSF. This comparison is provided in the NIST Cybersecurity Framework Summary and provides the basis for understanding where improvements could be made. ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "A reference crosswalk mapping the relationship of the CRR goals and practices to the NIST CSF categories and subcategories is included in the CRR Self-Assessment Kit. ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
      ],
    });

    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Cyber Resilience Review Results ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: "The CRR is an interview-based assessment. It is understood that participants often do not have complete knowledge of an organization's operations. Actual performance may vary from what is indicated in this report. Organizational performance is presented across several dimensions within the report. Scores are provided for individual Practices, Goals, and Domains. ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "Basic Rules ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: "1. Practices are either performed (answer =”Yes”), incompletely performed (answer = “Incomplete”), or not performed (answer = “No”) ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "2. A goal is achieved only if all practices are performed",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "3. A Domain is achieved at MIL-1 if all the Goals in the Domain are achieved",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "4. A Domain can be achieved at higher levels if the MIL questions for each level (MIL-2 through MIL-5) are answered. ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "Scoring Rubric ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: "Step 1",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "miniHeader",
        }),
        new docx.Paragraph({
          text: "Each Practice in a Domain is scored as the following: ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: " performed when the question is answered with a “Yes” (green)",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " not performed when a question is answered with an “Incomplete” (yellow) or “No” (red) or “Not Answered” (grey) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " if “Not Answered” (grey) is shown, the question was left blank and is scored the same as a “No” ",
          bullet: {
            level: 0,
          },
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "Step 2",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "miniHeader",
        }),
        new docx.Paragraph({
          text: "Each Goal within the Domain is then scored as the following:",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: " achieved when all practices are performed (green)",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " partially achieved when some practices are performed (yellow) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " not achieved when no practices are performed (red)",
          bullet: {
            level: 0,
          },
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "Step 3",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "miniHeader",
        }),
        new docx.Paragraph({
          text: "Each Domain is assigned a MIL level based on the following:",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: " MIL-0 if only some of the goals are achieved",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " MIL-1 if all of the goals are achieved ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " MIL-2 if MIL-1 is achieved and all of the MIL-2 questions are answered YES",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " MIL-3 if MIL-2 is achieved and all of the MIL-3 questions are answered YES",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " MIL-4 if MIL-3 is achieved and all of the MIL-4 questions are answered YES",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " MIL-5 if MIL-4 is achieved and all of the MIL-5 questions are answered YES",
          bullet: {
            level: 0,
          },
          spacing: {
            after: 400,
          },
        }),
      ],
    });

    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Maturity Indicator Levels ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: "Maturity Indicator Levels (MIL) are assigned by Domain and represent a consolidated view of performance. CERT-RMM MILs describe attributes that would be indicative of mature capabilities as represented in the model's capability levels. However, they do not fully represent capability levels as defined because a capability level can only be assigned through a formal appraisal process, not as the result of using an assessment-based instrument. ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "MIL0 Incomplete",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "miniHeader",
        }),
        new docx.Paragraph({
          text: "Indicates that Practices in the Domain are not being performed as measured by responses to the relevant CRR questions. If MIL0 is assigned, no further assessment of maturity indicator is performed. ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "MIL1 Performed",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "miniHeader",
        }),
        new docx.Paragraph({
          text: "Indicates that all Practices in a Domain are being performed as measured by responses to the relevant CRR questions. MIL1 means that there is sufficient and substantial support for the existence of the practices. ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "MIL2 Planned",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "miniHeader",
        }),
        new docx.Paragraph({
          text: "Indicates that all Practices in Domain are not only performed, but are supported by sufficient planning, stakeholders, and relevant standards and guidelines. A planned process/practice is ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: " established by the organization (Is the practice documented and communicable to all who need to know?)",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " planned (Is the practice performed according to a documented plan?)",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " supported by stakeholders (Are the stakeholders of the practice known and are they aware of the practice and their role in the practice?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " supported by relevant standards and guidelines (Have the standards and guidelines that support the practice been identified and implemented?) ",
          bullet: {
            level: 0,
          },
          spacing: {
            after: 400,
          },
        }),
        new docx.Paragraph({
          text: "MIL3 Managed",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "miniHeader",
        }),
        new docx.Paragraph({
          text: "Indicates that all Practices in a Domain are performed, planned, and have the basic infrastructure in place to support the process. A managed process/practice ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: " is governed by the organization (Is the practice supported by policy and is there appropriate oversight over the performance of the practice?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " is appropriately staffed and funded (Are the staff and funds necessary to perform the practice as intended available?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " is assigned to staff who are responsible and accountable for the performance of the practice (Have staff been assigned to perform the practice and are they responsible and accountable for the performance of the practice?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " is performed by staff who are adequately trained to perform the practice (Are the staff who perform the practice adequately skilled and trained to perform the practice?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " produces work products that are expected from performance of the practice and are placed under appropriate levels of configuration control (Does the practice produce artifacts and work products that are expected from performing the practice, and if so, are the configurations of these artifacts/work products managed?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " is managed for risk (Are risks related to the performance of the practice identified, analyzed, disposed of, monitored, and controlled?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "MIL4 Measured",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "miniHeader",
        }),
        new docx.Paragraph({
          text: " Indicates that all Practices in a Domain are performed, planned, managed, monitored, and controlled. A measured process/practice is ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: " periodically evaluated for effectiveness (Is the practice periodically reviewed to ensure that it is effective and producing intended results?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " monitored and controlled (Are appropriate implementation and performance measures identified, applied, and analyzed?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " objectively evaluated against its practice description and plan (Is the practice periodically evaluated to ensure that it adheres to the practice description and the plan for the practice?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: " periodically reviewed with higher-level management (Is higher-level management aware of any issues related to the performance of the practice?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "MIL5 Defined",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "miniHeader",
        }),
        new docx.Paragraph({
          text: " Indicates that all Practices in a Domain are performed, planned, managed, monitored, controlled, and consistent across all internal[1] constituencies who have a vested interest in the performance of the practice. A defined process/practice ensures that the organization reaps the benefits of consistent performance of the practice across organizational units and that all organizational units can benefit from improvements realized in any organizational unit. At MIL5, a process/practice ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph({
          text: " is defined by the organization and tailored by organizational units for their use (Is there an organization-sponsored definition of the practice from which organizational units can derive practices that fit their unique operating circumstances?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "  is supported by improvement information that is collected by and shared among organizational units for the overall benefit of the organization (Are practice improvements documented and shared across internal constituencies so that the organization as a whole reaps benefits from these improvements?) ",
          bullet: {
            level: 0,
          },
        }),
        new docx.Paragraph({
          text: "[1] In this case, “internal” refers to constituencies over which the organization has direct managerial control. ",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          style: "normalPara",
          spacing: {
            after: 200,
          },
        }),
      ],
    });

    // CRR MIL-1 Performance Summary
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph(image1),
        new docx.Paragraph({
          text: "CRR MIL-1 Performance",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph(image2),
        table2,
      ],
    });

    // CRR NIST Cybersecurity Framework Summary
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph(image9),
        new docx.Paragraph({
          text: "NIST Cybersecurity Framework Summary",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        new docx.Paragraph(image8),
        table3,
      ],
    });

    // Asset Management (AM)
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Asset Management (AM)",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        ...generateDocxTable(assetManageData.AssetManagement),
      ],
    });

    // Configuration and Change Management (CCM)
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Configuration and Change Management (CCM)",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        ...generateDocxTable(
          configurationManagementData.configurationManagement
        ),
      ],
    });

    // Controls Management (CM)
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Controls Management (CM)",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        ...generateDocxTable(controlManageData.ControlsManagement),
      ],
    });

    // External Dependencies Management (EDM)
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "External Dependencies Management (EDM)",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        ...generateDocxTable(
          externalDependenciesManageData.ExternalDependenciesManagement
        ),
      ],
    });

    // Incident Management (IM)
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Incident Management (IM)",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        ...generateDocxTable(incidentManageData.IncidentManagement),
      ],
    });

    // Risk Management (RM)
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Risk Management (RM)",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        ...generateDocxTable(riskManageData.RiskManagement),
      ],
    });

    // Service Continuity Management (SCM)
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Service Continuity Management (SCM)",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        ...generateDocxTable(
          serviceContinuityManageData.ServiceContinuityManagement
        ),
      ],
    });

    // Situational Awareness (SA)
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Situational Awareness (SA)",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        ...generateDocxTable(situaltionalAwarenessData.SituationalAwareness),
      ],
    });

    // Training and Awareness
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Training and Awareness (TA)",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        ...generateDocxTable(trainingAwarenessData.TrainingandAwareness),
      ],
    });

    // Vulnerability Management (AM)
    doc.addSection({
      footers: {
        default: new docx.Footer({
          children: [
            new docx.Paragraph({
              alignment: docx.AlignmentType.LEFT,
              children: [
                new docx.TextRun({
                  children: ["Page | ", docx.PageNumber.CURRENT],
                }),
              ],
            }),
            new docx.Paragraph(footerLogo),
          ],
        }),
      },
      children: [
        new docx.Paragraph({
          text: "Vulnerability Management (VM)",
          heading: docx.HeadingLevel.HEADING_2,
          alignment: docx.AlignmentType.LEFT,
          spacing: {
            after: 200,
          },
        }),
        ...generateDocxTable(
          vulnerabilityManagementData.VulnerabilityManagementData
        ),
      ],
    });
    //Document Library
    const documentTable = new docx.Table({
      rows: [
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: "Category",
                  style: "TableHeading",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: "Sub-Category",
                  style: "TableHeading",
                  alignment: docx.AlignmentType.LEFT,
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              //verticalAlign: docx.VerticalAlign.CENTER,
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  text: "File Name",
                  style: "TableHeading",
                }),
              ],
              margins: {
                top: docx.convertInchesToTwip(0.1),
                bottom: docx.convertInchesToTwip(0.1),
                left: docx.convertInchesToTwip(0.1),
                right: docx.convertInchesToTwip(0.1),
              },
              shading: {
                fill: "#d9d2e9",
                color: "auto",
              },
              verticalAlign: docx.VerticalAlign.CENTER,
            }),
          ],
        }),
        ...assessmentDocuments.map((doc: any) => {
          return new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    text: `${
                      doc.practise ? doc.practise.split("->")[0] : "Global"
                    }`,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#ffffff",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    text: `${
                      doc.question_Text === "Global"
                        ? "--"
                        : doc.practise
                        ? `${doc.practise.split("->")[1]} ${
                            doc.practise.split("->")[2]
                              ? "->Q." + doc.practise.split("->")[2]
                              : ""
                          }`
                        : ""
                    }`,
                    alignment: docx.AlignmentType.LEFT,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#ffffff",
                  color: "auto",
                },
                //verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                children: [
                  ...doc.document_id.map((docn: any) => {
                    return new docx.Paragraph({
                      text: `${docn.name}`,
                      alignment: docx.AlignmentType.LEFT,
                    });
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#ffffff",
                  color: "auto",
                },
              }),
            ],
          });
        }),
      ],
    });
    if (assessmentDocuments.length > 0) {
      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "Document Library",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 200,
            },
          }),
          documentTable,
        ],
      });
    }

    docx.Packer.toBlob(doc)
      .then(async (blob: any) => {
        if (from && from === "result") {
          await Promise.resolve()
            .then(() => {
              setLoading(false);
              reportBlobData = blob;
              reportBlobData["name"] = "CRR_Executive_Summary_Report.docx";
              handleReportGenerate(reportBlobData);
              setGenerateReport(false);
            })
            .catch((err) => {
              console.log("from Results Report", err);
              setLoading(false);
              setGenerateReport(false);
              Sentry.captureException(err)
            });
        } else {
          await Promise.resolve(
            saveAs(blob, "CRR_Executive_Summary_Report.docx")
          )
            .then(() => {
              setLoading(false);
              setGenerateReport(false);
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
              setGenerateReport(false);
              Sentry.captureException(error)
            });
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setGenerateReport(false);
      });
  };

  const handleBack = () => {
    history(routeConstant.CRR_REPORTS, { state: { param } });
  };

  return (
    <>
      {loading ? <SimpleBackdrop /> : null}
      <Grid item xs={12} sm={12} className={styles.FooterActions}>
        <div className={styles.prevBtn}>
          <Button
            id="previous-button"
            variant="contained"
            color="primary"
            onClick={handleBack}>
            {"Previous"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setGenerateReport(true)}>
            <GetAppIcon className={styles.EditIcon} />
            &nbsp; Doc
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default ExecutiveReportDocGeneration;
