import React, { useEffect, useState } from "react";
import styles from "./Client.module.css";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Alert from "../../components/UI/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "../../components/UI/Form/Button/Button";
import { DialogBox } from "../../components/UI/DialogBox/DialogBox";
import Input from "../../components/UI/Form/Input/Input";
import * as validations from "../../common/validateRegex";
import Paper from "@mui/material/Paper";
import MaterialTable from "../../components/UI/Table/MaterialTable";
import { GET_ORGANIZATION } from "../../graphql/queries/Organization";
import * as routeConstant from "../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import logout from "../../containers/Auth/Logout/Logout";
import { GET_INDIVIDUAL } from "../../graphql/queries/Individual";
import { GET_PARTNER_SUBSCRIPTION } from "../../graphql/queries/PartnerSubscription";
import { GET_CLIENT_SUBSCRIPTION } from "../../graphql/queries/ClientSubscription";
import { UPDATE_INDIVIDUAL } from "../../graphql/mutations/Individual";
import { UpdateCSETUser,CreateCSETUser } from "../../graphql/mutations/CSETUserDetails";
import * as msgConstants from "../../common/MessageConstants";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import {
  CSET_URL,
  CSET_USER_PASS,
  CSET_USER_OLD_PASS,
} from "../../config/index";
import { GetCSETUserDetails } from "../../graphql/queries/CSETUserDetails";
import { GET_CONTACT } from "../../graphql/queries/Contact";
import { GET_CSET_ASSESSMENT } from "../../graphql/queries/CSETAssessment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { setActiveFormStep } from "../../services/Data";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { HISTORY } from "../../graphql/mutations/AuditTrail";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_GUEST_CLIENT } from "../../graphql/mutations/ClientSubscription";
import { sentry_error_catch } from "../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";

export const Client: React.FC = (props: any) => {
  const location = useLocation();
  const TIME_FORMAT = "MM/DD/YYYY hh:mm a";
  const viewAssess = "viewAssess";
  const createAssess = "createAssess";
  const history = useNavigate();
  const { showBoundary } = useErrorBoundary();
  const [openAboutDialogBox,setOpenAboutDialogBox] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(true);
  const [newData, setNewData] = useState<any>([]);
  const [clientInfo, setClientInfo] = useState<any>({});
  const [subscriptionList, setSubscriptionList] = useState<any>();
  const [clickedValue, setClickedValue] = useState<any>();
  const RAuser = sessionStorage.getItem("ra_user") || logout();
  const RAcontact = sessionStorage.getItem("ra_contact") || logout();
  const RAuserRole = sessionStorage.getItem("is_pa") || logout();
  const user = JSON.parse(RAuser);
  const contact = JSON.parse(RAcontact);
  const userRole = RAuserRole;
  const [pgPartnerId, setPgPartnerId] = useState<any>();
  const [pgPartnerUserId, setPgPartnerUserId] = useState<any>();
  const [createUpdatePartnerUser] = useMutation(UPDATE_INDIVIDUAL);
  const [updateCSeTUser] = useMutation(UpdateCSETUser);
  const moment = require("moment-timezone");
  const [CSeTUserData, setCSeTUserData] = useState<any>(null);
  const [cSetUserId, setCSetUserId] = useState<any>();
  const [dataSubs, setDataSubs] = useState<any>(null);
  const [partnerTimeZone, setPartnerTimeZone] = useState<any>("");
  const [selectedRowData, setSelectedRowData] = useState<any>({});
  const [name, setName] = useState("");
  const [openGuestDialogBox,setOpenGuestDialogBox] = useState(false);
  const [email, setEmail] = useState("");
  const [isGuestClientDisabled,setIsGuestClientDisabled] = useState(true);
  const [guestClientData,setGuestClientData] = useState<any>([]);
  const [isNameError, setIsNameError] = useState<any>({
    name:false,
    msg:""
  });
  const [isEmailError, setIsEmailError] = useState<any>({
    email:false,
    msg:""
  });
  const [createGuestClient] = useMutation(CREATE_GUEST_CLIENT);
  const columns = [
    { title: "Company Name", key: "name" },
    { title: "Assessment Name", key: "assessment_name" },
    { title: "Last Activity ", key: "last_report_created_at" },
    {
      key: "createAssess",
      title: "",
      icon: <AddCircleIcon />,
      tooltip: "Create Assessment",
      displayIcon: (rowData: any) => true,
    },
    {
      key: "viewAssess",
      title: "",
      icon: <VisibilityIcon />,
      tooltip: "View Assessment",
      displayIcon: (rowData: any) => true,
    },
  ];

  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    isGuestClientFailed:false,
    errMessage: "",
  });

  // Display server Error Messsage if server not working.
  useEffect(() => {
    if (location.state === msgConstants.ERROR_TRY_AGAIN) {
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: true,
        isGuestClientFailed:false,
        errMessage: msgConstants.ERROR_MESSAGE,
      }));
    }
  }, [location]);
  
  useEffect(() => {
    if((name.trim() !== "" && name.length > 0 ) && (email.trim() !== "" && email.length > 0 ) && (email && validations.EMAIL_REGEX.test(email))){
      setIsGuestClientDisabled(false)
    }else{
      setIsGuestClientDisabled(true)
    }
  },[name,email])

  const [createCSETUser] = useMutation(CreateCSETUser);

  const UpdatePartnerUserRole = (data: any) => {
    createUpdatePartnerUser({
      variables: {
        id: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
        contact_id: contact?.id,
        pg_user_role: userRole === "yes" ? "Partner Admin" : "",
      },
    })
      .then((res: any) => {})
      .catch((err: any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState)
      });
  };

  const [auditTrail] = useMutation(HISTORY);

  const [GetCSETUserLoginDetails] = useLazyQuery(GetCSETUserDetails, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (LoginRequestData: any) => {
      setCSeTUserData(LoginRequestData?.csetUserDetails[0]);
    },
    onError: (err:any)=>{
          sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  });
  // Second Query..
  const [getIndividual, { data: iData }] = useLazyQuery(GET_INDIVIDUAL, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    },
  });

  useEffect(() => {
    if (CSeTUserData) {
      setCSetUserId(CSeTUserData.id);
      LoginRegisteredCSETUser(
        CSeTUserData.cset_username,
        CSeTUserData.cset_password
      );
    } else {
      RegisterCSETUser(
        selectedRowData.email,
        selectedRowData.first_name,
        selectedRowData.last_name,
        true
      );
    }
  }, [CSeTUserData]);

  useEffect(() => {
    if (iData !== undefined) {
      setPgPartnerUserId(iData?.individuals[0].pg_user_id);
      UpdatePartnerUserRole(iData?.individuals[0]);
      getPartnerSubs({
        variables: {
          where: { contact_id: iData?.individuals[0].partner_id.id },
        },
      }).catch((err:any)=>{
        sentry_error_catch(err,setShowBackDrop,setFormState)
      })
      getIndividualpartner({
        variables: {
          where: { partner_id: iData?.individuals[0].partner_id.id },
        },
      });
    }
  }, [iData,guestClientData.length]);

  const [getIndividualpartner] = useLazyQuery(GET_INDIVIDUAL, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (data) => {
      if (data.individuals.length !== 0) {
        getOrganization({
          variables: {
            where: {
              subtype: "Client",
              partner_id: data.individuals[0].partner_id.id,
            },
          },
        }).catch((err:any)=>{
          sentry_error_catch(err,setShowBackDrop,setFormState)
        })
        getPartnerOrganization({
          variables: {
            where: {
              subtype: "Partner",
              contact_id: data.individuals[0].partner_id.id,
            },
          },
        }).catch((err:any)=>{
          sentry_error_catch(err,setShowBackDrop,setFormState)
        })
      }
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    },
  });

  const [getClientSubs] = useLazyQuery(GET_CLIENT_SUBSCRIPTION, {
    onCompleted: (data: any) => {
      setDataSubs(data);
      const contactArray: any = [];
      dataOrg.organizations.forEach((val: any) => {
        if (val.contact_id) {
          contactArray.push(parseInt(val.contact_id.id));
        }
      });
      getAssessment({
        variables: {
          where: {
            contact_id: contactArray,
          },
          sort: "updated_at:DESC",
          limit: 500,
        },
      });
    },
    fetchPolicy: msgConstants.networkFetchPolicy,
  });

  const [getPartnerSubs] = useLazyQuery(GET_PARTNER_SUBSCRIPTION, {
    onCompleted: (data: any) => {
      setSubscriptionList(data.partnerSubscriptions[0]);
    },
    fetchPolicy: msgConstants.networkFetchPolicy,
  });

  const createTableDataObject = (data: any) => {
    const arr: any = [];
    const contactIdArray: any = [];
    let toDelete = false;
    try{
    if (data !== 0) {
      data.forEach((element: any, i: any) => {
        if (element.active_status === 1) {
          const obj: any = {};
          obj["createdon"] = moment(element.contact_id.created_at).format(
            TIME_FORMAT
          );
          obj["email"] = !element.contact_id.email
            ? "-"
            : element.contact_id.email;
          obj["name"] = `${element.contact_id.name}  ${
            element.contact_id.last_name ? element.contact_id.last_name : ""
          }`;
          obj["first_name"] = element.contact_id.name;
          obj["last_name"] = element.contact_id.last_name;
          obj["phone"] = !element.contact_id.phone
            ? "-"
            : element.contact_id.phone;
          obj["guest_client"] = element.guest_client ? element.guest_client : false
          obj["clientId"] = element.contact_id.id;
          obj["partnerId"] = element.partner_id.id;
          obj["clientOrgId"] = element.id;
          obj["partnerSubscriptions"] = subscriptionList;
          if (dataSubs) {
            for (const j in dataSubs.clientSubscriptions) {
              if (
                dataSubs.clientSubscriptions[j].contact_id !== null &&
                element.contact_id.id ===
                  dataSubs.clientSubscriptions[j].contact_id.id
              ) {
                obj["clientSubsId"] = dataSubs.clientSubscriptions[j].id;
                obj["ra_subs"] = subscriptionList?.ra_subscription
                  ? dataSubs.clientSubscriptions[j].ra_subscription
                  : false;
              }
            }
          }
          if (assessmentData) {
            for (const i in assessmentData.csetAssessments) {
              console.log("assessment client id",assessmentData.csetAssessments[i]?.contact_id?.id);
              if (
                element.contact_id.id ===
                  assessmentData.csetAssessments[i]?.contact_id?.id &&
                assessmentData.csetAssessments[i]?.deleteData === null
              ) {
                if (
                  !contactIdArray.includes(
                    assessmentData.csetAssessments[i]?.contact_id?.id
                  )
                ) {
                  toDelete =
                    assessmentData.csetAssessments[i]?.deleteData
                      ?.deleteAssessment[0]?.delete;
                  contactIdArray.push(
                    assessmentData.csetAssessments[i]?.contact_id?.id
                  );
                  obj["assessment_name"] = assessmentData.csetAssessments[i]
                    .assessment_name
                    ? assessmentData.csetAssessments[i].assessment_name.slice(
                        0,
                        assessmentData.csetAssessments[
                          i
                        ].assessment_name.indexOf("%")
                      )
                    : "Assessment Name";
                  console.log("assessment update date",assessmentData.csetAssessments[i]?.updated_at);
                  obj["last_report_created_at"] = moment(
                    assessmentData.csetAssessments[i]?.updated_at
                  )
                    .tz(partnerTimeZone)
                    .format(TIME_FORMAT);
                  obj["to_Delete"] = toDelete;
                }
              }
            }
          }
          arr.push(obj);
        }
      });
      const filterArray = arr.filter((x:any) => x.guest_client === false);
      const guestClientArray = arr.filter((x:any) => x.guest_client);
      console.log("guestClientArray", guestClientArray);
      setNewData(filterArray);
      setGuestClientData(guestClientArray);
    }
   }catch(err){
    console.log("err",err);
    Sentry.captureException(err);
   }
  };

  const [getOrganization, { data: dataOrg }] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: () => {
      const contactArray = dataOrg?.organizations?.map(
        (val: any) => val.contact_id.id
      );
      getClientSubs({
        variables: {
          where: { contact_id_in: contactArray },
        },
      }).catch((err:any)=>{
        sentry_error_catch(err,setShowBackDrop,setFormState)
      })
    },
  });

  const [getPartnerOrganization, { data: dataPartnerOrg }] = useLazyQuery(
    GET_ORGANIZATION,
    {
      fetchPolicy: msgConstants.networkFetchPolicy,
      onCompleted: (data: any) => {
        setPgPartnerId(data?.organizations[0].pg_id);
        setPartnerTimeZone(
          data?.organizations[0].timezone
            ? data?.organizations[0].timezone
            : "US/Eastern"
        );
        localStorage.setItem(
          "timezone",
          data?.organizations[0].timezone
            ? data?.organizations[0].timezone
            : "US/Eastern"
        );
      },
    }
  );

  const [getAssessment, { data: assessmentData }] = useLazyQuery(
    GET_CSET_ASSESSMENT,
    {
      fetchPolicy: msgConstants.cacheNetworkFetchPolicy,
      onError: (err:any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState)
      },
      onCompleted: () => {
        setShowBackDrop(false);
      },
    }
  );

  useEffect(() => {
    const controller = new AbortController();
    // Get contact info of the user Logged In
    getContact({
      variables: {
        id: user.user.id,
      },
    });
    return () => controller.abort();
  }, [RAuser]);

  useEffect(() => {
    const controller = new AbortController();
    if (dataOrg && assessmentData && dataSubs !== null) {
      createTableDataObject(dataOrg.organizations);
    }
    return () => controller.abort();
  }, [dataOrg, assessmentData, dataSubs]);

  const [getContact] = useLazyQuery(GET_CONTACT, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted(data: any) {},
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    },
  });

  useEffect(() => {
    const controller = new AbortController();
    if (
      formState.isDelete === true ||
      // formState.isFailed === true ||
      // formState.isSuccess === true ||
      formState.isUpdate === true
    ) {
      setTimeout(function () {
        handleAlertClose();
      }, msgConstants.ALERT_MESSAGE_TIMER);
    }
    return () => controller.abort();
  }, [formState]);

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      isGuestClientFailed:false,
      errMessage: "",
    }));
  };

  useEffect(() => {
    const controller = new AbortController();
    // runs after every render...
    if (location.state && location.state != null && location.state.formState) {
      setFormState(location.state.formState);
    }
    const contactdata = contact;
    getIndividual({
      variables: {
        where: { partner_id_null: false, contact_id: contactdata.id },
      },
    });
    return () => controller.abort();
  }, []);

  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  };

  const headerObj = {
    "Content-Type": msgConstants.CONTENT_TYPE,
  };

  const ResetPassword = async (
    username: any,
    errorUser?: any,
    cset_user_id?: any
  ) => {
    try {
      const response = await fetch(
        `${CSET_URL}api/ResetPassword/SecurityQuestions?email=${username}&appCode=CSET`,
        {
          method: "GET",
          headers: headerObj,
        }
      );
      await response.json();
      await updateCSeTUser({
        variables: {
          id: CSeTUserData?.id.toString(),
          cset_username: username,
          cset_password: CSET_USER_PASS,
        },
      })
        .then((data: any) => {
          LoginRegisteredCSETUser(username, CSET_USER_PASS);
        })
        .catch((err) => {
          sentry_error_catch(err,setShowBackDrop,setFormState)
        });
    } catch (error) {
      setShowBackDrop(false);
      console.error("Error in ResetPassword:", error);
    }
  };

  let hasAttemptedLogin = 0;
  let newCSeTPassword = false;

  const LoginRegisteredCSETUser = async (
    username: any,
    password: any,
    errorUser?: any
  ) => {
    try {
      const loginUserData = {
        Email: username,
        Password: password,
        TzOffset: new Date().getTimezoneOffset(),
        Scope: "CSET",
      };
      const response = await fetch(`${CSET_URL}api/auth/login`, {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(loginUserData),
      });

      const data = await response.json();
      hasAttemptedLogin++;
      if (data.email !== null && data.token == null && data.isPasswordExpired) {
        await ResetPassword(username, errorUser, data.userId);
      }
      if (
        (data.userId === 0 && data.email === null) ||
        hasAttemptedLogin === 2
      ) {
        if (hasAttemptedLogin == 1) {
          LoginRegisteredCSETUser(username, CSET_USER_PASS);
        }
        if (hasAttemptedLogin == 2 && data.token && CSeTUserData !== null) {
          updateCSeTUser({
            variables: {
              id: CSeTUserData?.id.toString(),
              cset_username: username,
              cset_password: CSET_USER_PASS,
            },
          })
            .then((updateCSeTUserdata: any) => {})
            .catch((err) => {
              sentry_error_catch(err,setShowBackDrop,setFormState)
            });
        }
      }
      if (
        data.token !== null &&
        data.userId !== 0 &&
        CSeTUserData === undefined &&
        cSetUserId === undefined
      ) {
        createCSETUser({
          variables: {
            username,
            password: CSET_USER_PASS,
            contact_id: selectedRowData.clientId,
            cset_user_id: data.userId.toString(),
          },
        })
          .then(() => {})
          .catch((err) => {
            sentry_error_catch(err,setShowBackDrop,setFormState)
          });
      }

      if (data.token !== null) {
        sessionStorage.setItem("ra_token", data.token);
        if (clickedValue === viewAssess) {
          setShowBackDrop(false);
          const val = {
            clientInfo,
            csetUserInfo: data,
            pgPartnerId,
            pgPartnerUserId,
          };
          history(routeConstant.ASSESSMENT_LIST, { state: { val } });
        }
        if (clickedValue === createAssess) {
          const val = {
            assessmentInfo: {},
            clientInfo,
            csetUserInfo: data,
            ccAssessmentInfo: {},
            pgPartnerId,
            pgPartnerUserId,
            flowType: {
              type: "",
            },
            individualId: iData?.individuals[0].id,
            partnerOrganizationId: dataPartnerOrg?.organizations[0].id,
            clientOrgId: clientInfo.clientOrgId,
          };
          setActiveFormStep(0);
          history(routeConstant.ASSESSMENT_TYPE, { state: { val } });
        }
      }

      // ... Continue with other conditional checks and actions
    } catch (err:any) {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  };

  const RegisterCSETUser = async (
    username: any,
    first_name: any,
    last_name: any,
    errorUser?: any
  ) => {
    try {
      const headerObj = {
        "Content-Type": msgConstants.CONTENT_TYPE,
        Accept: "*/*",
      };

      const registrationUserData = {
        appCode: "CSET",
        primaryEmail: username,
        confirmEmail: username,
        firstName: first_name,
        lastName: last_name,
      };

      const response = await fetch(
        `${CSET_URL}api/ResetPassword/RegisterUser`,
        {
          method: "POST",
          headers: headerObj,
          body: JSON.stringify(registrationUserData),
        }
      );

      const data = await response.text();

      if (data === "Created Successfully") {
        await LoginRegisteredCSETUser(username, CSET_USER_PASS, errorUser);
      } else if (data === "An account already exists for that email address") {
        await LoginRegisteredCSETUser(username, CSET_USER_OLD_PASS, errorUser);
      }

      // ... Continue with other conditional checks and actions
    } catch (err:any) {
        sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  };

  const onRowClick = async (rowData: any, columnKey: any) => {
    setClickedValue(columnKey);
    if (columnKey === viewAssess) {
      auditTrail({
        variables: {
          auditTrailInput: {
            individual: iData.individuals[0].id,
            organization: dataPartnerOrg.organizations[0].id,
            action: {
              message:
                "Clicked on View Assessment Button to Redirect to Assessment Listing Page",
              data: null,
            },
            flowtype: null,
            page: "Client Page",
            client: rowData.clientOrgId,
          },
        },
      }).then((res: any) => {
        setClientInfo(rowData);
        setShowBackDrop(true);
        GetCSETUserLoginDetails({
          variables: {
            where: { contact: { id: rowData.clientId } },
          },
        }).catch((err:any)=>{
          sentry_error_catch(err,setShowBackDrop,setFormState)
        })
        setSelectedRowData(rowData);
      }).catch((err:any)=>{
        sentry_error_catch(err,setShowBackDrop,setFormState)
      })
    }
    if (columnKey === createAssess) {
      auditTrail({
        variables: {
          auditTrailInput: {
            individual: iData.individuals[0].id,
            organization: dataPartnerOrg.organizations[0].id,
            action: {
              message:
                "Clicked on Create Assessment Button to Redirect to Assessment type Page",
              data: null,
            },
            flowtype: null,
            page: "Client Page",
            client: rowData.clientOrgId,
          },
        },
      }).then((res: any) => {
        setClientInfo(rowData);
        setShowBackDrop(true);
        GetCSETUserLoginDetails({
          variables: {
            where: { contact: { id: rowData.clientId } },
          },
        }).catch((err:any)=>{
          sentry_error_catch(err,setShowBackDrop,setFormState)
        })
        setSelectedRowData(rowData);
      }).catch((err:any)=>{
        sentry_error_catch(err,setShowBackDrop,setFormState)
      })
    }
  };

  const handleChange = (event:any) => {
    if(event.target.name === "Name"){
      setName(event.target.value)
      if(event.target.value.trim() === "" || event.target.value.length < 0 ){
       setIsNameError({
         name:true,
         msg:"User name is Required"
       })
      }
      // else if(/[^a-zA-Z\-\ ]/.test(event.target.value)){
      //   setIsNameError({
      //     name:true,
      //     msg:"Invalid user name"
      //   })
      // }
      else{
        setIsNameError({
          name:false,
          msg:""
        })
      }
    }
    if(event.target.name === "email"){
      setEmail(event.target.value)
      if(event.target.value.trim() === "" || event.target.value.length < 0 ){
        setIsEmailError({
          email:true,
          msg:"Email is Required"
        })
       }
       else if (event.target.value && !validations.EMAIL_REGEX.test(event.target.value)) {
        let errors = "Please enter valid email address.";
        setIsEmailError((error: any) => ({
          email:true,
          msg:errors
        }));
      }else{
        setIsEmailError({
          email:false,
          msg:""
        })
      }
    }
  }

  const closeDialogBox = () => {
    setOpenGuestDialogBox(false);
    setName("");
    setIsNameError({});
    setEmail("");
    setIsEmailError({});
    setIsGuestClientDisabled(true)
  }
  const addGuestClient = () => {
    try{
    setShowBackDrop(true)
    createGuestClient({
      variables:{
        input: {
          "clientName": name,
          "pg_partner_id":pgPartnerId,
          "clientEmail": email,
          "cc_subscription":false,
          "ra_subscription":true,
          "activeStatus":"1"
        }
      }
    }).then((data:any) => {
      setShowBackDrop(false)
      console.log("Guestdata",data);
      if(data.data.createGuestClient.message === "Guest Client created successfully"){
        closeDialogBox();
        setFormState((formState) => ({
          ...formState,
          isSuccess: true,
          isUpdate: false,
          isDelete: false,
          isFailed: false,
          isGuestClientFailed:false,
          errMessage: `  ${name}  `,
        }));
        const obj:any = {};
        const guest = data.data.createGuestClient.guest_client_data;
        console.log("data",data)
        obj["email"] = guest.email ? guest.email : "-";
        obj["name"] = guest.name ? guest.name : "-";
        obj["first_name"] = guest.name ? guest.name : "-";
        obj["last_name"] = guest.last_name ? guest.last_name : "-";
        obj["phone"] = guest.phone ? guest.phone : "-";
        obj["clientId"] = guest.contact_id ? guest.contact_id : "-";
        obj["clientOrgId"] = guest.organization_id ? guest.organization_id : "-"
        obj["partnerId"] = guest.partner_id ? guest.partner_id : "-" ; 
        obj["ra_subs"] = guest.ra_subscription;
        obj["partnerSubscriptions"] = true
        setGuestClientData((guestClientData:any) => [...guestClientData,obj])
      }else if(data.data.createGuestClient.message === "An account already exists for that email address"){
        setShowBackDrop(false)
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: false,
          isGuestClientFailed:true,
          errMessage: `${data.data.createGuestClient.message}`,
        }));
      }else{
        setShowBackDrop(false)
        Sentry.captureException(data.data.createGuestClient.message)
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: false,
          isGuestClientFailed:true,
          errMessage: msgConstants.ERROR_MESSAGE,
        }));
      }
    }).catch((err:any) => {
      setShowBackDrop(false)
      console.log("err",err)
      Sentry.captureException(err)
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        isGuestClientFailed:true,
        errMessage: msgConstants.ERROR_MESSAGE,
      }));
    })
   }catch(err){
    showBoundary(err)
   }
  }
  
  console.log("client page,pg partner Id",pgPartnerId);

  return (
    <React.Fragment>
      <CssBaseline />
      {showBackDrop ? <SimpleBackdrop /> : null}
      <Typography component="h5" variant="h1" classes={{h1:styles.title}}>
        Enrolled Clients
      </Typography>  
      <Grid>
        <DialogBox
          open={openGuestDialogBox}
          handleOk={addGuestClient}
          handleCancel={closeDialogBox}
          buttonOk={"Create"}
          buttonCancel={"Cancel"}
          classes={{
            root: styles.MainOfficeDialogRoot,
            container: styles.MainOfficeDialogboxContainer,
            paper: styles.MainOfficeDialogboxPaper,
            scrollPaper: styles.MainOfficeScrollPaper,
          }}
          disabled={isGuestClientDisabled}
        >
          {showBackDrop ? <SimpleBackdrop /> : null}
          <div className={styles.DialogBoxTitle}>
            <Typography component="h1" variant="h1">
              {"Guest Client"}
            </Typography>
          </div>
          <div>
          {formState.isGuestClientFailed ? (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
            </Alert>
          ) : null}
            <Grid container spacing={3}>
              <Grid item xs={4} classes={{ item: styles.MuiGridItem }}>
                <Typography component="h3" variant="h3"> Name :</Typography>
              </Grid>
              <Grid item xs={8} classes={{ item: styles.ReactInput1 }}>
                <Input
                  type="text"
                  label="Name"
                  name="Name"
                  value={name}
                  onChange={handleChange}
                  required
                  error={isNameError.name}
                  helperText={isNameError.msg}
                >
                  Name
                </Input>
              </Grid>
              <Grid item xs={4} classes={{ item: styles.MuiGridItem }}>
                <Typography component="h3" variant="h3"> Email :</Typography>
              </Grid>
              <Grid
                item
                xs={8}
                // className={rowData ? styles.disfield : styles.test}
                classes={{ item: styles.ReactInput2 }}
              >
                <Input
                  type="text"
                  label="Email"
                  name="email"
                  value={email}
                  // disabled={rowData}
                  required
                  onChange={handleChange}
                  error={isEmailError.email}
                  helperText={isEmailError.email ? isEmailError.msg :false}
                >
                  E-mail
                </Input>
              </Grid>
            </Grid>
          </div>
        </DialogBox>
        <Grid item xs={12} md={3} classes={{ item: styles.GuestClientButton }}>
          <Button
            id="create-guest-client"
            color="primary"
            variant="contained"
            onClick={() => {
              setOpenGuestDialogBox(true)
              handleAlertClose();
            }}
          >
            <AddCircleIcon className={styles.EditIcon} />
            &nbsp; Guest Client
          </Button>
        </Grid>
        {/* <Paper className={styles.paper}> */}
          {formState.isSuccess ? (
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Client<strong>{formState.errMessage}</strong>
              {msgConstants.SUCCESS}
            </Alert>
          ) : null}
          {formState.isFailed ? (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
            </Alert>
          ) : null}
          {formState.isUpdate ? (
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Client<strong>{formState.errMessage}</strong>
              {msgConstants.UPDATE}
            </Alert>
          ) : null}
        <Paper className={styles.paper}>
          <div className={styles.ScrollTable}>
            {newData.length !== 0 ? (
              <MaterialTable
                data={newData}
                columns={columns}
                pageSize={10}
                onButtonClick={onRowClick}
              />
            ) : !enableBackdrop() ? (
              <Typography component="h5" variant="h3">
                You don't have any client subscribed for RiskAssessment360
              </Typography>
            ) : null}
          </div>
        </Paper>
        {guestClientData.length !== 0 ? ( 
          <React.Fragment>
            <Typography component="h1" variant="h1" classes={{h1:styles.title}}>
              {"Guest Clients"}
            </Typography>
            <Paper className={styles.paper}>
              <div className={styles.ScrollTable}>
                <MaterialTable
                  columns={columns}
                  data={guestClientData}
                  pageSize={10}
                  onButtonClick={onRowClick}
                />
              </div>
            </Paper>
          </React.Fragment>
        ):
        null}
      </Grid>
    </React.Fragment>
  );
};

export default Client;
