import React, { useState, useEffect } from "react";
import styles from "./GoalPerformance.module.css";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CSET_URL } from "../../../../../config/index";
import MaterialTable from "../../../../../components/UI/Table/MaterialTable";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import logout from "../../../../Auth/Logout/Logout";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../../components/UI/Alert/Alert";
import { HorizontalBar } from "react-chartjs-2";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";
import { ERROR_MESSAGE } from "../../../../../common/MessageConstants";

export interface GoalPerformanceProps {
  data?: any;
}

export const GoalPerformance: React.FC<GoalPerformanceProps> = ({
  data,
}: GoalPerformanceProps) => {
  const [performanceDomainChart, setPerformanceDomainChart] = useState({});
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState<any>([]);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const column = [
    { title: " ", key: "name" },
    { title: "Yes", key: "yes" },
    { title: "No ", key: "no" },
    { title: "Unanswered", key: "unanswered" },
    { title: "Total Practices", key: "total" },
    { title: "Percent Complete", key: "percent" },
  ];
  const authToken = sessionStorage.getItem("ra_token") || logout();

  const headerObj = {
    "Content-Type": `application/json`,
    Authorization: authToken,
  };

  useEffect(() => {
    if (data !== undefined) {
      console.log(data);
    }
    getGoalPerformance();
  }, []);

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const buildbarGraphData = (data: any) => {
    // build the object to populate the chart
    const x: any = {};
    x.labels = [];
    x.datasets = [];
    const dataSet: any = {
      label: "",
      backgroundColor: "#245075",
      data: [],
    };
    x.datasets.push(dataSet);

    data.rraSummaryByGoal.forEach((element: any) => {
      if (element.answer_Text === "Y") {
        x.labels.push(element.title);
        dataSet.data.push(element.percent);
      }
    });
    setPerformanceDomainChart(x);
  };

  const getGoalPerformance = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/reports/rradetail`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          buildbarGraphData(data);
          createGoalTable(data);
          setLoading(false);
        })
        .catch((err:any)=>{
          sentry_error_catch(err,setLoading,setFormState)
        });
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const createGoalTable = (data: any) => {
    const goalList: any = [];
    data.rraSummaryByGoal.forEach((element: any) => {
      let goal = goalList.find((x: any) => x.name === element.title);
      if (!goal) {
        goal = {
          name: element.title,
          yes: 0,
          no: 0,
          unanswered: 0,
        };
        goalList.push(goal);
      }

      switch (element.answer_Text) {
        case "Y":
          goal.yes = element.qc;
          break;
        case "N":
          goal.no = element.qc;
          break;
        case "U":
          goal.unanswered = element.qc;
          break;
      }
    });

    goalList.forEach((g: any) => {
      g.total = g.yes + g.no + g.unanswered;
      g.percent = `${Math.round((g.yes / g.total) * 100)}%`;
    });
    setNewData(goalList);
  };

  const options = {
    title: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontSize: 18,
            fontColor: "Black",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontSize: 18,
            fontColor: "Black",
          },
        },
      ],
    },
  };

  if (loading) {
    return <SimpleBackdrop />;
  }

  return (
    <Grid>
      <React.Fragment>
        {formState.isFailed ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {formState.errMessage}
          </Alert>
        ) : null}
        <Paper className={styles.paper}>
          <h3 style={{ marginTop: "0px" }}>RRA Performance by Goal</h3>
          <HorizontalBar data={performanceDomainChart} options={options} />
          <h3>RRA Practice Scoring</h3>
          <div className={styles.ScrollTable}>
            <MaterialTable
              columns={column}
              data={newData}
              pageSize={10}
            />
          </div>
        </Paper>
      </React.Fragment>
    </Grid>
  );
};
export default GoalPerformance;
