import React, { useState, useEffect } from "react";
import { CSET_URL } from "../../../../../config";
import logout from "../../../../Auth/Logout/Logout";
import styles from "./CRRExecutiveSummary.module.css";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import CssBaseline from "@mui/material/CssBaseline";
export interface CrrSummaryResultsProps {
  data?: any;
  props?: any;
  situationalAwarenessRef?: any;
  handleError?: (err: any) =>void;
  handleChildCompError?: (err: any) => void;
}

export const CrrSummaryResults: React.FC<CrrSummaryResultsProps> = ({
  data,
  props,
  handleError = () => {},
  handleChildCompError = () => {}
}: CrrSummaryResultsProps) => {
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [crrDomains, setCrrDomains] = useState<any>([]);
  const [showBackDrop, setShowBackDrop] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    getCrrModel();
    if (props === false) {
      setShowBackDrop(false);
    }
    return () => controller.abort();
  }, []);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getCrrModel = async () => {
    await fetch(`${CSET_URL}api/reportscrr/getCrrModel`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response: any) => response.json())
      .then((data: any) => {
        setCrrDomains(data.crrResultsData.crrDomains);
        setShowBackDrop(false);
      })
      .catch((error: any) => {
        setShowBackDrop(false);
        console.log("getCrrModel", error);
        handleChildCompError(error)
        handleError(error)
      });
  };

  return (
    <React.Fragment>
    <CssBaseline />
    {showBackDrop ? <SimpleBackdrop /> : null}
    <div className="container-fluid">
      <div className={styles.parent}>
        <div>
          <div>
            <h3 className={styles.depictionTitle}>Summary of CRR Results</h3>
          </div>
          <br />
          <div className={styles.chartTitle}>
            <div>Maturity Indicator Level By Domain</div>
          </div>
        </div>

        <div className="CrrResultsBody">
          <div className={styles.crrResultsTable}>
            {crrDomains?.map((domain: any) => {
              return (
                <div key={domain?.domainName} className={styles.crrResultsTableRow}>
                  <div className={styles.crrResultsDomainCell}>
                    <div className={styles.crrResultsTableDomainName}>
                      {domain.domainName}
                    </div>
                  </div>
                  <div
                    className={`${styles.gridline} ${styles.rbl} ${styles.mil1Grid}`}
                  ></div>
                  <div
                    className={`${styles.gridline} ${styles.rbl} ${styles.mil1Grid}`}
                  ></div>
                  <div
                    className={`${styles.c} ${styles.rbl} ${styles.mil1Grid}`}
                  ></div>
                  <div
                    className={`${styles.gridline} ${styles.rbd} ${styles.mil1Grid}`}
                  ></div>
                  <div
                    className={`${styles.gridline} ${styles.rbd} ${styles.milUpperGrid}`}
                  ></div>
                  <div
                    className={`${styles.gridline} ${styles.rbd} ${styles.milUpperGrid}`}
                  ></div>
                  <div
                    className={`${styles.gridline} ${styles.rbd} ${styles.milUpperGrid}`}
                  ></div>
                  <div
                    className={styles.crrResultsProgressBar}
                    style={{ width: domain.widthValpx + "px" }}
                  ></div>
                </div>
              );
            })}
            <div
              style={{ marginLeft: "1px" }}
              className={`${styles.crrResultsTableRow} ${styles.crrResultsTableLabelRow}`}
            >
              <div className={styles.crrResultsLabelCell}>
                Maturity&nbsp;Indicator&nbsp;Level&nbsp;
                <strong style={{ fontSize: "18" }}>0</strong>
              </div>
              <div className={`${styles.crrTableLabel} ${styles.mil1Grid}`}>
                <p className={styles.milnumberlabels}>.25</p>
              </div>
              <div className={`${styles.crrTableLabel} ${styles.mil1Grid}`}>
                <p className={styles.milnumberlabels}>.5</p>
              </div>
              <div className={`${styles.crrTableLabel} ${styles.mil1Grid}`}>
                <p className={styles.milnumberlabels}>.75</p>
              </div>
              <div
                className={`${styles.crrTableLabel} ${styles.mil1Grid} ${styles.mil1Override}`}
              >
                <p className={styles.milnumberlabels}>1</p>
                <p className={styles.uppermillabelbold}>MIL-1 Performed</p>
                <p className={styles.uppermillabeldesc}>
                  Domain practices are being performed.
                </p>
              </div>
              <div
                className={`${styles.crrTableLabel} ${styles.rbd} ${styles.milUpperGrid}`}
              >
                <p className={styles.milnumberlabels}>2</p>
                <p className={styles.uppermillabelbold}>MIL-2 Planned</p>
                <p className={styles.uppermillabeldesc}>
                  Domain practices are supported by planning, policy,
                  stakeholders, and standards.
                </p>
              </div>
              <div
                className={`${styles.crrTableLabel} ${styles.rbd} ${styles.milUpperGrid}`}
              >
                <p className={styles.milnumberlabels}>3</p>
                <p className={styles.uppermillabelbold}>MIL-3 Managed</p>
                <p className={styles.uppermillabeldesc}>
                  Domain practices are supported by governance and adequate
                  resources.
                </p>
              </div>
              <div
                className={`${styles.crrTableLabel} ${styles.rbd} ${styles.milUpperGrid}`}
              >
                <p className={styles.milnumberlabels}>4</p>
                <p className={styles.uppermillabelbold}>MIL-4 Measured</p>
                <p className={styles.uppermillabeldesc}>
                  Domain practices are supported by measurement, monitoring, and
                  executive oversight.
                </p>
              </div>
              <div
                className={`${styles.crrTableLabel} ${styles.milUpperGrid} ${styles.ccrLastLabel}`}
              >
                <p className={styles.milnumberlabels}>5</p>
                <p className={styles.uppermillabelbold}>MIL-5 Defined</p>
                <p
                  className={`${styles.uppermillabeldesc} ${styles.pe0} ${styles.pr0}`}
                >
                  Domain practices are supported by enterprise standardization
                  and analysis of lessons learned.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </React.Fragment>
  );
};

export default CrrSummaryResults;
