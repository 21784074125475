import React, { useState } from "react";
import styles from "./Simple.module.css";
import Grid from "@mui/material/Grid";
import { CSET_URL } from "../../../../config/index";
import { Button } from "../../../../components/UI/Form/Button/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useErrorBoundary } from "react-error-boundary";
import Alert from "@mui/material/Alert";

interface FormProps {
  name?: string;
  data?: any;
  props?: any;
  handleChange: any;
  handleErrorOfSimple?:any;
}

export const Simple: React.FC<FormProps> = ({
  name,
  data,
  props,
  handleChange,
  handleErrorOfSimple
}: FormProps) => {
  const { showBoundary } = useErrorBoundary();
  const [overAllSalValue, setOverAllSalValue] = React.useState("");
  const [confidentialityValue, setConfidentialityValue] = React.useState("");
  const [integrityValue, setIntegrityValue] = React.useState("");
  const [availabilityValue, setAvailabilityValue] = React.useState("");
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [levels] = React.useState(["Low", "Moderate", "High", "Very High"]);
  const authToken = sessionStorage.getItem("ra_token") || "";

  if (overAllSalValue === "" && data.hasOwnProperty("selected_Sal_Level")) {
    setOverAllSalValue(data.selected_Sal_Level);
  }
  if (confidentialityValue === "" && data.hasOwnProperty("cLevel")) {
    setConfidentialityValue(data.cLevel);
  }
  if (integrityValue === "" && data.hasOwnProperty("iLevel")) {
    setIntegrityValue(data.iLevel);
  }
  if (availabilityValue === "" && data.hasOwnProperty("aLevel")) {
    setAvailabilityValue(data.aLevel);
  }

  const onSelectLevel = (ltype: any, level: any) => {
    try{
    switch (ltype) {
      case "confidentiality": {
        data.cLevel = level;
        data.SelectedSALOverride = false;
        setConfidentialityValue(level);
        break;
      }
      case "integrity": {
        data.iLevel = level;
        data.SelectedSALOverride = false;
        setIntegrityValue(level);
        break;
      }
      case "availability": {
        data.aLevel = level;
        data.SelectedSALOverride = false;
        setAvailabilityValue(level);
        break;
      }
      default: {
        data.SelectedSALOverride = true;
        data.selected_Sal_Level = level;
        setOverAllSalValue(level);
        break;
      }
    }
    postSecurityAssuranceLevel();
    handleChange(data);
   }catch(err){
    showBoundary(err);
   }
  };

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };


  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };


  const postSecurityAssuranceLevel = async () => {
    try {
      await fetch(`${CSET_URL}api/SAL`, {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data: any) => {
          handleChange(data);
        })
        .catch((err:any) => {
          handleErrorOfSimple(err);
        });
    } catch (err:any) {
      handleErrorOfSimple(err);
    }
  };

  return (

      <Grid>
        <Grid container>
          {formState.isFailed ? (
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {formState.errMessage}
            </Alert>
          ) : null}
          <Grid container>
            <Grid item>
              <Typography component="h4" variant="h3">
                Overall SAL
              </Typography>
            </Grid>
            <Grid container>
              {levels.map((val: any) => {
                return (
                  <div key={val} className={styles.TabOptions}>
                    <Button
                      id="overall-sal"
                      className={
                        data.selected_Sal_Level === val
                          ? styles.TabOptionsActive
                          : styles.TabOptionsInactive
                      }
                      onClick={() => onSelectLevel("overall_sal", val)}
                    >
                      <div
                        className={
                          data.selected_Sal_Level === val
                            ? styles.para
                            : styles.TabOptionsInactive
                        }
                      >
                        {val}
                      </div>
                    </Button>
                  </div>
                );
              })}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography component="h4" variant="h3">
                Confidentiality
              </Typography>
            </Grid>
            <Grid container>
              {levels.map((val: any) => {
                return (
                  <div key={val} className={styles.TabOptions}>
                    <Button
                      id="confidentiality"
                      className={
                        data.cLevel === val
                          ? styles.TabOptionsActive
                          : styles.TabOptionsInactive
                      }
                      onClick={() => onSelectLevel("confidentiality", val)}
                    >
                      <div
                        className={
                          data.cLevel === val
                            ? styles.para
                            : styles.TabOptionsInactive
                        }
                      >
                        {val}
                      </div>
                    </Button>
                  </div>
                );
              })}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography component="h4" variant="h3">
                Integrity
              </Typography>
            </Grid>
            <Grid container>
              {levels.map((val: any) => {
                return (
                  <div key={val} className={styles.TabOptions}>
                    <Button
                      id="integrity"
                      className={
                        data.iLevel === val
                          ? styles.TabOptionsActive
                          : styles.TabOptionsInactive
                      }
                      onClick={() => onSelectLevel("integrity", val)}
                    >
                      <div
                        className={
                          data.iLevel === val
                            ? styles.para
                            : styles.TabOptionsInactive
                        }
                      >
                        {val}
                      </div>
                    </Button>
                  </div>
                );
              })}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Typography component="h4" variant="h3">
                Availability{" "}
              </Typography>
            </Grid>
            <Grid container>
              {levels.map((val: any) => {
                return (
                  <div key={val} className={styles.TabOptions}>
                    <Button
                      id="availability"
                      className={
                        data.aLevel === val
                          ? styles.TabOptionsActive
                          : styles.TabOptionsInactive
                      }
                      onClick={() => onSelectLevel("availability", val)}
                    >
                      <div
                        className={
                          data.aLevel === val
                            ? styles.para
                            : styles.TabOptionsInactive
                        }
                      >
                        {val}
                      </div>
                    </Button>
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

  );
};

export default Simple;
