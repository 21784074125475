import React, { useState, useEffect } from "react";
import styles from "./RRAResults.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import PerformanceSumary from "./PerformanceSummary/PerformanceSummary";
import GoalPerformance from "./GoalPerformance/GoalPerformance";
import AssessmentTiers from "./AssessmentTiers/AssessmentTiers";
import { Button } from "../../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../../common/RouteConstants";
import TopStepper from "../../../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import rraStepper from "../../../../common/csetRRAStepperMenu.json";
import { setStepper } from "../../../../common/SetStepper";
import RRAReport from "../RRAReports/ExecutiveSummary/RRAExecutiveSummary";
import { CSET_URL } from "../../../../config/index";
import { uploadFiles, getFileByName } from "../../../../graphql/mutations/Upload";
import { UPDATE_CSET_ASSESSMENT } from "../../../../graphql/mutations/CSETAssessment";
import { GET_ORGANIZATION } from "../../../../graphql/queries/Organization";
import logout from "../../../Auth/Logout/Logout";
import { DialogBox } from "../../../../components/UI/DialogBox/DialogBox";
import * as msgConstants from "../../../../common/MessageConstants";
import RRADeficiencyReport from "../RRAReports/DeficiencyReport/RRADeficiencyReport";
import CommentsAndReview from "../RRAReports/CommentsAndReview/CommentsAndReview";
import { setActiveFormStep } from "../../../../services/Data";
import { HISTORY } from "../../../../graphql/mutations/AuditTrail";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import Alert from "../../../../components/UI/Alert/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={styles.tabBoxPanel} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export const RRAResults: React.FC = (props: any) => {
  const location = useLocation();
  const rraStepperObject = rraStepper;
  const JSZip = require("jszip");
  const { showBoundary } = useErrorBoundary();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const client = useApolloClient();
  const history = useNavigate();
  const [value, setValue] = React.useState(0);
  const [genReport, setGenReport] = useState<any>(false);
  const [param, setParam] = useState<any>(location?.state[Object.keys(location?.state)[0]]);
  const [stepperObj, setStepperObj] = useState<any>({ path: "" });
  const [updateAssessment] = useMutation(UPDATE_CSET_ASSESSMENT);
  const [uploadFile] = useMutation(uploadFiles);
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const [partnerData, setPartnerData] = useState<any>({});
  const [clientData, setClientData] = useState<any>({});
  const [cpaImage, setCPAImage] = useState<any>();
  const [getBlankImage] = useLazyQuery(getFileByName);
  const [partnerImage, setPartnerImage] = useState<any>();
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false;
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const [cpaImageDimensions, setCPAImageDimensions] = useState<any>("");
  const [partnerImageDimensions, setPartnerImageDimensions] = useState<any>("");
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const dialogBoxMsg = msgConstants.REPORT_GENERATION;
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const headerObj = {
    "Content-Type": msgConstants.CONTENT_TYPE,
    Authorization: authToken,
  };

  useEffect(() => {
    setParam(location?.state[Object.keys(location?.state)[0]]);
    getBlankImage({
      variables:{
        name:"blank_img.png",
      }
    }).then((data:any) => {
      console.log("data----",data)
    }).catch((err:any)=>{
      sentry_error_catch(err,setShowBackdrop,setFormState)
    });
  }, []);

  useEffect(() => {
    setStepper(
      client,
      rraStepperObject.results.name,
      rraStepperObject.results.value
    );
    setActiveFormStep(3);
  }, []);

  const getAssessmentDetails = async (val: any) => {
    await fetch(`${CSET_URL}api/assessmentdetail`, {
      method: "GET",
      headers: {
        "Content-Type": msgConstants.CONTENT_TYPE,
        Authorization: val,
      },
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        param.assessmentInfo = data;
        setGenReport(true);
      })
      .catch((err:any)=>{
        sentry_error_catch(err,setShowBackdrop,setFormState)
      });
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleNext = () => {
    setStepper(
      client,
      rraStepperObject.reports.name,
      rraStepperObject.reports.value
    );
    history(routeConstant.RRA_REPORTS, {state: {param}});
  };

  const handleBack = () => {
    try{
    setStepper(
      client,
      rraStepperObject.questions.name,
      rraStepperObject.questions.value
    );
    history(routeConstant.RRA_QUESTIONS, {state: {param}});
   }catch(err){
    showBoundary(err);
   }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleCancel = () => {
    try{
    if (authToken) {
      const val: any = {
        clientInfo: param?.clientInfo,
        csetUserInfo: param?.csetUserInfo,
        pgPartnerId: param?.pgPartnerId,
        pgPartnerUserId: param?.pgPartnerUserId,
      };
      history(routeConstant.ASSESSMENT_LIST, { state: { val } });
    } else {
      logout();
    }
   }catch(err){
    showBoundary(err);
   }
  };

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
  };

  const [auditTrail] = useMutation(HISTORY);

  const handleGenerate = () => {
    setShowBackdrop(true);
    auditTrail({
      variables: {
        auditTrailInput: {
          individual: param.individualId,
          organization: param.partnerOrganizationId,
          action: {
            message: "Clicked on Okay Button to Generate Report.",
            data: null,
          },
          flowtype: param.flowType.type
            ? param.flowType.type
            : param.flowType,
          page: "RRA Results Page",
          client: param.clientOrgId,
          cset_assessment: param.ccAssessmentInfo.id,
        },
      },
    }).then((res: any) => {
      getAssessmentDetails(authToken);
    }).catch((err:any)=>{
      sentry_error_catch(err,setShowBackdrop,setFormState)
    });
  };

  const zip = new JSZip();
  const testArr: any = [];

  const handleExecutiveSummaryDocData = async (event: any) => {
    testArr.push(event);
    await zip.folder("Report").file("RRA_Report.docx", event, { base64: true });
    generateDocUploadZip(event);
  };
  const handleDeficiencyReport = async (event: any) => {
    testArr.push(event);
    await zip
      .folder("Report")
      .file("RRA_Deficiency_Report.docx", event, { base64: true });
    generateDocUploadZip(event);
  };
  const handleCommentReport = async (event: any) => {
    testArr.push(event);
    await zip
      .folder("Report")
      .file("Comment_And_Review_Report.docx", event, { base64: true });
    generateDocUploadZip(event);
  };

  const generateDocUploadZip = (event: any) => {
    testArr.forEach((element: any) => {
      if (
        testArr.length === 3 &&
        element.name === "RRA_Report.docx" &&
        "RRA_Deficiency_Report.docx" &&
        "Comment_And_Review_Report.docx"
      ) {
        zip
          .generateAsync({ type: "blob" })
          .then(async function (content: any) {
            content["name"] = param.assessmentInfo.assessmentName + ".zip";
            if (content) {
              await uploadDocument(content);
            }
          })
          .catch((error: any) => {});
      }
    });
  };

  const uploadDocument = async (ComplianceDocumentData: any) => {
    const handleUploadPromise = new Promise(function (Resolve, Reject) {
      if (ComplianceDocumentData) {
        // when successfull
        Resolve(ComplianceDocumentData);
      }
      Reject("error"); // when error
    });

    handleUploadPromise.then(
      async function (value) {
        if (ComplianceDocumentData.size && ComplianceDocumentData.type) {
          const BlobDocData: any = ComplianceDocumentData;
          if (BlobDocData !== undefined) {
            await uploadFile({
              variables: {
                files: value,
              },
            })
              .then((uploadRes: any) => {
                updateAssessment({
                  variables: {
                    id: param.ccAssessmentInfo.id,
                    assessment_type: param.ccAssessmentInfo.assessment_type,
                    allow_download: true,
                    assessment_document: uploadRes.data.upload.id,
                  },
                }).then((updateCompRes: any) => {
                  param.ccAssessmentInfo["assessment_Document"] =
                    updateCompRes.data.updateCsetAssessment.csetAssessment.assessment_document;
                  setShowBackdrop(false);
                  handleNext();
                }).catch((err:any)=>{
                  sentry_error_catch(err,setShowBackdrop,setFormState)
                });
              })
              .catch((err:any)=>{
                sentry_error_catch(err,setShowBackdrop,setFormState)
              });
          }
        }
      },
      function (error) {
        console.log("Error :-", error);
      }
    );
  };

  useEffect(() => {
    GetOrganizationDataOfPartner({
      variables: {
        where: {
          contact_id: param?.clientInfo.partnerId,
        },
      },
    }).catch((err:any)=>{
      sentry_error_catch(err,setShowBackdrop,setFormState)
    });
    GetOrganizationDataOfClient({
      variables: {
        where: {
          id: param?.clientInfo.clientOrgId,
          subtype: "Client",
        },
      },
    }).catch((err:any)=>{
      sentry_error_catch(err,setShowBackdrop,setFormState)
    });
  }, [param]);

  useEffect(() => {
    if (partnerData && clientData) {
      getImagesWhileBuildingReports(partnerData, clientData);
    }
  }, [partnerData, clientData]);

  const [GetOrganizationDataOfPartner] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      if (param?.hasOwnProperty("clientInfo")) {
        OrgDataResponse.organizations.forEach((element: any) => {
          if (param.clientInfo.partnerId === element.contact_id.id) {
            setPartnerData(element);
          }
          if (param.clientInfo.clientOrgId === element.id) {
            setClientData(element);
          }
        });
      }
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackdrop,setFormState)
    },
  });

  const [GetOrganizationDataOfClient] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackdrop,setFormState)
    },
  });

  const getImagesWhileBuildingReports = async (partner: any, client: any) => {
    if (client && client.logo && client.logo[0]) { 
      const clientLogoUrl = client.logo[0].url.slice(1);
      await fetch(`${serverUrl}${clientLogoUrl}`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.blob())
        .then((data) => {
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = function (theFile: any) {
            const base64data = reader.result;
            setCPAImage(base64data);
            const image = new Image();
            image.src = theFile.target.result;
            image.onload = () => {
              setCPAImageDimensions({
                height: image.height,
                width: image.width,
              });
            };
          };
        }).catch((error)=>{
          Sentry.captureException(error)
        })
    }
    // else{
    //   console.log("logo not found",defaultBlankURL);
    //   fetch(`${serverUrl}${defaultBlankURL}`, {
    //     method: "GET",
    //     headers: headerObj,
    //   })
    //     .then((response:any) => response.blob())
    //     .then((data:any) => {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(data);
    //       reader.onloadend = function (theFile: any) {
    //         const base64data = reader.result;
    //         setCPAImage(base64data);
    //         const image = new Image();
    //         image.src = theFile.target.result;
    //         image.onload = () => {
    //           setCPAImageDimensions({
    //             height: image.height,
    //             width: image.width,
    //           });
    //         };
    //       };
    //     })
    //     .catch((error) => {
    //       console.log("Error", error);
    //       Sentry.captureException(error)
    //       // return response = ""
    //     });
    // }
    if (partner && partner.logo && partner.logo[0]) {
      const partnerLogoUrl = partner.logo[0].url.slice(1);
      await fetch(`${serverUrl}${partnerLogoUrl}`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.blob())
        .then((data) => {
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = function (theFile: any) {
            const base64data = reader.result;
            setPartnerImage(base64data);
            const image = new Image();
            image.src = theFile.target.result;
            image.onload = () => {
              setPartnerImageDimensions({
                height: image.height,
                width: image.width,
              });
            };
          };
        }).catch((error)=>{
          Sentry.captureException(error)
        })
    }
    // else{
    //   fetch(`${serverUrl}${defaultBlankURL}`, {
    //     method: "GET",
    //     headers: headerObj,
    //   })
    //   .then((response) => response.blob())
    //   .then((data) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(data);
    //     reader.onloadend = function (theFile: any) {
    //       const base64data = reader.result;
    //       setPartnerImage(base64data);
    //       const image = new Image();
    //       image.src = theFile.target.result;
    //       image.onload = () => {
    //         setPartnerImageDimensions({
    //           height: image.height,
    //           width: image.width,
    //         });
    //       };
    //     };
    //   }).catch((error)=>{
    //     Sentry.captureException(error)
    //   })
    // }
  };

  const handleClose = () => {
    setShowDialogBox(false);
  };

  const handleOkay = () => {
    setShowDialogBox(false);
    handleGenerate();
  };

  const handleShowDialog = () => {
    setShowDialogBox(true);
  };

  const closeDialogBox = () => {
    try{
      setOpenDialogBox(false);
    }catch(err: any){
      showBoundary(err)
    }
  };

  const cancelHandler = () => {
    try{
      setOpenDialogBox(true);
    }catch(err: any){
      showBoundary(err)
    }
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        Risk Assessment for {param ? param.clientInfo.name : null}
      </Typography>
      <DialogBox
        open={openDialogBox}
        handleOk={handleCancel}
        handleCancel={closeDialogBox}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}>
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Please Confirm
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <p>
          {msgConstants.CANCEL_MSG}{" "}
            {/* {param.assessmentInfo?.assessmentName}? */}
          </p>
        </div>
      </DialogBox>
      {showBackdrop ? <SimpleBackdrop /> : null}
      <Grid container spacing={0}>
        <Grid item xs={12} className={styles.FooterActions}>
          <div className={styles.topstepperButons}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                {"<< Previous"}
              </Button>
            </div>
            <div className={styles.topstepperButons}>
              <TopStepper
                param={param}
                handleSave={callingFromTopStepper}
                obj={stepperObj}
                submitDisabled={false}
              />
            </div>
            <div className={styles.nextBtn}>
              <Button
                id="next-button"
                variant="contained"
                color="primary"
                onClick={handleShowDialog}
              >
                {"Next >>"}
              </Button>
            </div>
            </div>
            {!is_link_assessment && (<div className={styles.crossBtn}>
              <Button
                id="cancel-button"
                variant="contained"
                color="primary"
                onClick={cancelHandler}
              >
                {"X"}
              </Button>
              <span className={styles.cancleTooltip}>Cancel</span>
            </div>)}
          
        </Grid>
      </Grid>
      <div>
        <DialogBox
          open={showDialogBox}
          handleOk={handleOkay}
          handleCancel={handleClose}
          buttonOk={"Yes"}
          buttonCancel={"No"}
          classes={{
            root: styles.MainOfficeDialogRoot,
            container: styles.MainOfficeDialogboxContainer,
            paper: styles.MainOfficeDialogboxPaper,
            scrollPaper: styles.MainOfficeScrollPaper,
          }}
        >
          <div className={styles.DialogBoxTitle}>
            <Typography component="h1" variant="h1">
              Warning
            </Typography>
          </div>
          <div className={styles.DialogBoxContext}>
            <p>{dialogBoxMsg}</p>
          </div>
        </DialogBox>
      </div>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formState.errMessage}
        </Alert>
      ) : null}
      <AppBar position="static" color="default" className={styles.cset_band}>
        <Tabs
          classes={{ indicator: styles.PrivateTabIndicator }}
          variant="fullWidth"
          value={value}
          selectionFollowsFocus
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons={false}
          aria-label="scrollable auto tabs example"
        >
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Performance Summary"
            {...a11yProps(0)}
          />
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Goal Performance"
            {...a11yProps(1)}
          />
          <Tab
            classes={{ selected: styles.selectedTab }}
            label="Assessment Tiers"
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <PerformanceSumary data={param} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GoalPerformance data={param} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AssessmentTiers data={param} />
      </TabPanel>
      {genReport ? (
        <>
          <div className={styles.docstyle}>
            {
              <RRAReport
                from={"result"}
                handleChange={handleExecutiveSummaryDocData}
                props={param}
                partnerLogo={partnerImage}
                partnerImageDimension={partnerImageDimensions}
                clientLogo={cpaImage}
                clientImageDimension={cpaImageDimensions}
              />
            }
          </div>
          <div className={styles.docstyle}>
            {
              <RRADeficiencyReport
                from={"result"}
                handleChange={handleDeficiencyReport}
                props={param}
                partnerLogo={partnerImage}
                partnerImageDimension={partnerImageDimensions}
                clientLogo={cpaImage}
                clientImageDimension={cpaImageDimensions}
              />
            }
          </div>
          <div className={styles.docstyle}>
            {
              <CommentsAndReview
                from={"result"}
                handleChange={handleCommentReport}
                props={param}
                partnerLogo={partnerImage}
                partnerImageDimension={partnerImageDimensions}
                clientLogo={cpaImage}
                clientImageDimension={cpaImageDimensions}
              />
            }
          </div>
        </>
      ) : null}
    </React.Fragment>
  );
};
export default RRAResults;
