import React, { useState, useEffect } from "react";
import styles from "./StandardSummary.module.css";
import Paper from "@mui/material/Paper";
import { CSET_URL } from "../../../../config/index";
import { HorizontalBar, Doughnut  } from "react-chartjs-2";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import logout from "../../../../containers/Auth/Logout/Logout";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../components/UI/Alert/Alert";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";
import { ERROR_MESSAGE } from "../../../../common/MessageConstants";

export interface summaryProps {
  data?: any;
  props?: any;
}

export const StandardSummary: React.FC<summaryProps> = ({
  data,
  props,
}: summaryProps) => {
  const [chartData, setChartData] = useState<any>({});
  const [standardPieChartData, setStandardPieChartData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const authToken = sessionStorage.getItem("ra_token") || logout();

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  useEffect(() => {
    if (data !== undefined) {
      console.log(data);
    }
    getStandardSummaryDetails();
  }, []);

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const Doughnutoptions = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
  };

  const getStandardSummaryDetails = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/analysis/StandardsSummary`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          setChartData({
            labels: data.labels,
            datasets: data.dataSets,
            colors: data.colors,
          });
          if (data.data.length > 0) {
            setStandardPieChartData({
              labels: data.labels,
              datasets: [
                {
                  data: data.data,
                  backgroundColor: data.colors,
                  borderColor: data.colors,
                  borderWidth: 1,
                },
              ],
            });
          }

          createTableDataObject(data.dataRowsPie);
        }).catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };
  const options = {
    title: {
      display: false,
    },
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
    },
  };
  const createTableDataObject = (data: any) => {
    const arr: any = [];
    data.forEach((element: any) => {
      if (element.answer_Full_Name !== "Alternate") {
        const obj: any = {};
        obj["standard"] = !element.short_Name ? "-" : element.short_Name;
        obj["answer"] = element.answer_Full_Name;
        obj["number"] = !element.qc ? "-" : element.qc;
        obj["total"] = element.total;
        obj["percentage"] = `${element.percent}%`;
        arr.push(obj);
      }
    });
  };

  if (loading) {
    return <SimpleBackdrop />;
  }

  return (
    <React.Fragment>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formState.errMessage}
        </Alert>
      ) : null}
      <Paper className={styles.paper}>
        <div className="App">
          {standardPieChartData?.datasets?.length > 0 ? (
            <Paper className={styles.paper}>
              <Doughnut data={standardPieChartData} options={Doughnutoptions} />
            </Paper>
          ) : (
            <Paper className={styles.paper}>
              <HorizontalBar data={chartData} options={options} />
            </Paper>
          )}
        </div>
        {/* <Paper className={styles.paper}>
          <div className={styles.ScrollTable}>
            <MaterialTable
              columns={column}
              data={newData}
              options={{
                headerStyle: {
                  backgroundColor: "#fcfaff",
                  color: "#002F60",
                },
                actionsColumnIndex: -1,
                paging: true,
                sorting: true,
                search: false,
                filter: true,
                draggable: false,
                pageSize: 25,
                pageSizeOptions: [25, 50, 75, 100], // rows selection options
              }}
            />
          </div>
        </Paper> */}
      </Paper>
    </React.Fragment>
  );
};
export default StandardSummary;
