import React from "react";
import styles from "./AboutSection.module.css";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Button } from "../../components/UI/Form/Button/Button";
import * as routeConstant from "../../common/RouteConstants";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

const AboutSection = () => {
    const history = useNavigate();
    const { showBoundary } = useErrorBoundary();
    const backToList = () => {
        try{   
            history(routeConstant.CLIENT);
        }catch(err){
            showBoundary(err);
        }
    }
    return (
    <Grid>
        <Grid item xs={2} className={styles.FilterAddWrap}>
          <div className={styles.ButtonGroup2}>
            <Button
              id="cancel-button"
              className={styles.BackToButton}
              variant={"contained"}
              onClick={backToList}
              color="secondary"
              data-testid="cancel-button"
            >
              <img
                src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
                alt="user icon"
              />
              &nbsp; Back to Home
            </Button>
          </div>
        </Grid>
        <Paper className={styles.paper}>
            <div>
              <div className={styles.header}>ABOUT RA360</div>
              <p className={styles.content}>
                RA360 is based on CSET, which was developed under the DHS, to guarantee 
                that your risk assessment meets government compliance standards. 
                Our team is dedicated to keeping compliance continuously up to date with the 
                latest industry standards and recommendations, for your protection and satisfaction.
              </p>
              <div className={styles.subHeader}>In RA360, we have two types of Assessments :</div>
              <p className={styles.content}>
                <b>1. Quick Assessment :</b> A streamlined evaluation within RA360 designed to 
                provide a basic overview of your system's cybersecurity posture. It focuses 
                on key security areas to identify potential vulnerabilities quickly.
              </p>
              <p className={styles.content}>
                <b>2. Advanced Assessment :</b> Advanced Assessments are based on specific cybersecurity 
                standards.A comprehensive evaluation within RA360 that offers a deeper analysis of your 
                cybersecurity posture.It allows for customization, delves into specific security 
                controls and configurations, and provides a more detailed report on identified 
                vulnerabilities and overall security posture.
              </p>
              <p className={styles.content}>
                <b>For more information, please refer the RA360 section in the Knowledge Base.</b>
              </p>
            </div>
        </Paper>
    </Grid>
    )
}

export default AboutSection;