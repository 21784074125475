import React, { useState, useEffect, useRef } from "react";
import styles from "./MaturityQuestions.module.css";
import Typography from "@mui/material/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import QuestionsMode from "./QuestionsMode/QuestionsMode";
import Grid from "@mui/material/Grid";
import * as routeConstant from "../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import TopStepper from "../../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import cmmcStepper from "../../../common/csetCMMCStepperMenu.json";
import rraStepper from "../../../common/csetRRAStepperMenu.json";
import crrStepper from "../../../common/csetCRRStepperMenu.json";
import { setStepper } from "../../../common/SetStepper";
import { setActiveFormStep } from "../../../services/Data";
import { CSET_URL } from "../../../config/index";
import Pagination from "../../../components/UI/Pagination/Pagination";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import InPageSideMenus from "./InPageSideMenu/InPageSideMenus";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import GetAppIcon from "@mui/icons-material/GetApp";
import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material//DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../../components/UI/Alert/Alert";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import DOMPurify from "dompurify";
import { HISTORY } from "../../../graphql/mutations/AuditTrail";
import { saveAs } from "file-saver";
import { deleteFileById } from "../../../graphql/mutations/Upload";
import * as msgConstants from "../../../common/MessageConstants";
import {
  GET_DOCUMENT_BY_ASSESSMENT_ID,
  GET_ALL_DOCUMENTS,
} from "../../../graphql/queries/QuestionDocument";
import {
  CREATE_QUESTION_DOCUMENT_BY_ASS_ID,
  DELETE_QUESTION_DOCUMENT,
} from "../../../graphql/mutations/QuestionDocument";
import * as Sentry from "@sentry/react";
import { EXPSECONDS } from "../../../common/CSET";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { useErrorBoundary } from "react-error-boundary";
import { sentry_error_catch } from "../../../common/sentry_error_catch";

export const MaturityQuestions: React.FC = (props: any) => {
  const authToken = sessionStorage.getItem("ra_token") || "";
  const { showBoundary } = useErrorBoundary();
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false;
  const history = useNavigate();
  const location = useLocation();
  const [param, setParam] = useState<any>(
    location?.state[Object.keys(location?.state)[0]]
  );
  const [stepperObj, setStepperObj] = useState<any>({ path: "" });
  const [pageCount, setPageCount] = useState<any>(0);
  const [questionData, setQuestionData] = useState<any>([]);
  const stepperObject = cmmcStepper;
  const rraStepperObject = rraStepper;
  const crrStepperObject = crrStepper;
  const [currentPage, setCurrentPage] = useState(1);
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const [showQuestionRefList, setShowQuestionRefList] =
    useState<boolean>(false);
  const [referenceList, setReferenceList] = useState<string>("");
  const dialogBoxMsg =
    "Some questions have not been answered. Are you sure you want to go to Results ?";
  const [questionList, setQuestionList] = useState([]);
  const [allDocuments, setAllDocuments] = useState<any>();
  const [showAllDocuments, setShowAllDocuments] = useState(false);
  const [getDocumentsByAssId] = useLazyQuery(GET_DOCUMENT_BY_ASSESSMENT_ID, {
    fetchPolicy: "cache-and-network",
  });
  const [getAllDocuments] = useLazyQuery(GET_ALL_DOCUMENTS, {
    fetchPolicy: msgConstants.FETCH_POLICY,
  });
  const [createQuestionDocumentByAssId] = useMutation(
    CREATE_QUESTION_DOCUMENT_BY_ASS_ID
  );
  const [deleteDocumentFileById] = useMutation(deleteFileById);
  const [deleteDocument] = useMutation(DELETE_QUESTION_DOCUMENT);
  const [documentData, setDocumentData] = useState<any>();
  const authSession = sessionStorage.getItem("ra_session") || "";
  const inputFileRef: any = useRef();
  const [openConfireDeleteDialog, setOpenConfireDeleteDialog] = useState(false);
  const [infoDoc, setInfoDoc] = useState();
  const [documentInfo, setDocumentInfo] = useState();
  const [isAnswerCountChange, setIsAnswerCountChange] = useState<any>();
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  useEffect(() => {
    getTotalQuestionsAns(questionData);
  }, [isAnswerCountChange]);

  useEffect(() => {
    const controller = new AbortController();
    setParam(location?.state[Object.keys(location?.state)[0]]);
    getQuestionList();
    getTokenForAssessment()
    setLoading(true);
    return () => controller.abort();
  }, []);

  const [auditTrail] = useMutation(HISTORY);

  const getPageCount = (data: any) => {
    setCurrentPage(1);
    setPageCount(data.pageCount);
  };

  const getQuestionData = (data: any) => {
    setQuestionData(data);
    setIsAnswerCountChange((isAnswerCountChange: any) => !isAnswerCountChange);
  };

  const questionListDisplay = (data: any) => {
    if (data.resourceDocumentList && data?.resourceDocumentList.length > 0) {
      setQuestionList(data.resourceDocumentList);
      setShowQuestionRefList(true);
      setLoading(false);
    }
    if (data.referenceTextList.length > 0) {
      const refText: string = data.referenceTextList[0];
      setReferenceList(refText);
    }
    if (
      data.additionalDocumentsList &&
      data.additionalDocumentsList.length > 0
    ) {
      setQuestionList(data.additionalDocumentsList);
    }
  };

  const PageSize = 1;
  useEffect(() => {
    const controller = new AbortController();
    if (param !== undefined && param.flowType !== undefined) {
      if (param.flowType.type === "RRA") {
        setStepper(
          client,
          rraStepperObject.questions.name,
          rraStepperObject.questions.value
        );
        setActiveFormStep(2);
      }
      if (param.flowType.type === "CMMC2") {
        setStepper(
          client,
          stepperObject.questions.name,
          stepperObject.questions.value
        );
        setActiveFormStep(2);
      }
      if (param.flowType.type === "CRR") {
        setStepper(
          client,
          crrStepperObject.questions.name,
          crrStepperObject.questions.value
        );
        setActiveFormStep(2);
      }
    }
    return () => controller.abort();
  }, [param]);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getTokenForAssessment = async () => {
    await fetch(
      `${CSET_URL}api/auth/token?assessmentId=${param.assessmentInfo.id}&expSeconds=${EXPSECONDS}`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
    .then((response) => response.json())
    .then((data) => {
      if (data.token) {
        sessionStorage.setItem("ra_token", data.token);
      }
    })
    .catch((error) => {
      setLoading(false);
      handleCancel(error);
    });
  };

  const getQuestionList = async () => {
    try {
      await fetch(
        `${CSET_URL}api/MaturityQuestions?installationMode=CSET&fill=false`,
        {
          method: "GET",
          headers: headerObj,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.Message === "An error has occurred.") {
            handleCancel(data.Message);
          }
          setLoading(false);
          setCurrentPage(1);
          setPageCount(data.groupings?.length);
        })
        .catch((error) => {
          handleCancel(error);
        });
    } catch (e) {
      setLoading(false);
      handleCancel(e);
    }
  };

  const handleNext = () => {
    if (authToken) {
      if (param.flowType.type === "RRA") {
        setStepper(
          client,
          rraStepperObject.questions.name,
          rraStepperObject.questions.value
        );
        history(routeConstant.RRA_RESULTS, { state: { param } });
      }
      if (param.flowType.type === "CMMC2") {
        setStepper(
          client,
          stepperObject.questions.name,
          stepperObject.questions.value
        );
        history(routeConstant.CMMC2_RESULTS, { state: { param } });
      }
      if (param.flowType.type === "CRR") {
        setStepper(
          client,
          crrStepperObject.questions.name,
          crrStepperObject.questions.value
        );
        history(routeConstant.CRR_RESULTS, { state: { param } });
      }
    }
  };

  const handleBack = () => {
    try{
    if (param.flowType.type === "RRA") {
      history(routeConstant.ASSESSMENT_DEMOGRAPHICS, { state: { param } });
    }
    if (param.flowType.type === "CMMC2") {
      history(routeConstant.CMMC2_LEVEL, { state: { param } });
    }
    if (param.flowType.type === "CRR") {
      history(routeConstant.ASSESSMENT_DEMOGRAPHICS, { state: { param } });
    }
   }catch(err){
    showBoundary(err);
   }
  };

  const handleSaveAndExit = () => {
    try{
    if(!is_link_assessment){
    const val: any = {
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      pgPartnerId: param.pgPartnerId,
      pgPartnerUserId: param.pgPartnerUserId,
    };
    history(routeConstant.ASSESSMENT_LIST, { state: { val } });
   }else{
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: true,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: `Your Assessment has been saved Successfully.`,
    }))
   }
   }catch(err){
    showBoundary(err);
   }
  };

  const handleCancel = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
    handleNext();
  };

  const handleOnSideMenuClick = (event: any, index: any) => {
    setCurrentPage(index + 1);
  };

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  const QuestionRefList = (event: any) => {
    setShowQuestionRefList(true);
  };

  const getTotalQuestions = (arr: any) => {
    let TotalCount: any = 0;
    if (arr !== undefined) {
      const tempArray: any = [];
      arr.forEach((element: any, index: any) => {
        element.subGroupings.forEach((ques: any, index: any) => {
          if (param.MaturityLevel === "1" && param.flowType.type === "CMMC2") {
            const MatLevel1Data = ques.questions.filter(
              (x: any) => x.maturityLevel === 1
            );
            tempArray.push(MatLevel1Data.length);
          } else {
            const filterArray = ques.questions.filter(
              (x: any) => !x.isParentQuestion
            );
            tempArray.push(filterArray.length);
          }
        });
      });
      for (let i = 0; i < tempArray.length; ++i) {
        TotalCount += tempArray[i];
      }
    }
    return TotalCount;
  };

  const getTotalQuestionsAns = (arr: any) => {
    let TotalAnswerd: any = 0;
    if (arr !== undefined) {
      const tempArray: any = [];
      arr.forEach((element: any) => {
        element.subGroupings.forEach((ques: any) => {
          if (param.MaturityLevel === "1" && param.flowType.type === "CMMC2") {
            const MatLevel1Data = ques.questions.filter(
              (x: any) => x.maturityLevel === 1
            );
            MatLevel1Data.forEach((questionElement: any) => {
              tempArray.push(questionElement.answer);
            });
          } else {
            ques.questions.forEach((questionElement: any) => {
              tempArray.push(questionElement.answer);
            });
          }
        });
      });
      for (let i = 0; i < tempArray.length; ++i) {
        if (tempArray[i] !== "U" && tempArray[i] !== null) {
          TotalAnswerd++;
        }
      }
    }
    return TotalAnswerd;
  };

  const handleClose = () => {
    setShowDialogBox(false);
    setLoading(false);
    setShowQuestionRefList(false);
    setQuestionList([]);
  };

  const handleOkay = () => {
    setShowDialogBox(false);
    auditTrail({
      variables: {
        auditTrailInput: {
          individual: param.individualId,
          organization: param.partnerOrganizationId,
          action: {
            message: "Clicked on Okay Button to Redirect to Results Page",
            data: null,
          },
          flowtype: param.flowType.type ? param.flowType.type : param.flowType,
          page: "Maturity Questions Page",
          client: param.clientOrgId,
          cset_assessment: param.ccAssessmentInfo.id,
        },
      },
    }).then((res: any) => {
      if (param.flowType.type === "RRA" && authToken) {
        setStepper(
          client,
          rraStepperObject.results.name,
          rraStepperObject.results.value
        );
        history(routeConstant.RRA_RESULTS, { state: { param } });
      }

      if (param.flowType.type === "CMMC2" && authToken) {
        setStepper(
          client,
          rraStepperObject.results.name,
          rraStepperObject.results.value
        );
        history(routeConstant.CMMC2_RESULTS, { state: { param } });
      }

      if (param.flowType.type === "CRR" && authToken) {
        setShowDialogBox(true);
        setStepper(
          client,
          rraStepperObject.results.name,
          rraStepperObject.results.value
        );
        history(routeConstant.CRR_RESULTS, { state: { param } });
      }
    }).catch((err: any) => {
      sentry_error_catch(err,setLoading,setFormState)
    });
  };

  const handleNexSubmitAnswer = () => {
    if (
      getTotalQuestions(questionData) !== undefined &&
      getTotalQuestionsAns(questionData) === getTotalQuestions(questionData)
    ) {
      setShowDialogBox(false);
      handleOkay();
    } else {
      setShowDialogBox(true);
    }
  };

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const onClickHandler = async (docInfo: any, val: any, docList?: any) => {
    try{
    if (val === "download") {
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const urlDoc = docInfo.url.slice(1);
      const url = serverUrl + urlDoc;
      await fetch(url, {
        method: "GET",
      })
        .then((res) => res.blob())
        .then((data) => {
          if (data) {
            saveAs(data, docInfo.name);
          }
        })
        .catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
    }
    }catch(err){
      showBoundary(err);
    }
  };

  const documentClickHandler = (isDocumentClick: any) => {
    setShowAllDocuments(true);
    handleAlertClose();
    setLoading(true);
    if (isDocumentClick) {
      getDocumentsByAssId({
        variables: {
          assId: +param.ccAssessmentInfo.id,
          featureType: "Document",
        },
      })
        .then((data: any) => {
          setLoading(false);
          const displayData = data.data.questionDocuments;
          setAllDocuments(displayData);
        })
        .catch((err: any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
    }
  };
  const handleDocumentChange = (event: any) => {
    try{
    let validFile: boolean = false;
    const fileTypes: any = [".pdf", ".txt", ".docx", ".doc", ".xlsx"];
    if (event && event?.target?.files && event?.target?.files[0]?.name) {
      fileTypes.forEach((filetype: any) => {
        if (event.target.files[0].name.endsWith(filetype)) {
          validFile = true;
        }
        if (validFile) {
          return;
        }
      });
      if (validFile) {
        setDocumentData(event.target.files[0]);
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isFailed: false,
          errMessage: "",
        }));
        addFile(event.target.files[0]);
      } else {
        setDocumentData("");
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isFailed: true,
          errMessage: "Selected File Type is not Valid.",
        }));
      }
    }
    event.target.value = null;
   }catch(err){
    showBoundary(err);
   }
  };
  const openFileHandler = () => {
    inputFileRef.current.click();
  };
  const addFile = async (fileInfo: any) => {
    const formData: FormData = new FormData();
    let docId;
    setLoading(true);
    try {
      if (fileInfo) {
        // console.log('start----------->',fileInfo)
        const existingDocumentsArr = await getDocumentsByAssId({
          variables: {
            assId: +param.ccAssessmentInfo.id,
            featureType: "Document",
          },
        })
        .then((data: any) => {
          return data.data.questionDocuments.filter((doc:any)=>doc.question_Text === "Global") || [];
        })
        .catch((err: any) => {
          sentry_error_catch(err, setLoading, setFormState);
          return []; 
        });

        let fileName = fileInfo.name;
        let orignalName = fileName.replace(/\.[^/.]+$/, ""); 
        let extension = fileName.split('.').pop(); 
        let count = 1;
        let updatedFileNames = [];
        if(existingDocumentsArr[0]?.document_id.length > 0){
          const fetchedFileNames = existingDocumentsArr.map((doc: any) => doc.document_id[0].name);
          
          updatedFileNames = [...fetchedFileNames];
          if (updatedFileNames.includes(`${orignalName}_global.${extension}`)) {
            count = updatedFileNames.filter(item => item.includes(`${orignalName}`)).length
            fileName = `${orignalName}_global_${count}.${extension}`;
          }else{
            fileName = `${orignalName}_global.${extension}`;
            formData.append("files", new File([fileInfo], fileName));
          }
          formData.append("files", new File([fileInfo], fileName));
        }else{
          fileName = `${orignalName}_global.${extension}`;
          formData.append("files", new File([fileInfo], fileName));
        }
        await fetch(process.env.REACT_APP_SERVER_URL + "upload/", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authSession}`,
          },
          body: formData,
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error(res.statusText);
          })
          .then((data: any) => {
            docId = data[0].id;
          })
          .catch((err:any) => {
            sentry_error_catch(err,setLoading,setFormState)
          });
        createQuestionDocumentByAssId({
          variables: {
            assId: +param.ccAssessmentInfo.id,
            flow_type: param.flowType.type,
            document_id: docId,
            featureType: "Document",
          },
        })
          .then((data: any) => {
            setAllDocuments((allDocuments: any) => [
              ...allDocuments,
              data.data.createQuestionDocument.questionDocument,
            ]);
            setFormState((formState: any) => ({
              ...formState,
              isSuccess: true,
              isFailed: false,
              isDelete: false,
              isUpdate: false,
              errMessage: "Document Added Successfully.",
            }));
          })
          .catch((err: any) => {
            sentry_error_catch(err,setLoading,setFormState)
          });
      }
    } catch (err: any) {
      sentry_error_catch(err,setLoading,setFormState)
    }
    setLoading(false);
  };
  const handleAlertClose = () => {
    setFormState((formState: any) => ({
      ...formState,
      isSuccess: false,
      isFailed: false,
      isDelete: false,
      isUpdate: false,
      errMessage: "",
    }));
  };
  const openConfirmDeleteBoxHandler = (documentInfo: any, quesInfo: any) => {
    setInfoDoc(documentInfo);
    setDocumentInfo(quesInfo);
    setOpenConfireDeleteDialog(true);
  };
  const closeConfirmDeleteBoxHandler = () => {
    setOpenConfireDeleteDialog(false);
  };
  const closeFileHandler = () => {
    setShowAllDocuments(false);
  };
  const handleDocumentToggle = async (practise: any) => {
    await getAllDocuments({
      variables: {
        featureType: "Document",
        assId: parseInt(param?.ccAssessmentInfo?.id),
        practise,
      },
    })
      .then((data: any) => {
        setAllDocuments(data.data.questionDocuments);
      })
      .catch((err: any) => {
        sentry_error_catch(err,setLoading,setFormState)
      });
  };
  const deleteFile = (docInfo: any, doc: any) => {
    try{
    setLoading(true);
    deleteDocumentFileById({
      variables: {
        id: docInfo.id,
      },
    })
      .then(async (data: any) => {
        if (doc?.document_id?.length <= 1) {
          await deleteDocument({
            variables: {
              id: doc?.id,
            },
          })
            .then((data: any) => {})
            .catch((err: any) => {
              sentry_error_catch(err,setLoading,setFormState)
            });
        }
        const docData: any = await getDocumentsByAssId({
          variables: {
            assId: +param.ccAssessmentInfo.id,
            featureType: "Document",
          },
        })
          .then((data: any) => data)
          .catch((err: any) => {
            sentry_error_catch(err,setLoading,setFormState)
          });
        setAllDocuments(
          (allDocuments: any) => docData?.data?.questionDocuments
        );
        setFormState((formState) => ({
          ...formState,
          isSuccess: true,
          errMessage: "Document Deleted Successfully.",
        }));
        setLoading(false);
      })
      .catch((err: any) => {
        sentry_error_catch(err,setLoading,setFormState)
      });
    if (doc.question_Text !== "Global") {
      handleDocumentToggle(
        `${doc.practise.split("->")[0]} -> ${doc.practise.split("->")[1]}`
      );
    }
    setOpenConfireDeleteDialog(false);
    }catch(err){
      showBoundary(err);
    }
  };

  return (
    <React.Fragment>
      <Typography component="h5" variant="h1">
        Risk Assessment for{" "}
        {param
          ? param.clientInfo === undefined
            ? param.assessmentInfo.CreatorName
            : param.clientInfo.name
          : null}
      </Typography>
      <div>
        <DialogBox
          open={showDialogBox}
          handleOk={handleOkay}
          handleCancel={handleClose}
          buttonOk={"Yes"}
          buttonCancel={"No"}
          classes={{
            root: styles.MainOfficeDialogRoot,
            container: styles.MainOfficeDialogboxContainer,
            paper: styles.MainOfficeDialogboxPaper,
            scrollPaper: styles.MainOfficeScrollPaper,
          }}>
          <div className={styles.DialogBoxTitle}>
            <Typography component="h1" variant="h1">
              Warning
            </Typography>
          </div>
          <div className={styles.DialogBoxContext}>
            <p>{dialogBoxMsg}</p>
          </div>
        </DialogBox>
      </div>
      <DialogBox
        open={openConfireDeleteDialog}
        handleOk={() => {
          deleteFile(infoDoc, documentInfo);
        }}
        handleCancel={closeConfirmDeleteBoxHandler}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}>
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Please Confirm
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <p>Are you sure you want to remove {documentData?.name}?</p>
        </div>
      </DialogBox>
      <DialogBox
        open={showAllDocuments}
        handleOk={openFileHandler}
        handleCancel={closeFileHandler}
        buttonOk={"Add"}
        buttonCancel={"Cancel"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.allDocumentDialogboxContainer,
          paper: styles.allDocumentDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}>
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            All Documents
          </Typography>
        </div>
        {loading ? <SimpleBackdrop /> : null}
        <div className={styles.DialogBoxContext}>
          <table className={styles.tableStyle}>
            <tr>
              <th style={{ textAlign: "left" }}>Category</th>
              <th style={{ textAlign: "left" }}>Sub-category</th>
              <th style={{ textAlign: "center" }}>File Name</th>
            </tr>
            {allDocuments && allDocuments.length !== 0 ? (
              allDocuments.map((doc: any, index: any) => {
                const splitArr = doc.practise ? doc.practise.split("->") : "";
                return (
                  <tr key={doc?.id}>
                    <td>{doc.practise ? splitArr[0] : "Global"}</td>
                    <td>
                      {doc.question_Text === "Global"
                        ? "--"
                        : doc.practise
                        ? `${splitArr[1]} ${
                            splitArr[2] ? "-> Q." + splitArr[2] : ""
                          }`
                        : ""}
                    </td>
                    <td>
                      {doc.document_id.map((docInfo: any) => {
                        return (
                          <div key={docInfo?.id} className={styles.fileNameContainer}>
                            <span className={styles.fileNameText}>
                              {docInfo.name.charAt(0).toUpperCase() +
                                docInfo.name.slice(1)}
                            </span>
                            <span className={styles.fileNameDownload}>
                              <GetAppIcon
                                id="download"
                                style={{ color: "#4b0079", cursor: "pointer" }}
                                fontSize="small"
                                onClick={() => {
                                  onClickHandler(docInfo, "download");
                                }}
                              />{" "}
                            </span>
                            <span className={styles.fileNameDelete}>
                              <DeleteOutlineIcon
                                id="delete"
                                style={{ color: "#4b0079", cursor: "pointer" }}
                                onClick={() => {
                                  openConfirmDeleteBoxHandler(docInfo, doc);
                                }}
                              />{" "}
                            </span>
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={3}>
                  <h4 style={{ alignItems: "center" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;No documents Found.
                  </h4>
                </td>
              </tr>
            )}
          </table>
          <p className={styles.noteTitle}>
            {formState.isFailed ? (
              <b style={{ color: "red" }}>Not a Valid File.</b>
            ) : (
              "Note:File type should be pdf,word,excel or text."
            )}
          </p>
          <input
            type="file"
            ref={inputFileRef}
            onChange={handleDocumentChange}
            style={{ display: "none" }}
          />
        </div>
      </DialogBox>
      {questionList.length > 0 &&
      showQuestionRefList &&
      referenceList.length === 0 ? (
        <div>
          <DialogBox
            open={showQuestionRefList}
            handleOk={handleClose}
            skipCancel
            buttonOk={"Close"}
            classes={{
              root: styles.MainOfficeDialogRoot,
              container: styles.refrenceDialogboxContainer,
              paper: styles.refrenceDialogboxPaper,
              scrollPaper: styles.MainOfficeScrollPaper,
            }}>
            <div className={styles.DialogBoxTitle}>
              <Typography component="h1" variant="h1">
                Reference Documents
              </Typography>
            </div>
            <div className={styles.DialogBoxContext}>
              <table id="ReferenceTable" className={styles.tableStyle}>
                <tr>
                  <th colSpan={3}>Help Documents</th>
                  <th colSpan={2}>Sections</th>
                  <th colSpan={2}>&nbsp;</th>
                </tr>
                {questionList.map((element: any, index: any) => {
                  return (
                    <tr key={element?.title}>
                      <td colSpan={3} className={styles.referenceText}>
                        {" "}
                        {element.title}
                      </td>
                      <td colSpan={2}>{element.section_Ref}</td>
                      <td colSpan={2}>
                        <a
                          className={styles.referenceBtn}
                          href={`${CSET_URL}Documents/${element.file_Name}`}
                          rel="noopener noreferrer"
                          target="_blank">
                          <GetAppIcon
                            id="download"
                            style={{ color: "#4b0079" }}
                          />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </DialogBox>
        </div>
      ) : (
        <></>
      )}
      {questionList.length === 0 &&
      referenceList !== null &&
      referenceList?.length === 0 &&
      showQuestionRefList ? (
        // <div className={classes.container}>
        <div>
          <Fade in={showQuestionRefList}>
            <Paper elevation={4}>
              {/* className={classes.paper}> */}
              <DialogBox
                open={showQuestionRefList}
                handleOk={handleClose}
                skipCancel
                buttonOk={"Close"}>
                <div className={styles.DialogBoxTitle}>
                  <Typography component="h1" variant="h1">
                    Reference Documents
                  </Typography>
                </div>
                <div className={styles.DialogBoxContext}>
                  No Reference Documents are currently Available !.
                </div>
              </DialogBox>
            </Paper>
          </Fade>
        </div>
      ) : (
        <></>
      )}
      {referenceList?.length !== 0 &&
      referenceList !== null &&
      (param.flowType.type === "RRA" || param.flowType.type === "CRR") ? (
        <div>
          <Fade in={showQuestionRefList}>
            <Paper elevation={4}>
              <DialogBox
                open={showQuestionRefList}
                handleOk={handleClose}
                skipCancel
                buttonOk={"Close"}>
                <div className={styles.DialogBoxTitle}>
                  <Typography component="h1" variant="h1">
                    Reference Documents
                  </Typography>
                </div>
                <div className={styles.DialogBoxContext}>
                  <p
                    dangerouslySetInnerHTML={createDangerousHTML(
                      referenceList
                    )}></p>
                </div>
                {param.flowType.type === "CRR" ? (
                  <div
                    className={styles.DialogBoxContext}
                    style={{ paddingTop: "0px !important" }}>
                    <h4 style={{ textAlign: "left" }}>Additional Documents</h4>
                    <table id="ReferenceTable" className={styles.tableStyle}>
                      <tr>
                        <th colSpan={3}>Help Documents</th>
                        <th colSpan={2}>Sections</th>
                        <th colSpan={2}>&nbsp;</th>
                      </tr>
                      {questionList.map((element: any, index: any) => {
                        return (
                          <tr key={element?.title}>
                            <td colSpan={3} className={styles.referenceText}>
                              {" "}
                              {element.title}
                            </td>
                            <td colSpan={2}>{element.section_Ref}</td>
                            <td colSpan={2}>
                              <a
                                className={styles.referenceBtn}
                                href={`${CSET_URL}Documents/${element.file_Name}`}
                                rel="noopener noreferrer"
                                target="_blank">
                                <GetAppIcon
                                  id="download"
                                  style={{ color: "#4b0079" }}
                                />
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                ) : (
                  <></>
                )}
              </DialogBox>
            </Paper>
          </Fade>
        </div>
      ) : (
        <></>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} className={styles.FooterActions}>
          <div className={styles.top_nav}>
            <div className={styles.topstepperButonsWrap}>
              <div className={styles.prevBtn}>
                <Button
                  id="previous-button"
                  variant="contained"
                  color="primary"
                  onClick={handleBack}>
                  {"<< Previous"}
                </Button>
              </div>
              <div className={styles.topstepperButons}>
                <TopStepper
                  param={param}
                  handleSave={callingFromTopStepper}
                  obj={stepperObj}
                  submitDisabled={false}
                />
              </div>
              {!is_link_assessment ? <div className={styles.nextBtn}>
                <Button
                  id="next-button"
                  variant="contained"
                  color="primary"
                  onClick={handleNexSubmitAnswer}>
                  {"Next >>"}
                </Button>
              </div> : null}
            </div>
            <div className={styles.saveAndExit}>
              <Button
                id="cancel-button"
                variant="contained"
                color="primary"
                onClick={handleSaveAndExit}>
                {!is_link_assessment ? "Save and Exit" : "Save"}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      {loading ? <SimpleBackdrop /> : null}
      <div className={styles.sideNavPanel}>
        <div className={styles.sideNavPanel_inner}>
          {questionData && (
            <div className={styles.sidePanelQuestionCount}>
              {`Total Questions:  ${getTotalQuestionsAns(
                questionData
              )} / ${getTotalQuestions(questionData)}`}
              {questionData.applicationMode === "R"
                ? questionData.requirementCount
                : questionData.questionCount}
            </div>
          )}
          <InPageSideMenus
            paginationData={currentPage}
            menuListData={questionData}
            propsdata={param}
            opened={true}
            handleOnclick={handleOnSideMenuClick}
            documentClicked={documentClickHandler}
          />
        </div>
      </div>
      <div className={styles.Qbank}>
        <div className={styles.uploadFilePaper}>
          {formState.isSuccess ? (
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              <strong>{formState.errMessage}</strong>
            </Alert>
          ) : null}
          {formState.isFailed ? (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {formState.errMessage}
            </Alert>
          ) : null}
        </div>
        <div className={styles.topPaginationButtonDiv}>
          <div className={styles.paginationDiv}>
            <Pagination
              className={styles.paginationBar}
              currentPage={currentPage}
              totalCount={pageCount}
              pageSize={PageSize}
              onPageChange={(page: any) => handlePageChange(page)}
            />
          </div>
        </div>
        <div className={styles.TabButtons}>
          <QuestionsMode
            data={"Questions"}
            getQuestionData={getQuestionData}
            getPageCount={getPageCount}
            paginationData={currentPage}
            props={param}
            questionListmessageDisplay={questionListDisplay}
            showQuestionRefList={QuestionRefList}
            allDocument={allDocuments}></QuestionsMode>
        </div>
        <div className={styles.bottomPaginationButtonDiv}>
          <div className={styles.bottomPagination}>
            <Pagination
              className={styles.paginationBar}
              currentPage={currentPage}
              totalCount={pageCount}
              pageSize={PageSize}
              onPageChange={(page: any) => handlePageChange(page)}
            />
          </div>
          <div className={styles.saveAndExitBottom}>
            <Button
              id="cancel-button"
              variant="contained"
              color="primary"
              onClick={handleSaveAndExit}>
              {!is_link_assessment ? "Save and Exit" : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MaturityQuestions;
