import React, { useState, useEffect } from "react";
import styles from "./RRAExecutiveSummary.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Button } from "../../../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { CSET_URL } from "../../../../../config/index";
import MaterialTable from "../../../../../components/UI/Table/MaterialTable";
import CloseIcon from "@mui/icons-material/Close";
import autoTable from "jspdf-autotable";
import { useLazyQuery } from "@apollo/client";
import IconButton from "@mui/material/IconButton";
import Alert from "../../../../../components/UI/Alert/Alert";
import { GET_ORGANIZATION } from "../../../../../graphql/queries/Organization";
import { HorizontalBar, Doughnut } from "react-chartjs-2";
import { saveAs } from "file-saver";
import * as docx from "docx";
import logout from "../../../../Auth/Logout/Logout";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import { jsPDF } from "jspdf";
import GetAppIcon from "@mui/icons-material/GetApp";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { GET_DOCUMENT_BY_ASSESSMENT_ID } from "../../../../../graphql/queries/QuestionDocument";
import * as msgConstants from "../../../../../common/MessageConstants";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";

export interface ExecutiveProps {
  from?: string;
  props?: any;
  data?: any;
  handleChange?: any;
  partnerLogo?: any;
  clientLogo?: any;
  partnerImageDimension?: any;
  clientImageDimension?: any;
}

export const RRAExecutiveSummary: React.FC<ExecutiveProps> = ({
  from,
  data,
  props,
  handleChange,
  partnerLogo,
  clientLogo,
  partnerImageDimension,
  clientImageDimension,
}: ExecutiveProps) => {
  const history = useNavigate();
  const location = useLocation();
  const [dataObj, setDataObj] = useState<any>({});
  const [overallScore, setOverallScore] = useState<any>();
  const [practicePerformed, setPracticePerformed] = useState({});
  const [practicePerformedByGoal, setPracticePerformedByGoal] = useState({});
  const [suggestedAreaOfImprovement, setSuggestedAreaOfImprovement] = useState({});
  const [questionsAnswernewData, setQuestionsAnswernewData] = useState([]);
  const [overall, setOverall] = useState<any>({});
  const [basic, setBasic] = useState<any>({});
  const [intermediate, setIntermediate] = useState<any>({});
  const [advanced, setAdvanced] = useState<any>({});
  const [newData, setNewData] = useState<any>([]);
  const componentRef = React.useRef<HTMLHeadingElement>(null);
  const chartRef1 = React.useRef<any>(null);
  const chartRef2 = React.useRef<any>(null);
  const chartRef3 = React.useRef<any>(null);
  const chartRef4 = React.useRef<any>(null);
  const chartRef5 = React.useRef<any>(null);
  const chartRef6 = React.useRef<any>(null);
  const chartRef7 = React.useRef<any>(null);
  const preparedForBy = "preparedFor/By";
  const notApplicable = "Not Applicable";
  const subCategory = "sub-category";
  const PracticeQuestionsAnswered = "Practice Questions Answered Per Tier";
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const [partnerData, setPartnerData] = useState<any>({});
  const [clientData, setClientData] = useState<any>({});
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [param, setParam] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [bannerImage, setBannerImage] = useState<any>("");
  const [cpaImage, setCPAImage] = useState<any>("");
  const [partnerImage, setPartnerImage] = useState<any>("");
  const [cpaImageDimensions, setCPAImageDimensions] = useState<any>("");
  const [partnerImageDimensions, setPartnerImageDimensions] = useState<any>("");
  const [defaultCPAImage, setDefaultCPAImage] = useState<any>("");
  const [practiceListnewData, setPracticeListnewData] = useState<any>([]);
  const [documentListData, setDocumentListData] = useState<any>([]);
  const [rraModelImage, setRRAModelImage] = useState<any>("");
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [getAllDocumentsByAssId] = useLazyQuery(GET_DOCUMENT_BY_ASSESSMENT_ID, {
    fetchPolicy: msgConstants.networkFetchPolicy,
  });
  const [assessmentDocument, setAssessmentDocument] = useState([]);
  const column = [
    { title: " ", key: "name" },
    { title: "Yes", key: "yes" },
    { title: "No ", key: "no" },
    { title: "Unanswered", key: "unanswered" },
    { title: "Total Practices", key: "total" },
    { title: "Percent Complete", key: "percent" },
  ];

  const QuestionsAnswerdcolumn = [
    { title: "Level Name / Response", key: "name" },
    { title: "Yes", key: "yvalue" },
    { title: "No", key: "nvalue" },
    { title: "Unanswered", key: "uvalue" },
    { title: notApplicable, key: "naValue" },
    { title: "Total", key: "total" },
  ];

  const PracticeListColumn = [
    { title: "Identifier", key: "question_Title" },
    { title: "Practice", key: "question_Text" },
    { title: "References", key: "referenceText" },
  ];
  const DocumentListColumn = [
    { title: "Category", key: "category" },
    { title: "Sub-Category", key: subCategory },
    { title: "File Name", key: "File Name" },
  ];
  useEffect(() => {
   const controller = new AbortController();
    if (props) {
      setParam(props);
    } else {
      setParam(location?.state[Object.keys(location?.state)[0]]);
    }
    getDataforRRAReport();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    getDocumentData();
  },[param?.ccAssessmentInfo?.id,props?.ccAssessmentInfo?.id])

  useEffect(() => {
     const controller = new AbortController();
    GetOrganizationDataOfPartner({
      variables: {
        where: {
          contact_id: param?.clientInfo.partnerId,
        },
      },
    });
    GetOrganizationDataOfClient({
      variables: {
        where: {
          id: param?.clientInfo.clientOrgId,
          subtype: "Client",
        },
      },
    });
    return () => controller.abort();
  }, [param]);

  const getDocumentData = async () => {
    await getAllDocumentsByAssId({
      variables: {
        assId:
          parseInt(props?.ccAssessmentInfo?.id) ||
          parseInt(param?.ccAssessmentInfo?.id),
        featureType: "Document",
      },
    })
      .then((data: any) => {
        createDocumentListingTable(data.data.questionDocuments);
        setAssessmentDocument(data.data.questionDocuments);
      })
      .catch((err:any)=>{
        sentry_error_catch(err,setLoading,setFormState)
      })
  };

  const createDocumentListingTable = (documentData: any) => {
    const arr: any = [];
    documentData.forEach((doc: any) => {
      const obj: any = {};
      const docArr: any = [];
      const splitArr = doc.practise ? doc.practise.split("->") : " ";
      obj["category"] = doc.practise ? splitArr[0] : "Global";
      obj[subCategory] =
        doc.question_Text === "Global"
          ? "--"
          : doc.practise
          ? `${splitArr[1]} ${splitArr[2] ? "-> Q." + splitArr[2] : ""}`
          : "";
      doc.document_id.forEach((docInfo: any) => {
        docArr.push(docInfo.name);
      });
      obj["File Name"] = docArr.join(", ");
      arr.push(obj);
    });
    console.log("arr", arr);
    setDocumentListData(arr);
  };
  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  const options = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
    title: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
    },
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const getDataforRRAReport = async () => {
    try {
      setLoading(true);
      getRRADetails();
      getRRAQuestions();
    } catch (err:any) {
        sentry_error_catch(err,setLoading,setFormState)
    }
  };

  const Doughnutoptions = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
  };

  const segmentColor = (ans: string) => {
    switch (ans) {
      case "U":
      case "Unanswered":
        return "#CCCCCC";
      case "Y":
      case "Yes":
        return "#28A745";
      case "A":
      case "Alternate":
        return "#B17300";
      case "NA":
      case notApplicable:
        return "#007BFF";
      case "N":
      case "No":
        return "#DC3545";
      default:
        return "#000000";
    }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const getRRADetails = async () => {
    try {
      await fetch(`${CSET_URL}api/reports/rradetail`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          createGoalTable(data);
          createAnswerDistribByLevel(data);
          createPerformanceSummary(data);
          createAnswerDistribByGoal(data);
          setOverallScore(Math.round(data.rraSummaryOverall[0].percent));
          const obj: any = {};
          data.rraSummary.forEach((element: any) => {
            if (
              element.answer_Full_Name === "Yes" &&
              element.level_Name === "Basic"
            ) {
              obj["Basic"] = Math.round(element.percent);
            }
            if (
              element.answer_Full_Name === "Yes" &&
              element.level_Name === "Intermediate"
            ) {
              obj["Intermediate"] = Math.round(element.percent);
            }
            if (
              element.answer_Full_Name === "Yes" &&
              element.level_Name === "Advanced"
            ) {
              obj["Advanced"] = Math.round(element.percent);
            }
          });
          setDataObj(obj);
          buildPercentOfPracticeGraph(
            obj,
            Math.round(data.rraSummaryOverall[0].percent)
          );
          BuildPracticePerformedByGoalGraph(data.rraSummaryByGoal);
        }).catch((err:any)=>{
          sentry_error_catch(err,setLoading,setFormState)
        })
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const getRRAQuestions = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/reports/rraquestions`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          createQuestionsLisitingtable(data);
        }).catch((err:any)=>{
          sentry_error_catch(err,setLoading,setFormState)
        })
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const createQuestionsLisitingtable = (data: any) => {
    const arr: any = [];
    data.forEach((element: any) => {
      const obj: any = {};
      obj["question_Title"] = element.question_Title;
      obj["question_Text"] = element.question_Text;
      obj["answer"] = element.answer.answer_Text;
      obj["referenceText"] = element.referenceText
        .replaceAll(/(<([^>]+)>)/gi, " ")
        .replace(/&#10;/gi, "");
      arr.push(obj);
    });
    setPracticeListnewData(arr);
  };

  const createPerformanceSummary = (data: any) => {
    if (data) {
      if (data.rraSummaryOverall) {
        // build the data object for Chart
        const level1Data: any = {};
        level1Data.label = "";
        level1Data.labels = [];
        level1Data.data = [];
        level1Data.colors = [];
        data.rraSummaryOverall.forEach((element: any) => {
          level1Data.data.push(element.percent);
          level1Data.labels.push(
            `${element.answer_Text}:${Math.round(element.percent)}%`
          );
          level1Data.colors.push(segmentColor(element.answer_Text));
        });
        setOverall({
          labels: level1Data.labels,
          datasets: [
            {
              data: level1Data.data,
              backgroundColor: level1Data.colors,
              borderColor: level1Data.colors,
              borderWidth: 1,
            },
          ],
        });
      }
      if (data.rraSummary) {
        // build the data object for Chart
        const basicData: any = {};
        basicData.label = "";
        basicData.labels = [];
        basicData.data = [];
        basicData.colors = [];
        const IntermediateData: any = {};
        IntermediateData.label = "";
        IntermediateData.labels = [];
        IntermediateData.data = [];
        IntermediateData.colors = [];
        const AdvancedData: any = {};
        AdvancedData.label = "";
        AdvancedData.labels = [];
        AdvancedData.data = [];
        AdvancedData.colors = [];
        data.rraSummary.forEach((element: any) => {
          if (element.level_Name === "Basic") {
            basicData.data.push(element.percent);
            basicData.labels.push(
              `${element.answer_Text}:${Math.round(element.percent)}%`
            );
            basicData.colors.push(segmentColor(element.answer_Text));
          }
          if (element.level_Name === "Intermediate") {
            IntermediateData.data.push(element.percent);
            IntermediateData.labels.push(
              `${element.answer_Text}:${Math.round(element.percent)}%`
            );
            IntermediateData.colors.push(segmentColor(element.answer_Text));
          }
          if (element.level_Name === "Advanced") {
            AdvancedData.data.push(element.percent);
            AdvancedData.labels.push(
              `${element.answer_Text}:${Math.round(element.percent)}%`
            );
            AdvancedData.colors.push(segmentColor(element.answer_Text));
          }
        });
        setBasic({
          labels: basicData.labels,
          datasets: [
            {
              data: basicData.data,
              backgroundColor: basicData.colors,
              borderColor: basicData.colors,
              borderWidth: 1,
            },
          ],
        });
        setAdvanced({
          labels: AdvancedData.labels,
          datasets: [
            {
              data: AdvancedData.data,
              backgroundColor: AdvancedData.colors,
              borderColor: AdvancedData.colors,
              borderWidth: 1,
            },
          ],
        });
        setIntermediate({
          labels: IntermediateData.labels,
          datasets: [
            {
              data: IntermediateData.data,
              backgroundColor: IntermediateData.colors,
              borderColor: IntermediateData.colors,
              borderWidth: 1,
            },
          ],
        });
      }
    }
  };

  const buildPercentOfPracticeGraph = (data: any, OverallData: any) => {
    // build the object to populate the chart
    const x: any = {};
    x.labels = ["OverAll", "Basic", "Intermediate", "Advanced"];
    x.datasets = [];
    const ds = {
      label: "",
      backgroundColor: "#245075",
      data: [OverallData, data.Basic, data.Intermediate, data.Advanced],
    };
    x.datasets.push(ds);
    setPracticePerformed(x);
  };

  const BuildPracticePerformedByGoalGraph = (data: any) => {
    const x: any = {};
    x.labels = [];
    x.datasets = [];
    const ds: any = {
      label: "",
      backgroundColor: "#245075",
      data: [],
    };
    x.datasets.push(ds);
    data.forEach((element: any) => {
      if (element.answer_Full_Name === "Yes") {
        x.labels.push(element.title);
        ds.data.push(Math.round(element.percent));
      }
    });
    setPracticePerformedByGoal(x);
  };

  const SuggestedAreaOfImprovement = (data: any) => {
    const suggestedImprovement: any = {};
    suggestedImprovement.labels = [];
    suggestedImprovement.datasets = [];
    const Improvementds: any = {
      label: "",
      backgroundColor: "#DC3545",
      data: [],
    };
    suggestedImprovement.datasets.push(Improvementds);
    data.forEach((element: any) => {
      suggestedImprovement.labels.push(element.name);

      Improvementds.data.push(element.value);
    });
    setSuggestedAreaOfImprovement(suggestedImprovement);
  };

  /**
   * Builds the answer distribution broken into goals.
   */
  const createAnswerDistribByGoal = (r: any) => {
    const goalList: any = [];
    r.rraSummaryByGoal.forEach((element: any) => {
      let goal: any = goalList.find((x: any) => x.name === element.title);
      if (!goal) {
        goal = {
          name: element.title,
          series: [
            { name: "Yes", value: 0 },
            { name: "No", value: 0 },
            { name: "Unanswered", value: 0 },
          ],
        };
        goalList.push(goal);
      }

      const p = goal.series.find(
        (x: any) => x.name === element.answer_Full_Name
      );
      p.value = element.percent;
    });
    createTopRankedGoals(goalList);
  };

  /**
   * Build a chart sorting the least-compliant goals to the top.
   * Must build answerDistribByGoal before calling this function.
   * @param data
   */
  const createTopRankedGoals = (data: any) => {
    const goalList: any = [];
    data.forEach((element: any) => {
      const yesPercent = element.series.find(
        (x: any) => x.name === "Yes"
      ).value;
      const goal = {
        name: element.name,
        value: 100 - Math.round(yesPercent),
      };
      goalList.push(goal);
    });

    goalList.sort((a: any, b: any) => {
      return b.value - a.value;
    });
    SuggestedAreaOfImprovement(goalList);
  };

  const createGoalTable = (data: any) => {
    const goalList: any = [];
    data.rraSummaryByGoal.forEach((element: any) => {
      let goal = goalList.find((x: any) => x.name === element.title);
      if (!goal) {
        goal = {
          name: element.title,
          yes: 0,
          no: 0,
          unanswered: 0,
        };
        goalList.push(goal);
      }

      switch (element.answer_Text) {
        case "Y":
          goal.yes = element.qc;
          break;
        case "N":
          goal.no = element.qc;
          break;
        case "U":
          goal.unanswered = element.qc;
          break;
      }
    });

    goalList.forEach((g: any) => {
      g.total = g.yes + g.no + g.unanswered;
      g.percent = Math.round((g.yes / g.total) * 100);
    });
    setNewData(goalList);
  };

  const levelList: any = [];
  const createAnswerDistribByLevel = (r: any) => {
    r.rraSummary.forEach((element: any) => {
      let level = levelList.find((x: any) => x.name === element.level_Name);
      if (!level) {
        level = {
          name: element.level_Name,
          yes: "Yes",
          yvalue: "",
          no: "No",
          nvalue: "",
          unanswered: "Unanswered",
          uvalue: "",
          total: "",
        };
        levelList.push(level);
      }

      if (level.yes === element.answer_Full_Name) {
        level.yvalue = element.qc;
        level.total = element.total;
      }
      if (level.no === element.answer_Full_Name) {
        level.nvalue = element.qc;
        level.total = element.total;
      }
      if (level.unanswered === element.answer_Full_Name) {
        level.uvalue = element.qc;
        level.total = element.total;
      }
      level.naValue =
        level.total - (level.yvalue + level.nvalue + level.uvalue);
    });
    setQuestionsAnswernewData(levelList);
  };

  const [GetOrganizationDataOfPartner] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.partnerId === element.contact_id.id) {
          setPartnerData(element);
        }
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err)
    },
  });
  const [GetOrganizationDataOfClient] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err);
    },
  });
  const getBannerImages = async () => {
   setLoading(true);
   await fetch(`${process.env.PUBLIC_URL}/images/ExecutiveSummary.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setBannerImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        setLoading(false);
        Sentry.captureException(error)
      });
   await fetch(`${process.env.PUBLIC_URL}/images/blank_img.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setDefaultCPAImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        setLoading(false);
        Sentry.captureException(error)
      });

   await fetch(`${process.env.PUBLIC_URL}images/OPT1.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setRRAModelImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        setLoading(false);
        Sentry.captureException(error)
      });
    if (from === "result") {
      setPartnerImage(partnerLogo);
      setCPAImage(clientLogo);
      setCPAImageDimensions(clientImageDimension);
      setPartnerImageDimensions(partnerImageDimension);
    } else {
      if (clientData.logo && clientData.logo[0]) {
        const clientLogoUrl = clientData.logo[0].url.slice(1);
       await fetch(`${serverUrl}${clientLogoUrl}`, {
          method: "GET",
          headers: headerObj,
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const base64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setCPAImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setCPAImage(base64data);
            };
          })
          .catch((error) => {
            console.log("__Error", error);
            setLoading(false);
            Sentry.captureException(error)
          });
      }
      if (partnerData.logo && partnerData.logo[0]) {
        const partnerLogoUrl = partnerData.logo[0].url.slice(1);
       await fetch(`${serverUrl}${partnerLogoUrl}`, {
          method: "GET",
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const ClientLogobase64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setPartnerImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };
              setPartnerImage(ClientLogobase64data);
            };
          })
          .catch((error) => {
            console.log("__Error", error);
            setLoading(false);
            Sentry.captureException(error)
          });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      from &&
      from === "result" &&
      bannerImage &&
      chartRef1 &&
      chartRef3 &&
      partnerData.contact_id &&
      practiceListnewData.length > 0 &&
      rraModelImage.length > 0 &&
      assessmentDocument
    ) {
      generate();
    }
  },[ bannerImage &&
      chartRef1 &&
      chartRef3 &&
      partnerData.contact_id &&
      practiceListnewData.length > 0 &&
      rraModelImage.length > 0 &&
      assessmentDocument]);

  useEffect(() => {
    const controller = new AbortController();
    getBannerImages();
    return () => controller.abort();
  }, [partnerData, clientData]);

  const handleBack = () => {
    history(routeConstant.RRA_REPORTS, { state: { param } });
  };

  const generateTableHeaderCell = (text: string) => {
    return new docx.TableCell({
      children: [new docx.Paragraph({ text, style: "TableHeading" })],
      margins: {
        top: docx.convertInchesToTwip(0.1),
        bottom: docx.convertInchesToTwip(0.1),
        left: docx.convertInchesToTwip(0.1),
        right: docx.convertInchesToTwip(0.1),
      },
      shading: {
        fill: "#d9d2e9",
        color: "auto",
      },
      verticalAlign: docx.VerticalAlign.CENTER,
    });
  };

  const generateTableIndividualCell = (text: string) => {
    let t = text;
    if (typeof text === "number") {
      t = String(text);
    }
    return new docx.TableCell({
      children: [
        new docx.Paragraph({
          text: t,
          heading: docx.HeadingLevel.HEADING_3,
        }),
      ],
      margins: {
        top: docx.convertInchesToTwip(0.1),
        bottom: docx.convertInchesToTwip(0.1),
        left: docx.convertInchesToTwip(0.1),
        right: docx.convertInchesToTwip(0.1),
      },
      shading: {
        fill: "fcfaff",
        color: "auto",
      },
    });
  };

  const generateTableCells = (data: any, columns: any[]) => {
    let tableRows: any[] = [];
    tableRows = columns.map((c) => {
      return generateTableIndividualCell(data[c.key]);
    });
    return new docx.TableRow({
      children: [...tableRows],
    });
  };

  const generateTableHeaderRow = (columns: any[]) => {
    let tableColumns: any[] = [];
    tableColumns = columns.map((c) => {
      return generateTableHeaderCell(c.title);
    });
    return new docx.TableRow({
      children: [...tableColumns],
    });
  };

  const generateFinalTable = (data: any[], columns: any[]) => {
    const rows: any[] = data.map((d) => {
      return generateTableCells(d, columns);
    });
    return new docx.Table({
      rows: [generateTableHeaderRow(columns), ...rows],
    });
  };

  const RRAPracticeList = generateFinalTable(
    practiceListnewData,
    PracticeListColumn
  );
  const PracticeQuestionAnswered = generateFinalTable(
    questionsAnswernewData,
    QuestionsAnswerdcolumn
  );
  const RRAPracticeScoring = generateFinalTable(newData, column);

  const fitImg = (targetHeight: any, originalSize: any) => {
    const originalAspectRatio = originalSize.width / originalSize.height;
    let width: number;
    let height: number;
    if (!originalSize) {
      width = targetHeight;
    } else {
      height = targetHeight;
      width = height * originalAspectRatio;
    }
    return width;
  };
  const generate = async () => {
    console.log("__Generate");
    try {
      setLoading(true);
      const doc = new docx.Document({
        numbering: {
          config: [
            {
              reference: "top_categories",
              levels: [
                {
                  level: 0,
                  format: docx.LevelFormat.DECIMAL,
                  text: "%1",
                  alignment: docx.AlignmentType.START,
                  style: {
                    paragraph: {
                      indent: { left: 720, hanging: 260 },
                    },
                  },
                },
              ],
            },
            {
              reference: "questions",
              levels: [
                {
                  level: 0,
                  format: docx.LevelFormat.DECIMAL,
                  text: "%1",
                  alignment: docx.AlignmentType.START,
                  style: {
                    paragraph: {
                      indent: { left: 720, hanging: 260 },
                    },
                  },
                },
              ],
            },
          ],
        },
        styles: {
          default: {
            heading1: {
              run: {
                font: "Helvetica",
                size: 36,
                bold: true,
                color: "#4b0079",
              },
              paragraph: {
                alignment: docx.AlignmentType.LEFT,
                spacing: { line: 340 },
              },
            },
            heading2: {
              run: {
                font: "Helvetica",
                size: 30,
                bold: true,
                color: "#0b4785",
              },
              paragraph: {
                spacing: { line: 340 },
              },
            },
            heading3: {
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                spacing: { line: 276 },
              },
            },
            heading4: {
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                alignment: docx.AlignmentType.JUSTIFIED,
              },
            },
          },
          paragraphStyles: [
            {
              id: "normalPara",
              name: "Normal Para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                spacing: {
                  line: 276,
                  before: 20 * 72 * 0.1,
                  after: 20 * 72 * 0.05,
                },
                rightTabStop: docx.TabStopPosition.MAX,
                leftTabStop: 453.543307087,
              },
            },
            {
              id: "mainHeading",
              name: "Normal Para2",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 80,
                color: "#4B0079",
                bold: true,
              },
            },
            {
              id: "date",
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                color: "#4B0079",
                size: 40,
                bold: true,
              },
            },
            {
              id: "bulletText",
              name: "Normal para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
            {
              id: "customFontCng",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
            {
              id: "miniHeader",
              name: "Normal para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 24,
                bold: true,
              },
            },
            {
              id: "TableHeading",
              name: "Normal Para2",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
                color: "#4B0079",
                bold: true,
              },
            },
            {
              id: "BoldPara",
              name: "Normal Para3",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
                bold: true,
              },
            },
            {
              id: preparedForBy,
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                color: "#4B0079",
                size: 20,
                bold: true,
              },
            },
            {
              id: "clientPartner",
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
          ],
        },
      });

      const imageBase64Data1 = chartRef1.current.chartInstance.toBase64Image();
      const imageBase64Data2 = chartRef2.current.chartInstance.toBase64Image();
      const imageBase64Data3 = chartRef3.current.chartInstance.toBase64Image();
      const imageBase64Data4 = chartRef4.current.chartInstance.toBase64Image();
      const imageBase64Data5 = chartRef5.current.chartInstance.toBase64Image();
      const imageBase64Data6 = chartRef6.current.chartInstance.toBase64Image();
      const imageBase64Data7 = chartRef7.current.chartInstance.toBase64Image();
      const firstchart = imageBase64Data1?.split(",")[1];
      const secondchart = imageBase64Data2?.split(",")[1];
      const thirdchart = imageBase64Data3?.split(",")[1];
      const fourthchart = imageBase64Data4?.split(",")[1];
      const fifthchart = imageBase64Data5?.split(",")[1];
      const sixthchart = imageBase64Data6?.split(",")[1];
      const seventhchart = imageBase64Data7?.split(",")[1];
      const exeSummaryLogo = bannerImage ? bannerImage.split(",")[1] : "";
      const defaultcpaLogo = defaultCPAImage
        ? defaultCPAImage.split(",")[1]
        : "";
      const cpaLogo = cpaImage ? cpaImage.split(",")[1] : defaultcpaLogo;
      const msplogo = partnerImage
        ? partnerImage.split(",")[1]
        : defaultcpaLogo;
      const modelImage = rraModelImage.split(",")[1];
      const image1 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(firstchart), (c) => c.charCodeAt(0)),
        600,
        350
      );

      const image2 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(secondchart ? secondchart : ""), (c) =>
          c.charCodeAt(0)
        ),
        600,
        400
      );
      const image3 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(thirdchart), (c) => c.charCodeAt(0)),
        600,
        400
      );

      const image4 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(rraModelImage ? modelImage : ""), (c) =>
          c.charCodeAt(0)
        ),
        550,
        300
      );

      const image5 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(exeSummaryLogo), (c) => c.charCodeAt(0)),
        600,
        350
      );

      const image6 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(cpaLogo), (c) => c.charCodeAt(0)),
        fitImg(50, cpaImageDimensions),
        50
      );

      const image7 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
        fitImg(50, partnerImageDimensions),
        50
      );

      const image8 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(fourthchart), (c) => c.charCodeAt(0)),
        300,
        150,
        {
          floating: {
            horizontalPosition: {
              offset: 1014400,
            },
            verticalPosition: {
              offset: 2014400,
            },
          },
        }
      );

      const image9 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(fifthchart), (c) => c.charCodeAt(0)),
        300,
        150,
        {
          floating: {
            horizontalPosition: {
              offset: 4014400,
            },
            verticalPosition: {
              offset: 2014400,
            },
          },
        }
      );

      const image10 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(sixthchart), (c) => c.charCodeAt(0)),
        300,
        150,
        {
          floating: {
            horizontalPosition: {
              offset: 1014400,
            },
            verticalPosition: {
              offset: 4014400,
            },
          },
        }
      );

      const image11 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(seventhchart), (c) => c.charCodeAt(0)),
        300,
        150,
        {
          floating: {
            horizontalPosition: {
              offset: 4014400,
            },
            verticalPosition: {
              offset: 4014400,
            },
          },
        }
      );

      const footerLogo = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
        fitImg(50, partnerImageDimensions),
        50,
        {
          floating: {
            horizontalPosition: {
              align: docx.HorizontalPositionAlign.RIGHT,
            },
            verticalPosition: {
              align: docx.VerticalPositionAlign.BOTTOM,
            },
          },
        }
      );
      const bannertable = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    heading: docx.HeadingLevel.HEADING_1,
                    style: preparedForBy,
                    children: [
                      new docx.TextRun({
                        text: "Prepared for",
                        bold: true,
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),

                  new docx.Paragraph(image6),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph({
                    text: param?.clientInfo.name,
                    style: "clientPartner",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.2),
                  right: docx.convertInchesToTwip(1.8),
                },
                columnSpan: 5,
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },

                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    heading: docx.HeadingLevel.HEADING_1,
                    style: preparedForBy,
                    children: [
                      new docx.TextRun({
                        text: "Prepared By",
                        bold: true,
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph(image7),
                  new docx.Paragraph({
                    text: "",
                  }),
                  new docx.Paragraph({
                    text: partnerData.contact_id?.name,
                    style: "clientPartner",
                  }),
                  new docx.Paragraph({
                    text: "",
                    style: "clientPartner",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.5),
                  right: docx.convertInchesToTwip(2),
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                columnSpan: 5,
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
            ],
          }),
        ],
      });

      doc.addSection({
        children: [
          new docx.Paragraph(image5),
          new docx.Paragraph({
            text: "RANSOMWARE READINESS ASSESSMENT (RRA) REPORT",
            style: "mainHeading",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: moment().format("MMMM DD, YYYY"),
            heading: docx.HeadingLevel.HEADING_1,
            style: "date",
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),

          bannertable,
        ],
      });

      const overAllTableScore = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({ text: "", style: "TableHeading" }),
                ],
                shading: {
                  color: "auto",
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 100,
                  type: docx.WidthType.AUTO,
                },
                children: [
                  new docx.Paragraph({
                    text: "Overall Score",
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
            ],
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({ text: "", style: "TableHeading" }),
                ],
                shading: {
                  color: "auto",
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 100,
                  type: docx.WidthType.AUTO,
                },
                children: [
                  new docx.Paragraph({
                    text: `${overallScore} %`,
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
            ],
          }),
        ],
      });

      const practicePerformedTableScore = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({ text: "", style: "TableHeading" }),
                ],
                shading: {
                  color: "auto",
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 100,
                  type: docx.WidthType.AUTO,
                },
                children: [
                  new docx.Paragraph({
                    text: "Basic",
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 100,
                  type: docx.WidthType.AUTO,
                },
                children: [
                  new docx.Paragraph({
                    text: "Intermediate",
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 100,
                  type: docx.WidthType.AUTO,
                },
                children: [
                  new docx.Paragraph({
                    text: "Advanced",
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
            ],
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({ text: "", style: "TableHeading" }),
                ],
                shading: {
                  color: "auto",
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                width: {
                  size: 100,
                  type: docx.WidthType.AUTO,
                },
                children: [
                  new docx.Paragraph({
                    text: `${dataObj?.Basic} %`,
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
              new docx.TableCell({
                width: {
                  size: 100,
                  type: docx.WidthType.AUTO,
                },
                children: [
                  new docx.Paragraph({
                    text: `${dataObj?.Intermediate} %`,
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
              new docx.TableCell({
                width: {
                  size: 100,
                  type: docx.WidthType.AUTO,
                },
                children: [
                  new docx.Paragraph({
                    text: `${dataObj?.Advanced} %`,
                    heading: docx.HeadingLevel.HEADING_3,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
            ],
          }),
        ],
      });

      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "This report was prepared as an account of work sponsored by an agency of the U.S. Government. Neither the U.S. Government nor any agency thereof, nor any employee, makes any warranty, expressed or implied, or assumes any legal liability or responsibility for any third-party's use, or the results of such use, or any information, apparatus, product, or process disclosed in this publication, or represents that its use by such third party would not infringe privately owned rights.",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            style: "normalPara",
            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: "Percentage of Practices Performed",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          overAllTableScore,
          new docx.Paragraph({
            text: " ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
          }),
          practicePerformedTableScore,
          new docx.Paragraph({
            text: " ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
          }),
          new docx.Paragraph({
            text: "Scores are calculated as the percentage of 'Yes' answers.",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          new docx.Paragraph(image1),
        ],
      });

      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "Percentage of Practices Performed by Goal",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          new docx.Paragraph(image2),
          new docx.Paragraph({
            text: " ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
          }),
          new docx.Paragraph({
            text: "RRA Practice Scoring",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          RRAPracticeScoring,
        ],
      });

      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "Suggested Areas for Improvement",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          new docx.Paragraph({
            text: `The goals in the assessment are ranked in order of deficiency with goals having fewer 
            satisfied practices ranked higher in the chart. The bar graph reflects the percentage of practices 
            for each goal that are answered ;'No' or are left unanswered.`,
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          new docx.Paragraph(image3),
          new docx.Paragraph({
            text: " ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
          }),
             ],
      });
      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: `The RRA model encourages ransomware readiness in three stages – Basic, Intermediate, and Advanced.`,
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 100,
            },
          }),
          new docx.Paragraph(image4),
          new docx.Paragraph({
            text: " ",
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          new docx.Paragraph({
            text: `The RRA practices are grouped by basic, intermediate, and advanced tiers. The practices are 
            intended to be implemented from one tier to the next and are meant to provide a clear path for 
            improvement.`,
            heading: docx.HeadingLevel.HEADING_4,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          new docx.Paragraph({
            text: PracticeQuestionsAnswered,
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          PracticeQuestionAnswered,
          new docx.Paragraph({
            text: "",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 300,
            },
          }),
        ],
      });
      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "These charts represent the answer distribution overall and across all tiers.",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 100,
            },
          }),
          new docx.Paragraph(image8),
          new docx.Paragraph(image9),
          new docx.Paragraph({
            text: " ",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph(image10),
          new docx.Paragraph(image11),
          new docx.Paragraph({
            text: "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 400,
            },
          }),
        ],
      });

      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "RRA Practice List with Corresponding References",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          new docx.Paragraph({
            text: "Red-shaded rows indicate practices that have been answered as 'No' or left unanswered.",
            heading: docx.HeadingLevel.HEADING_3,
            alignment: docx.AlignmentType.LEFT,
            contextualSpacing: true,
            spacing: {
              after: 200,
            },
          }),
          RRAPracticeList,
        ],
      });

      const documentTable = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    text: "Category",
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    text: "Sub-Category",
                    style: "TableHeading",
                    alignment: docx.AlignmentType.LEFT,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                //verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    text: "File Name",
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
            ],
          }),
          ...assessmentDocument.map((doc: any) => {
            return new docx.TableRow({
              children: [
                new docx.TableCell({
                  children: [
                    new docx.Paragraph({
                      text: `${
                        doc.practise ? doc.practise.split("->")[0] : "Global"
                      }`,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                    right: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "#ffffff",
                    color: "auto",
                  },
                  verticalAlign: docx.VerticalAlign.CENTER,
                }),
                new docx.TableCell({
                  children: [
                    new docx.Paragraph({
                      text: `${
                        doc.question_Text === "Global"
                          ? "--"
                          : doc.practise
                          ? `${doc.practise.split("->")[1]} ${
                              doc.practise.split("->")[2]
                                ? "->Q." + doc.practise.split("->")[2]
                                : ""
                            }`
                          : ""
                      }`,
                      alignment: docx.AlignmentType.LEFT,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                    right: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "#ffffff",
                    color: "auto",
                  },
                  //verticalAlign: docx.VerticalAlign.CENTER,
                }),
                new docx.TableCell({
                  children: [
                    ...doc.document_id.map((docn: any) => {
                      return new docx.Paragraph({
                        text: `${docn.name}`,
                        alignment: docx.AlignmentType.LEFT,
                      });
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                    right: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "#ffffff",
                    color: "auto",
                  },
                }),
              ],
            });
          }),
        ],
      });
      if (assessmentDocument.length > 0) {
        doc.addSection({
          footers: {
            default: new docx.Footer({
              children: [
                new docx.Paragraph({
                  alignment: docx.AlignmentType.LEFT,
                  children: [
                    new docx.TextRun({
                      children: ["Page | ", docx.PageNumber.CURRENT],
                    }),
                  ],
                }),
                new docx.Paragraph(footerLogo),
              ],
            }),
          },
          children: [
            new docx.Paragraph({
              text: "Document Library",
              heading: docx.HeadingLevel.HEADING_1,
              alignment: docx.AlignmentType.LEFT,
              spacing: {
                after: 200,
              },
            }),
            documentTable,
          ],
        });
      }
      docx.Packer.toBlob(doc)
        .then(async (blob: any) => {
          if (from && from === "result") {
            await Promise.resolve()
              .then(() => {
                setLoading(false);
                data = blob;
                data["name"] = "RRA_Report.docx";
                handleChange(data);
              })
              .catch((err) => {
                setLoading(false);
                console.log(err);
                Sentry.captureException(err)
              });
          } else {
            await Promise.resolve(saveAs(blob, "RRA_Report.docx"))
              .then(() => {
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                console.log(err);
                Sentry.captureException(err)
              });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (error) {
      setLoading(false);
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: true,
        errMessage: "Something went wrong, Please try Again !",
      }));
      console.log(error);
    }
  };

  const startLoader = async () => {
    setLoading(true);
    setTimeout(function () {
      generatePdf();
    }, 1000);
  };

  const generatePdf = async () => {
    if (bannerImage) {
      try {
        setLoading(true);
        const pdf: any = new jsPDF({compress:true});
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(12);
        const imageBase64Data1 =
          chartRef1?.current?.chartInstance.toBase64Image();
        const imageBase64Data2 =
          chartRef2?.current?.chartInstance.toBase64Image();
        const imageBase64Data3 =
          chartRef3?.current?.chartInstance.toBase64Image();
        const imageBase64Data4 =
          chartRef4?.current?.chartInstance.toBase64Image();
        const imageBase64Data5 =
          chartRef5?.current?.chartInstance.toBase64Image();
        const imageBase64Data6 =
          chartRef6?.current?.chartInstance.toBase64Image();
        const imageBase64Data7 =
          chartRef7?.current?.chartInstance.toBase64Image();
        //cover page
        pdf.addImage(bannerImage, "JPEG", 10, 10, 190, 125);
        pdf.setDrawColor(0);
        pdf.setTextColor(75, 0, 121);
        pdf.setFontSize(40);
        pdf.setFont("helvetica", "bold");
        pdf.text("RANSOMWARE ", 20, 150);
        pdf.text("READINESS", 20, 165);
        pdf.text("ASSESSMENT (RRA)", 20, 180);
        pdf.text("REPORT", 20, 195);
        pdf.setFontSize(20);
        const date = moment().toDate();
        const formatedDate = moment(date).format("MMMM DD, YYYY");
        pdf.text(formatedDate, 20, 210);
        pdf.setFontSize(10);
        pdf.text("Prepared For", 20, 225);
        pdf.text("Prepared By", 110, 225);
        pdf.setDrawColor(75, 0, 126);
        pdf.setLineWidth(0.75);
        pdf.line(100, 220, 100, 270);
        if (cpaImage !== undefined) {
          pdf.addImage(
            cpaImage,
            "JPEG",
            20,
            230,
            fitImg(18, cpaImageDimensions),
            18
          );
        }
        if (partnerImage !== undefined) {
          pdf.addImage(
            partnerImage,
            "JPEG",
            107,
            230,
            fitImg(18, partnerImageDimensions),
            18
          );
        }
        pdf.setFontSize(10);
        pdf.setTextColor(0, 0, 0);
        pdf.text(param.clientInfo.name, 20, 260);
        pdf.text(partnerData.contact_id.name, 110, 260);
        pdf.text("", 110, 265);

        // first page
        pdf.addPage("a4", "p");
        //header
        pdf.setTextColor(75, 0, 121); //heading blue color
        pdf.setFontSize(18);
        pdf.text("RANSOMWARE READINESS ASSESSMENT (RRA) REPORT", 10, 20);
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0); //paragraph black color
        pdf.setFontSize(10);
        const description = pdf.splitTextToSize(
          "This report was prepared as an account of work sponsored by an agency of the U.S. Government. Neither the U.S. Government nor any agency thereof, nor any employee, makes any warranty, expressed or implied, or assumes any legal liability or responsibility for any third-party's use, or the results of such use, or any information, apparatus, product, or process disclosed in this publication, or represents that its use by such third party would not infringe privately owned rights. ",
          190
        );
        pdf.text(description, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        pdf.text("Percentage of Practices Performed", 10, 60);
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(0, 0, 0); //paragraph black color
        pdf.setFontSize(10);
        autoTable(pdf, {
          head: [["Overall Score"]],
          body: [[overallScore]],
          theme: "grid",
          margin: { top: 75, left: 10, right: 160 },
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
        autoTable(pdf, {
          head: [["Basic", "Interediate", "Advanced"]],
          body: [[dataObj?.Basic, dataObj?.Intermediate, dataObj?.Advanced]],
          margin: { top: 80, left: 10, right: 50 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
        const imgData1 = imageBase64Data1;
        pdf.addImage(imgData1, "JPEG", 15, 130, 180, 0);
        pdf.addPage("a4", "p");
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        pdf.text("Percentage of Practices Performed by Goal", 10, 20);
        const imgData2 = imageBase64Data2;
        pdf.addImage(imgData2, "JPEG", 15, 30, 180, 0);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133); //subheading orange color
        pdf.text("RRA Practice Scoring", 10, 140);
        const practiceScoreHeaders = [
          [
            " ",
            "Yes",
            "No",
            "Unanswered",
            "Total Practices",
            "Percent Complete",
          ],
        ];
        const practiceScoringTable: any[] = [];
        newData.forEach((rq: any) => {
          const data = [];
          data.push(rq["name"]);
          data.push(rq["yes"]);
          data.push(rq["no"]);
          data.push(rq["unanswered"]);
          data.push(rq["total"]);
          data.push(rq["percent"]);
          practiceScoringTable.push(data);
        });
        autoTable(pdf, {
          head: practiceScoreHeaders,
          body: practiceScoringTable,
          margin: { top: 160, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            5: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
        //footer
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(10);
        pdf.setTextColor(0, 0, 0);
        // second page
        pdf.addPage("a4", "p");
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133); //subheading orange color
        pdf.text("Suggested Areas for Improvement ", 10, 30);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(10);
        pdf.setTextColor(0, 0, 0);
        const suggestedAreadescription = pdf.splitTextToSize(
          "The goals in the assessment are ranked in order of deficiency with goals having fewer satisfied practices ranked higher in the chart. The bar graph reflects the percentage of practices for each goal that are answered 'No' or are left unanswered.",
          190
        );
        pdf.text(suggestedAreadescription, 10, 40);
        const imgData3 = imageBase64Data3;
        pdf.addImage(imgData3, "JPEG", 15, 60, 190, 0);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133); //subheading orange color
        const ransomRedinessStages = pdf.splitTextToSize(
          "The RRA model encourages ransomware readiness in three stages – Basic, Intermediate, and Advanced.",
          190
        );
        pdf.text(ransomRedinessStages, 10, 170);
        const rraModel = rraModelImage.split(",")[1];
        pdf.addImage(rraModel, "PNG", 15, 180, 180, 0);
        //footer
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(10);
        pdf.setTextColor(0, 0, 0);

        pdf.addPage("a4", "p");

        const QuestionsAnsweredPerTier = pdf.splitTextToSize(
          "The RRA practices are grouped by basic, intermediate, and advanced tiers. The practices are intended to be implemented from one tier to the next and are meant to provide a clear path for improvement.",
          190
        );
        pdf.text(QuestionsAnsweredPerTier, 10, 20);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133); //subheading orange color
        pdf.text(PracticeQuestionsAnswered, 10, 40);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(10);
        pdf.setTextColor(0, 0, 0); //paragraph black color
        const QuestionsAnsweredheaders = [
          ["Level Name", "Yes", "No", "Un-Answered", notApplicable, "Total"],
        ];
        const QuestionsAnswered: any[] = [];
        questionsAnswernewData.forEach((rq: any) => {
          const data = [];
          data.push(rq["name"]);
          data.push(rq["yvalue"]);
          data.push(rq["nvalue"]);
          data.push(rq["uvalue"]);
          data.push(rq["naValue"]);
          data.push(rq["total"]);
          QuestionsAnswered.push(data);
        });
        autoTable(pdf, {
          head: QuestionsAnsweredheaders,
          body: QuestionsAnswered,
          margin: { top: 50, left: 10, bottom: 10 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            5: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 51, 133); //subheading orange color
        pdf.text(
          "These charts represent the answer distribution overall and across all tiers.",
          10,
          120
        );
        const imgData4 = imageBase64Data4;
        pdf.addImage(imgData4, "JPEG", 15, 135, 90, 0);
        const imgData5 = imageBase64Data5;
        pdf.addImage(imgData5, "JPEG", 100, 135, 90, 0);
        const imgData6 = imageBase64Data6;
        pdf.addImage(imgData6, "JPEG", 15, 200, 90, 0);
        const imgData7 = imageBase64Data7;
        pdf.addImage(imgData7, "JPEG", 100, 200, 90, 0);

        // third page
        pdf.addPage("a4", "p");
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133); //subheading orange color
        pdf.text("RRA Practice List with Corresponding References", 10, 20);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(10);
        pdf.setTextColor(0, 0, 0); //paragraph black color
        const headers = [["Identifier", "Practice", "References"]];
        const CorrespondingRefrence: any[] = [];
        practiceListnewData.forEach((rq: any) => {
          const data = [];
          data.push(rq["question_Title"]);
          data.push(rq["question_Text"]);
          data.push(rq["referenceText"]);
          CorrespondingRefrence.push(data);
        });
        autoTable(pdf, {
          head: headers,
          body: CorrespondingRefrence,
          margin: { top: 30, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });

        // fourth Page
        if (documentListData.length > 0) {
          pdf.addPage("a4", "p");
          pdf.setFont("helvetica", "bold");
          pdf.setFontSize(15);
          pdf.setTextColor(11, 71, 133);
          pdf.text("Document Library", 10, 30);
          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0);
          const documentdata: any[] = [];
          documentListData.forEach((doc: any) => {
            const arr = [];
            arr.push(doc.category);
            arr.push(doc[subCategory]);
            arr.push(doc["File Name"]);
            documentdata.push(arr);
          });
          autoTable(pdf, {
            head: [["Category", "Sub Category", "File Name"]],
            body: documentdata,
            startY: 40,
            margin: { top: 165, left: 10, bottom: 50 },
            headStyles: {
              fillColor: [217, 210, 233],
              textColor: [75, 0, 121],
              lineWidth: 0.1,
              lineColor: "#000",
            },
            theme: "grid",
            columnStyles: {
              0: {
                lineWidth: 0.1,
                lineColor: "#000",
                cellWidth: "auto",
              },
              1: {
                lineWidth: 0.1,
                lineColor: "#000",
                cellWidth: "auto",
              },
              2: {
                lineWidth: 0.1,
                lineColor: "#000",
                cellWidth: "auto",
              },
            },
          });
        }

        //header and footer
        const pageCount = pdf.internal.getNumberOfPages();
        for (let i = 0; i <= pageCount; i++) {
          if (i > 1) {
            pdf.setPage(i);
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(10);
            pdf.setTextColor(0, 0, 0); //paragraph black color
            pdf.text(
              `Page | ${pdf.internal.getCurrentPageInfo().pageNumber - 1}`,
              10,
              290
            );
            const width = pdf.internal.pageSize.getWidth();
            const height = pdf.internal.pageSize.getHeight();
            if (partnerImage !== undefined) {
              pdf.addImage(
                partnerImage,
                "JPEG",
                180,
                270,
                width / 10,
                height / 14
              );
            }
          }
        }

        await Promise.resolve(pdf.save("RRA_Report.pdf"))
          .then(() => {
            setLoading(false);
          })
          .catch((error: any) => {
            setLoading(false);
            Sentry.captureException(error)
          });
      } catch (error){
        setLoading(false);
        console.log("ERR");
        Sentry.captureException(error)
      }
    }
  };

  return (

      <div>
        <CssBaseline />
        <Grid container ref={componentRef}>
          <Grid item md={12}>
            <Typography component="h5" variant="h1">
              {msgConstants.EXECUTIVE_SUMMARY}
            </Typography>
            <Grid item xs={12} sm={6} className={styles.FooterActions}>
              <div className={styles.prevBtn}>
                <Button
                  id="previous-button1"
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                >
                  {"Previous"}
                </Button>
                <div className={styles.borderButton}>
                  <Button
                    id="doc-generate-button1"
                    variant="contained"
                    color="primary"
                    onClick={() => generate()}
                  >
                    <GetAppIcon className={styles.EditIcon} />
                    &nbsp; Doc
                  </Button>
                  <Button
                    id="pdf-generate-button1"
                    variant="contained"
                    color="primary"
                    onClick={() => startLoader()}
                  >
                    <GetAppIcon className={styles.EditIcon} />
                    &nbsp; PDF
                  </Button>
                </div>
              </div>
            </Grid>
            {formState.isFailed ? (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <strong>{formState.errMessage}</strong>
              </Alert>
            ) : null}
            <Paper className={styles.paper}>
              {loading ? <SimpleBackdrop /> : null}

              <Grid container>
                <Grid>
                  <p>
                    This report was prepared as an account of work sponsored by
                    an agency of the U.S. Government. Neither the U.S.
                    Government nor any agency thereof, nor any employee, makes
                    any warranty, expressed or implied, or assumes any legal
                    liability or responsibility for any third-party's use, or
                    the results of such use, or any information, apparatus,
                    product, or process disclosed in this publication, or
                    represents that its use by such third party would not
                    infringe privately owned rights.
                  </p>
                </Grid>
                <Grid item xs={12} md={12} sm={12} className={styles.gridSection}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    className={styles.gridSection}
                  >
                    <h3>Percentage of Practices Performed</h3>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      sm={4}
                      className={styles.FooterActions}
                    >
                      <div className={styles.cal_block}>
                        <div className={styles.cal_head}>
                          <div className={styles.OverallHeadWrap}>
                            <Typography>Overall Score</Typography>
                            <div className={styles.cal_level}>
                              <Typography>{overallScore} %</Typography>
                            </div>
                          </div>
                        </div>
                        <div className={styles.cal_head}>
                          <div className={styles.BasicHeadWrap}>
                            <Typography>Basic</Typography>
                            <div className={styles.cal_level}>
                              <Typography>{dataObj?.Basic} %</Typography>
                            </div>
                          </div>
                          <div className={styles.InterHeadWrap}>
                            <Typography>Intermediate</Typography>
                            <div className={styles.cal_level}>
                              <Typography>{dataObj?.Intermediate} %</Typography>
                            </div>
                          </div>
                          <div className={styles.AdvHeadWrap}>
                            <Typography>Advanced</Typography>
                            <div className={styles.cal_level}>
                              <Typography>{dataObj?.Advanced} %</Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Paper className={styles.paper}>
                    <HorizontalBar
                      data={practicePerformed}
                      ref={chartRef1}
                      options={options}
                    />
                  </Paper>
                  <h4 style={{ color: "#707070" }}>
                    Scores are calculated as the percentage of "Yes" answers.
                  </h4>

                  <h3>Percentage of Practices Performed by Goal</h3>
                  <Paper className={styles.paper}>
                    <HorizontalBar
                      data={practicePerformedByGoal}
                      ref={chartRef2}
                      options={options}
                    />
                  </Paper>
                  <h3>RRA Practice Scoring</h3>
                  <Paper className={styles.paper}>
                    <div className={styles.ScrollTable}>
                      <MaterialTable
                        columns={column}
                        data={newData}
                        pageSize={25}
                      />
                    </div>
                  </Paper>
                  <h3>Suggested Areas for Improvement </h3>
                  <Paper className={styles.paper}>
                    <p>
                      The goals in the assessment are ranked in order of
                      deficiency with goals having fewer satisfied practices
                      ranked higher in the chart. The bar graph reflects the
                      percentage of practices for each goal that are answered
                      "No" or are left unanswered.
                    </p>
                    <HorizontalBar
                      data={suggestedAreaOfImprovement}
                      ref={chartRef3}
                      options={options}
                    />
                  </Paper>
                  <h3>
                    The RRA model encourages ransomware readiness in three
                    stages – Basic, Intermediate, and Advanced.
                  </h3>
                  <Grid item xs={12} className={styles.RRAModelImg}>
                    <div className={styles.RRAModel}>
                      <img
                        src={`${process.env.PUBLIC_URL}images/OPT1.png`}
                        alt="RRA Model"
                      />
                    </div>
                  </Grid>
                  <h4 style={{ color: "#707070" }}>
                    The RRA practices are grouped by basic, intermediate, and
                    advanced tiers. The practices are intended to be implemented
                    from one tier to the next and are meant to provide a clear
                    path for improvement.{" "}
                  </h4>
                  <Paper className={styles.paper}>
                    {PracticeQuestionsAnswered}
                    <div className={styles.ScrollTable}>
                      <MaterialTable
                        columns={QuestionsAnswerdcolumn}
                        data={questionsAnswernewData}
                        pageSize={25}
                      />
                    </div>
                  </Paper>
                  <Paper className={styles.paper}>
                    <div className="App">
                      <h3>
                        {" "}
                        These charts represent the answer distribution overall
                        and across all tiers.{" "}
                      </h3>
                      <Grid
                        container
                        spacing={4}
                        className={styles.justifyCenter}
                      >
                        <Grid item xs={12} md={4}>
                          <h3 className={styles.doughnutHeader}>Overall</h3>
                          <Paper className={styles.paper}>
                            <Doughnut
                              data={overall}
                              ref={chartRef4}
                              options={Doughnutoptions}
                            />
                          </Paper>
                        </Grid>
                      </Grid>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                          <h3 className={styles.doughnutHeader}>Basic</h3>
                          <Paper className={styles.paper}>
                            <Doughnut
                              data={basic}
                              ref={chartRef5}
                              options={Doughnutoptions}
                            />
                          </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <h3 className={styles.doughnutHeader}>
                            Intermediate
                          </h3>
                          <Paper className={styles.paper}>
                            <Doughnut
                              data={intermediate}
                              ref={chartRef6}
                              options={Doughnutoptions}
                            />
                          </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <h3 className={styles.doughnutHeader}>Advanced</h3>
                          <Paper className={styles.paper}>
                            <Doughnut
                              data={advanced}
                              ref={chartRef7}
                              options={Doughnutoptions}
                            />
                          </Paper>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                  <h3>RRA Practice List with Corresponding References </h3>
                  <p>
                    Red-shaded rows indicate practices that have been answered
                    as "No" or left unanswered.{" "}
                  </p>
                  <Paper className={styles.paper}>
                    <div className={styles.ScrollTable}>
                      <MaterialTable
                        columns={PracticeListColumn}
                        data={practiceListnewData}
                        pageSize={10}
                      />
                    </div>
                  </Paper>
                  {documentListData.length > 0 ? (
                    <Paper className={styles.paper}>
                      <MaterialTable
                        columns={DocumentListColumn}
                        data={documentListData}
                        pageSize={10}
                      />
                    </Paper>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} className={styles.FooterActions}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button2"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                {"Previous"}
              </Button>
              <div className={styles.borderButton}>
                <Button
                  id="doc-generate-button2"
                  variant="contained"
                  color="primary"
                  onClick={() => generate()}
                >
                  <GetAppIcon className={styles.EditIcon} />
                  &nbsp; Doc
                </Button>
                <Button
                  id="pdf-generate-button2"
                  variant="contained"
                  color="primary"
                  onClick={() => startLoader()}
                >
                  <GetAppIcon className={styles.EditIcon} />
                  &nbsp; PDF
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
  );
};

export default RRAExecutiveSummary;
