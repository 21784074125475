import React,{useState} from "react";
import styles from "./Header.module.css";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Logout from "../../../../containers/Auth/Logout/Logout";
import * as routeConstant from "../../../../common/RouteConstants";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import GetAppIcon from "@mui/icons-material/GetApp";
import { DialogBox } from "../../DialogBox/DialogBox";
import Typography from "@mui/material/Typography";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import { useErrorBoundary } from 'react-error-boundary';

export const Header: React.FC = () => {
  const history = useNavigate();
  const { resetBoundary } = useErrorBoundary();
  const RAcontact = sessionStorage.getItem("ra_contact") || Logout();
  const userdata =  sessionStorage.getItem("ra_user") || Logout();
  const ra_user = userdata ? JSON.parse(userdata) : "";
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false;
  const [openSampleReportDialogBox,setOpenSampleReportDialogBox] = useState(false);
  const [showDownloadBackdrop, setShowDownloadBackdrop] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState("Quick Assessments");
  const [openAboutDialogBox,setOpenAboutDialogBox] = useState(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    isGuestClientFailed:false,
    errMessage: "",
  });
  const user = JSON.parse(RAcontact) ? JSON.parse(RAcontact) || "" : Logout();
  const getHelpManual = () => {
    resetBoundary();
    let ra_manual = process.env.REACT_APP_HELP_URL;
    window.open(ra_manual, "_blank");
  };
  const getHome = () => {
    resetBoundary();
    if(!is_link_assessment){
      if(ra_user?.user?.role?.name === "Administrator"){
        history(routeConstant.ADMIN_DASHBOARD);
      }else{
        history(routeConstant.CLIENT);
        localStorage.clear();
      }
    }
  };
  const openSampleReportDialogBoxHandler = () => {
    // history(routeConstant.SAMPLEREPORTS)
    setOpenSampleReportDialogBox(true)
  }

  const openAboutDialogBoxHandler = () => {
    // setOpenAboutDialogBox(true);
    history(routeConstant.ABOUTSECTION);
  }

  const sampleReportDownloadHandler = (assessmentType:string,assessmentName:string) => {
    setShowDownloadBackdrop(true);
    let DocUrl = "" ;
    const invalidChars = /[\\/.:*?"<>|]/g;
    const newAssessmentName = assessmentName.replace(invalidChars, '');
    let fileName = 'Sample ' + newAssessmentName;
    if(assessmentType === "Quick Assessments"){
       DocUrl = `${process.env.PUBLIC_URL}/sample_reports/Quick_Assessment/${assessmentName}/Report.zip`;
    }else{
       DocUrl = `${process.env.PUBLIC_URL}/sample_reports/Advanced_Assessment/${newAssessmentName.trim()}/Report.zip`;
    }

    fetch(DocUrl, {
      method: "GET",
      cache: 'no-store'
    })
      .then((response: any) => {
        response.blob().then((blobData: any) => {
          saveAs(blobData,fileName);
          setShowDownloadBackdrop(false)
        });
      })
      .catch((err:any) => {
        sentry_error_catch(err,setShowDownloadBackdrop,setFormState)
      });
  }
  const handleTabChange = (event: any,newValue: string) => {
   setSelectedTab(newValue);
  }
  return (
    <>
      <DialogBox
        open={openSampleReportDialogBox}
        handleOk={() => {
          setOpenSampleReportDialogBox(false);
        }}
        skipCancel={true}
        buttonOk={"Cancel"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainSampleReportDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        {showDownloadBackdrop ? <SimpleBackdrop /> : null}
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            {"Sample Reports"}
          </Typography>
        </div>
        <div style={{ margin: "8px 0px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                classes={{ flexContainer: styles.muiTabsFlexContainer }}
              >
                <Tab
                  value="Quick Assessments"
                  label="Quick Assessments"
                  classes={{ selected: styles.selectedTab }}
                />
                <Tab
                  value="Advanced Assessments"
                  label="Advanced Assessments"
                  classes={{ selected: styles.selectedTab }}
                />
              </Tabs>
            </Grid>
          </Grid>
          {selectedTab === "Quick Assessments" ? (
            <Grid container spacing={4} className={styles.reportListText}>
              <Grid item xs={10} className={styles.assTypeName}>
                {"CISA Ransomware Readiness Assessment (RRA)"}
              </Grid>
              <Grid item xs={2}>
                <GetAppIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sampleReportDownloadHandler(selectedTab, "CISA Ransomware Readiness Assessment (RRA)")
                  }}
                />
              </Grid>
              <Grid item xs={10} className={styles.assTypeName}>
                {"CISA Cyber Resilience Review (CRR)"}
              </Grid>
              <Grid item xs={2}>
                <GetAppIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sampleReportDownloadHandler(selectedTab, "CISA Cyber Resilience Review (CRR)")
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={4} className={styles.reportListText}>
              <Grid item xs={10} className={styles.assTypeName}>
                {"Draft CMMC 2.0 w/SPRS Scorecard"}
              </Grid>
              <Grid item xs={2}>
                <GetAppIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sampleReportDownloadHandler(selectedTab, "Draft CMMC 20 wSPRS Scorecard")
                  }}
                />
              </Grid>
              <Grid item xs={10} className={styles.assTypeName}>
                {"NIST SP 800-171 Revision 2: Protecting Controlled Unclassified Information in Nonfederal Systems and Organizations"}
              </Grid>
              <Grid item xs={2}>
                <GetAppIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sampleReportDownloadHandler(selectedTab, "NIST SP 800-171 Revision 2: Protecting Controlled Unclassified Information in Nonfederal Systems and Organizations")
                  }}
                />
              </Grid>
            </Grid>)}
          {/* </Grid> */}
        </div>
      </DialogBox>
    <div className={styles.Header} data-testid="Header">
      <Card className={styles.root}>
        <Grid container>
          <Grid item xs={12} className={styles.RALogoImg}>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/RA360.png`}
                alt="user icon"
                onClick={getHome}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={styles.userInfo}>
           {!is_link_assessment && (
            <>
            <Grid item xs={4}></Grid>
            <Grid item xs={8} style={{textAlign:"left"}}>
              <span onClick={getHome} style={{cursor:"pointer"}} className={styles.userNameLabel}>Home</span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              <span onClick={openAboutDialogBoxHandler} style={{cursor:"pointer"}} className={styles.userNameLabel}>About</span>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              <span onClick={openSampleReportDialogBoxHandler} style={{cursor:"pointer"}} className={styles.userNameLabel}>Sample Reports</span>
            </Grid>
            </>
            )}
            <Grid item xs={8} style={{textAlign:"right"}}>
              {!is_link_assessment && (<span className={styles.userNameLabel}>
                {user.name}&nbsp;{user.last_name} &nbsp;&nbsp;|&nbsp;&nbsp;
              </span>)}
              {!is_link_assessment && (<span className={styles.userNameLabel}>
                <a className={styles.logout} onClick={getHelpManual}>
                  Help
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </span>)}
              <span className={styles.userNameLabel}>
                <a className={styles.logout} onClick={Logout}>
                  Logout
                </a>
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
    </>
  );
};

export default Header;
