import React, { useEffect, useState, useRef } from "react";
import { Grid, Paper, Typography, IconButton } from "@mui/material";
import styles from "./ExternalDependenciesManagement.module.css";
import { CSET_URL } from "../../../../../config";
import logout from "../../../../Auth/Logout/Logout";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import DOMPurify from "dompurify";
import * as msgConstants from "../../../../../common/MessageConstants";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../../../../components/UI/Alert/Alert";
export interface ExternalDependenciesManagementProps {
  data?: any;
  props?: any;
  externalManagementRef?: any;
  getExternalManagementData?: (data: any) => void;
  handleError?: (err :any) => void;
  handleChildCompError?: (err: any) => void;
}
export const ExternalDependenciesManagement: React.FC<
  ExternalDependenciesManagementProps
> = ({
  props,
  getExternalManagementData = () => {},
  handleError = () =>{},
  handleChildCompError = () => {}
}: ExternalDependenciesManagementProps) => {
  const ExternalDependenciesManagementSVGRef = useRef<any>(null);
  const [showBackDrop, setShowBackDrop] = useState(true);
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [ExternalDependenciesManagement, setExternalDependenciesManagement] =
    useState<any>({});
  const [milData, setMilData] = useState<any>(null);
  const [milData2, setMilData2] = useState<any>(null);
  const [milData3, setMilData3] = useState<any>(null);
  const [milData4, setMilData4] = useState<any>(null);
  const [milData5, setMilData5] = useState<any>(null);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  const headerObj = {
    "Content-Type": msgConstants.CONTENT_TYPE,
    Authorization: authToken,
  };

  useEffect(() => {
    const controller = new AbortController();
    getExternalDependenciesManagement();
    getMilHeatMapData();
    if (props === false) {
      setShowBackDrop(false);
    }
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (ExternalDependenciesManagement) {
      passDatatoReportFunc();
    }
  }, [ExternalDependenciesManagement]);

  const passDatatoReportFunc = async () => {
    await getExternalManagementData({
      ExternalDependenciesManagement,
    });
  };

  useEffect(() => {
    if (
      Object.keys(ExternalDependenciesManagement).length > 0 &&
      milData &&
      milData2 &&
      milData3 &&
      milData4 &&
      milData5
    ) {
      setShowBackDrop(false);
    }
  }, [milData && milData2 && milData3 && milData4 && milData5]);

  const getExternalDependenciesManagement = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/MaturityStructure?domainAbbrev=EDM`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.json();
    data.Domain.Goal.forEach((g: any) => {
      // The Question object needs to be an array for the template to work.
      // A singular question will be an object.  Create an array and push the question into it
      if (!Array.isArray(g.Question)) {
        const onlyChild = Object.assign({}, g.Question);
        g.Question = [];
        g.Question.push(onlyChild);
      }
    });
    setExternalDependenciesManagement(data.Domain);
  }catch(err:any){
    setShowBackDrop(false)
    handleError(err)
    handleChildCompError(err)
   }
  };

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const getMilHeatMapData = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=EDM&mil=MIL-1&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.text();

    const response2 = await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=EDM&mil=MIL-2&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data2 = await response2.text();
    const response3 = await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=EDM&mil=MIL-3&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data3 = await response3.text();
    const response4 = await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=EDM&mil=MIL-4&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data4 = await response4.text();
    const response5 = await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=EDM&mil=MIL-5&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data5 = await response5.text();

    // .then((data:any)=>{
    //   response.json();
    setMilData(data);
    setMilData2(data2);
    setMilData3(data3);
    setMilData4(data4);
    setMilData5(data5);
    // })
    // const data = await Response;
    }catch(err:any){
      setShowBackDrop(false)
      handleError(err)
      handleChildCompError(err)
    }
  };

  /**
   * Actually, "non-child questions"
   * @param q
   */
  const parentQuestions = (q: any): any[] => {
    // q might be a single question or might be an array of questions
    let questions: any = [];

    if (q instanceof Array) {
      questions = q;
    } else {
      questions = [].concat(q);
    }

    return questions.filter((x: any) => !x.parentquestionid);
  };

  /**
   * Until question numbers are broken out into their own data element,
   * we'll parse them from the question text.
   * @param q
   */

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const getQuestionNumber = (q: any): string => {
    const dot = q.questiontext.trim().indexOf(".");
    if (dot < 0) {
      return "Q";
    }
    return "Q" + q.questiontext.trim().substring(0, dot);
  };
  return (
    <React.Fragment>
      {showBackDrop ? <SimpleBackdrop /> : null}
      <Typography
        component="h5"
        variant="h1"
        style={{ padding: "0px", fontWeight: 600 }}
      >
        External Dependencies Management (EDM)
      </Typography>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formState.errMessage}
        </Alert>
      ) : null}
      <Paper className={styles.paper}>
        <Grid container>
          <div ref={ExternalDependenciesManagementSVGRef}>
            <Grid item xs={12} className={styles.QuestionViewMIL}>
              <div dangerouslySetInnerHTML={createDangerousHTML(milData)} />
              <div dangerouslySetInnerHTML={createDangerousHTML(milData2)} />
              <div dangerouslySetInnerHTML={createDangerousHTML(milData3)} />
              <div dangerouslySetInnerHTML={createDangerousHTML(milData4)} />
              <div dangerouslySetInnerHTML={createDangerousHTML(milData5)} />
            </Grid>
          </div>
          <Grid item xs={12} md={12} sm={12} className={styles.FooterActions}>
            <h4
              style={{ padding: "12px 8px", margin: "0px", textAlign: "left" }}
            >
              The purpose of External Dependencies Management is to establish
              processes to manage an appropriate level of controls to ensure the
              sustainment and protection of services and assets that are
              dependent on the actions of external entities.{" "}
            </h4>

            <div className={styles.securityPlanTable}>
              {ExternalDependenciesManagement.Goal
                ? ExternalDependenciesManagement?.Goal.map(
                    (data: any) => {
                      return (
                        <table
                          id="secondTable"
                          key={data?.groupingid}
                          className={styles.tableStyle}
                        >
                          <tr>
                            <td colSpan={3}> {data.title} </td>
                          </tr>

                          {data.Question.map(
                            (Quesdata: any) => {
                              return (
                                  <tr key={Quesdata?.questionid}>
                                    <td colSpan={2}>
                                      {" "}
                                      {Quesdata.questiontext}{" "}
                                    </td>
                                    <td colSpan={1}>
                                      {Quesdata.isparentquestion === "true"
                                        ? " "
                                        : Quesdata.answer === "Y"
                                        ? "Yes"
                                        : Quesdata.answer === "N"
                                        ? "No"
                                        : Quesdata.answer === "NA"
                                        ? "NA"
                                        : "Unanswered"}{" "}
                                    </td>
                                  </tr>
                              );
                            }
                          )}
                          <tr>
                            <td colSpan={3}> Option(s) for Consideration </td>
                          </tr>
                          {parentQuestions(data.Question).map(
                            (refData: any) => {
                              return (
                                <tr key={refData?.questionid}>
                                    <td colSpan={1}>
                                      {getQuestionNumber(refData)}
                                    </td>
                                    <td
                                      colSpan={1}
                                      dangerouslySetInnerHTML={createDangerousHTML(
                                        refData.referencetext
                                      )}
                                    ></td>
                                    <td colSpan={1}> </td>
                                </tr>
                              );
                            }
                          )}
                        </table>
                      );
                    }
                  )
                : null}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default ExternalDependenciesManagement;
