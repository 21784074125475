import React, { useState, useEffect } from "react";
import styles from "./FirmType.module.css";
import { Button } from "../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../common/RouteConstants";
import * as CSET from "../../../common/CSET";
import { useNavigate, useLocation } from "react-router-dom";
import TopStepper from "../../../components/UI/Layout/Navigation/TopStepper/TopStepper";
import standardStepper from "../../../common/csetStandardStepperMenu.json";
import { setStepper } from "../../../common/SetStepper";
import { setActiveFormStep } from "../../../services/Data";
import { GET_CSET_FIRM } from "../../../graphql/queries/CSETFirm";
import {
  useQuery,
  useLazyQuery,
  useMutation,
  useApolloClient,
} from "@apollo/client";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import { UPDATE_CSET_ASSESSMENT } from "../../../graphql/mutations/CSETAssessment";
import { GET_CSET_ASSESSMENT } from "../../../graphql/queries/CSETAssessment";
import Alert from "../../../components/UI/Alert/Alert";
import CloseIcon from "@mui/icons-material/Close";
import * as msgConstants from "../../../common/MessageConstants";
import logout from "../../../containers/Auth/Logout/Logout";
import {
  CssBaseline,
  Grid,
  Paper,
  FormControl,
  IconButton,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import {
  CSET_URL,
  REACT_APP_PG_URL,
  PG_DEDUCT_SCAN_COUNT_API_KEY,
} from "../../../config/index";
import { HISTORY } from "../../../graphql/mutations/AuditTrail";

export const FirmType: React.FC = (props: any) => {
  const client = useApolloClient();
  const history = useNavigate();
  const location = useLocation();
  const contentType = msgConstants.CONTENT_TYPE;
  const [param, setParam] = useState<any>(
    location.state[Object.keys(location.state)[0]]
  );
  const [stepperObj, setStepperObj] = useState<any>({ path: "" });
  const [firmData, setFirmData] = useState<any>({});
  const authToken = sessionStorage.getItem("ra_token") ?? logout();
  const is_link_assessment =  Boolean(sessionStorage.getItem("is_link_authenticated")) || false;
  const [firmValue, setFirmValue] = useState<any>({});
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [questionsCount, setQuestionsCount] = useState<any>("0");
  const [requirementCount, setRequirementCount] = useState<any>("0");
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [modeType, setModeType] = useState("");
  const [customCset, setCustomCset] = useState(false);
  const [updateAssessement] = useMutation(UPDATE_CSET_ASSESSMENT);

  const headerObj = {
    "Content-Type": contentType,
    Authorization: authToken,
  };

  const standardStepperObject = standardStepper;

  useEffect(() => {
    //for deducting 1 credit from standard flow
    setParam(location.state[Object.keys(location.state)[0]]);
    setStepper(
      client,
      standardStepperObject.firmType.name,
      standardStepperObject.firmType.value
    );
    setActiveFormStep(1);
  }, []);

  useEffect(() => {
    if (param !== null || param !== undefined) {
      getAssessment({
        variables: {
          where: {
            assessment_id: param?.assessmentInfo.id,
          },
        },
      });
      if (param.activeMode) {
        setModeType(param.activeMode);
      }
    }
  }, [param]);

  useEffect(() => {
    //for deducting 1 credit from standard flow
    if (param?.assessmentCreate && !param?.assessmentUpdate) {
      handleDeduction();
    }
  }, []);

  const [auditTrail] = useMutation(HISTORY);

  const handleDeduction = async () => {
    const deductionObj = {
      partner_id: param.pgPartnerId,
      scan_type:
        param.flowType.type === "Custom" ||
        param.flowType.type === "Standard" ||
        param.flowType.type === "CMMC2"
          ? "Advanced Assessment"
          : "Quick Assessment",
      uid: param.pgPartnerUserId,
      main_type:
        param.flowType.type === "Custom" || param.flowType.type === "Standard"
          ? "RAA"
          : param.flowType.type === "CMMC2"
          ? "CMMC"
          : param.flowType.type,
      timestamp: new Date().getTime() / 1000,
      target_id: param.ccAssessmentInfo.id,
    };
    await fetch(
      `${REACT_APP_PG_URL}/ob-ra-scans/api/v1/deduct-scan-count/post`,
      {
        method: "POST",
        headers: {
          "api-key": PG_DEDUCT_SCAN_COUNT_API_KEY,
          "Content-type": msgConstants.CONTENT_TYPE,
        },
        body: JSON.stringify(deductionObj),
      }
    )
      .then((response) => response.json())
      .then((response) => {})
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const { loading: loadingF } = useQuery(GET_CSET_FIRM, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (data: any) => {
      createObject(data.csetFirms);
    },
  });

  const getQuestionList = async () => {
    try {
      setShowBackDrop(true);
      await fetch(`${CSET_URL}api/questionlist`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setModeType(data.applicationMode);
          setQuestionsCount(getTotalQuestions(data));
        })
        .catch((error) => {
          setShowBackDrop(false);
          setModeType("Q");
        });
    } catch (e) {
      setShowBackDrop(false);
      console.error("error", e);
    }
  };

  const [getAssessment] = useLazyQuery(
    GET_CSET_ASSESSMENT,
    {
      fetchPolicy: msgConstants.networkFetchPolicy,
      onCompleted: (data: any) => {
        if (data.csetAssessments[0].assessment_type === "Custom") {
          setCustomCset(true);
        }
        if (data.csetAssessments[0].assessment_type === "Standard") {
          setCustomCset(false);
        }
        const code: any = [];
        if (
          data.csetAssessments[0].cset_firm_id !== null ||
          data.csetAssessments[0].cset_firm_id !== undefined
        ) {
          data.csetAssessments.forEach((element: any, index: any) => {
            if (
              element.id === param.ccAssessmentInfo.id &&
              data.csetAssessments[0].cset_firm_id !== null
            ) {
              data.csetAssessments[0].cset_firm_id?.cset_standards.forEach(
                function (obj: any) {
                  code.push(obj.code);
                }
              );
              setFirmValue(element.cset_firm_id);
            }
          });
          postStandardSelection(code);
        }
      },
    }
  );

  const createObject = (data: any) => {
    const accountingFirm = "Accounting Firm";
    const financialFirm = "Financial Firm";
    const healthCareFirm = "Health Care Firm";
    const firmObj = [
      {
        key: accountingFirm,
        value: [
          data.filter(
            (item: any) =>
              item.firm_size === "Small" && item.name === accountingFirm
          )[0],
          data.filter(
            (item: any) =>
              item.firm_size === "Medium" && item.name === accountingFirm
          )[0],
        ],
      },
      {
        key: financialFirm,
        value: [
          data.filter(
            (item: any) =>
              item.firm_size === "Small" && item.name === financialFirm
          )[0],
          data.filter(
            (item: any) =>
              item.firm_size === "Medium" && item.name === financialFirm
          )[0],
        ],
      },
      {
        key: healthCareFirm,
        value: [
          data.filter(
            (item: any) =>
              item.firm_size === "Small" && item.name === healthCareFirm
          )[0],
          data.filter(
            (item: any) =>
              item.firm_size === "Medium" && item.name === healthCareFirm
          )[0],
        ],
      },
    ];
    setFirmData(firmObj);
  };

  const onSelectFirmType = async (val: any) => {
    setShowBackDrop(true);
    handleAlertClose();
    setFirmValue(val);
    const code: any = [];
    val.cset_standards.forEach(function (obj: any) {
      code.push(obj.code);
    });
    const temp = {
      assessmentId: param.assessmentInfo.id,
      industryId: val.industry.industry_id,
      sectorId: val.sector.sector_id,
      size: parseInt(val.relative_effort.demographics_id),
      AssetValue: param.AssetValue,
      agency: "Agency",
      isScoped: true,
    };
    const obj = {
      "Content-Type": contentType,
      Authorization: authToken,
    };
    await fetch(`${CSET_URL}api/Demographics/`, {
      method: "POST",
      headers: obj,
      body: JSON.stringify(temp),
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error: any) => {});
    const salObj = {
      ALevel: val.sal,
      AssessmentName: param.assessmentInfo.assessmentName,
      CLevel: val.sal,
      ILevel: val.sal,
      Last_Sal_Determination_Type: "Simple",
      SelectedSALOverride: false,
      Selected_Sal_Level: val.sal,
      Sort_Set_Name: null,
    };
    await fetch(`${CSET_URL}api/SAL`, {
      method: "POST",
      headers: headerObj,
      body: JSON.stringify(salObj),
    }).then((res: any) => {
      postStandardSelection(code);
    });
  };

  const postStandardSelection = async (code: any) => {
    await fetch(`${CSET_URL}api/standard`, {
      method: "POST",
      headers: headerObj,
      body: JSON.stringify(code),
    })
      .then((response) => response.json())
      .then((data) => {
        setRequirementCount(data.requirementCount);
        getQuestionList();
      })
      .catch((error) => {
        console.log("__ERROR", error);
        handleCancel();
      });
  };

  const setMode = async (type?: any) => {
    await fetch(`${CSET_URL}api/setmode?mode=${type || modeType}`, {
      method: "POST",
      headers: headerObj,
      body: JSON.stringify(CSET.MODE_REQUEST),
    })
      .then((data) => {
        if (Object.keys(firmValue).length > 0) {
          onSelectFirmType(firmValue);
        }
      })
      .catch((error) => {
        setShowBackDrop(false);
      });
  };

  const handleSubmit = () => {
    if (authToken) {
      if (customCset || firmValue.hasOwnProperty("id")) {
        auditTrail({
          variables: {
            auditTrailInput: {
              individual: param.individualId,
              organization: param.partnerOrganizationId,
              action: {
                message:
                  "Clicked on Next Button to Redirect to Questions Page.",
                data: null,
              },
              flowtype: param.flowType.type
                ? param.flowType.type
                : param.flowType,
              page: "FirmType Page",
              client: param.clientOrgId,
              cset_assessment: param.ccAssessmentInfo.id,
            },
          },
        }).then((res: any) => {
          updateAssessment();
          param["activeMode"] = modeType;
        });
      }
      if (!firmValue.hasOwnProperty("id")) {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: "Please select firm type",
        }));
      }
    } else {
      logout();
    }
  };

  const updateAssessment = () => {
    updateAssessement({
      variables: {
        id: param.ccAssessmentInfo.id,
        cset_firm_id: firmValue.id,
        assessment_type:
          param.flowType.type === undefined
            ? param.flowType
            : param.flowType.type,
      },
    })
      .then(async () => {
        handleNext();
      })
      .catch((error) => {
        console.log("__ERROR", error);
        handleCancel();
      });
  };

  const handleNext = () => {
    param.assessmentCreate = false;
    param.assessmentUpdate = true;
    history(routeConstant.QUESTIONS, { state: { param } });
  };

  const enableBackdrop = () => {
    if (loadingF || showBackDrop || !modeType) {
      return true;
    } else {
      return false;
    }
  };

  const callingFromTopStepper = (event: any) => {
    setStepperObj(event);
  };

  const handleBack = () => {
    setStepper(
      client,
      standardStepperObject.assessmentInformation.name,
      standardStepperObject.assessmentInformation.value
    );
    history(routeConstant.ASSESSMENT_INFO, { state: { param } });
  };

  const handleCancel = () => {
    const val: any = {
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      pgPartnerId: param.pgPartnerId,
      pgPartnerUserId: param.pgPartnerUserId,
    };
    history(routeConstant.ASSESSMENT_LIST, { state: { val } });
  };

  const handleModeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = (event.target as HTMLInputElement).value;
    setMode(type);
    setModeType(type);
    param["activeMode"] = type;
    if (type === "Q") {
      setRequirementCount("0");
    }
    if (type === "R") {
      setQuestionsCount("0");
    }
  };

  const getTotalQuestions = (arr: any) => {
    let TotalCount: any = 0;
    if (arr.domains !== undefined) {
      const tempArray: any = [];
      arr.domains.forEach((data: any, index: any) => {
        data.categories.forEach((element: any, index: any) => {
          element.subCategories.forEach((ques: any) => {
            tempArray.push(ques.questions.length);
          });
        });
      });
      for (let i = 0; i < tempArray.length; ++i) {
        TotalCount += tempArray[i];
      }
    }
    setShowBackDrop(false);
    return TotalCount;
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {enableBackdrop() ? <SimpleBackdrop /> : null}
      <Typography component="h5" variant="h1">
        Risk Assessment for {param ? param.clientInfo.name : null}
      </Typography>
      <Grid container spacing={0} className={styles.FooterGridWrap}>
        <Grid item xs={12} className={styles.FooterActions}>
          <div className={styles.topstepperButons}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                {"<< Previous"}
              </Button>
            </div>
            <div className={styles.topstepperButons}>
              <TopStepper
                param={param}
                handleSave={callingFromTopStepper}
                obj={stepperObj}
                submitDisabled={false}
              />
            </div>
            <div className={styles.nextBtn}>
              <Button
                id="next-button"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {"Next >>"}
              </Button>
            </div>
          </div>
          {!is_link_assessment && (<div className={styles.crossBtn}>
            <Button
              id="cancel-button"
              variant="contained"
              color="primary"
              onClick={handleCancel}
            >
              {"X"}
            </Button>
            <span className={styles.cancleTooltip}>Cancel</span>
          </div>)}
        </Grid>
      </Grid>
      <Grid container spacing={0}></Grid>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={styles.paper}>
            <div>
              <Typography component="h5" variant="h2">
                {"Select firm type"}
              </Typography>
              <Grid item xs={12} className={styles.warningWrap}>
                {formState.isFailed ? (
                  <Alert
                    severity="warning"
                    action={
                      <IconButton
                        id="close"
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleAlertClose}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {formState.errMessage}
                  </Alert>
                ) : null}
              </Grid>
              <div className={styles.Companyrequireblock}>
                <div className={styles.CompanyrequireblockWrap}>
                  {modeType === "R" ? (
                    <>
                      <div className={styles.Companyrequire}>Requirement:</div>
                      <div className={styles.Companyrequire}>
                        <span className={styles.Companyrequirenos}>
                          {" "}
                          {requirementCount}
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={styles.CompanyrequireblockWrap}>
                  {modeType === "Q" ? (
                    <>
                      <div className={styles.Companyrequire}>Questions:</div>
                      <div className={styles.Companyrequire}>
                        <span className={styles.Companyrequirenos}>
                          {questionsCount}
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <FormControl
                component="fieldset"
                className={styles.FieldsetControl}
              >
                <span className={styles.CompanyFormHead}>{"Select Mode"}</span>
                <RadioGroup
                  row
                  aria-label="Assessment Type"
                  name="Assessment Type"
                  value={modeType}
                  onChange={handleModeType}
                >
                  <FormControlLabel
                    id="Questions-Mode"
                    value="Q"
                    control={<Radio className={styles.CompanySelect} />}
                    label="Questions Mode"
                  />
                  <FormControlLabel
                    id="Requirement-Mode"
                    value="R"
                    control={<Radio className={styles.CompanySelect} />}
                    label="Requirement Mode"
                  />
                </RadioGroup>
              </FormControl>
              <div className={styles.firmTypeButton}>
                {firmData.length > 0 &&
                  firmData.map((element: any) => {
                    return (
                      <div key={element.key} className={styles.TabOptionsWrap}>
                        {element.value.map((val: any) => {
                          return (
                            <div className={styles.TabOptions} key={val.id}>
                              {val.firm_size !== "Medium" ? (
                                <Button
                                  id={element.key}
                                  className={
                                    val.id === firmValue.id
                                      ? styles.TabOptionsActive
                                      : styles.TabOptionsInactive
                                  }
                                  onClick={() => onSelectFirmType(val)}
                                >
                                  <div
                                    className={
                                      val.id === firmValue.id
                                        ? styles.para
                                        : styles.TabOptionsInactive
                                    }
                                  >
                                    {element.key}
                                  </div>
                                </Button>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FirmType;
