import React, { useState, useEffect } from "react";
import { CSET_URL } from "../../../../../config";
import logout from "../../../../Auth/Logout/Logout";
import DOMPurify from "dompurify";
import styles from "./CRRExecutiveSummary.module.css";

export interface CrrNistCsfCatPerformanceProps {
  CRRModelAPIData?: any;
  handleChildCompError?: (err: any) => void;
}

export const CrrNistCsfCatPerformance: React.FC<
  CrrNistCsfCatPerformanceProps
> = ({ CRRModelAPIData,handleChildCompError =()=>{} }: CrrNistCsfCatPerformanceProps) => {
  const [bodyData, setBodyData] = useState<any>([]);
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [crrModel, setCRRModel] = useState<any>({});

  useEffect(() => {
    getNistCsfCatPerformanceBodyData();
  }, []);

  useEffect(() => {
    if (CRRModelAPIData) {
      setCRRModel(CRRModelAPIData.structure.Model);
    }
  }, [CRRModelAPIData]);

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const getNistCsfCatPerformanceBodyData = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/reportscrr/getNistCsfCatPerformanceBodyData`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.json();
    if (response.status === 200) {
      setBodyData(data);
    }else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }catch(err){
    handleChildCompError(err)
  }
  };

  return (
    <div className={`${styles.containerFluid} ${styles.crrNistCatSumWrap}`}>
      <div className={styles.row}>
        <div className={styles.col6}>
          <h3 className={styles.depictionTitle}>
            NIST Cybersecurity Framework
            <br />
            Category Performance
          </h3>
        </div>
        <div className={styles.col6}></div>
      </div>

      <br />
      {bodyData.map((e: any) => {
        return (
          <div key={e?.subCatsCount}>
            <table
              cellPadding={0}
              cellSpacing={0}
              className={styles.domainFunction}
              style={{ height: "90%", border: "none" }}
            >
              <tbody>
                <tr style={{ width: "100%" }}>
                  <th style={{ width: "75px" }}>
                    <div style={{ textAlign: "center" }}>Function</div>
                  </th>
                  <th className={styles.category}>Category</th>
                  <th className={styles.subcategory}>Subcategory</th>
                  <th className={styles.references}>CRR References</th>
                </tr>
                <tr className={styles.bb2} style={{ border: "none" }}>
                  <td
                    rowSpan={e.subCatsCount}
                    style={{
                      height: "100%",
                      padding: 0,
                      border: "none",
                      backgroundColor: `${
                        crrModel?.crrScores?.csfFunctionColors[
                          e.function.Function.code
                        ]
                      }`,
                    }}
                  >
                    <div className={styles.function}>
                      <div className={styles.verticalText}>
                        {e.function.Function.name.toUpperCase()} (
                        {e.function.Function.code.toUpperCase()})
                      </div>
                    </div>
                  </td>

                  <td
                    className={styles.summaryCellPadding}
                    style={{ padding: "0 1rem !important", border: "none" }}
                  >
                    <b>{e.function.Function.name.toUpperCase() + " Summary"}</b>
                    <br />
                    <p
                      dangerouslySetInnerHTML={createDangerousHTML(e.chart)}
                    ></p>
                  </td>
                  <td colSpan={2} style={{ fontSize: "12px", border: "none" }}>
                    <b>{e.function.Function.desc}</b>
                  </td>
                </tr>
                {e.cats.map((cat: any) => {
                  return (
                    <tr key={cat?.name} className={styles.bb2} style={{ border: "none" }}>
                      <td
                        className={`${styles.summaryCellPadding} ${styles.category}`}
                        style={{
                          padding: "0 1rem !important",
                          border: "none",
                        }}
                      >
                        <div
                          style={{
                            width: "70%",
                            fontSize: "10px",
                            marginBottom: "1rem",
                          }}
                        >
                          <b>
                            {cat.name} {cat.code}:
                          </b>{" "}
                          {cat.desc}
                        </div>
                        {cat.heatMaps.length && (
                          <>
                            CRR Practices
                            <br />
                            <div
                              className={`${styles.dFlex} ${styles.justifyContentStart} ${styles.flexWrap} ${styles.alignItemsCenter}`}
                              style={{ columnGap: "15px", width: "70%" }}
                            >
                              {cat.heatMaps.map((heatMap: any) => {
                                return (
                                  <div
                                    className={styles.flex00a}
                                    dangerouslySetInnerHTML={createDangerousHTML(
                                      heatMap
                                    )}
                                  ></div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </td>
                      <td
                        colSpan={2}
                        style={{ padding: "3px 0 0 0", border: "none" }}
                      >
                        <table style={{ margin: 0, border: "none" }}>
                          {cat.subCats.map((subCat: any, i: any) => {
                            return (
                              <tr key={subCat?.subCat?.Subcategory?.title} style={{ padding: "1rem", border: "none" }}>
                                <td
                                  style={{
                                    width: "210px",
                                    padding: "0.25rem",
                                    border: "none",
                                    fontSize: "10px",
                                  }}
                                >
                                  <b>{subCat.subCat.Subcategory.title}:</b>{" "}
                                  {subCat.subCat.Subcategory.desc}
                                </td>
                                <td
                                  className={styles.references}
                                  style={{ width: "280px", border: "none" }}
                                >
                                  <div
                                    className={`${styles.dFlex} ${styles.justifyContentStart} ${styles.flexWrap}`}
                                    style={{ columnGap: "15px" }}
                                  >
                                    {subCat.heatMaps.map(
                                      (subCatHeatMap: any) => {
                                        return (
                                          <div
                                            className={styles.flex00a}
                                            dangerouslySetInnerHTML={createDangerousHTML(
                                              subCatHeatMap
                                            )}
                                          ></div>
                                        );
                                      }
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </td>
                    </tr>
                  );
                })}
                {e.function.Function.code !== "RC" ? (
                  <div className="break"></div>
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default CrrNistCsfCatPerformance;
