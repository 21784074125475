import React, { useState, useEffect } from "react";
import styles from "./CMMC2ExecutiveSummary.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Button } from "../../../../../components/UI/Form/Button/Button";
import * as routeConstant from "../../../../../common/RouteConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { CSET_URL } from "../../../../../config/index";
import autoTable from "jspdf-autotable";
import { GET_ORGANIZATION } from "../../../../../graphql/queries/Organization";
import { HorizontalBar, Doughnut } from "react-chartjs-2";
import { saveAs } from "file-saver";
import { useLazyQuery } from "@apollo/client";
import * as docx from "docx";
import logout from "../../../../Auth/Logout/Logout";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import { GET_DOCUMENT_BY_ASSESSMENT_ID } from "../../../../../graphql/queries/QuestionDocument";
import { jsPDF } from "jspdf";
import GetAppIcon from "@mui/icons-material/GetApp";
import moment from "moment";
import * as msgConstants from "../../../../../common/MessageConstants";
import DOMPurify from "dompurify";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../../components/UI/Alert/Alert";
import html2canvas from "html2canvas";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";
export interface ExecutiveProps {
  from?: string;
  props?: any;
  reportBlobData?: any;
  handleChange?: any;
  partnerLogo?: any;
  clientLogo?: any;
  partnerImageDimension?: any;
  clientImageDimension?: any;
  sprsSVGRef?:any;
}

export const CMMC2ExecutiveSummary: React.FC<ExecutiveProps> = ({
  from,
  reportBlobData,
  props,
  handleChange,
  partnerLogo,
  clientLogo,
  clientImageDimension,
  partnerImageDimension,
  sprsSVGRef,
}: ExecutiveProps) => {
  const history = useNavigate();
  const location = useLocation();
  const accessControl = "Access Control (AC)";
  const awarenessTraining = "Awareness and Training (AT)";
  const auditAccountability = "Audit and Accountability (AU)";
  const configurationManagement = "Configuration Management (CM)";
  const identificationAuthentication = "Identification and Authentication (IA)";
  const incidentResponse = "Incident Response (IR)";
  const maintenance = "Maintenance (MA)";
  const mediaProtection = "Media Protection (MP)";
  const personnelSecurity = "Personnel Security (PS)";
  const physicalProtection = "Physical Protection (PE)";
  const riskAssessment = "Risk Assessment (RA)";
  const securityAssessment = "Security Assessment (CA)";
  const systemCommunicationProtection =
    "Systems and Communications Protection (SC)";
  const systemInformationIntegrity = "System and Information Integrity (SI)";
  const [level1, setLevel1] = useState<any>({});
  const [level2, setLevel2] = useState<any>({});
  const [performanceDomainChart, setPerformanceDomainChart] = useState({});
  const [newData, setNewData] = useState<any>([]);
  const [svgState,setSVGState] = useState<any>([]);
  const componentRef = React.useRef<HTMLHeadingElement>(null);
  const chartRef1 = React.useRef<any>(null);
  const chartRef2 = React.useRef<any>(null);
  const chartRef3 = React.useRef<any>(null);
  const sprsRef = React.useRef<any>(null);
  const [gaugeSvg, setGaugeSvg] = useState<any>("");
  const preparedForBy = "preparedFor/By";
  const envVariables = process.env;
  const serverUrl = envVariables.REACT_APP_SERVER_URL;

  const [partnerData, setPartnerData] = useState<any>({});
  const [clientData, setClientData] = useState<any>({});
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [param, setParam] = useState<any>(
    location?.state[Object.keys(location?.state)[0]]
  );
  const [loading, setLoading] = useState(false);
  const [bannerImage, setBannerImage] = useState<any>("");
  const [cpaImage, setCPAImage] = useState<any>("");
  const [cpaImageDimensions, setCPAImageDimensions] = useState<any>("");
  const [partnerImageDimensions, setPartnerImageDimensions] = useState<any>("");
  const [partnerImage, setPartnerImage] = useState<any>("");
  const [defaultCPAImage, setDefaultCPAImage] = useState<any>("");
  const [assessmentDocument, setAssessmentDocument] = useState<any>([]);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [getAllDocumentsByAssId] = useLazyQuery(GET_DOCUMENT_BY_ASSESSMENT_ID, {
    fetchPolicy: msgConstants.FETCH_POLICY,
  });

  const column = [
    { title: "Practice", field: "id" },
    { title: "Question", field: "questionText" },
    { title: "Response", field: "answerText" },
    { title: "Value", field: "score" },
  ];

  useEffect(() => {
    if (props) {
      setParam(props);
    } else {
      setParam(location?.state[Object.keys(location?.state)[0]]);
    }
    getDataforExecutiveSummaryReport();
    getDocumentData();
    setSVGState(sprsSVGRef)
  }, []);

  useEffect(() => {
    GetOrganizationDataOfPartner({
      variables: {
        where: {
          contact_id: param?.clientInfo.partnerId,
        },
      },
    });
    GetOrganizationDataOfClient({
      variables: {
        where: {
          id: param?.clientInfo.clientOrgId,
          subtype: "Client",
        },
      },
    });
  }, [param]);

  const getDocumentData = async () => {
    await getAllDocumentsByAssId({
      variables: {
        assId:
          parseInt(param?.ccAssessmentInfo?.id) ||
          parseInt(param?.ccAssessmentInfo?.id),
        featureType: "Document",
      },
    })
      .then((data: any) => {
        setAssessmentDocument(data.data.questionDocuments);
      })
      .catch((err:any)=>{
        sentry_error_catch(err,setLoading,setFormState)
      })
  };

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  const options = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
    title: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100,
            fontStyle: "bold",
            fontSize: 18,
            fontColor: "black",
          },
        },
      ],
    },
  };

  const options2 = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
  };


  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const getDataforExecutiveSummaryReport = async () => {
    try {
      setLoading(true);
      getStandardSummaryDetails();
      getPerformanceByDomain();
      getSPRSScore();
    } catch (err:any) {
      setLoading(false);
      Sentry.captureException(err)
    }
  };

  const segmentColor = (ans: string) => {
    switch (ans) {
      case "U":
      case "Unanswered":
        return "#CCCCCC";
      case "Y":
      case "Yes":
        return "#28A745";
      case "A":
      case "Alternate":
        return "#B17300";
      case "NA":
      case "Not Applicable":
        return "#007BFF";
      case "N":
      case "No":
        return "#DC3545";
      default:
        return "#000000";
    }
  };

  const getStandardSummaryDetails = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/results/compliancebylevel`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          if (data.length > 0) {
            if (data[0] !== undefined) {
              const pieData1 = data[0].answerDistribution.find(
                (a: any) => a.value === "Y"
              );
              data[0].compliancePercent = !!pieData1
                ? pieData1.percent.toFixed(2)
                : 0;
              data[0].nonCompliancePercent = 100 - data[0].compliancePercent;
              // build the data object for Chart
              const level1Data: any = {};
              level1Data.label = "";
              level1Data.labels = [];
              level1Data.data = [];
              level1Data.colors = [];
              data[0].answerDistribution.forEach((element: any) => {
                level1Data.data.push(element.percent.toFixed(1));
                level1Data.labels.push(
                  `${element.value}:${element.percent.toFixed(1)}%`
                );
                level1Data.colors.push(segmentColor(element.value));
              });
              setLevel1({
                labels: level1Data.labels,
                datasets: [
                  {
                    data: level1Data.data,
                    backgroundColor: level1Data.colors,
                    borderColor: level1Data.colors,
                    borderWidth: 1,
                  },
                ],
              });
            }
            if (data[1] !== undefined) {
              const pieData2 = data[1].answerDistribution.find(
                (a: any) => a.value === "Y"
              );
              data[1].compliancePercent = !!pieData2
                ? pieData2.percent.toFixed(2)
                : 0;
              data[1].nonCompliancePercent = 100 - data[1].compliancePercent;
              // build the data object for Chart
              const level2Data: any = {};
              level2Data.label = "";
              level2Data.labels = [];
              level2Data.data = [];
              level2Data.colors = [];
              data[1].answerDistribution.forEach((element: any) => {
                level2Data.data.push(element.percent.toFixed(1));
                level2Data.labels.push(
                  `${element.value}:${element.percent.toFixed(1)}%`
                );
                level2Data.colors.push(segmentColor(element.value));
              });
              setLevel2({
                labels: level2Data.labels,
                datasets: [
                  {
                    data: level2Data.data,
                    backgroundColor: level2Data.colors,
                    borderColor: level2Data.colors,
                    borderWidth: 1,
                  },
                ],
              });
            }
          }
        }).catch((err:any)=>{
          sentry_error_catch(err,setLoading,setFormState)
        })
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const buildbarGraphData = (data: any) => {
    // build the object to populate the chart
    const x: any = {};
    x.labels = [];
    x.datasets = [];
    const ds: any = {
      label: "",
      backgroundColor: "#245075",
      data: [],
    };
    x.datasets.push(ds);

    data.forEach((element: any) => {
      x.labels.push(element.domainName);
      ds.data.push(sumCompliantPercentages(element.answerDistribution));
    });
    setPerformanceDomainChart(x);
  };

  /**
   * Returns the sum of the Y and NA percentages
   */
  const sumCompliantPercentages = (data: any) => {
    let total = 0;
    data.forEach((element: any) => {
      if (element.value === "Y" || element.value === "NA") {
        total += element.percent.toFixed(1);
      }
    });
    return total;
  };

  const getPerformanceByDomain = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/results/compliancebydomain`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          buildbarGraphData(data);
          setLoading(false);
        })
        .catch((err:any)=>{
          sentry_error_catch(err,setLoading,setFormState)
        })
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const getSPRSScore = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/SPRSScore`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setNewData(data.domains);
          setGaugeSvg(data.gaugeSvg);
        })
        .catch((err:any)=>{
          sentry_error_catch(err,setLoading,setFormState)
        })
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const [GetOrganizationDataOfPartner] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.partnerId === element.contact_id.id) {
          setPartnerData(element);
        }
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err);
    },
  });
  const [GetOrganizationDataOfClient] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (OrgDataResponse: any) => {
      OrgDataResponse.organizations.forEach((element: any) => {
        if (param && param.clientInfo.clientOrgId === element.id) {
          setClientData(element);
        }
      });
    },
    onError: (err) => {
      handleError(err);
    },
  });
  const getBannerImages = () => {
    fetch(`${process.env.PUBLIC_URL}/images/ExecutiveSummary.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setBannerImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    fetch(`${process.env.PUBLIC_URL}/images/blank_img.png`, {
      method: "GET",
      headers: headerObj,
    })
      .then((response) => response.blob())
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64data = reader.result;
          setDefaultCPAImage(base64data);
        };
      })
      .catch((error) => {
        console.log("__Error", error);
        Sentry.captureException(error)
      });
    if (from === "result") {
      setPartnerImage(partnerLogo);
      setCPAImage(clientLogo);
      setCPAImageDimensions(clientImageDimension);
      setPartnerImageDimensions(partnerImageDimension);
    } else {
      if (clientData.logo && clientData.logo[0]) {
        const clientLogoUrl = clientData.logo[0].url.slice(1);
        fetch(`${serverUrl}${clientLogoUrl}`, {
          method: "GET",
          headers: headerObj,
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const base64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setCPAImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };

              setCPAImage(base64data);
            };
          })
          .catch((error) => {
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
      if (partnerData.logo && partnerData.logo[0]) {
        const partnerLogoUrl = partnerData.logo[0].url.slice(1);
        fetch(`${serverUrl}${partnerLogoUrl}`, {
          method: "GET",
        })
          .then((response) => response.blob())
          .then((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function (theFile: any) {
              const ClientLogobase64data = reader.result;
              const image = new Image();
              image.src = theFile.target.result;
              image.onload = () => {
                setPartnerImageDimensions({
                  height: image.height,
                  width: image.width,
                });
              };

              setPartnerImage(ClientLogobase64data);
            };
          })
          .catch((error) => {
            console.log("__Error", error);
            Sentry.captureException(error)
          });
      }
    }
  };

  useEffect(() => {
    if (from && from === "result") {
      setTimeout(() => {
        generate();
      }, 800);
    }
  }, [
    bannerImage &&
      chartRef1 &&
      chartRef3 &&
      partnerData.contact_id &&
      newData.length > 0,
    assessmentDocument,
  ]);
  useEffect(() => {
    getBannerImages();
  }, [partnerData, clientData]);

  const handleBack = () => {
    history(routeConstant.CMMC2_REPORTS, { state: { param } });
  };

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const generateTableHeaderCell = (text: string) => {
    return new docx.TableCell({
      children: [new docx.Paragraph({ text, style: "TableHeading" })],
      margins: {
        top: docx.convertInchesToTwip(0.1),
        bottom: docx.convertInchesToTwip(0.1),
        left: docx.convertInchesToTwip(0.1),
        right: docx.convertInchesToTwip(0.1),
      },
      shading: {
        fill: "#d9d2e9",
        color: "auto",
      },
      verticalAlign: docx.VerticalAlign.CENTER,
    });
  };

  const generateTableIndividualCell = (text: string) => {
    let t = text;
    if (typeof text === "number") {
      t = String(text);
    }
    if (t === "N") {
      t = "No";
    }
    if (t === "U") {
      t = "Unanswered";
    }
    if (t === "Y") {
      t = "Yes";
    }
    if (t === "NA") {
      t = "NA";
    }
    let tParas: any = [];
    if (Array.isArray(t)) {
      tParas = t.map((it: any) => {
        return new docx.Paragraph({
          text: it,
          heading: docx.HeadingLevel.HEADING_3,
        });
      });
    }
    return new docx.TableCell(
      Array.isArray(t)
        ? {
            children: [...tParas],
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1),
            },
            shading: {
              fill: "fcfaff",
              color: "auto",
            },
          }
        : {
            children: [
              new docx.Paragraph({
                text: t,
                heading: docx.HeadingLevel.HEADING_3,
              }),
            ],
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1),
            },
            shading: {
              fill: "fcfaff",
              color: "auto",
            },
          }
    );
  };

  const generateTableCells = (data: any, columns: any[]) => {
    let tableRows: any[] = [];
    tableRows = columns.map((c) => {
      return generateTableIndividualCell(data[c.field]);
    });
    return new docx.TableRow({
      children: [...tableRows],
    });
  };

  const generateTableHeaderRow = (columns: any[]) => {
    let tableColumns: any[] = [];
    tableColumns = columns.map((c) => {
      return generateTableHeaderCell(c.title);
    });
    return new docx.TableRow({
      children: [...tableColumns],
    });
  };

  const generateFinalTable = (data: any[], columns: any[]) => {
    console.log("datadatadata", data);
    const rows: any[] = data.map((d) => {
      return generateTableCells(d, columns);
    });
    return new docx.Table({
      rows: [generateTableHeaderRow(columns), ...rows],
    });
  };

  const fitImg = (targetHeight: any, originalSize: any) => {
    const originalAspectRatio = originalSize.width / originalSize.height;
    let width: number;
    let height: number;
    if (!originalSize) {
      width = targetHeight;
    } else {
      height = targetHeight;
      width = height * originalAspectRatio;
    }
    return width;
  };

  const generate = async () => {
    if (
      bannerImage &&
      chartRef1 &&
      chartRef3 &&
      partnerData.contact_id !== undefined &&
      newData.length > 0 &&
      assessmentDocument
    ) {
      console.log("newData[0].questions", newData[0].questions);
      const DeficiencyTable1 = generateFinalTable(
        newData[0].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).
        map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable2 = generateFinalTable(
        newData[1].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable3 = generateFinalTable(
        newData[2].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable4 = generateFinalTable(
        newData[3].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable5 = generateFinalTable(
        newData[4].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable6 = generateFinalTable(
        newData[5].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable7 = generateFinalTable(
        newData[6].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable8 = generateFinalTable(
        newData[7].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable9 = generateFinalTable(
        newData[8].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable10 = generateFinalTable(
        newData[9].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable11 = generateFinalTable(
        newData[10].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable12 = generateFinalTable(
        newData[11].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable13 = generateFinalTable(
        newData[12].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      const DeficiencyTable14 = generateFinalTable(
        newData[13].questions.map((item:any) => Array.isArray(item) ? [...item] : { ...item }).map((rq: any) => {
          const sanitizedHTML = createDangerousHTML(rq["questionText"]);
          const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
          rq["questionText"] = plainText.split("\n");
          return rq;
        }),
        column
      );
      console.log("__Generate CMMC2 Executive");
      setLoading(true);
      const doc = new docx.Document({
        numbering: {
          config: [
            {
              reference: "top_categories",
              levels: [
                {
                  level: 0,
                  format: docx.LevelFormat.DECIMAL,
                  text: "%1",
                  alignment: docx.AlignmentType.START,
                  style: {
                    paragraph: {
                      indent: { left: 720, hanging: 260 },
                    },
                  },
                },
              ],
            },
            {
              reference: "questions",
              levels: [
                {
                  level: 0,
                  format: docx.LevelFormat.DECIMAL,
                  text: "%1",
                  alignment: docx.AlignmentType.START,
                  style: {
                    paragraph: {
                      indent: { left: 720, hanging: 260 },
                    },
                  },
                },
              ],
            },
          ],
        },
        styles: {
          default: {
            heading1: {
              run: {
                font: "Helvetica",
                size: 36,
                bold: true,
                color: "#4b0079",
              },
              paragraph: {
                alignment: docx.AlignmentType.LEFT,
                spacing: { line: 340 },
              },
            },
            heading2: {
              run: {
                font: "Helvetica",
                size: 30,
                bold: true,
                color: "#0b4785",
              },
              paragraph: {
                spacing: { line: 340 },
              },
            },
            heading3: {
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                spacing: { line: 276 },
              },
            },
            heading4: {
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                alignment: docx.AlignmentType.JUSTIFIED,
              },
            },
          },
          paragraphStyles: [
            {
              id: "normalPara",
              name: "Normal Para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
              paragraph: {
                spacing: {
                  line: 276,
                  before: 20 * 72 * 0.1,
                  after: 20 * 72 * 0.05,
                },
                rightTabStop: docx.TabStopPosition.MAX,
                leftTabStop: 453.543307087,
              },
            },
            {
              id: "mainHeading",
              name: "Normal Para2",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 80,
                color: "#4B0079",
                bold: true,
              },
            },
            {
              id: "date",
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                color: "#4B0079",
                size: 40,
                bold: true,
              },
            },
            {
              id: "bulletText",
              name: "Normal para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
            {
              id: "customFontCng",
              quickFormat: true,
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
            {
              id: "miniHeader",
              name: "Normal para",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 24,
                bold: true,
              },
            },
            {
              id: "TableHeading",
              name: "Normal Para2",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
                color: "#4B0079",
                bold: true,
              },
            },
            {
              id: "BoldPara",
              name: "Normal Para3",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,

              run: {
                font: "Helvetica",
                size: 20,
                bold: true,
              },
            },
            {
              id: preparedForBy,
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                color: "#4B0079",
                size: 20,
                bold: true,
              },
            },
            {
              id: "clientPartner",
              name: "Aside",
              basedOn: "Normal",
              next: "Normal",
              run: {
                font: "Helvetica",
                size: 20,
              },
            },
          ],
        },
      });

      const imageBase64Data1 = chartRef1?.current.chartInstance.toBase64Image();
      const imageBase64Data2 =
        chartRef2.current !== null
          ? chartRef2?.current.chartInstance.toBase64Image()
          : "";
      const imageBase64Data3 = chartRef3?.current.chartInstance.toBase64Image();
      const firstchart = imageBase64Data1?.split(",")[1];
      const secondchart = imageBase64Data2?.split(",")[1];
      const thirdchart = imageBase64Data3?.split(",")[1];
      const exeSummaryLogo = bannerImage ? bannerImage.split(",")[1] : "";
      const defaultcpaLogo = defaultCPAImage
        ? defaultCPAImage.split(",")[1]
        : "";
      const cpaLogo = cpaImage ? cpaImage.split(",")[1] : defaultcpaLogo;
      const msplogo = partnerImage
        ? partnerImage.split(",")[1]
        : defaultcpaLogo;

      const image1 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(firstchart), (c) => c.charCodeAt(0)),
        500,
        250
      );

      const image2 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(secondchart ? secondchart : ""), (c) =>
          c.charCodeAt(0)
        ),
        500,
        250
      );

      const image3 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(thirdchart), (c) => c.charCodeAt(0)),
        600,
        400
      );
      console.log("setSVGState",svgState);
      let image4:any = null;
      if(param?.MaturityLevel === "2" ){
        if(svgState){
        sprsRef.current = svgState.current;
        sprsRef.current.style.display = "block";
        }
        const canvas = await html2canvas(sprsRef.current);
        if(svgState){
          sprsRef.current.style.display = "None";
        }
        const imgData = canvas.toDataURL("image/png");
        image4 = docx.Media.addImage(
          doc,
          Uint8Array.from(atob(imgData.split(',')[1]), (c) => c.charCodeAt(0)),
          600,
          100
        );
      }
      const image5 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(exeSummaryLogo), (c) => c.charCodeAt(0)),
        600,
        350
      );

      const image6 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(cpaLogo), (c) => c.charCodeAt(0)),
        fitImg(50, cpaImageDimensions),
        50
      );

      const image7 = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
        fitImg(50, partnerImageDimensions),
        50
      );
      const footerLogo = docx.Media.addImage(
        doc,
        Uint8Array.from(atob(msplogo), (c) => c.charCodeAt(0)),
        fitImg(50, partnerImageDimensions),
        50,
        {
          floating: {
            horizontalPosition: {
              align: docx.HorizontalPositionAlign.RIGHT,
            },
            verticalPosition: {
              align: docx.VerticalPositionAlign.BOTTOM,
            },
          },
        }
      );
      const bannertable = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    heading: docx.HeadingLevel.HEADING_1,
                    style: preparedForBy,
                    children: [
                      new docx.TextRun({
                        text: "Prepared for",
                        bold: true,
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),

                  new docx.Paragraph(image6),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph({
                    text: param?.clientInfo.name,
                    style: "clientPartner",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.2),
                  right: docx.convertInchesToTwip(1.8),
                },
                columnSpan: 5,
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },

                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    heading: docx.HeadingLevel.HEADING_1,
                    style: preparedForBy,
                    children: [
                      new docx.TextRun({
                        text: "Prepared By",
                        bold: true,
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    text: "                                               ",
                  }),
                  new docx.Paragraph(image7),
                  new docx.Paragraph({
                    text: "",
                  }),
                  new docx.Paragraph({
                    text: partnerData.contact_id?.name,
                    style: "clientPartner",
                  }),
                  new docx.Paragraph({
                    text: "",
                    style: "clientPartner",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.5),
                  right: docx.convertInchesToTwip(2),
                },
                borders: {
                  top: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  right: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  bottom: {
                    style: docx.BorderStyle.SINGLE,
                    size: 0,
                    color: "ffffff",
                  },
                  left: {
                    style: docx.BorderStyle.THICK,
                    size: 3,
                    color: "4B0079",
                  },
                },
                columnSpan: 5,
                shading: {
                  fill: "ffffff",
                  color: "auto",
                },
              }),
            ],
          }),
        ],
      });

      doc.addSection({
        children: [
          new docx.Paragraph(image5),
          new docx.Paragraph({
            text: "Executive Summary Report CMMC 2.0",
            style: "mainHeading",
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: moment().format("MMMM DD, YYYY"),
            heading: docx.HeadingLevel.HEADING_1,
            style: "date",
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),

          bannertable,
        ],
      });

      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: msgConstants.EXECUTIVE_SUMMARY,
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.LEFT,

            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: "This report was prepared as an account of work sponsored by an agency of the U.S. Government.Neither the U.S. Government nor any agency thereof, nor any employee, makes any warranty, expressed or implied, or assumes any legal liability or responsibility for any third-party's use, or the results of such use, or any information, apparatus, product, or process disclosed in this publication,or represents that its use by such third party would not infringe privately owned rights. ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            style: "normalPara",
            spacing: {
              after: 400,
            },
          }),
          new docx.Paragraph({
            text: "Performance by Level",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          new docx.Paragraph({
            text: "Level 1",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          new docx.Paragraph(image1),
          new docx.Paragraph({
            text: " ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 400,
            },
          }),
          secondchart
            ? new docx.Paragraph({
                text: "Level 2",
                heading: docx.HeadingLevel.HEADING_2,
                alignment: docx.AlignmentType.LEFT,
                spacing: {
                  after: 400,
                },
              })
            : new docx.Paragraph({
                text: " ",
                heading: docx.HeadingLevel.HEADING_2,
                alignment: docx.AlignmentType.LEFT,
                spacing: {
                  after: 400,
                },
              }),
          secondchart
            ? (new docx.Paragraph({
                text: "Level 2",
                heading: docx.HeadingLevel.HEADING_2,
                alignment: docx.AlignmentType.LEFT,
                spacing: {
                  after: 400,
                },
              }),
              new docx.Paragraph(image2))
            : new docx.Paragraph({
                text: " ",
                heading: docx.HeadingLevel.HEADING_2,
                alignment: docx.AlignmentType.LEFT,
                spacing: {
                  after: 400,
                },
              }),
          new docx.Paragraph({
            text: " ",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 400,
            },
          }),
        ],
      });
      doc.addSection({
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.LEFT,
                children: [
                  new docx.TextRun({
                    children: ["Page | ", docx.PageNumber.CURRENT],
                  }),
                ],
              }),
              new docx.Paragraph(footerLogo),
            ],
          }),
        },
        children: [
          new docx.Paragraph({
            text: "",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          new docx.Paragraph({
            text: "Compliance by Domain",
            heading: docx.HeadingLevel.HEADING_2,
            alignment: docx.AlignmentType.LEFT,
            spacing: {
              after: 300,
            },
          }),
          new docx.Paragraph(image3),
        ],
      });
      {
        param?.MaturityLevel === "2" &&
          doc.addSection({
            footers: {
              default: new docx.Footer({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.LEFT,
                    children: [
                      new docx.TextRun({
                        children: ["Page | ", docx.PageNumber.CURRENT],
                      }),
                    ],
                  }),
                  new docx.Paragraph(footerLogo),
                ],
              }),
            },
            children: [
              new docx.Paragraph({
                text: "SPRS Scorecard",
                heading: docx.HeadingLevel.HEADING_1,
                alignment: docx.AlignmentType.LEFT,
                contextualSpacing: true,
                spacing: {
                  after: 200,
                },
              }),
              new docx.Paragraph(image4),
              new docx.Paragraph({
                text: accessControl,
                heading: docx.HeadingLevel.HEADING_2,
                alignment: docx.AlignmentType.LEFT,
                spacing: {
                  after: 300,
                  before: 500,
                },
              }),
              DeficiencyTable1,
              newData[1].questions.length > 0
                ? new docx.Paragraph({
                    text: awarenessTraining,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[1].questions.length > 0
                ? DeficiencyTable2
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[2].questions.length > 0
                ? new docx.Paragraph({
                    text: auditAccountability,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[2].questions.length > 0
                ? DeficiencyTable3
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[3].questions.length > 0
                ? new docx.Paragraph({
                    text: configurationManagement,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[3].questions.length > 0
                ? DeficiencyTable4
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[4].questions.length > 0
                ? new docx.Paragraph({
                    text: identificationAuthentication,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[4].questions.length > 0
                ? DeficiencyTable5
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[5].questions.length > 0
                ? new docx.Paragraph({
                    text: incidentResponse,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[5].questions.length > 0
                ? DeficiencyTable6
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[6].questions.length > 0
                ? new docx.Paragraph({
                    text: maintenance,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[6].questions.length > 0
                ? DeficiencyTable7
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[7].questions.length > 0
                ? new docx.Paragraph({
                    text: mediaProtection,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[7].questions.length > 0
                ? DeficiencyTable8
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[8].questions.length > 0
                ? new docx.Paragraph({
                    text: personnelSecurity,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[8].questions.length > 0
                ? DeficiencyTable9
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[9].questions.length > 0
                ? new docx.Paragraph({
                    text: physicalProtection,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[9].questions.length > 0
                ? DeficiencyTable10
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[10].questions.length > 0
                ? new docx.Paragraph({
                    text: riskAssessment,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[10].questions.length > 0
                ? DeficiencyTable11
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[11].questions.length > 0
                ? new docx.Paragraph({
                    text: securityAssessment,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[11].questions.length > 0
                ? DeficiencyTable12
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[12].questions.length > 0
                ? new docx.Paragraph({
                    text: systemCommunicationProtection,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[12].questions.length > 0
                ? DeficiencyTable13
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[13].questions.length > 0
                ? new docx.Paragraph({
                    text: systemInformationIntegrity,
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                    spacing: {
                      after: 300,
                      before: 500,
                    },
                  })
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
              newData[13].questions.length > 0
                ? DeficiencyTable14
                : new docx.Paragraph({
                    text: "",
                    heading: docx.HeadingLevel.HEADING_2,
                    alignment: docx.AlignmentType.LEFT,
                  }),
            ],
          });
      }
      const documentTable = new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    text: "Category",
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    text: "Sub-Category",
                    style: "TableHeading",
                    alignment: docx.AlignmentType.LEFT,
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                //verticalAlign: docx.VerticalAlign.CENTER,
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    text: "File Name",
                    style: "TableHeading",
                  }),
                ],
                margins: {
                  top: docx.convertInchesToTwip(0.1),
                  bottom: docx.convertInchesToTwip(0.1),
                  left: docx.convertInchesToTwip(0.1),
                  right: docx.convertInchesToTwip(0.1),
                },
                shading: {
                  fill: "#d9d2e9",
                  color: "auto",
                },
                verticalAlign: docx.VerticalAlign.CENTER,
              }),
            ],
          }),
          ...assessmentDocument.map((doc: any) => {
            return new docx.TableRow({
              children: [
                new docx.TableCell({
                  children: [
                    new docx.Paragraph({
                      text: `${
                        doc.practise ? doc.practise.split("->")[0] : "Global"
                      }`,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                    right: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "#ffffff",
                    color: "auto",
                  },
                  verticalAlign: docx.VerticalAlign.CENTER,
                }),
                new docx.TableCell({
                  children: [
                    new docx.Paragraph({
                      text: `${
                        doc.question_Text === "Global"
                          ? "--"
                          : doc.practise
                          ? `${doc.practise.split("->")[1]} ${
                              doc.practise.split("->")[2]
                                ? "->Q." + doc.practise.split("->")[2]
                                : ""
                            }`
                          : ""
                      }`,
                      alignment: docx.AlignmentType.LEFT,
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                    right: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "#ffffff",
                    color: "auto",
                  },
                  //verticalAlign: docx.VerticalAlign.CENTER,
                }),
                new docx.TableCell({
                  children: [
                    ...doc.document_id.map((docn: any) => {
                      return new docx.Paragraph({
                        text: `${docn.name}`,
                        alignment: docx.AlignmentType.LEFT,
                      });
                    }),
                  ],
                  margins: {
                    top: docx.convertInchesToTwip(0.1),
                    bottom: docx.convertInchesToTwip(0.1),
                    left: docx.convertInchesToTwip(0.1),
                    right: docx.convertInchesToTwip(0.1),
                  },
                  shading: {
                    fill: "#ffffff",
                    color: "auto",
                  },
                }),
              ],
            });
          }),
        ],
      });
      if (assessmentDocument.length > 0) {
        doc.addSection({
          footers: {
            default: new docx.Footer({
              children: [
                new docx.Paragraph({
                  alignment: docx.AlignmentType.LEFT,
                  children: [
                    new docx.TextRun({
                      children: ["Page | ", docx.PageNumber.CURRENT],
                    }),
                  ],
                }),
                new docx.Paragraph(footerLogo),
              ],
            }),
          },
          children: [
            new docx.Paragraph({
              text: "Document Library",
              heading: docx.HeadingLevel.HEADING_1,
              alignment: docx.AlignmentType.LEFT,
              spacing: {
                after: 200,
              },
            }),
            documentTable,
          ],
        });
      }
      docx.Packer.toBlob(doc)
        .then(async (blob: any) => {
          if (from && from === "result") {
            await Promise.resolve()
              .then(() => {
                setLoading(false);
                reportBlobData = blob;
                reportBlobData["name"] = "Executive_Summary_Report.docx";
                handleChange(reportBlobData);
              })
              .catch((err) => {
                console.log(err);
                Sentry.captureException(err)
              });
          } else {
            await Promise.resolve(saveAs(blob, "Executive_Summary_Report.docx"))
              .then(() => {
                setLoading(false);
              })
              .catch((err) => {
                console.log(err);
                Sentry.captureException(err)
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const startLoader = async () => {
    setLoading(true);
    setTimeout(function () {
      generatePdf();
    }, 1000);
  };

  const generatePdf = async () => {
    try {
      setLoading(true);
      const pdf: any = new jsPDF({compress:true});
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(12);
      const imageBase64Data1 = chartRef1.current.chartInstance.toBase64Image();
      const imageBase64Data2 =
        chartRef2.current !== null
          ? chartRef2.current.chartInstance.toBase64Image()
          : "";
      const imageBase64Data3 = chartRef3.current.chartInstance.toBase64Image();
      //cover page
      pdf.addImage(bannerImage, "JPEG", 10, 10, 190, 125);
      pdf.setDrawColor(0);
      pdf.setTextColor(75, 0, 121);
      pdf.setFontSize(40);
      pdf.setFont("helvetica", "bold");
      pdf.text(msgConstants.EXECUTIVE_SUMMARY, 20, 150);
      pdf.text("Report", 20, 165);
      pdf.setFontSize(20);
      const date = moment().toDate();
      const formatedDate = moment(date).format("MMMM DD, YYYY");
      pdf.text(formatedDate, 20, 180);
      pdf.setFontSize(10);
      pdf.text("Prepared For", 20, 200);
      pdf.text("Prepared By", 110, 200);
      pdf.setDrawColor(75, 0, 121);
      pdf.setLineWidth(0.75);
      pdf.line(100, 200, 100, 240);
      if (cpaImage !== undefined) {
        pdf.addImage(
          cpaImage,
          "JPEG",
          20,
          205,
          fitImg(18, cpaImageDimensions),
          18
        );
      }
      if (partnerImage !== undefined) {
        pdf.addImage(
          partnerImage,
          "JPEG",
          107,
          205,
          fitImg(18, partnerImageDimensions),
          18
        );
      }
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      pdf.text(param.clientInfo.name, 20, 235);
      pdf.text(partnerData.contact_id.name, 110, 235);
      pdf.text("", 110, 240);

      // first page
      pdf.addPage("a4", "p");
      //header
      pdf.setTextColor(75, 0, 121); //heading blue color
      pdf.setFontSize(18);
      pdf.text(msgConstants.EXECUTIVE_SUMMARY, 10, 20);
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(0, 0, 0); //paragraph black color
      pdf.setFontSize(10);
      const description = pdf.splitTextToSize(
        "This report was prepared as an account of work sponsored by an agency of the U.S. Government.Neither the U.S. Government nor any agency thereof, nor any employee, makes any warranty, expressed or implied, or assumes any legal liability or responsibility for any third-party's use, or the results of such use, or any information, apparatus, product, or process disclosed in this publication, or represents that its use by such third party would not infringe privately owned rights. ",
        190
      );
      pdf.text(description, 10, 30);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      pdf.text("Level1", 10, 60);
      const imgData1 = imageBase64Data1;
      pdf.addImage(imgData1, "JPEG", 15, 60, 180, 0);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133);
      chartRef2.current !== null && pdf.text("Level2", 10, 180);
      const imgData2 = imageBase64Data2;
      chartRef2.current !== null &&
        pdf.addImage(imgData2, "JPEG", 15, 180, 180, 0);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(18);
      pdf.setTextColor(75, 0, 121);
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133); //subheading orange color
      //footer
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      // second page
      pdf.addPage("a4", "p");
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(15);
      pdf.setTextColor(11, 71, 133); //subheading orange color
      pdf.text("Compliance By Domain", 10, 30);
      const imgData3 = imageBase64Data3;
      pdf.addImage(imgData3, "JPEG", 15, 70, 190, 0);
      //footer
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);

      const headers = [["Practice", "Question", "Response", "value"]];

      const defiTable1: any[] = [];
      const defiTable2: any[] = [];
      const defiTable3: any[] = [];
      const defiTable4: any[] = [];
      const defiTable5: any[] = [];
      const defiTable6: any[] = [];
      const defiTable7: any[] = [];
      const defiTable8: any[] = [];
      const defiTable9: any[] = [];
      const defiTable10: any[] = [];
      const defiTable11: any[] = [];
      const defiTable12: any[] = [];
      const defiTable13: any[] = [];
      const defiTable14: any[] = [];
      newData.forEach((rq: any) => {
        if (rq.domainName === accessControl) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);
            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);
            defiTable1.push(data);
          });
        }
        if (rq.domainName === awarenessTraining) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);
            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);
            defiTable2.push(data);
          });
        }
        if (rq.domainName === auditAccountability) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);
            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);

            defiTable3.push(data);
          });
        }
        if (rq.domainName === configurationManagement) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);
            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);

            defiTable4.push(data);
          });
        }
        if (rq.domainName === identificationAuthentication) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);
            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);

            defiTable5.push(data);
          });
        }
        if (rq.domainName === incidentResponse) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);
            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);

            defiTable6.push(data);
          });
        }
        if (rq.domainName === maintenance) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);

            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);
            defiTable7.push(data);
          });
        }
        if (rq.domainName === mediaProtection) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);

            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);
            defiTable8.push(data);
          });
        }
        if (rq.domainName === personnelSecurity) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);

            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);
            defiTable9.push(data);
          });
        }
        if (rq.domainName === physicalProtection) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);

            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);
            defiTable10.push(data);
          });
        }
        if (rq.domainName === riskAssessment) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);

            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);
            defiTable11.push(data);
          });
        }
        if (rq.domainName === securityAssessment) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);

            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);
            defiTable12.push(data);
          });
        }
        if (rq.domainName === systemCommunicationProtection) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);

            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);
            defiTable13.push(data);
          });
        }
        if (rq.domainName === systemInformationIntegrity) {
          rq.questions.forEach((q: any) => {
            const data: any = [];
            data.push(q["id"]);
            const sanitizedHTML = createDangerousHTML(q["questionText"]);
            const plainText = sanitizedHTML.__html
            .replace(/&nbsp;/g, "\n")
            .replace(/<[^>]+>/g, "");
            data.push(plainText);
            data.push(
              q["answerText"] === "Y"
                ? "Yes"
                : q["answerText"] === "N"
                ? "No"
                : q["answerText"] === "NA"
                ? "NA"
                : "Unanswered"
            );
            data.push(q["score"]);
            defiTable14.push(data);
          });
        }
      });

      if (defiTable1.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133); //subheading orange color
        pdf.text("SPRS Scorecard", 10, 10);
        const canvas = await html2canvas(sprsRef.current);
        const imgData = canvas.toDataURL("image/jpeg");
        pdf.addImage(imgData, "JPEG", 0, 15, 200, 20);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(10);
        pdf.setTextColor(0, 0, 0); //paragraph black color
        pdf.text(accessControl, 10, 40);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable1,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable2.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(awarenessTraining, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable2,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable3.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(auditAccountability, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable3,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable4.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(configurationManagement, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable4,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable5.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(identificationAuthentication, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable5,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable6.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(incidentResponse, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable6,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable7.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(maintenance, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable7,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable8.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(mediaProtection, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable8,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable9.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(personnelSecurity, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable9,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable10.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(physicalProtection, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable10,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable11.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(riskAssessment, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable11,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable12.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(securityAssessment, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable12,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable13.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(systemCommunicationProtection, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable13,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }
      if (defiTable14.length > 0 && param?.MaturityLevel === "2") {
        pdf.addPage("a4", "p");
        pdf.text(systemInformationIntegrity, 10, 30);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(15);
        pdf.setTextColor(11, 71, 133);
        autoTable(pdf, {
          head: headers,
          body: defiTable14,
          margin: { top: 45, left: 10, bottom: 30 },
          theme: "grid",
          headStyles: {
            fillColor: [217, 210, 233],
            textColor: [75, 0, 121],
            lineWidth: 0.1,
            lineColor: "#000",
          },
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            3: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
            4: {
              lineWidth: 0.1,
              lineColor: "#000",
            },
          },
        });
      }

      if (assessmentDocument.length > 0) {
        pdf.addPage("a4", "p");
        pdf.text("Document Library", 10, 30);
        autoTable(pdf, {
          html: "#cmmcDocTable",
          startY: 40,
          margin: { top: 165, left: 10, bottom: 50 },
          didParseCell(data) {
            if (data.row.index === 0) {
              data.cell.styles.textColor = [75, 0, 121];
              data.cell.styles.fillColor = [217, 210, 233];
              data.cell.styles.fontStyle = "bold";
              data.cell.styles.font = "helvetica";
            }
          },
          theme: "grid",
          columnStyles: {
            0: {
              lineWidth: 0.1,
              lineColor: "#000",
              cellWidth: "auto",
            },
            1: {
              lineWidth: 0.1,
              lineColor: "#000",
              cellWidth: "auto",
            },
            2: {
              lineWidth: 0.1,
              lineColor: "#000",
              cellWidth: "auto",
            },
          },
        });
      }
      //header and footer
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 0; i <= pageCount; i++) {
        if (i > 1) {
          pdf.setPage(i);
          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(10);
          pdf.setTextColor(0, 0, 0); //paragraph black color
          pdf.text(
            `Page | ${pdf.internal.getCurrentPageInfo().pageNumber - 1}`,
            10,
            290
          );
          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();
          if (partnerImage !== undefined) {
            pdf.addImage(
              partnerImage,
              "JPEG",
              180,
              270,
              width / 10,
              height / 14
            );
          }
        }
      }

      await Promise.resolve(pdf.save("Executive_Summary_Report.pdf"))
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          Sentry.captureException(error)
        });
    } catch (error){
      setLoading(false);
      console.log("ERR");
      Sentry.captureException(error)
    }
  };

  return (
      <div>
        <CssBaseline />
        <Grid container ref={componentRef}>
          <Grid item md={12}>
            <Typography component="h5" variant="h1">
              {msgConstants.EXECUTIVE_SUMMARY}
            </Typography>
            <Grid item xs={12} sm={6} className={styles.FooterActions}>
              <div className={styles.prevBtn}>
                <Button
                  id="previous-button"
                  variant="contained"
                  color="primary"
                  onClick={handleBack}
                >
                  {"Previous"}
                </Button>
                <div className={styles.borderButton}>
                  <Button
                    id="doc-generate-button"
                    variant="contained"
                    color="primary"
                    onClick={() => generate()}
                  >
                    <GetAppIcon className={styles.EditIcon} />
                    &nbsp; Doc
                  </Button>
                  <Button
                    id="pdf-generate-button"
                    variant="contained"
                    color="primary"
                    onClick={() => startLoader()}
                  >
                    <GetAppIcon className={styles.EditIcon} />
                    &nbsp; PDF
                  </Button>
                </div>
              </div>
            </Grid>
            <Paper className={styles.paper}>
              {formState.isFailed ? (
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleAlertClose}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {formState.errMessage}
                </Alert>
              ) : null}
              {loading ? <SimpleBackdrop /> : null}

              <Grid container>
                <Grid>
                  <p>
                    This report was prepared as an account of work sponsored by
                    an agency of the U.S. Government. Neither the U.S.
                    Government nor any agency thereof, nor any employee, makes
                    any warranty, expressed or implied, or assumes any legal
                    liability or responsibility for any third-party's use, or
                    the results of such use, or any information, apparatus,
                    product, or process disclosed in this publication, or
                    represents that its use by such third party would not
                    infringe privately owned rights.
                  </p>
                </Grid>
                <Grid item xs={12} md={4} className={styles.gridSection}>
                  <h3> This page shows the answer distribution by level. </h3>
                  {Object.keys(level1).length > 0 &&
                  level1?.datasets.length > 0 ? (
                    <>
                      <h1>Level 1</h1>
                      <Paper className={styles.paper}>
                        <Doughnut
                          ref={chartRef1}
                          data={level1}
                          options={options2}
                        />
                      </Paper>
                    </>
                  ) : null}
                  {Object.keys(level2).length > 0 &&
                  level2?.datasets.length > 0 ? (
                    <>
                      <h1>Level 2</h1>
                      <Paper className={styles.paper}>
                        <Doughnut
                          ref={chartRef2}
                          data={level2}
                          options={options2}
                        />
                      </Paper>
                    </>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  className={styles.FooterActions}
                >
                  <hr className={styles.ruler} />
                </Grid>
                <Grid item xs={12} className={styles.gridSection}>
                  <h3>Compliance by Domain</h3>

                  <h4>
                    The following chart shows the percentage of practices met
                    for each domain at target level 2.
                  </h4>
                  <Paper className={styles.paper}>
                    <HorizontalBar
                      data={performanceDomainChart}
                      ref={chartRef3}
                      options={options}
                    />
                  </Paper>
                  {param?.MaturityLevel === "2" ? (
                    <Paper className={styles.paper}>
                      <div style={{ textAlign: "center" }}>
                        <Typography variant="h3" component="h3">
                          <b>SPRS Scorecard</b>
                        </Typography>
                        <br></br>
                        <div
                          ref={sprsRef}
                          dangerouslySetInnerHTML={createDangerousHTML(
                            gaugeSvg
                          )}
                        ></div>
                      </div>

                      <Typography component="h5" variant="h3">
                        The following is an accounting of CMMC Level 2
                        interpreted as your NIST 171 SPRS score. For more
                        information on SPRS scoring methodology and preliminary
                        requirements for its use, please refer to the tutorial.
                      </Typography>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        sm={12}
                        className={styles.FooterActions}
                      >
                        <div className={styles.securityPlanTable}>
                          {newData.map((element: any, index: any) => {
                            return (
                              <table
                                id="secondTable"
                                key={element?.domainName}
                                className={styles.tableStyle}
                              >
                                <tr>
                                  <td colSpan={5}>
                                    <Typography component="h3" variant="h2">
                                      {element.domainName}
                                    </Typography>{" "}
                                  </td>
                                </tr>
                                <tr key={element?.domainName}>
                                  <td colSpan={3}>
                                    {" "}
                                    <Typography component="h3" variant="h3">
                                      Practice
                                    </Typography>
                                  </td>
                                  <td>
                                    {" "}
                                    <Typography component="h3" variant="h3">
                                      Response
                                    </Typography>
                                  </td>
                                  <td>
                                    {" "}
                                    <Typography component="h3" variant="h3">
                                      Value
                                    </Typography>
                                  </td>
                                </tr>

                                {element.questions.map(
                                  (questionData: any, index: number) => {
                                    return (
                               
                                        <tr key={questionData?.id}>
                                          <td colSpan={1}>
                                            {questionData.id}{" "}
                                          </td>
                                          <td colSpan={2}>
                                            <span
                                              dangerouslySetInnerHTML={createDangerousHTML(
                                                questionData.questionText
                                              )}
                                            ></span>
                                          </td>
                                          <td colSpan={1}>
                                            {questionData.answerText === "Y"
                                              ? "Yes"
                                              : questionData.answerText === "N"
                                              ? "No"
                                              : questionData.answerText === "NA"
                                              ? "NA"
                                              : "Unanswered"}{" "}
                                          </td>
                                          <td colSpan={1}>
                                            {questionData.score}
                                          </td>
                                        </tr>
                                    
                                    );
                                  }
                                )}
                              </table>
                            );
                          })}
                        </div>
                      </Grid>
                    </Paper>
                  ) : (
                    <></>
                  )}
                  <Paper>
                    {
                      <Grid
                        item
                        xs={12}
                        md={12}
                        sm={12}
                        className={styles.FooterActions}
                      >
                        <table id="cmmcDocTable" className={styles.tableStyle}>
                          <td colSpan={5}>
                            <Typography component="h3" variant="h2">
                              Document Library
                            </Typography>{" "}
                          </td>
                          <tr>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>File Name</th>
                          </tr>
                          {assessmentDocument &&
                          assessmentDocument.length > 0 ? (
                            assessmentDocument.map((assDoc: any) => {
                              const splitArr = assDoc.practise
                                ? assDoc.practise.split("->")
                                : "";
                              return (
                                <tr key={assDoc?.id}>
                                  <td align="center">
                                    {assDoc.practise ? splitArr[0] : "Global"}
                                  </td>
                                  <td align="center">
                                    {assDoc.question_Text === "Global"
                                      ? "--"
                                      : assDoc.practise
                                      ? `${splitArr[1]} ${
                                          splitArr[2]
                                            ? "-> Q." + splitArr[2]
                                            : "-"
                                        }`
                                      : ""}
                                  </td>
                                  <td>
                                    {assDoc?.document_id.map((doc: any) => {
                                      return (
                                        <div key={doc?.id}>
                                          <div>{doc.name}</div>
                                          <br />
                                        </div>
                                      );
                                    })}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={5}>
                                There are no documents to display
                              </td>
                            </tr>
                          )}
                        </table>
                      </Grid>
                    }
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} className={styles.FooterActions}>
            <div className={styles.prevBtn}>
              <Button
                id="previous-button"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                {"Previous"}
              </Button>
              <div className={styles.borderButton}>
                <Button
                  id="doc-generate-button-2"
                  variant="contained"
                  color="primary"
                  onClick={() => generate()}
                >
                  <GetAppIcon className={styles.EditIcon} />
                  &nbsp; Doc
                </Button>
                <Button
                  id="pdf-generate-button-2"
                  variant="contained"
                  color="primary"
                  onClick={() => startLoader()}
                >
                  <GetAppIcon className={styles.EditIcon} />
                  &nbsp; PDF
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
  );
};

export default CMMC2ExecutiveSummary;
