import React, { useState, useEffect } from "react";
import styles from "./PerformanceSummary.module.css";
import Paper from '@mui/material/Paper';
import { CSET_URL } from "../../../../../config/index";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import logout from "../../../../Auth/Logout/Logout";
import Doughnut from "react-chartjs-2";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Grid } from "@mui/material";
import Alert from "../../../../../components/UI/Alert/Alert";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";
import { ERROR_MESSAGE } from "../../../../../common/MessageConstants";

export interface SummaryProps {
  data?: any;
  props?: any;
}

export const PerformanceSummary: React.FC<SummaryProps> = ({
  data,
  props,
}: SummaryProps) => {
  const [overall, setOverall] = useState<any>({});
  const [basic, setBasic] = useState<any>({});
  const [intermediate, setIntermediate] = useState<any>({});
  const [advanced, setAdvanced] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const authToken = sessionStorage.getItem("ra_token") || logout();

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  useEffect(() => {
    if (data !== undefined) {
      console.log(data);
    }
    getRRADetails();
  }, []);


  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const Doughnutoptions = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18, fontColor:"black"
      },
    },
  };

  const segmentColor = (ans: string) => {
    switch (ans) {
      case "U":
      case "Unanswered":
        return "#CCCCCC";
      case "Y":
      case "Yes":
        return "#28A745";
      case "A":
      case "Alternate":
        return "#B17300";
      case "NA":
      case "Not Applicable":
        return "#007BFF";
      case "N":
      case "No":
        return "#DC3545";
      default:
        return "#000000";
    }
  };

  const getRRADetails = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/reports/rradetail`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          if (data) {
            if (data.rraSummaryOverall) {
              // build the data object for Chart
              const level1Data: any = {};
              level1Data.label = "";
              level1Data.labels = [];
              level1Data.data = [];
              level1Data.colors = [];
              data.rraSummaryOverall.forEach((element: any) => {
                level1Data.data.push(element.percent);
                level1Data.labels.push(
                  `${element.answer_Text}:${Math.round(element.percent)}%`
                );
                level1Data.colors.push(segmentColor(element.answer_Text));
              });
              setOverall({
                labels: level1Data.labels,
                datasets: [
                  {
                    data: level1Data.data,
                    backgroundColor: level1Data.colors,
                    borderColor: level1Data.colors,
                    borderWidth: 1,
                  },
                ],
              });
            }
            if (data.rraSummary) {
              // build the data object for Chart
              const basicData: any = {};
              basicData.label = "";
              basicData.labels = [];
              basicData.data = [];
              basicData.colors = [];
              const IntermediateData: any = {};
              IntermediateData.label = "";
              IntermediateData.labels = [];
              IntermediateData.data = [];
              IntermediateData.colors = [];
              const AdvancedData: any = {};
              AdvancedData.label = "";
              AdvancedData.labels = [];
              AdvancedData.data = [];
              AdvancedData.colors = [];
              data.rraSummary.forEach((element: any) => {
                if (element.level_Name === "Basic") {
                  basicData.data.push(element.percent);
                  basicData.labels.push(
                    `${element.answer_Text}:${Math.round(element.percent)}%`
                  );
                  basicData.colors.push(segmentColor(element.answer_Text));
                }
                if (element.level_Name === "Intermediate") {
                  IntermediateData.data.push(element.percent);
                  IntermediateData.labels.push(
                    `${element.answer_Text}:${Math.round(element.percent)}%`
                  );
                  IntermediateData.colors.push(segmentColor(element.answer_Text));
                }
                if (element.level_Name === "Advanced") {
                  AdvancedData.data.push(element.percent);
                  AdvancedData.labels.push(
                    `${element.answer_Text}:${Math.round(element.percent)}%`
                  );
                  AdvancedData.colors.push(segmentColor(element.answer_Text));
                }
              });
              setBasic({
                labels: basicData.labels,
                datasets: [
                  {
                    data: basicData.data,
                    backgroundColor: basicData.colors,
                    borderColor: basicData.colors,
                    borderWidth: 1,
                  },
                ],
              });
              setAdvanced({
                labels: AdvancedData.labels,
                datasets: [
                  {
                    data: AdvancedData.data,
                    backgroundColor: AdvancedData.colors,
                    borderColor: AdvancedData.colors,
                    borderWidth: 1,
                  },
                ],
              });
              setIntermediate({
                labels: IntermediateData.labels,
                datasets: [
                  {
                    data: IntermediateData.data,
                    backgroundColor: IntermediateData.colors,
                    borderColor: IntermediateData.colors,
                    borderWidth: 1,
                  },
                ],
              });
            }
          }
        }).catch((err:any)=>{
          sentry_error_catch(err,setLoading,setFormState)
        });
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  if (loading) {
    return <SimpleBackdrop />;
  }
  
  return (
    <React.Fragment>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formState.errMessage}
        </Alert>
      ) : null}
      <Paper className={styles.paper}>
        <div className="App">
          <h3 className={styles.tabSummaryHeader}>These charts represent the answer distribution overall and across
            all tiers.</h3>
          <Grid container spacing={4} className={styles.justifyCenter}>
            <Grid item xs={12} lg={4}>
              <h3 className={styles.doughnutHeader}>Overall</h3>
              <Paper className={styles.paper}>
                <Doughnut data={overall} options={Doughnutoptions}/>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4} >
              <h3 className={styles.doughnutHeader}>Basic</h3>
              <Paper className={styles.paper}>
                <Doughnut data={basic} options={Doughnutoptions} />
              </Paper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <h3 className={styles.doughnutHeader}>Intermediate</h3>
              <Paper className={styles.paper}>
                <Doughnut data={intermediate} options={Doughnutoptions}/>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <h3 className={styles.doughnutHeader}>Advanced</h3>
              <Paper className={styles.paper}>
                <Doughnut data={advanced} options={Doughnutoptions}/>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </React.Fragment>
  );
};
export default PerformanceSummary;
