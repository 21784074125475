import React, { useState, useEffect } from "react";
import styles from "./AssessmentTiers.module.css";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import Paper from '@mui/material/Paper';
import { CSET_URL } from "../../../../../config/index";
import MaterialTable from "../../../../../components/UI/Table/MaterialTable";
import Grid from '@mui/material/Grid';
import logout from "../../../../Auth/Logout/Logout";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../../components/UI/Alert/Alert";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";
import { ERROR_MESSAGE } from "../../../../../common/MessageConstants";
export interface SPRSScoreProps {
  data?: any;
}

export const AssessmentTiers: React.FC<SPRSScoreProps> = ({
  data,
}: SPRSScoreProps) => {
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [newData, setNewData] = useState<any>([]);

  const column = [
    { title: "Level Name / Response", key: "name" },
    { title: "Yes", key: "yvalue" },
    { title: "No", key: "nvalue" },
    { title: "UnAnswered", key: "uvalue" },
    { title: "Total", key: "total" },
  ];
  const authToken = sessionStorage.getItem("ra_token") || logout();

  useEffect(() => {
    if (data !== undefined) {
      console.log(data);
    }
    getAssessmentTiers();
  }, []);

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };


  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const getAssessmentTiers = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/reports/rradetail`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          createAnswerDistribByLevel(data);
        })
        .catch((err:any)=>{
          sentry_error_catch(err,setLoading,setFormState)
        });
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  const levelList: any = [];
  const createAnswerDistribByLevel = (r: any) => {
    r.rraSummary.forEach((element: any) => {
      let level = levelList.find((x: any) => x.name === element.level_Name);
      if (!level) {
        level = {
          name: element.level_Name,
          yes: "Yes",
          yvalue: "",
          no: "No",
          nvalue: "",
          unanswered: "Unanswered",
          uvalue: "",
          total: "",
        };
        levelList.push(level);
      }

      if (level.yes === element.answer_Full_Name) {
        level.yvalue = element.qc;
        level.total = element.total;
      }
      if (level.no === element.answer_Full_Name) {
        level.nvalue = element.qc;
        level.total = element.total;
      }
      if (level.unanswered === element.answer_Full_Name) {
        level.uvalue = element.qc;
        level.total = element.total;
      }
      level.total = level.yvalue + level.nvalue + level.uvalue
    });
    setNewData(levelList);
    setLoading(false);
  };

  if (loading) {
    return <SimpleBackdrop />;
  }

  return (
    <React.Fragment>

      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formState.errMessage}
        </Alert>
      ) : null}
      <Paper className={styles.paper}>
        <h3 style={{marginTop:"0px"}}>RRA Assessment Tiers</h3>
        <h3 className={styles.tabSummaryHeader}>
          The RRA model encourages ransomware readiness in three stages – Basic,
          Intermediate, and Advanced.
        </h3>
        <Grid item xs={12} className={styles.RRAModelImg}>
            <div className={styles.RRAModel}>
              <img src={`${process.env.PUBLIC_URL}images/OPT1.png`} alt="RRA Model" />
            </div>
        </Grid>
        <h3 className={styles.tabSummaryHeader}>
          The RRA practices are grouped by basic, intermediate, and advanced
          tiers. The practices are intended to be implemented from one tier to
          the next and are meant to provide a clear path for improvement.{" "}
        </h3>
          <h3>Practice Questions Answered Per Tier</h3>
          <div className={styles.ScrollTable}>
            <MaterialTable
              columns={column}
              data={newData}
              pageSize={10}
            />
          </div>
        </Paper>
    </React.Fragment>
  );
};
export default AssessmentTiers;
