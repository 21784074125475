import React, { useEffect, useState } from "react";
import { Button } from "../../../components/UI/Form/Button/Button";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import styles from "./Help.module.css";
import CSET_HELP from "../../../common/CSET_HELP.json";
import CSET_PRE_SELEC_STANDARDS from "../../../common/CSET_PRE_SELEC_STANDARDS.json";
import Grid from "@mui/material/Grid";
import Input from "../../../components/UI/Form/Input/Input";
import ClearIcon from "@mui/icons-material//Clear";
import SearchIcon from "@mui/icons-material/Search";

export const Help = (props: any) => {
  const [showListing, setShowListing] = useState<any>(false);
  const [showStandardsLisiting, setShowStandardsLisiting] =
    useState<boolean>(false);
  const [showPreSelectedLisiting, setShowPreSelectedLisiting] =
    useState<boolean>(false);
  const [newData, setNewData] = useState<any>([]);
  const [newFilterData, setNewFilterData] = useState<any>([]);
  const [noFilterData, setNoFilterData] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState("");
 

  const StandardsColumn = [
    { title: "Standards Name", key: "StandardsName", sorting: "asc" },
    { title: "Description", key: "description" },
    { title: "Additional Info", key: "addInfo" },
  ];

  const PreSelectedColumn = [
    { title: "FirmType", key: "firmtype" },
    { title: "Standards Name", key: "StandardsName", sorting: "asc" },
    { title: "Description", key: "description" },
  ];

  useEffect(() => {
    createTableDataObject();
  }, [showPreSelectedLisiting, showStandardsLisiting]);



  const createTableDataObject = () => {
    if (showStandardsLisiting) {
      const arr: any = [];
      if (CSET_HELP) {
        CSET_HELP.StandardsData.forEach((element: any, index: any) => {
          const obj: any = {};
          obj["StandardsName"] = element.StandardsName;
          obj["description"] = element.Description;
          obj["addInfo"] = element.AdditionalInfo;
          arr.push(obj);
        });
        setNewData(arr);
        setFilterValue("");
      }
    }
    if (showPreSelectedLisiting) {
      const arr: any = [];
      if (CSET_PRE_SELEC_STANDARDS) {
        CSET_PRE_SELEC_STANDARDS.PreSelectedData.forEach(
          (element: any, index: any) => {
            const obj: any = {};
            obj["firmtype"] = element.FirmType;
            obj["StandardsName"] = element.StandardsName;
            obj["description"] = element.Description;
            arr.push(obj);
          }
        );
        setNewData(arr);
        setFilterValue("");
      }
    }
  };

  const handleClick = (event: any) => {
    if (event.target.id === "ra360-manual") {
      const raManual = `${process.env.PUBLIC_URL}/user_manual/RA360_Manual.pdf`;
      window.open(raManual, "_blank");
    }

    if (event.target.id === "pre-Selected") {
      setShowPreSelectedLisiting(true);
      setShowListing(true);
    }

    if (event.target.id === "standards-Info") {
      setShowStandardsLisiting(true);
      setShowListing(true);
    }
  };

  const checkfilterData = (data: any) => {
    const arr: any = [];
    newData.forEach((element: any) => {
      if (element.StandardsName.includes(data)) {
        arr.push(element);
      }
      if (element.StandardsName.includes(data) === false) {
        setNoFilterData(true);
      }
    });
    setNewFilterData(arr);
  };

  const checkFirmTypefilterData = (data: any) => {
    const arr: any = [];
    newData.forEach((element: any) => {
      if (element.firmtype.includes(data)) {
        arr.push(element);
      }
      if (element.firmtype.includes(data) === false) {
        setNoFilterData(true);
      }
    });
    setNewFilterData(arr);
  };

  const handlefilterChange = (event: any) => {
    setFilterValue(event?.target.value);
  };

  const handleFilterSearch = () => {
    checkfilterData(filterValue);
  };

  const handleFirmtypeFilterChange = (event: any) => {
    checkFirmTypefilterData(event.target.name);
    setFilterValue(event.target.name);
  };

  const backToList = () => {
    setShowPreSelectedLisiting(false);
    setShowStandardsLisiting(false);
    setShowListing(false);
    setFilterValue("");
    setNewFilterData([]);
    setNoFilterData(false);
    createTableDataObject();
  };

  const onClickClear = () => {
    createTableDataObject();
    setNewFilterData([]);
    setNoFilterData(false);
  };

  return (
    <React.Fragment>
      {!showListing ? (
        <div>
          <h1> Help Menus </h1>
          <ul>
            <li>
              <Button
                id="ra360-manual"
                className={styles.helpMenus}
                name="raManual"
                onClick={(event: any) => handleClick(event)}
              >
                RA360 Manual
              </Button>
            </li>
            {/* <br></br>
            <li>
              <Button
                id="pre-Selected"
                className={styles.helpMenus}
                name="preSelected"
                onClick={(event: any) => handleClick(event)}
              >
                Pre-Selected
              </Button>
            </li> */}
            <br></br>
            <li>
              <Button
                id="standards-Info"
                className={styles.helpMenus}
                name="StandardsInfo"
                onClick={(event: any) => handleClick(event)}
              >
                Advanced Info
              </Button>
            </li>
          </ul>
        </div>
      ) : (
        <div>
          <Grid item xs={12} sm={4} className={styles.FilterAddWrap}>
            <div className={styles.ButtonGroup2}>
              <Button
                id="back-button"
                className={styles.BackToButton}
                variant={"contained"}
                onClick={backToList}
                color="secondary"
                data-testid="cancel-button"
              >
                <img
                  src={process.env.PUBLIC_URL + "/icons/svg-icon/back-list.svg"}
                  alt="user icon"
                />
                &nbsp; Back to List
              </Button>
            </div>
          </Grid>
          {showStandardsLisiting ? (
            <Grid container className={styles.filterContainer}>
              <span style={{ width: "250px" }}>
                <Grid item md={12} xs={12}>
                  <Input
                    required={true}
                    id="standard-id"
                    type="search"
                    name="filterStandards"
                    label="Search Standard Name"
                    variant="outlined"
                    value={filterValue}
                    onChange={handlefilterChange}
                    error
                  />
                </Grid>
              </span>
              <div className={styles.FilterWrap1}>
                <span>
                  <Button id="search" onClick={handleFilterSearch}>
                    Search <SearchIcon />
                  </Button>
                </span>
              </div>
              <div className={styles.FilterWrap2}>
                <span>
                  <Button id="reset" color="secondary" onClick={onClickClear}>
                    RESET <ClearIcon />
                  </Button>
                </span>
              </div>
            </Grid>
          ) : (
            <Grid container className={styles.filterContainer}>
              <div
                className={
                  filterValue === "Financial Firm"
                    ? styles.FilterWrap3
                    : styles.FilterWrap2
                }
              >
                <Button
                  id="Financial-Firm"
                  name="Financial Firm"
                  onClick={handleFirmtypeFilterChange}
                >
                  Financial Firm
                </Button>
              </div>
              <div
                className={
                  filterValue === "Accounting Firm"
                    ? styles.FilterWrap3
                    : styles.FilterWrap2
                }
              >
                <Button
                  id="Accounting-Firm"
                  name="Accounting Firm"
                  onClick={handleFirmtypeFilterChange}
                >
                  Accounting Firm
                </Button>
              </div>
              <div
                className={
                  filterValue === "Health Care Firm"
                    ? styles.FilterWrap3
                    : styles.FilterWrap2
                }
              >
                <Button
                  id="Health-Care-Firm"
                  name="Health Care Firm"
                  onClick={handleFirmtypeFilterChange}
                >
                  Health Care Firm
                </Button>
              </div>
              <div className={styles.FilterWrap2}>
                <span>
                  <Button id="reset" color="secondary" onClick={onClickClear}>
                    RESET <ClearIcon />
                  </Button>
                </span>
              </div>
            </Grid>
          )}
          <div className={styles.tableBox}>
            {noFilterData === false || newFilterData.length !== 0 ? (
              <MaterialTable
                columns={
                  showStandardsLisiting ? StandardsColumn : PreSelectedColumn
                }
                data={newFilterData.length > 0 ? newFilterData : newData}
                pageSize={10}
              />
            ) : (
              <div style={{ textAlign: "center" }}>
                {" "}
                <p style={{ color: "#4b0079" }}>No Standards Found !!</p>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Help;
