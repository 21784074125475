import React, { useState } from "react";
import Pagination from "../Pagination/Pagination";
import styles from "./MaterialTable.module.css";

interface Column {
  key: string;
  title: string;
  render?: (value: any) => React.ReactNode;
  icon?: any;
  tooltip?: string;
  disable?: boolean;
  displayIcon?: (rowData: any) => boolean; // new property to control display of icon
}

interface Props {
  data: any[];
  columns: Column[];
  pageSize: number;
  table?:string;
  onButtonClick?: (rowData: any, columnKey: string) => void;
}

interface SortConfig {
  key: string;
  direction: "ascending" | "descending";
}

const MaterialTable: React.FC<Props> = ({
  data,
  columns,
  pageSize,
  table,
  onButtonClick,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);
  const sortedData = data.sort((a, b) => {
    if (!sortConfig) {
      return 0;
    }
    const { key, direction } = sortConfig;
    const aValue = a[key];
    const bValue = b[key];

    if (aValue < bValue) {
      return direction === "ascending" ? -1 : 1;
    }
    if (aValue > bValue) {
      return direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const pageCount = Math.ceil(sortedData.length / pageSize);
  const displayedData = sortedData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleSort = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key) {
      direction =
        sortConfig.direction === "ascending" ? "descending" : "ascending";
    }
    setSortConfig({ key, direction });
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleButtonClick = (rowData: any, columnKey: string) => {
    if (onButtonClick) {
      onButtonClick(rowData, columnKey);
    }
  };

  const PageSize = 1;
  return (
    <div>
      <table style={{width:"100%"}}>
        <thead style={{ backgroundColor: "#fcfaff", color: "#002F60" }}>
          <tr>
            {columns.map(({ key, title }) => (
              <th style = {(title === "Assessment Name" && table === "Assessment") || title === "Assessment SubType" ? {width :"12em",wordWrap:"break-word"} : {} } key={key} onClick={() => handleSort(key)}>
                {title}
                {sortConfig && sortConfig.key === key && (
                  <span>
                    {sortConfig.direction === "ascending" ? "↑" : "↓"}
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {displayedData.map((rowData, rowIndex) => (
            <tr key={rowData?.id}>
              {columns.map((column, columnIndex) => (
                  <td className={styles.data} key={column.key}>
                    {column.render
                      ? column.render(rowData[column.key])
                      : rowData[column.key]}
                  </td>
              ))}
              <td style={table === "Assessment" ? {width:"25em"} : {}}>
                {columns.map((column, columnIndex) => (
                  <>
                    {column.displayIcon &&
                    !column.displayIcon(rowData) &&
                    column.icon ? (
                      <></>
                    ) : (
                      <button
                        id={column.key}
                        className={`${styles.button} ${column.key === "link" || column.key === "linkExpired" ? styles.linkButton : ''}`}
                        onClick={() => handleButtonClick(rowData, column.key)}
                        title={column.tooltip}
                      > 
                        {typeof column.icon === "function" ? column.icon(rowData) : column.icon}
                      </button>
                    )}
                  </>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ float: "right" }}>
        <Pagination
          currentPage={currentPage}
          totalCount={pageCount}
          pageSize={PageSize}
          onPageChange={(page: any) => handlePageChange(page)}
        />
      </div>
    </div>
  );
};

export default MaterialTable;
