import React, { useState, useEffect } from "react";
import styles from "./PerformanceByLevel.module.css";
import Paper from "@mui/material/Paper";
import { CSET_URL } from "../../../../../config/index";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import logout from "../../../../../containers/Auth/Logout/Logout";
import Doughnut from "react-chartjs-2";
import Grid from "@mui/material/Grid";
import * as Sentry from "@sentry/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Alert from "../../../../../components/UI/Alert/Alert";
import * as msgConstants from "../../../../../common/MessageConstants";
import { sentry_error_catch } from "../../../../../common/sentry_error_catch";

export interface PerformanceByLevelProps {
  propsData?: any;
  props?: any;
}

export const PerformanceByLevel: React.FC<PerformanceByLevelProps> = ({
  propsData,
  props,
}: PerformanceByLevelProps) => {
  const [level1, setLevel1] = useState<any>({});
  const [level2, setLevel2] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const authToken = sessionStorage.getItem("ra_token") || logout();

  const headerObj = {
    "Content-Type": msgConstants.CONTENT_TYPE,
    Authorization: authToken,
  };

  useEffect(() => {
    if (propsData !== undefined) {
      console.log(propsData);
    }
    getStandardSummaryDetails();
  }, []);


  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  
  const handleError = (err:any) => {
    setLoading(false);
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: msgConstants.ERROR_MESSAGE,
    }))
    Sentry.captureException(err);
  };

  const segmentColor = (ans: string) => {
    switch (ans) {
      case "U":
      case "Unanswered":
        return "#CCCCCC";
      case "Y":
      case "Yes":
        return "#28A745";
      case "A":
      case "Alternate":
        return "#B17300";
      case "NA":
      case "Not Applicable":
        return "#007BFF";
      case "N":
      case "No":
        return "#DC3545";
      default:
        return "#000000";
    }
  };

  const options2 = {
    legend: {
      labels: {
        fontStyle: "bold",
        fontSize: 18,
        fontColor: "black",
      },
    },
  };

  const getStandardSummaryDetails = async () => {
    try {
      setLoading(true);
      await fetch(`${CSET_URL}api/results/compliancebylevel`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((compliancebylevel: any) => {
          setLoading(false);
          if (compliancebylevel.length > 0) {
            if (compliancebylevel[0] !== undefined) {
              const pieData1 = compliancebylevel[0].answerDistribution.find(
                (a: any) => a.value === "Y"
              );
              compliancebylevel[0].compliancePercent = !!pieData1
                ? pieData1.percent.toFixed(2)
                : 0;
              compliancebylevel[0].nonCompliancePercent =
                100 - compliancebylevel[0].compliancePercent;
              // build the data object for Chart
              const level1Data: any = {};
              level1Data.label = "";
              level1Data.labels = [];
              level1Data.data = [];
              level1Data.colors = [];
              compliancebylevel[0].answerDistribution.forEach(
                (element: any) => {
                  level1Data.data.push(element.percent);
                  level1Data.labels.push(
                    `${element.value}:${element.percent.toFixed(1)}%`
                  );
                  level1Data.colors.push(segmentColor(element.value));
                }
              );
              setLevel1({
                labels: level1Data.labels,
                datasets: [
                  {
                    data: level1Data.data,
                    backgroundColor: level1Data.colors,
                    borderColor: level1Data.colors,
                    borderWidth: 1,
                  },
                ],
              });
            }
            if (compliancebylevel[1] !== undefined) {
              const pieData2 = compliancebylevel[1].answerDistribution.find(
                (a: any) => a.value === "Y"
              );
              compliancebylevel[1].compliancePercent = !!pieData2
                ? pieData2.percent.toFixed(2)
                : 0;
              compliancebylevel[1].nonCompliancePercent =
                100 - compliancebylevel[1].compliancePercent;
              // build the compliancebylevel object for Chart
              const level2Data: any = {};
              level2Data.label = "";
              level2Data.labels = [];
              level2Data.data = [];
              level2Data.colors = [];
              compliancebylevel[1].answerDistribution.forEach(
                (element: any) => {
                  level2Data.data.push(element.percent);
                  level2Data.labels.push(
                    `${element.value}:${element.percent.toFixed(1)}%`
                  );
                  level2Data.colors.push(segmentColor(element.value));
                }
              );
              setLevel2({
                labels: level2Data.labels,
                datasets: [
                  {
                    data: level2Data.data,
                    backgroundColor: level2Data.colors,
                    borderColor: level2Data.colors,
                    borderWidth: 1,
                  },
                ],
              });
            }
          }
        }).catch((err:any) => {
          sentry_error_catch(err,setLoading,setFormState)
        });
    } catch (e) {
      setLoading(false);
      handleError(e);
      console.error("error", e);
    }
  };

  if (loading) {
    return <SimpleBackdrop />;
  }

  return (
    <React.Fragment>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formState.errMessage}
        </Alert>
      ) : null}
      <Paper className={styles.paper}>
        <h3 className={styles.tabSummaryHeader}>
          {" "}
          This page shows the Performace by level.{" "}
        </h3>
        <div className="App">
          <Grid container spacing={4} className={styles.justifyCenter}>
            {Object.keys(level1).length > 0 && level1?.datasets.length > 0 ? (
              <Grid item xs={12} md={4}>
                <h3 className={styles.doughnutHeader}>Level 1</h3>
                <Paper className={styles.paper}>
                  <Doughnut data={level1} options={options2} />
                </Paper>
              </Grid>
            ) : null}
            {Object.keys(level2).length > 0 && level2?.datasets.length > 0 ? (
              <Grid item xs={12} md={4}>
                <h3 className={styles.doughnutHeader}>Level 2</h3>
                <Paper className={styles.paper}>
                  <Doughnut data={level2} options={options2} />
                </Paper>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </Paper>
    </React.Fragment>
  );
};
export default PerformanceByLevel;
