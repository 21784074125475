
const envVariables = process.env;
export const RA_URI = envVariables.REACT_APP_GRAPHQL_RA_API;
export const URI = envVariables.REACT_APP_GRAPHQL_API;
export const RA_URL = envVariables.REACT_APP_CLIENT_URL;
export const RA_SERVER = envVariables.REACT_APP_SERVER_URL;
export const SENTRY_DSN = envVariables.REACT_APP_SENTRY_DSN;
export const RA_REPORT_DOWNLOAD = envVariables.REACT_APP_REPORT_DOWNLOAD;
export const CSET_URL = envVariables.REACT_APP_CSET_URL
export const CSET_URL_V2 = envVariables.REACT_APP_CSET_URLV2
export const CSET_URL_V3 = envVariables.REACT_APP_CSET_URLV3
export const REACT_APP_PG_URL = envVariables.REACT_APP_PG_URL
export const PG_CHECK_SCAN_COUNT_API_KEY : string = envVariables.REACT_APP_PG_CHECK_SCAN_COUNT_API_KEY?envVariables.REACT_APP_PG_CHECK_SCAN_COUNT_API_KEY:""
export const PG_DEDUCT_SCAN_COUNT_API_KEY : string = envVariables.REACT_APP_PG_DEDUCT_SCAN_COUNT_API_KEY?envVariables.REACT_APP_PG_DEDUCT_SCAN_COUNT_API_KEY:""
export const CSET_USER_PASS = envVariables.REACT_APP_CSET_USER_PASS
export const PG_PARTNER_API_KEY = envVariables.REACT_APP_PG_PARTNER_API_KEY
export const PG_USER_API_KEY = envVariables.REACT_APP_PG_USER_API_KEY
export const PG_CLIENT_API_KEY = envVariables.REACT_APP_PG_CLIENT_API
export const RESET_CSET_USER_PASS = envVariables.REACT_APP_RESET_CSET_USER_PASS
export const CSET_USER_OLD_PASS = envVariables.REACT_APP_CSET_USER_OLD_PASS
export const OR_PROJECT_KEY:any = envVariables.REACT_APP_OPENREPLAY_PROJECT_KEY
export const OR_INGESTPOINT:any = envVariables.REACT_APP_OPENREPLAY_INGESTPOINT
export const OR_ENABLE:any = envVariables.REACT_APP_OPENREPLAY_ENABLE === 'true'
export const PARTNER_SUPORT_EMAIL = envVariables.REACT_APP_PARTNER_SUPPORT_EMAIL