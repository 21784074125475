import React from "react";
import { TextField } from "@mui/material";
import styles from "./Input.module.css";

const Input = (props: any) => {
  const { required, autoFocus, variant, error, className, ...rest } = props;

  return (
    <TextField
      id="outlined-basic" 
      InputLabelProps={{
        className:styles.label,
        styles: { color: "#a6a6a6" },
      }}
      required={required || false}
      autoFocus={autoFocus || false}
      variant="outlined"
      fullWidth
      error={error || false}
      {...rest}
      className={className || styles.ReactInput}
    />
  );
};

export default Input;
