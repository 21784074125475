import React, { useEffect, useState } from "react";
import * as msgConstants from "../../../common/MessageConstants";
import * as routeConstants from "../../../common/RouteConstants"
import classes from "./User.module.css";
import { useLocation,useNavigate } from "react-router-dom";
import { Button } from "../../../components/UI/Form/Button/Button";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import { useLazyQuery,useMutation } from '@apollo/client';
import Input from "../../../components/UI/Form/Input/Input";
import ClearIcon from "@mui/icons-material//Clear";
import SearchIcon from "@mui/icons-material/Search";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Alert from "../../../components/UI/Alert/Alert";
import { ADMIN_GET_USER_DELETE_DATA } from '../../../graphql/queries/AdminUI';
import { DELETE_DATA } from '../../../graphql/mutations/Delete';
import { sentry_error_catch } from "../../../common/sentry_error_catch";

const User = (props: any) => {
  const [showBackDrop, setShowBackDrop] = useState(true);
  const timeFormat = "MM/DD/YYYY hh:mm a";
  const moment = require("moment-timezone");
  const [newData, setNewData] = useState<any>([])
  const deleteIcon = "/icons/svg-icon/delete.svg";
  const [partnerUserFilter,setPartnerUserFilter] = useState<any>("");
  const [searchData,setSearchData] = useState<any>([]);
  const location: any = useLocation();
  const [openDeletePartnerUserDialogBox,setOpenDeletePartnerUserDialogBox] = useState<any>(false);
  const [rowData,setRowData] = useState<any>({});
  const [formState, setFormState] = useState({
    isDelete: false,
    isSuccess: false,
    isFailed: false,
    errMessage: "",
  })
  const history = useNavigate();

  // query for Getting users of particular partner
  const [partnerUserDelete] = useMutation(DELETE_DATA);
  const [getDeleteUsers] = useLazyQuery(ADMIN_GET_USER_DELETE_DATA, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (data) => {
      setShowBackDrop(false);
      createTableDataObject(data.individuals);
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  })
  // runs after every initial render. 
  useEffect(() => {
    console.log("location",location.state)
    getDeleteUsers({
      variables: {
        where: { active_status: 3 },
        limit: 1000,
        sort: "id:desc"
      }
    })
  }, [])

  // column array for display table  
  const column = [
    { title: "Partner Name", key: "partner_name" },
    { title: "User Name", key: "user_name" },
    { title: "Email", key: "email" },
    { title: "Created on", key: "created_at" },
    { title: "Pg User Id", key: "pg_user_id" },
    {
      icon: ( <img
        className={classes.EditIcon}
        src={process.env.PUBLIC_URL + deleteIcon}
        alt="delete icon"
      />
      ),
      tooltip: "Delete Partner User",
      key: "deletepartneruser",
      title: "",
      displayIcon: (rowData: any) => true,
    }
  ]

  // function for data to be displayed.
  function createTableDataObject(individualData: any) {
    const arr: any = [];
    if (individualData !== 0) {
      individualData.forEach((element: any) => {
        const obj: any = {}
        obj['individual_id'] = element.id ? element.id : " ";
        obj['first_name'] = element.first_name ? element.first_name : " ";
        obj['last_name'] = element.last_name ? element.last_name : " ";
        obj["user_name"] = `${obj['first_name']} ${obj['last_name']}`;
        obj['email'] = element.contact_id.email ? element.contact_id.email : " ";
        obj['created_at'] = element.contact_id.created_at ? moment(element.contact_id.created_at).tz("US/Eastern").format(
          timeFormat
        ) : " ";
        obj['partner_name'] = element.partner_id.name ? element.partner_id.name : " ";
        obj['user_contactId'] = element.contact_id.id ? element.contact_id.id : " ";
        obj['user_partnerId'] = element.partner_id.id ? element.partner_id.id : " ";
        obj['pg_user_id'] = element.pg_user_id? element.pg_user_id : " ";
        arr.push(obj);
      })
    }
    setNewData(arr);
    setSearchData(arr)
  }

  const enableBackdrop = () => {
    if (showBackDrop) {
      return true;
    } else {
      return false;
    }
  }
  const onRowClick:any = (rowData: any,val: any) => {
    console.log("Hii",val,rowData)
    if (val === 'deletepartneruser') {
      setOpenDeletePartnerUserDialogBox(true);
      setRowData(rowData);
    }
  }
  const backToList = () => {
    history(routeConstants.ADMIN_DASHBOARD);
  }
  const handlePartnerFilterChange = (event:any) => {
    setPartnerUserFilter(event.target.value)
   }
   const searchResetHandler = (event:any) => {
    setPartnerUserFilter("")
     setSearchData(newData)
   }
   const searchHandler = (event:any) => {
     // console.log("event",event)
    if(partnerUserFilter.trim().length > 0){
     console.log("event",event)
       const search:any = []
       newData.forEach((element:any) => {
         console.log("element",element)
         if(element["user_name"].includes(partnerUserFilter)){
           search.push(element)
         }
       });
       setSearchData(search)
    }else{
     console.log("event",event)
     console.log(true)
    }
   }
   const confirmDelete = () => {
    console.log("rowData",rowData)
    setOpenDeletePartnerUserDialogBox(false)
    setShowBackDrop(true);
    if(rowData && rowData.pg_user_id){
      console.log("rowData",rowData.pg_user_id)
      partnerUserDelete({
        variables: {
          id:parseInt(rowData.pg_user_id),
          type: "PartnerUser",
        }
      }).then((data:any) => {
        setShowBackDrop(false);
        if(data.data.DeleteContactData.message === "Deleted Successfully!"){
          setFormState((formState) => ({
            ...formState,
            isDelete: false,
            isSuccess: true,
            isFailed: false,
            errMessage: "Partner User has been Deleted Successfully!"
          }))
          getDeleteUsers({
            variables: {
              where: { active_status: 3 },
              limit: 1000,
              sort: "id:desc"
            }
          })
        }else{
          setFormState((formState) => ({
            ...formState,
            isDelete: false,
            isSuccess: false,
            isFailed: true,
            errMessage: msgConstants.ERROR_MESSAGE
          }))
        }
      }).catch((err:any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState)
      })
    }
   }
   const closeDialogBox = () => {
    setOpenDeletePartnerUserDialogBox(false)
    setRowData({});
   }
   const handleAlertClose = () => {
     setFormState((formState) => ({
       ...formState,
       isDelete: false,
       isSuccess: false,
       isFailed: false,
       errMessage: ""
     }))
    }

  return <React.Fragment>
    <CssBaseline />
    {showBackDrop ? <SimpleBackdrop /> : null}
    <DialogBox
      open={openDeletePartnerUserDialogBox}
      handleOk={confirmDelete}
      handleCancel={closeDialogBox}
      buttonOk={"Yes"}
      buttonCancel={"No"}
      classes={{
        root: classes.MainOfficeDialogRoot,
        container: classes.MainOfficeDialogboxContainer,
        paper: classes.MainOfficeDialogboxPaper,
        scrollPaper: classes.MainOfficeScrollPaper,
      }}
    >
      <div className={classes.DialogBoxTitle}>
        <Typography component="h1" variant="h1">
          Please Confirm
        </Typography>
      </div>
      <div className={classes.DialogBoxContext}>
        <p>Are you sure you want to Delete <b>{rowData.user_name}</b> User ?</p>
      </div>
    </DialogBox>
    <Typography component="h5" variant="h1">
      Delete Partner Users List
    </Typography>
    <Grid container>
      <Grid item xs={12} sm={12}>
        {formState.isSuccess ? (
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <strong>{formState.errMessage}</strong>
          </Alert>
        ) : null}
        {formState.isFailed ? (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <strong>{formState.errMessage}</strong>
          </Alert>
        ) : null}
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={12} sm={8} className={classes.FilterWrap}>
      <span style={{ width: "250px" }}>
        <Grid item md={12} xs={12}>
          <Input
            required={true}
            id="user-id"
            type="search"
            name="filterUser"
            label="Search User Name"
            variant="outlined"
            value={partnerUserFilter}
            onChange={handlePartnerFilterChange}
            error
          />
        </Grid>
        </span>
        <div className={classes.FilterWrap1}>
          <span>
            <Button id="search" onClick={searchHandler}>
              Search <SearchIcon />
            </Button>
          </span>
        </div>
        <div className={classes.FilterWrap2}>
          <span>
            <Button id="reset" color="secondary" onClick={searchResetHandler}>
              RESET <ClearIcon />
            </Button>
          </span>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.FilterAddWrap}>
        <div className={classes.ButtonGroup2}>
          <Button
            id="cancel-button"
            className={classes.BackToButton}
            variant={"contained"}
            onClick={backToList}
            color="secondary"
          >
            <img
              src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
              alt="user icon"
            />
            &nbsp; Back to List
          </Button>
        </div>
      </Grid>
    </Grid>
    <Grid>
      <Paper className={classes.paper}>
        <div className={classes.ScrollTable}>
          {searchData.length !== 0 ? (
            <MaterialTable
              data={searchData}
              columns={column}
              pageSize={10}
              onButtonClick={onRowClick}
            />
          ) : !enableBackdrop() ? (
            <Typography component="h5" variant="h3">
              You don't have any user subscribed for RiskAssessment360
            </Typography>
          ) : null}
        </div>
      </Paper>
    </Grid>
  </React.Fragment>
}

export default User;

