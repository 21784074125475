import * as Sentry from "@sentry/react";
import { ERROR_MESSAGE } from "./MessageConstants";

export const sentry_error_catch = (err:any,setBackdrop:any,setFormState:any,err_msg?:string)=> {
    console.log("Unexcepted error",err)
    
    if(setBackdrop){
      setBackdrop(false)
    }
    Sentry.captureException(err)
    setFormState((formState:any) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: true,
      errMessage: err_msg || ERROR_MESSAGE,
    }));
  }