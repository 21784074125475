import React, { useEffect, useState, useRef } from "react";
import { Grid, Paper, Typography, IconButton } from "@mui/material";
import styles from "./ConfigurationandChangeManagement.module.css";
import { CSET_URL } from "../../../../../config";
import logout from "../../../../Auth/Logout/Logout";
import SimpleBackdrop from "../../../../../components/UI/Layout/Backdrop/Backdrop";
import CssBaseline from "@mui/material/CssBaseline";
import DOMPurify from "dompurify";
import * as msgConstants from "../../../../../common/MessageConstants";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../../../../components/UI/Alert/Alert";
export interface ConfigurationandChangeManagementProps {
  data?: any;
  props?: any;
  configurationandChangeRef?: any;
  generateDataURL?: any;
  getConfigurationandChangeData?: (data: any) => void;
  handleError?: (err :any) => void;
  handleChildCompError?: (err: any) => void;
}
export const ConfigurationandChangeManagement: React.FC<
  ConfigurationandChangeManagementProps
> = ({
  data,
  props,
  generateDataURL,
  configurationandChangeRef,
  getConfigurationandChangeData = () => {},
  handleError = () =>{},
  handleChildCompError = () => {}
}: ConfigurationandChangeManagementProps) => {
  const [showBackDrop, setShowBackDrop] = useState(true);
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const [configurationManagement, SetconfigurationManagement] = useState<any>(
    {}
  );
  const configurationManagementSVGRef = useRef<any>(null);
  const [milData, setMilData] = useState<any>(null);
  const [milData2, setMilData2] = useState<any>(null);
  const [milData3, setMilData3] = useState<any>(null);
  const [milData4, setMilData4] = useState<any>(null);
  const [milData5, setMilData5] = useState<any>(null);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const headerObj = {
    "Content-Type": msgConstants.CONTENT_TYPE,
    Authorization: authToken,
  };

  useEffect(() => {
    const controller = new AbortController();
    getConfigurationManagemnetData();
    getMilHeatMapData();
    if (props === false) {
      setShowBackDrop(false);
    }
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (
      Object.keys(configurationManagement).length > 0 &&
      milData &&
      milData2 &&
      milData3 &&
      milData4 &&
      milData5
    ) {
      setShowBackDrop(false);
    }
  }, [milData && milData2 && milData3 && milData4 && milData5]);

  useEffect(() => {
    if (configurationManagement) {
      passDatatoReportFunc();
    }
  }, [configurationManagement]);

  const passDatatoReportFunc = async () => {
    await getConfigurationandChangeData({
      configurationManagement,
    });
  };

  const getConfigurationManagemnetData = async () => {
    try{
      const response = await fetch(
      `${CSET_URL}api/MaturityStructure?domainAbbrev=CCM`,
      {
        method: "GET",
        headers: headerObj,
      }
    );
    const data = await response.json();
    SetconfigurationManagement(data.Domain);
  }catch(err:any){
    setShowBackDrop(false)
    handleError(err)
    handleChildCompError(err)
  }
  };

  /**
   * Convert String to html Response
   * @param html
   */
  const createDangerousHTML = (html: string) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const getMilHeatMapData = async () => {
    await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=CCM&mil=MIL-1&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response: any) => response.text())
      .then((data: any) => {
        setMilData(data);
      }).catch((err: any) => {
        setShowBackDrop(false)
        handleError(err)
        handleChildCompError(err)
      });

    await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=CCM&mil=MIL-2&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response2: any) => response2.text())
      .then((data2: any) => {
        setMilData2(data2);
      }).catch((err: any) => {
        setShowBackDrop(false)
        handleError(err)
        handleChildCompError(err)
      });

    await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=CCM&mil=MIL-3&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response3: any) => response3.text())
      .then((data3: any) => {
        setMilData3(data3);
      }).catch((err: any) => {
        setShowBackDrop(false)
        handleError(err)
        handleChildCompError(err)
      });

    await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=CCM&mil=MIL-4&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response4: any) => response4.text())
      .then((data4: any) => {
        setMilData4(data4);
      }).catch((err: any) => {
        setShowBackDrop(false)
        handleError(err)
        handleChildCompError(err)
      });

    await fetch(
      `${CSET_URL}api/reportscrr/widget/milheatmap?domain=CCM&mil=MIL-5&scale=1.5`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response5: any) => response5.text())
      .then((data5: any) => {
        setMilData5(data5);
      }).catch((err: any) => {
        setShowBackDrop(false)
        handleError(err)
        handleChildCompError(err)
      });
  };

  /**
   * Actually, "non-child questions"
   * @param q
   */
  const parentQuestions = (q: any): any[] => {
    // q might be a single question or might be an array of questions
    let questions: any = [];
    if (q instanceof Array) {
      questions = q;
    } else {
      questions = [].concat(q);
    }

    return questions.filter((x: any) => !x.parentquestionid);
  };

  /**
   * Until question numbers are broken out into their own data element,
   * we'll parse them from the question text.
   * @param q
   */

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const getQuestionNumber = (q: any): string => {
    const dot = q.questiontext.trim().indexOf(".");
    if (dot < 0) {
      return "Q";
    }
    return "Q" + q.questiontext.trim().substring(0, dot);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {showBackDrop ? <SimpleBackdrop /> : null}
      <Typography
        component="h5"
        variant="h1"
        style={{ padding: "0px", fontWeight: 600 }}>
        Configuration and Change Management (CCM)
      </Typography>
      {formState.isFailed ? (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleAlertClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {formState.errMessage}
        </Alert>
      ) : null}
      <Paper className={styles.paper}>
        <Grid container>
          {milData !== null &&
          milData2 !== null &&
          milData3 !== null &&
          milData4 !== null &&
          milData5 !== null ? (
            <div ref={configurationManagementSVGRef}>
              <Grid item xs={12} className={styles.QuestionViewMIL}>
                <div dangerouslySetInnerHTML={createDangerousHTML(milData)} />
                <div dangerouslySetInnerHTML={createDangerousHTML(milData2)} />
                <div dangerouslySetInnerHTML={createDangerousHTML(milData3)} />
                <div dangerouslySetInnerHTML={createDangerousHTML(milData4)} />
                <div dangerouslySetInnerHTML={createDangerousHTML(milData5)} />
              </Grid>
            </div>
          ) : (
            <></>
          )}
          <Grid item xs={12} md={12} sm={12} className={styles.FooterActions}>
            <h4
              style={{ padding: "12px 8px", margin: "0px", textAlign: "left" }}>
              The purpose of Configuration and Change Management is to establish
              processes to ensure the integrity of assets using change control
              and change control audits.{" "}
            </h4>
            <div className={styles.securityPlanTable}>
              {configurationManagement.Goal
                ? configurationManagement?.Goal.map(
                    (data: any) => {
                      return (
                        <table
                          id="secondTable"
                          key={data?.groupingid}
                          className={styles.tableStyle}>
                          <tr>
                            <td colSpan={3}> {data.title} </td>
                          </tr>
                          {data.Question.map(
                            (Quesdata: any) => {
                              return (
                                  <tr key={Quesdata?.questionid}>
                                    <td colSpan={2}>
                                      {" "}
                                      {Quesdata.questiontext}{" "}
                                    </td>
                                    <td colSpan={1}>
                                      {Quesdata.isparentquestion === "true"
                                        ? " "
                                        : Quesdata.answer === "Y"
                                        ? "Yes"
                                        : Quesdata.answer === "N"
                                        ? "No"
                                        : Quesdata.answer === "NA"
                                        ? "NA"
                                        : "Unanswered"}{" "}
                                    </td>
                                  </tr>
                              );
                            }
                          )}
                          <tr>
                            <td colSpan={3}> Option(s) for Consideration </td>
                          </tr>
                          {parentQuestions(data.Question).map(
                            (refData: any) => {
                              return (
                                <tr key={refData?.questionid}>
                                    <td colSpan={1}>
                                      {getQuestionNumber(refData)}
                                    </td>
                                    <td
                                      colSpan={1}
                                      dangerouslySetInnerHTML={createDangerousHTML(
                                        refData?.referencetext
                                          ?.replaceAll(
                                            '<font color="#000000" size="3">',
                                            ""
                                          )
                                          .replaceAll(
                                            '<font color="#000000">',
                                            ""
                                          )
                                          .replaceAll('<font size="3">', "")
                                      )}></td>
                                    <td colSpan={1}> </td>
                                </tr>
                              );
                            }
                          )}
                        </table>
                      );
                    }
                  )
                : null}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default ConfigurationandChangeManagement;
